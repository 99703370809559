
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  InputAdornment, Stack,
  Typography
} from "@mui/material";
import FormProvider from "../../form/form-provider";
import {useForm} from "react-hook-form";
import styles from './styles.module.scss';
import React, {useState} from "react";
import Iconify from "../../iconify";
import InputSelectBase from "../../input/inputSelect";
import {defaultOption} from "../../../constants";
import {errorSnackbar} from "../../../commons/snackbar";
import {AdminAssignCompany} from "../../../redux/actions/admin";
import {useDispatch} from "react-redux";

const DialogAddCompanyToTrash = (props: any) => {
  const {
    openDialogAddCompanyToTrash,
    setOpenDialogAddCompanyToTrash,
    companyOptions,
    dataTrashCanIdCheckBox,
    actionNext,
  } = props;
  const [companyId, setCompanyId] = useState("");
  const dispatch = useDispatch();


  const defaultValues = {
    trashcan_ids: '',
    company_id: '',
  }

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async () => {
    if (!companyId) {
      errorSnackbar('連絡先会社を選択してください。');
      return;
    }
    const formData = {
      trashcan_ids: dataTrashCanIdCheckBox,
      company_id: companyId,
    }

    const result = await dispatch(AdminAssignCompany(formData) as any);
    if (result) {
      handleCloseModal()
      actionNext();
    }
  });

  const handleCloseModal = () => {
    setOpenDialogAddCompanyToTrash(false);
    setCompanyId("")
  };

  const handleInputChange = (key: any, value: any) => {
    setCompanyId(value);
  };

  return (
    <Dialog open={openDialogAddCompanyToTrash} fullWidth maxWidth="sm">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className={styles.formDialog} sx={{ py: 2, px: 3 }}>
          <DialogTitle className={styles.dialogTitleAddCompany}>割り当て</DialogTitle>
          <DialogContent>
            <Box className={`mb-3`}>
              <Typography className={styles.titleAddCompany}>選択されたゴミ箱に収集スタッフを割り当てます。</Typography>
            </Box>
            <Grid container spacing={2} className={`mb-3`}>
              <Grid item xs={12}>
                <Box className={styles.titleName}>担当者</Box>
                <InputSelectBase
                  name="company_id"
                  keyword="company_id"
                  type="text"
                  className={styles.inputCompany}
                  handleChange={handleInputChange}
                  value={companyId ?? ''}
                  size="small"
                  placeholder="会社"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="inputAdornment" sx={{ padding: '8px', marginRight: 0 }}>
                        <Iconify icon="" width={20} />
                      </InputAdornment>
                    ),
                  }}
                  options={companyOptions}
                  defaultOption={defaultOption}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" columnGap={2} sx={{ px: 2 }}>
              <Button className={styles.btnCloseModal} onClick={handleCloseModal}>
                キャンセル
              </Button>
              <Button type="submit" className={styles.btnSave}>
                <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                <Typography variant="subtitle1">追加</Typography>
              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
}
export default DialogAddCompanyToTrash;
