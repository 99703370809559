/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Container,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import HeaderAdmin from "../../../../components/header/admin/header";
import styles from "./../styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  assignTrashToCollector,
  getCollectorDetail,
} from "../../../../redux/actions/collector";
import avatarDefault from "../../../../../src/assets/images/default.png";
import Iconify from "../../../../components/iconify";
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  useTable,
} from "../../../../components/table";
import { getListTrashByAdmin } from "../../../../redux/actions/trashcan";
import CustomPagination from "../../../../components/pagination";
import AssignCollectorTableRow from "./table-row";
import statusCode from "../../../../constants/statusCode";
import paths from "../../../../constants/paths";
import { DEFAULT_LENGTH_TEXT_30, ITEMS_PER_PAGE, MEDIUM_WIDTH_SCREEN } from "../../../../constants";
import { TableRowSkeleton } from "../../../../components/skeleton/table-row";
import { getClientWidth, truncateText } from "../../../../helpers";
import BreadcrumbsComponent from "../../../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import pageTitles from "../../../../constants/pageTitles";

export default function AdminAssignCollector() {
  const [currentPage, setCurrentPage] = useState(1);
  const [isHandleCancel, setIsHandleCancel] = useState<boolean>(false);
  const [trashCanIds, setTrashCanIds] = useState<string[]>([]);
  const [loading, setLoading] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataCollector = useSelector((state: any) => state.collector.dataCollectorDetail?.data);
  const dataTrashCans = useSelector((state: any) => state.trash.dataListTrashByAdmin?.data);
  const paginate = useSelector((state: any) => state.trash.dataListTrashByAdmin?.paginate);

  const TABLE_HEAD = [
    { id: "id", label: (<Box className={styles.textCommon}>No.</Box>), width: 80 },
    { id: "trashcan_name", label: (<Box className={styles.textCommon}>ゴミ箱</Box>), width: 240 },
    { id: "group_name", label: (<Box className={styles.textCommon}>グループ</Box>), width: 240 },
    { id: "level", label: (<Box className={styles.textCommon}>ステータス</Box>), width: 240 },
    { id: "user", label: (<Box className={styles.textCommon}>担当者</Box>), width: 250 },
    { id: "assignment", label: (<Box className={styles.textCommon}>割り当て</Box>), width: 100, align: "center" },
  ];

  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "収集スタッフ一覧", href: paths.admin.collectorList },
  ];

  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 1,
  });

  const buildQuery = () => {
    const query: any = {
      pages: currentPage,
      collector_id: id,
    };
    return query;
  };

  const { id } = useParams();
  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getCollectorDetail(id as any) as any);
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getListTrashByAdmin(buildQuery()) as any).then((result: any) => {
        setLoading(false);
      });
    }
  }, [currentPage]);

  const notFound = !dataTrashCans?.length;

  const handleCancelSelected = () => {
    setIsHandleCancel(!isHandleCancel);
    setTrashCanIds([]);
  };

  const handleValueChange = (id: string) => {
    const ids: string[] = trashCanIds;
    const idIndex = ids.indexOf(id);
    if (idIndex !== -1) {
      ids.splice(idIndex, 1);
    } else {
      ids.push(id);
    }
    setTrashCanIds([...ids]);
  };

  const handleSubmit = async () => {
    if (id && trashCanIds.length > 0) {
      const result = await dispatch(assignTrashToCollector({ trashcan_ids: trashCanIds }, id) as any);
      if (result.code === statusCode.OK) navigate(`${paths.admin.collectorDetail}/${id}`);
    }
  };

  const handleSetPage = (value: number) => {
    setCurrentPage(value);
    setTrashCanIds([]);
  };

  const renderSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD.length} />
      ))}
    </>
  );

  const renderList = (
    <>
      {dataTrashCans?.map((row: any, index: any) => (
        <AssignCollectorTableRow
          key={row.trashcan_id}
          row={row}
          index={(currentPage - 1) * 10 + index + 1}
          isCancel={isHandleCancel}
          onValueChange={handleValueChange}
        />
      ))}
    </>
  );

  return (
    <>
      <Helmet>
        <title>{pageTitles.admin.assignCollector}</title>
      </Helmet>
      <HeaderAdmin />
      <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
        <Card
          sx={{ py: 1 }}
          className={styles.cardContainer}>
          <BreadcrumbsComponent items={breadcrumbItems} currentLabel={dataCollector?.full_name} />
        </Card>
        <Box
          className="h-100 mt-4 px-0">
          <Stack direction="row" justifyContent="space-between">
            <Stack direction="row" alignItems="center">
              <Box sx={{ marginRight: "32px" }}>
                <img
                  className={styles.bigAvatar}
                  src={dataCollector?.avatar ? dataCollector?.avatar : avatarDefault}
                  alt="avatar"
                />
              </Box>
              <Box>
                <Box className={styles.centerFlex}>
                  <Box className={`${styles.textTitle} ${styles.longText2}`}>
                    {truncateText(dataCollector?.full_name, DEFAULT_LENGTH_TEXT_30)}
                  </Box>
                  <Typography className={`ps-3 ${styles.textCommon}`}>
                    {dataCollector?.is_active === 1 ? (
                      <span className={styles.btnActive}>アクティブ</span>
                    ) : (
                      <span className={styles.btnInActive}>無効</span>
                    )}
                  </Typography>
                </Box>
                <Box className={styles.textSubtitle} sx={{ mt: 1 }}>
                  {truncateText(dataCollector?.company?.name, 30)}
                </Box>
              </Box>
            </Stack>
            <Stack direction="row" gap="10px" alignItems="start">
              <Button
                className={styles.btnBack}
                onClick={handleCancelSelected}
                disabled={trashCanIds.length === 0}>
                <Typography
                  variant="subtitle1"
                  className={`${styles.btnText} ${styles.textCommon}`}>
                  キャンセル
                </Typography>
              </Button>
              <Button
                className={styles.btnAdd}
                onClick={handleSubmit}
                disabled={trashCanIds.length === 0}>
                <Iconify
                  icon="material-symbols:check"
                  width={24}
                  sx={{ mr: 1 }} />
                <Typography
                  variant="subtitle1"
                  className={`${styles.btnText} ${styles.textCommon}`}>
                  保存
                </Typography>
              </Button>
            </Stack>
          </Stack>
        </Box>
        <TableContainer className={styles.tableContainer}>
          <Table size="small" sx={{ minWidth: 960 }}>
            <TableHeadCustom
              className={styles.borderTableHeader}
              headLabel={TABLE_HEAD}
              rowCount={dataTrashCans?.length}
              numSelected={table.selected?.length} />

            <TableBody className={styles.borderTable}>
              {loading ? renderSkeleton : renderList}
              {dataTrashCans && !loading && (
                <>
                  <TableEmptyRows
                    height={56}
                    emptyRows={emptyRows(
                      table.page - 1,
                      table.rowsPerPage,
                      dataTrashCans.length
                    )} />
                  <TableNoData notFound={notFound} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataTrashCans && paginate?.total_page > 1 && (
          <CustomPagination
            paginate={paginate}
            page={currentPage}
            setPage={handleSetPage}
            disabled={paginate?.total_page === 1} />
        )}
      </Container>
    </>
  );
}
