export const adminPrimaryColor = "#0B6BCB";
export const superAdminPrimaryColor = "#1F7A1F";
export const primaryFont = 'Noto Sans JP';
export const placeholderColor = "#555E68";
export const paginateBorderColor = "#F0F4F8";
export const borderColor = "#CDD7E1";
export const snackbarColor = {
  successColor: '#0A470A',
  successBgColor: '#E3FBE3',
  successborderColor: '#E3FBE3',
  errorColor: '#C41C1C',
  errorBgColor: 'white',
  errorborderColor: '#F09898',
  warningColor: '#EA9A3E',
  warningBgColor: '#F09898',
};
export const spaTabsBarColor = {
  activeBorderColor: '#1F7A1F',
  activeBgColor: '#E3FBE3',
  activeContentColor: '#1F7A1F',
  inactiveBorderColor: '#CDD7E1',
  inactiveContentColor: '#32383E',
  bgColor: '#FBFCFE'
}

export const adminTabsBarColor = {
  activeBorderColor: '#1F7A1F',
  activeBgColor: '#E3FBE3',
  activeContentColor: '#1F7A1F',
  inactiveBorderColor: '#CDD7E1',
  inactiveContentColor: '#32383E',
}

export const spaPrimaryColor = '#1F7A1F'
