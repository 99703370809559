import {
  Chip,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./../styles.module.scss";
import { getTrashCanStyleBySuperAdmin } from "../../../../helpers";

type Props = {
  row: any;
  index: any;
};

export default function TrashcansOfCompany({ row, index }: Props) {
  const { trashcan_name, level } = row;
  const chipStyle = getTrashCanStyleBySuperAdmin(level);
  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.longText2}`}>
          {trashcan_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          グループ #1
        </Typography>
      </TableCell>
      <TableCell>
        <Chip size="small" label={chipStyle.label}
          sx={{ background: chipStyle.background, color: "white", fontWeight: "bold", fontFamily: chipStyle.font }}
        />
      </TableCell>
    </TableRow>
  );
}
