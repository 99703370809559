import { doAction } from ".";
import { baseAxios } from "../../commons/axiosBase";
import { REQUEST_METHOD } from "../../constants";
import * as t from "../constants/index";

export const getByPostCode = (params: { postal_code: string }) => async (dispatch: any) => {
  const { postal_code } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/postal-code`,
    method: REQUEST_METHOD.GET,
    params: {
      postal_code,
    },
  });

  try {
    const apiResponse = await dispatch(doAction({
      action: callApi
    }));

    if (apiResponse?.data) {
      dispatch({
        type: t.DATA_POSTAL_CODE,
        payload: apiResponse.data,
      });
      dispatch({
        type: t.ERROR,
        payload: null,
      });
    }

    return apiResponse;
  } catch (error) {
    dispatch({
      type: t.ERROR,
      payload: 'Invalid postal code.',
    });
    return null;
  }
};
