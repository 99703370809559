import { APIProvider, Map, Marker, useMap } from "@vis.gl/react-google-maps";
import { Box, Skeleton } from "@mui/material";
import { useEffect } from "react";
import level1 from "../../../assets/images/level1.png";
import level2 from "../../../assets/images/level2.png";
import level3 from "../../../assets/images/level3.png";
import { HIGH_LEVEL_TRASH_CAN, LAT_DEFAULT, LONG_DEFAULT, MEDIUM_LEVEL_TRASH_CAN } from "../../../constants";

type CustomMapProps = {
  markerLocation: { lat: number, lng: number },
  setMarkerLocation: (location: { lat: number, lng: number }) => void
  trashcanDetail?: any
  isHasSkeleton?: any,
  setIsHasSkeleton?: any,
};

const CustomAddMap = ({ markerLocation, setMarkerLocation, trashcanDetail, isHasSkeleton, setIsHasSkeleton }: CustomMapProps) => {
  return (
    <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}>
      <DetailMap markerLocation={markerLocation} setMarkerLocation={setMarkerLocation} trashcanDetail={trashcanDetail} isHasSkeleton={isHasSkeleton} setIsHasSkeleton={setIsHasSkeleton} />
    </APIProvider>
  );
}

export default CustomAddMap;

const DetailMap = ({ markerLocation, setMarkerLocation, trashcanDetail, isHasSkeleton, setIsHasSkeleton }: CustomMapProps) => {
  const map = useMap();
  useEffect(() => {
    if (markerLocation) {
      map?.panTo(markerLocation);
    }
  }, [markerLocation, map]);

  const handleMapClick = (event: any) => {
    const newLocation = {
      lat: event?.detail?.latLng?.lat,
      lng: event?.detail?.latLng?.lng
    };
    setMarkerLocation(newLocation);
    if (newLocation) {
      map?.panTo(newLocation);
    }
  };

  const handleUrlLevel = (level: number) => {
    if (level < MEDIUM_LEVEL_TRASH_CAN) return level1;
    if (level < HIGH_LEVEL_TRASH_CAN && level >= MEDIUM_LEVEL_TRASH_CAN) return level2;
    return level3;
  }

  const markerIcon = trashcanDetail ? {
    url: handleUrlLevel(trashcanDetail.level),
  } : undefined;

  useEffect(() => {
    if (markerLocation?.lat === LAT_DEFAULT && markerLocation?.lng === LONG_DEFAULT) {
      setIsHasSkeleton(true);
    }
  }, [markerLocation?.lat, markerLocation?.lng])

  return (
    isHasSkeleton) ? (
    <Skeleton variant="rectangular" sx={{ height: "532px", width: "100%", borderRadius: "20px", overflow: "hidden" }} />
  ) : (
    <Box sx={{ height: "532px", width: "100%", borderRadius: "20px", overflow: "hidden" }}>
      <Map
        defaultZoom={18}
        defaultCenter={markerLocation}
        gestureHandling={"greedy"}
        disableDefaultUI
        onClick={handleMapClick}
      >
        <Marker position={markerLocation} icon={markerIcon} />
      </Map>
    </Box>
  );
}
