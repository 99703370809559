const statusCode = {
    OK: 200,
    CREATED: 201,
    BAD_REQUEST: 400,
    INTERNAL_SERVER_ERROR: 500,
    UNAUTHORIZED: 401,
    PAYMENT_REQUIRED : 402,
    NOTFOUND: 404
}

export default statusCode;
