import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography, Box, Button, Tooltip, Chip, Popover, Grid } from "@mui/material";
import styles from './styles.module.scss';
import Iconify from "../../../components/iconify";
import React, { useState } from "react";
import Checkbox from "@mui/material/Checkbox";
import { getTrashCanStyleBySuperAdmin } from "../../../helpers";
import { useDispatch, useSelector } from "react-redux";
import { getListAllCollectorsByTrashCanId } from "../../../redux/actions/collector";
import avatarDefault from "../../../assets/images/default.png";
import { sendNotificationFullTrashToCollector } from "../../../redux/actions/admin";
import { errorSnackbar } from "../../../commons/snackbar";
import { useNavigate } from "react-router-dom";
import { MAP_TYPE, trashCanLevelLabels } from "../../../constants";
import paths from "../../../constants/paths";

type Props = {
  id: string,
  row: any;
  index: any;
  typePage: any;
  setTypePage: any;
  markerLocation: any;
  setMarkerLocation: any;
  dataCheckBox: any;
  setDataCheckBox: any;
  dataTrashCanSelected: any;
  setDataTrashCanSelected: any;
  checkButtonNotification: any;
  page: number;
};

export default function AdminTrashCanTableRow({ row, id, index, setTypePage, setMarkerLocation, setDataCheckBox, dataCheckBox, setDataTrashCanSelected, checkButtonNotification, page }: Props) {
  const { trashcan_name, level, collectors, total_collectors } = row;
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);
  const idPopover = open ? 'simple-popover' : undefined;
  const chipStyle = getTrashCanStyleBySuperAdmin(level);
  const allCollectors = useSelector((state: any) => state?.collector?.dataAllCollectorsByTrashCan?.data);
  const navigate = useNavigate();
  const handleRedirectMap = () => {
    setTypePage(MAP_TYPE);
    setMarkerLocation({
      ...row,
      isDefaultLocation: false,
    });
  };

  const handleRedirectEditTrash = () => {
    navigate(`${paths.admin.trashCanDetail}/${id}`, { state: { viewCollectorInfoFrom: false, backPage: page } });
  }

  const handleCheckBox = () => {
    const collectorsIds = row?.collectors.map((item: any) => item.id);
    const updatedCheckShow = !checkValues(id);
    setDataTrashCanSelected((prevCollectors: any) => {
      if (updatedCheckShow) {
        return [
          ...prevCollectors,
          {
            trashcan_id: row?.trashcan_id,
            collector_ids: collectorsIds,
            level: row?.level,
          }
        ];
      } else {
        return prevCollectors.filter((item: any) => item.trashcan_id !== row?.trashcan_id);
      }
    });
    setDataCheckBox((prevData: any) => {
      if (updatedCheckShow) {
        return [...prevData, row?.trashcan_id];
      } else {
        return prevData.filter((id: any) => id !== row?.trashcan_id);
      }
    });
  };

  const handleGetAllCollectorsByTrashCan = (event: React.MouseEvent<HTMLElement>) => {
    dispatch(getListAllCollectorsByTrashCanId(row?.trashcan_id) as any)
    setAnchorEl(event.currentTarget);
  }

  const handlesendNotificationFullTrashToCollector = async () => {
    const collectorsIds = row?.collectors.map((item: any) => item.id);
    if (!collectorsIds.length) {
      errorSnackbar('通知を送信する担当者を選択してください');
      return;
    }
    const formData = {
      data: [
        {
          trashcan_id: row?.trashcan_id,
          collector_ids: collectorsIds,
        }
      ]
    }
    await dispatch(sendNotificationFullTrashToCollector(formData) as any);
  }

  const handleClose = () => {
    setAnchorEl(null);
  };

  const checkValues = (id: string) => {
    return dataCheckBox?.includes(id);
  };

  const renderCollectors = (collectors: any[]) => {
    const maxDisplay = 5;
    const displayedCollectors = collectors.slice(0, maxDisplay);

    return (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {displayedCollectors.map((collector) => (
            <Tooltip key={collector.id} title={collector.full_name} arrow>
              <Box className={styles.managerAvatar} sx={{ mr: 1 }}>
                {collector.avatar ? (
                  <img className={styles.imageAvatar} src={collector.avatar ?? avatarDefault} alt={collector.full_name} />
                ) : (
                  <img className={styles.imageAvatar} src={avatarDefault} alt={collector.full_name} />
                )}
              </Box>
            </Tooltip>
          ))}
        </Box>
        {total_collectors > maxDisplay && (
          <Iconify
            icon="ri:more-fill"
            width={30}
            sx={{ ml: 2, cursor: "pointer" }}
            onClick={handleGetAllCollectorsByTrashCan}
          />
        )}
      </Box>
    );
  };

  return (
    <TableRow hover>
      <TableCell>
        <Checkbox
          checked={checkValues(id)}
          onChange={handleCheckBox}
        />
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.colorTable} ${styles.textTable}`}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.colorTable} ${styles.textTable} ${styles.longText2}`}>
          {trashcan_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.colorTable} ${styles.textTable}`}>
          グループ #1
        </Typography>
      </TableCell>

      <TableCell>
        <Chip size="small" label={chipStyle.label}
          sx={{
            background: chipStyle.background,
            color: "white",
            fontWeight: "bold",
            fontFamily: chipStyle.font
          }} />
      </TableCell>

      <TableCell>
        {collectors ? renderCollectors(collectors) : null}
      </TableCell>

      <TableCell>
        <Box className={(chipStyle?.label === trashCanLevelLabels.high || chipStyle?.label === trashCanLevelLabels.medium ) ? styles.sectionAction : styles.sectionActionNotNoti}>
          <Box className={styles.boxIconMap}>
            <Iconify className={styles.iconMap} icon="zondicons:location" width={20} onClick={handleRedirectMap} />
          </Box>
          <Box className={styles.boxIconEdit} onClick={handleRedirectEditTrash}>
            <Iconify icon="ic:baseline-edit" color="#636B74" width={20} />
          </Box>
          {(chipStyle?.label === trashCanLevelLabels.high || chipStyle?.label === trashCanLevelLabels.medium) && (
            <Box>
              <Button className={styles.btnNotification} onClick={handlesendNotificationFullTrashToCollector}>
                <Typography className={styles.textNotification}>お知らせ</Typography>
              </Button>
            </Box>
          )}
        </Box>
      </TableCell>
      <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        className={styles.popover}>
        <Box sx={{ p: 2 }}>
          <Typography className={`${styles.listCollectorAssign} ${styles.textCommon}`}>{trashcan_name}の収集スタッフ一覧</Typography>
          <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
            {allCollectors?.map((collector: any) => (
              <Grid container alignItems="center" spacing={2} sx={{ pt: 2 }}>
                <Grid item>
                  <Box className={styles.managerAvatarPopup}>
                    {collector.avatar ? (
                      <img className={styles.imageAvatar} src={collector.avatar} alt={collector.full_name} />
                    ) : (
                      <img className={styles.imageAssignAvatar} src={avatarDefault} alt={collector.full_name} />
                    )}
                  </Box>
                </Grid>
                <Grid item className={`${styles.longText2} ${styles.popUpMoreCollector}`} >
                  <Box>{collector?.full_name}</Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>
      </Popover>
    </TableRow>
  );
}
