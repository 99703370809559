import { doAction } from ".";
import { baseAxios } from "../../commons/axiosBase";
import { REQUEST_METHOD } from "../../constants";
import * as t from "../constants/index";

interface BodyReadNotification {
  id: number;
}

interface BodySendMailSetPassword {
  email: string;
}

export const getListAdmin = (params: { full_name?: string, orderBy?: string, order?: string, pages: number }) => async (dispatch: any) => {
  const { pages, full_name, orderBy, order } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/list-admin`,
    method: REQUEST_METHOD.GET,
    params: {
      pages,
      full_name,
      orderBy,
      order
    },
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));


  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_ADMIN_LIST,
      payload: apiResponse,
    });
  }

  return apiResponse;
};

export const addNewAdmin = (body: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/create-admin`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.ADD_NEW_ADMIN,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const getAdminDetail = (id: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/detail-admin/${id}`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_ADMIN_DETAIL,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const editAdmin = (body: any, id: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/update-admin/${id}`,
    data: body,
    method: REQUEST_METHOD.PUT,
    headers: { "Content-Type": "multipart/form-data" },
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.SUPER_ADMIN_EDIT_ADMIN,
      payload: apiResponse,
    });
  }

  return apiResponse;
}

export const changeStatusAdmin = (id: number , body: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/change-active-admin/${id}`,
    data: body,
    method: REQUEST_METHOD.PATCH,
    showNotificationSuccess: true,
  });

  return dispatch(doAction({
    action: callApi,
    loading: 'loadingChangeActiveAccountAdmin'
  }));
}

export const sendNotificationFullTrashToCollector = (body: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/send-notification-full-trash-to-collector`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  return dispatch(doAction({
    action: callApi
  }));
}

export const getListCollectorOfAdmin = (params: { orderBy?: string, order?: string, pages: number }, id: any) => async (dispatch: any) => {
  const { pages, orderBy, order } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/admin/${id}/list-collector`,
    method: REQUEST_METHOD.GET,
    params: {
      pages,
      orderBy,
      order
    },
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_LIST_COLLECTOR_OF_ADMIN,
      payload: apiResponse,
    });
  }

  return apiResponse;
};

export const getListTrashCanOfAdmin = (params: { orderBy?: string, order?: string, pages: number }, id: any) => async (dispatch: any) => {
  const { pages, orderBy, order } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/admin/${id}/list-trash-can`,
    method: REQUEST_METHOD.GET,
    params: {
      pages,
      orderBy,
      order
    },
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_LIST_TRASHCAN_OF_ADMIN,
      payload: apiResponse,
    });
  }

  return apiResponse;
};

export const AdminUpdateProfile = (body: any, typeUpdate: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/profile`,
    data: body,
    params: {
      type: typeUpdate
    },
    method: REQUEST_METHOD.PATCH,
    showNotificationSuccess: true,
    headers: { "Content-Type": "multipart/form-data" },
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.ADMIN_UPDATE_PROFILE,
      payload: apiResponse,
    });
  }

  return apiResponse;
}

export const AdminAssignCollector = (body: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/assign`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  return dispatch(doAction({
    action: callApi
  }));
}

export const AdminAssignCompany = (body: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/trashcan/assign-to-company`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  return dispatch(doAction({
    action: callApi
  }));
}

export const getProfileAdmin = () => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/profile`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_ADMIN_PROFILE,
      payload: apiResponse,
    });

  }

  return apiResponse;
}

export const getListAdminByCompany = (params: { orderBy?: string, order?: string, pages: number }, id: any) => async (dispatch: any) => {
  const { pages, orderBy, order } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/${id}/list-admin`,
    method: REQUEST_METHOD.GET,
    params: {
      pages,
      orderBy,
      order
    },
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_LIST_ADMIN_BY_COMPANY,
      payload: apiResponse,
    });

  }

  return apiResponse;
}

export const getListTrashCanByCompany = (params: { orderBy?: string, order?: string, pages: number }, id: any) => async (dispatch: any) => {
  const { pages, orderBy, order } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/${id}/list-trash-can`,
    method: REQUEST_METHOD.GET,
    params: {
      pages,
      orderBy,
      order
    },
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_LIST_TRASH_BY_COMPANY,
      payload: apiResponse,
    });

  }

  return apiResponse;
}

export const getListNotification = (params: { pages: number }) => async (dispatch: any) => {
  const { pages } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/notification/list`,
    method: REQUEST_METHOD.GET,
    params: { pages },
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.GET_LIST_NOTIFICATION,
      payload: apiResponse,
    });
  }

  return apiResponse;
}

export const readNotification = (body: BodyReadNotification) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/notification/read`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}

export const sendMailSetPasswordToAdmin = (body: BodySendMailSetPassword) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/send-mail-set-password`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}

export const readAllNotification = () => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/notification/read-all`,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}

export const clearData = () => async (dispatch: any) => {
  dispatch({
    type: t.CLEAR_DATA,
  });
};
