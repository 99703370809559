import {
  Box,
  Button,
  Dialog, DialogActions,
  DialogContent, DialogTitle, InputAdornment, Stack, Typography, IconButton, Grid,
  CircularProgress
} from "@mui/material";
import styles from "./styles.module.scss";
import FormProvider from "../../form/form-provider";
import Iconify from "../../iconify";
import { useForm } from "react-hook-form";
import InputSelectBase from "../../input/inputSelect";
import { useState, useMemo, useCallback, useEffect } from "react";
import { AdminAssignCollector } from "../../../redux/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import statusCode from "../../../constants/statusCode";
import { useBoolean } from "../../../hooks/use-boolean";

const DialogCollectorAssignment = (props: any) => {
  const {
    openDialogCollectorAssignment,
    setOpenDialogCollectorAssignment,
    defaultOption,
    options,
    dataTrashCanIdCheckBox,
    actionNext,
  } = props;
  const [collectors, setCollectors] = useState([{ id: Date.now(), collector_id: '', error: '' }]);
  const [deactivedIds, setDeactivedIds] = useState<any[]>([]);
  const transformedOptions = options?.map((item: any) => ({
    value: item.id,
    label: item.full_name
  }));
  const errorMsgs = useSelector((state: any) => state.admin?.error?.response?.data);
  const isAssignTrashcanToCollectorLoading = useSelector((state: any) => state.collector?.loading);

  const dispatch = useDispatch();
  const defaultValues = {
    trashcan_ids: '',
    collector_ids: '',
  }

  const isAdminAssignCollector = useBoolean(true)
  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async () => {
    setCollectors((prevState: any) => prevState.map((collector: any) =>
      !collector.collector_id ? { ...collector, error: "担当者を選択してください。" } : collector
    ));
    const formData = {
      trashcan_ids: dataTrashCanIdCheckBox,
      collector_ids: collectors.map(item => item.collector_id),
    }
    if (formData.collector_ids.includes('')) {
      return;
    }
    setDeactivedIds([]);
    const result = await dispatch(AdminAssignCollector(formData) as any);
    if (result.code === statusCode.OK) {
      actionNext();
      handleCloseModal()
    }
  });

  const handleCloseModal = () => {
    setCollectors([{ id: Date.now(), collector_id: '', error: '' }]);
    setOpenDialogCollectorAssignment(false);
  };

  const handleAddInputSelectCollectors = () => {
    setCollectors([...collectors, { id: Date.now(), collector_id: '', error: '' }]);
  };

  const handleDeleteCollector = (id: any) => {
    setCollectors(collectors.filter(collector => collector.id !== id));
  };

  const handleInputChange = useCallback(
    (name: string, value: any) => {
      setCollectors((prevState: any) => prevState.map((collector: any) =>
        name === `collector_id_${collector.id}` ? { ...collector, collector_id: value, error: '' } : collector
      ));
    }, [],
  );

  const getFilteredOptions = useCallback((collectorId: any) => {
    if (collectors && transformedOptions) {
      const selectedCollectorIds = collectors.map(collector => collector.collector_id).filter(id => id !== '');
      return transformedOptions?.filter((option: any) => !selectedCollectorIds.includes(option.value) || option.value === collectorId);
    }
  }, [collectors, transformedOptions]);

  const collectorInputs = useMemo(() => collectors.map((collector, index) => (
    <Grid container spacing={2} className={`mb-3`} key={collector.id}>
      <Grid item xs={10}>
        <Box className={styles.titleName}>担当者 {index + 1}</Box>
        <InputSelectBase
          name={`collector_id_${collector.id}`}
          keyword={`collector_id_${collector.id}`}
          type="text"
          className={styles.inputCompany}
          handleChange={(keyword: any, value: any) => handleInputChange(keyword, value)}
          size="small"
          placeholder={`担当者 #${index + 1}`}
          errorText={collector?.error}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" className="inputAdornment" sx={{ padding: '8px', marginRight: 0 }}>
                <Iconify icon="" width={20} />
              </InputAdornment>
            ),
          }}
          options={getFilteredOptions(collector.collector_id)}
          defaultOption={defaultOption}
          isAdminAssignCollector={isAdminAssignCollector}
        />
        {collector?.error && (
          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{collector.error}</Box>
        )}
        {collector && deactivedIds.includes(collector.collector_id) && (
          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errorMsgs?.message}</Box>
        )}
      </Grid>
      <Grid item xs={2}>
        {collectors.length > 1 && (
          <IconButton className={styles.iconDelete} onClick={() => handleDeleteCollector(collector.id)} aria-label="delete" size="small">
            <Iconify icon="mdi:trash" width={20} height={20} />
          </IconButton>
        )}
      </Grid>
    </Grid>
    // eslint-disable-next-line
  )), [collectors, getFilteredOptions, defaultOption]);

  useEffect(() => {
    if (errorMsgs && errorMsgs?.error?.collector_ids && Array.isArray(errorMsgs?.error?.collector_ids)) {
      setDeactivedIds(errorMsgs?.error?.collector_ids);
    }
  }, [errorMsgs]);

  return (
    <Dialog open={openDialogCollectorAssignment} fullWidth maxWidth="sm">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className={styles.formDialog} sx={{ py: 2, px: 3 }}>
          <DialogTitle className={styles.dialogCollectorAssignTitle}>
            <Box className={styles.dialogTitle}>割り当て</Box>
            <Box sx={{ cursor: 'pointer' }}>
              <Iconify icon="material-symbols:close" width={20} onClick={handleCloseModal} />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={`mb-3`}>
              <Typography className={styles.titleCollectorAssignment}>選択されたゴミ箱に収集スタッフを割り当てます。</Typography>
            </Box>
            {collectorInputs}
            <Box className={`mt-4`}>
              <Button className={styles.btnAddInputCollector} onClick={handleAddInputSelectCollectors}>
                <Iconify icon="material-symbols:add" width={18.33} className={styles.iconAddCollector} />
                <Typography variant="subtitle1" className={styles.btnText}>担当者を追加</Typography>
              </Button>
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" columnGap={2} sx={{ px: 2 }}>
              <Button className={styles.btnCloseModal} onClick={handleCloseModal}>
                キャンセル
              </Button>
              <Button type="submit" className={styles.btnSave}>
                {isAssignTrashcanToCollectorLoading ? (
                  <Stack flexDirection="row" className={styles.btnLoadingAssignCollector}>
                    <CircularProgress size={25} />
                  </Stack>
                ) : (
                  <Stack flexDirection="row" className={styles.btnSave}>
                    <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                    <Typography className={styles.textSave} variant="subtitle1">追加</Typography>
                  </Stack>
                )}

              </Button>
            </Stack>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
};

export default DialogCollectorAssignment;
