import AdminListCollector from '../pages/admin/collector';
import AdminList from '../pages/super-admin/admin';
import AdminDetail from '../pages/super-admin/admin/detail';
import AdminLogin from "../pages/(auth)/login";
import { ROLE_ADMIN, ROLE_SUPER_ADMIN } from "../constants";
import AdminEditCollector from "../pages/admin/collector/edit";
import AddNewTrash from "../pages/super-admin/trashcan/add-trashcan";
import TrashDetail from "../pages/super-admin/trashcan/detail";
import AdminTrashList from "../pages/admin/trashcan";
import CompanyDetail from '../pages/super-admin/company/detail';
import AdminAssignCollector from '../pages/admin/collector/assign';
import CollectorDetail from '../pages/admin/collector/detail/index';
import AdminTrashDetail from "../pages/admin/trashcan/detail";
import AdminDetailView from '../pages/super-admin/admin/view-info';
import CollectorForgotPassword from '../pages/(auth)/collector/forgot-password';
import CollectorResetPassword from '../pages/(auth)/collector/reset-password';
import AdminForgotPassword from '../pages/(auth)/admin/forgot-password';
import AdminResetPassword from '../pages/(auth)/admin/reset-password';
import CompanyList from '../pages/super-admin/company';
import TrashList from '../pages/super-admin/trashcan';
import paths from '../constants/paths';
import AdminProfile from "../pages/admin/profile";
import CompanyDetailView from "../pages/super-admin/company/view-info";
import AdminListNotification from "../pages/admin/notification";
import AdminSetPassword from '../pages/(auth)/admin/set-password';
import CollectorSetPassword from '../pages/(auth)/collector/set-password';

const routes = {
  public: [
    {
      path: paths.auth.login,
      element: <AdminLogin />,
    }
  ],
  protected: [
    // admin route
    {
      path: paths.admin.notificationList,
      element: <AdminListNotification />,
      role: ROLE_ADMIN,
    },
    {
      path: paths.admin.collectorList,
      element: <AdminListCollector />,
      role: ROLE_ADMIN,
    },
    {
      path: `${paths.admin.editCollector}/:id`,
      element: <AdminEditCollector />,
      role: ROLE_ADMIN,
    },
    {
      path: `${paths.admin.collectorDetail}/:id`,
      element: <CollectorDetail />,
      role: ROLE_ADMIN,
    },
    {
      path: `${paths.admin.assignCollector}/:id`,
      element: <AdminAssignCollector />,
      role: ROLE_ADMIN,
    },
    {
      path: paths.admin.trashCanList,
      element: <AdminTrashList />,
      role: ROLE_ADMIN,
    },
    {
      path: `${paths.admin.trashCanDetail}/:id`,
      element: <AdminTrashDetail />,
      role: ROLE_ADMIN,
    },
    {
      path: paths.admin.profile,
      element: <AdminProfile />,
      role: ROLE_ADMIN,
    },

    // super admin route
    {
      path: paths.superAdmin.adminList,
      element: <AdminList />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: `${paths.superAdmin.adminDetail}/:id`,
      element: <AdminDetail />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: `${paths.superAdmin.adminInfo}/:id`,
      element: <AdminDetailView />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: paths.superAdmin.companyList,
      element: <CompanyList />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: `${paths.superAdmin.companyDetail}/:id`,
      element: <CompanyDetail />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: `${paths.superAdmin.companyInfo}/:id`,
      element: <CompanyDetailView />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: paths.superAdmin.trashList,
      element: <TrashList />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: paths.superAdmin.addTrash,
      element: <AddNewTrash />,
      role: ROLE_SUPER_ADMIN,
    },
    {
      path: `${paths.superAdmin.trashDetail}/:id`,
      element: <TrashDetail />,
      role: ROLE_SUPER_ADMIN,
    },
  ],
  exceptionRoute: [
    {
      path: paths.auth.adminSetPassword,
      element: <AdminSetPassword />,
    },
    {
      path: paths.auth.adminForgotPassword,
      element: <AdminForgotPassword />,
    },
    {
      path: paths.auth.adminResetPassword,
      element: <AdminResetPassword />,
    },
    {
      path: paths.auth.collectorSetPassword,
      element: <CollectorSetPassword />,
    },
    {
      path: paths.auth.collectorForgotPassword,
      element: <CollectorForgotPassword />,
    },
    {
      path: paths.auth.collectorResetPassword,
      element: <CollectorResetPassword />,
    },
  ]
};

export default routes;
