import { useState } from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TextField, InputAdornment, IconButton } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { useSelector } from "react-redux";
import { ROLE_SUPER_ADMIN } from "../../../constants";
import {
  adminPrimaryColor,
  superAdminPrimaryColor,
  borderColor,
  placeholderColor,
  primaryFont
} from "../../../constants/colors";

const DatePickerCustom = (props: any) => {
  const { values, setValues, name, size } = props;
  const authState = useSelector((state: any) => state.auth);
  const primaryColor = authState.dataInfoUserLogin?.role === ROLE_SUPER_ADMIN ? superAdminPrimaryColor : adminPrimaryColor;
  const [open, setOpen] = useState(false);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        inputFormat="YYYY/MM/DD"
        value={values?.[name] ?? null}
        onChange={(newValue) => {
          setValues && setValues((state: any) => ({
            ...state,
            [name]: newValue ? new Date(newValue) : null
          }));
        }}
        maxDate={new Date()}
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              size={size}
              fullWidth
              onClick={() => setOpen(true)}
              InputProps={{
                ...params.InputProps,
                endAdornment: null,
                startAdornment: (
                  <InputAdornment position="start">
                    <IconButton sx={{ padding: 0 }} onClick={() => setOpen(true)}>
                      <CalendarToday sx={{ fontSize: "1.1rem" }} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
              sx={{
                '& .MuiOutlinedInput-root': {
                  paddingLeft: '14px',
                  '& fieldset': {
                    borderWidth: '1.5px',
                    borderColor: borderColor,
                    borderRadius: '8px'
                  },
                  '&:hover fieldset': {
                    borderWidth: '1.5px',
                    borderColor: primaryColor,
                  },
                  '&.Mui-focused fieldset': {
                    borderWidth: '1.5px',
                    borderColor: primaryColor,
                  },
                },
                '& .MuiInputBase-input::placeholder': {
                  fontSize: '14px',
                  color: placeholderColor,
                  fontFamily: primaryFont
                },
                '& .MuiInputBase-input': {
                  display: 'flex',
                  alignItems: 'center',
                  height: '2.7rem',
                  padding: 0,
                },
              }}
            />
          )
        }}
      />
    </LocalizationProvider>
  )
}

export default DatePickerCustom;
