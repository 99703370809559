import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, InputAdornment, DialogActions, Stack, Button, Box, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import InputBase from "../../../components/input/inputBase";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import { useEffect, useState } from 'react';
import FormProvider from "../../../components/form/form-provider";
import { useDispatch, useSelector } from 'react-redux';
import InputSelectBase from '../../../components/input/inputSelect';
import { addNewAdmin } from '../../../redux/actions/admin';
import { filterEmptyFields } from '../../../helpers';
import { isArray } from 'lodash';

type Props = {
  openAddNewAdminDialog: any,
  defaultValues: any,
  actionNext: any,
  setIsBackToPage1: any
}

type CompanyOption = {
  id: number,
  name: string
}

export default function AddNewAdmin({ openAddNewAdminDialog, defaultValues, actionNext, setIsBackToPage1 }: Props) {
  const defaultErrors = {
    full_name: "",
    email: "",
    company_id: ""
  }

  const [selectedCompany, setSelectedCompany] = useState<string | number>('');
  const [dataAdd, setDataAdd] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultValues);

  const errorMsgs = useSelector((state: any) => state.admin?.error?.response?.data?.error)
  const loadingAddNewAdmin = useSelector((state: any) => state?.admin?.loading);

  const defaultOption = {
    value: '0',
    label: (<Box className={styles.textPlaceholder}>会社を選択してください。</Box>)
  }

  const dispatch = useDispatch();
  const dataCompany = useSelector((state: any) => state.company.dataGetAllCompany?.data)
  const companyOptions = dataCompany?.map((company: CompanyOption) => ({
    value: company.id,
    label: company.name,
  })) || [];

  const handleChangeCompany = (key: any, value: any) => {
    setSelectedCompany(value);
    setDataAdd({
      ...dataAdd,
      [key]: value
    });
    setErrors({
      ...errors,
      [key]: ''
    });
  };

  const handleInputChange = (key: any, value: any) => {
    setDataAdd({
      ...dataAdd,
      [key]: value
    });
    setErrors({
      ...errors,
      [key]: ''
    });
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('正しいメールアドレスを入力してください')
      .matches(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+/, '正しいメールアドレスを入力してください')
      .required('メールアドレスは必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    full_name: yup
      .string()
      .required('名前は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    company_id: yup
      .string()
      .required('会社名は必ず入力してください')
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataAdd, { abortEarly: false });
      setErrors(defaultErrors);
      return true;
    } catch (err: any) {
      const newErrors: any = defaultErrors;
      err?.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultErrors] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async () => {
    const isValid = await validate();
    if (!isValid) return;

    const formData = {
      full_name: dataAdd.full_name,
      email: dataAdd.email,
      company_id: dataAdd.company_id,
    }

    const result = await dispatch(addNewAdmin(filterEmptyFields(formData)) as any);
    if (result?.code === 201) {
      setIsBackToPage1(true)
      setDataAdd(defaultValues);
      setErrors(defaultValues);
      openAddNewAdminDialog.onFalse();
      setSelectedCompany('')
      actionNext();
    }
  });

  const handleCloseDialog = () => {
    openAddNewAdminDialog.onFalse();
    setDataAdd(defaultValues);
    setSelectedCompany('')
    setErrors(defaultErrors);
  };

  useEffect(() => {
    const updatedErrors = isArray(errorMsgs) && errorMsgs?.reduce((acc: any, error: any) => {
      if (acc.hasOwnProperty(error.field)) {
        acc[error.field] = error.message;
      }
      return acc;
    }, { ...defaultErrors });

    setErrors(updatedErrors);
  }, [errorMsgs]);

  return (
    <Box>
      <Dialog open={openAddNewAdminDialog.value} fullWidth maxWidth="sm">
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box sx={{ py: 2, px: 3 }}>
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", fontFamily: "Noto Sans JP" }}>
              <Box className={styles.titleDialog}>管理者を追加</Box>
              <Box sx={{ cursor: 'pointer' }}>
                <Iconify icon="material-symbols:close" width={20} onClick={handleCloseDialog} />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box className={styles.nameBox}>
                <Stack flexDirection="row">
                  <Box className={styles.titleName}>名前</Box>
                  <Box className={styles.iconRequired}>*</Box>
                </Stack>
                <InputBase
                  name="full_name"
                  keyword="full_name"
                  errorText={errors?.full_name}
                  type="text"
                  className={styles.inputName}
                  handleChange={handleInputChange}
                  placeholder="管理者の名前を入力してください。"
                  size="small"
                  value={dataAdd?.full_name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="heroicons:user-circle-16-solid" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.full_name && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.full_name}</Box>
                )}
              </Box>

              <Box className={styles.companyBox} sx={{ mt: 2 }}>
                <Stack flexDirection="row">
                  <Box className={styles.titleCompany}>会社名</Box>
                  <Box className={styles.iconRequired}>*</Box>
                </Stack>
                <InputSelectBase
                  name="company_id"
                  keyword="company_id"
                  type="text"
                  errorText={errors?.company_id}
                  className={styles.inputCompany}
                  handleChange={handleChangeCompany}
                  size="small"
                  value={selectedCompany ?? defaultOption?.value}
                  placeholder="会社を選択してください。"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className="inputAdornment" sx={{ padding: '12px', marginRight: 0 }}>
                        <Iconify icon="ion:bag" width={20} />
                      </InputAdornment>
                    ),
                  }}
                  options={companyOptions}
                  defaultOption={defaultOption}
                />
                {errors?.company_id && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.company_id}</Box>
                )}
              </Box>

              <Box className={styles.emailBox} sx={{ mt: 2 }}>
                <Stack flexDirection="row">
                  <Box className={styles.titleCompany}>メールアドレス</Box>
                  <Box className={styles.iconRequired}>*</Box>
                </Stack>
                <InputBase
                  name="email"
                  keyword="email"
                  errorText={errors?.email}
                  type="text"
                  className={styles.inputEmail}
                  handleChange={handleInputChange}
                  placeholder="example@example.com"
                  size="small"
                  value={dataAdd?.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="ic:baseline-email" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.email && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.email}</Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" columnGap={2} sx={{ px: 2 }}>
                <Button sx={{ px: 2 }} className={styles.btnBack} onClick={() => {
                  openAddNewAdminDialog.onFalse()
                  setErrors(defaultErrors)
                  setDataAdd(defaultValues)
                  setSelectedCompany('')
                }} >
                  キャンセル
                </Button>
                {loadingAddNewAdmin ? (
                  <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnAddSpa} sx={{ width: 100 }}>
                    <CircularProgress size={18} sx={{ color: "white" }} />
                  </Stack>
                ) : (
                  <Button type="submit" sx={{ px: 2, width: 100 }} className={styles.btnAddSpa}>
                    <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                    <Box className={`${styles.textCommon} ${styles.btnText}`}>追加</Box>
                  </Button>
                )}
              </Stack>
            </DialogActions>
          </Box>
        </FormProvider>
      </Dialog>
    </Box>
  );
}
