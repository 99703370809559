/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Container,
  Chip,
  InputAdornment,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import styles from './styles.module.scss';
import Iconify from "../../../components/iconify";
import InputBase from "../../../components/input/inputBase";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { TableHeadCustom, TableNoData, useTable } from "../../../components/table";
import AdminTrashCanTableRow from "./table-row";
import HeaderAdmin from "../../../components/header/admin/header";
import {
  ITEMS_PER_PAGE, LAT_DEFAULT,
  LIST_TYPE, LONG_DEFAULT,
  MAP_TYPE,
  MEDIUM_LEVEL_TRASH_CAN,
  MEDIUM_WIDTH_SCREEN,
  trashCanLevelOptions
} from "../../../constants";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import CustomPagination from "../../../components/pagination";
import { getListTrashByAdmin } from "../../../redux/actions/trashcan";
import { isEmpty } from "lodash";
import CustomMapAdmin from "../../../components/admin-maps";
import InputSelectBase from "../../../components/input/inputSelect";
import DialogCollectorAssignment from "../../../components/dialog/dialogCreateCollectorAssignment";
import { adminPrimaryColor, borderColor, primaryFont } from "../../../constants/colors";
import { getAllCollectors } from "../../../redux/actions/collector";
import { checkButtonNotificationTrashCanBySuperAdmin, getClientWidth } from "../../../helpers";
import { TableRowSkeleton } from "../../../components/skeleton/table-row";
import { sendNotificationFullTrashToCollector } from "../../../redux/actions/admin";
import statusCode from "../../../constants/statusCode";
import { errorSnackbar } from "../../../commons/snackbar";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

const INIT_STATE = {
  trashcan_name: '',
  group: '',
  collector_ids: '',
  status: '',
  orderBy: '',
  order: 'asc'
}

const AdminTrashList = () => {
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const [filters, setFilters] = useState(INIT_STATE);
  const [typePage, setTypePage] = useState(LIST_TYPE);
  const [trashCanName, setTrashCanName] = useState<any>('');
  const [groupName, setGroupName] = useState<any>('');
  const [status, setStatus] = useState('');
  const [pageTrash, setPageTrash] = useState(backPage ?? 1);
  const [markerLocation, setMarkerLocation] = useState<any>({
    lat: LAT_DEFAULT,
    long: LONG_DEFAULT,
  });
  const [checkBoxAll, setCheckBoxAll] = useState<any>(false);
  const [dataCheckBox, setDataCheckBox] = useState<any>([]);
  const [dataTrashCanSelected, setDataTrashCanSelected] = useState<any>([]);
  const [openDialogCollectorAssignment, setOpenDialogCollectorAssignment] = useState<any>(false);
  const [choiceCollectors, setChoiceCollectors] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [hideButtonSendNotification, setHideButtonSendNotification] = useState(false);

  const dispatch = useDispatch();
  const dataAllCollectors = useSelector((state: any) => state?.collector?.dataAllCollectors?.data);
  const dataListTrashByAdmin = useSelector((state: any) => state?.trash?.dataListTrashByAdmin?.data);
  const paginate = useSelector((state: any) => state?.trash?.dataListTrashByAdmin?.paginate)
  const primaryColor = adminPrimaryColor;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const checkButtonNotification = checkButtonNotificationTrashCanBySuperAdmin(dataListTrashByAdmin);

  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 1,
  });

  const handleCheckBoxAll = () => {
    setCheckBoxAll(!checkBoxAll);
  }

  const handleSort = (column: any) => {
    let order = 'asc';
    if (filters.orderBy === column && filters.order === 'asc') {
      order = 'desc';
    }
    setFilters({
      ...filters,
      orderBy: column,
      order,
    });
    setDataCheckBox([]);
    setCheckBoxAll(false);
  };

  useEffect(() => {
    if (dataTrashCanSelected?.length) {
      const allLevelsBelow70 = dataTrashCanSelected.every((trashCan: any) => trashCan.level < MEDIUM_LEVEL_TRASH_CAN);
      setHideButtonSendNotification(allLevelsBelow70);
    }
  }, [dataTrashCanSelected]);


  useEffect(() => {
    if (checkBoxAll) {
      const trashCanIds = dataListTrashByAdmin.map((item: any) => item.trashcan_id);
      setDataCheckBox(trashCanIds);
      const dataTrashCanSelect = dataListTrashByAdmin.map((trashcan: any) => {
        return {
          trashcan_id: trashcan.trashcan_id,
          collector_ids: trashcan.collectors.map((collector: any) => collector.id),
          level: trashcan?.level,
        };
      });
      setDataTrashCanSelected(dataTrashCanSelect)
    } else {
      setDataCheckBox([]);
      setDataTrashCanSelected([])
    }
  }, [checkBoxAll]);

  useEffect(() => {
    setCheckBoxAll(false);
  }, [pageTrash]);

  const TABLE_HEAD = [{
    id: "checkbox",
    label: (
      <Checkbox
        checked={checkBoxAll}
        onChange={handleCheckBoxAll}
      />
    ),
    width: 60
  },
  { id: "id.", label: (<Box className={`${styles.textCommon} ${styles.colorTable}`}>No.</Box>), width: 80 },
  {
    id: "trashcan_name",
    label: (
      <Stack
        className={`${styles.textCommon} ${styles.pointer} ${styles.colorTable}`}
        direction="row"
        alignItems="center"
        onClick={() => handleSort('trashcan_name')}>
        <Box className={styles.titleStatus}>ゴミ箱</Box>
        {filters.orderBy === 'trashcan_name' ? (
          filters.order === 'asc' ? (
            <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
          ) : (
            <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
          )
        ) : (
          <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
        )}
      </Stack>
    ),
    width: 300
  },
  {
    id: "group",
    label: (
      <Stack direction="row" alignItems="center">
        <Box className={`${styles.pointer} ${styles.titleStatus} ${styles.textCommon} ${styles.colorTable}`}>グループ</Box>
        <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
      </Stack>
    ),
    width: 200
  },
  {
    id: "level",
    label: (
      <Stack
        className={`${styles.pointer} ${styles.textCommon} ${styles.colorTable}`}
        direction="row"
        alignItems="center"
        onClick={() => handleSort('level')}>
        <Box className={styles.titleStatus}>ステータス</Box>
        {filters.orderBy === 'level' ? (
          filters.order === 'asc' ? (
            <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
          ) : (
            <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
          )
        ) : (
          <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
        )}
      </Stack>
    ),
    width: 200
  },
  { id: "user", label: (<Box className={`${styles.textCommon} ${styles.colorTable}`}>担当者</Box>), width: 250 },
  { id: "action", label: (<Box className={`${styles.pointer} ${styles.textCommon} ${styles.colorTable}`}></Box>), width: 100, align: 'center' },
  ];

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });

        if (permission.state === 'granted' || permission.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (pos: any) => {
              const coords = pos.coords;
              setMarkerLocation({
                lat: coords.latitude ?? LAT_DEFAULT,
                long: coords.longitude ?? LONG_DEFAULT,
                isDefaultLocation: true,
              });
            },
            (error) => {
              console.error(error);
              setMarkerLocation({
                lat: LAT_DEFAULT,
                long: LONG_DEFAULT,
                isDefaultLocation: true,
              });
            }
          );
        } else {
          setMarkerLocation({
            lat: LAT_DEFAULT,
            long: LONG_DEFAULT,
            isDefaultLocation: true,
          });
        }
      } catch (error) {
        console.error(error);
        setMarkerLocation({
          lat: LAT_DEFAULT,
          long: LONG_DEFAULT,
          isDefaultLocation: true,
        });
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    dispatch(getAllCollectors() as any);
  }, []);

  useEffect(() => {
    setLoading(true);
    dispatch(getListTrashByAdmin(buildQuerySearch()) as any).then((result: any) => {
      if (typePage === MAP_TYPE && result) {
        const [dataTrash] = result?.data;
        setMarkerLocation({
          ...dataTrash,
          isDefaultLocation: true,
        });
      }
      setLoading(false);
    });
  }, [pageTrash, filters]);

  const callApiGetListTrash = () => {
    dispatch(getListTrashByAdmin(buildQuerySearch()) as any)
    setDataCheckBox([]);
    setCheckBoxAll(false);
  };

  const notFound = !dataListTrashByAdmin?.length;

  const renderSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD.length} />
      ))}
    </>
  );

  const renderList = (
    <>
      {dataListTrashByAdmin?.map((row: any, index: any) => (
        <AdminTrashCanTableRow
          key={row.trashcan_id}
          id={row.trashcan_id}
          row={row}
          index={(pageTrash - 1) * 10 + index + 1}
          typePage={typePage}
          setTypePage={setTypePage}
          markerLocation={markerLocation}
          setMarkerLocation={setMarkerLocation}
          dataCheckBox={dataCheckBox}
          setDataCheckBox={setDataCheckBox}
          dataTrashCanSelected={dataTrashCanSelected}
          setDataTrashCanSelected={setDataTrashCanSelected}
          checkButtonNotification={checkButtonNotification}
          page={pageTrash}
        />
      ))}
    </>
  );

  const handleInputName = (name: string, value: any) => {
    setTrashCanName(value);
  };

  const handleInputGroupName = (name: string, value: any) => {
    setGroupName(value);
  };

  const handleInputChange = useCallback(
    (name: string, value: any) => {
      switch (name) {
        case 'trashcan_name':
          setTrashCanName(value);
          break;
        case 'status':
          setStatus(value);
          break;
        default:
          setFilters((prevState: any) => ({
            ...prevState,
            [name]: value,
          }));
          break;
      }
    }, [],
  );

  const defaultOption = {
    value: '0',
    label: '選択'
  }

  const buildQuerySearch = () => {
    const query: any = {
      pages: pageTrash,
    };

    if (!isEmpty(filters.trashcan_name)) {
      query.trashcan_name = filters.trashcan_name;
    }

    if (!isEmpty(filters.status.toString())) {
      query.status = filters.status;
    }

    if (!isEmpty(filters.orderBy)) {
      query.orderBy = filters.orderBy;
      query.order = filters.order;
    }

    if (!isEmpty(filters.collector_ids)) {
      query.collector_ids = filters.collector_ids;
    }

    return query;
  };

  const handleSearch = (isEmpty: boolean = false) => {
    if (isEmpty) {
      setTrashCanName('');
      setStatus('');
      setGroupName('');
      setChoiceCollectors([]);

      setFilters((prevState) => ({
        ...prevState,
        trashcan_name: '',
        status: '',
        collector_ids: '',
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        trashcan_name: trashCanName,
        status: status,
        collector_ids: choiceCollectors.toString(),
      }));
    }
    setPageTrash(1);
  }

  const handleAddCollectorAssignments = () => {
    setOpenDialogCollectorAssignment(true);
  }

  const handleSelectCollectors = (event: any) => {
    const {
      target: { value },
    } = event;
    setChoiceCollectors(
      typeof value === 'string' ? value.split(',') : value,
    );
  };

  function mapIdsToValuesProvince(ids: any, collectors: any) {
    return ids.map((id: any) => {
      const item = collectors.find((tag: any) => tag.id === id);
      return item
        ? { id: item.id, full_name: item.full_name }
        : null;
    });
  }

  const menuCollectorsItems = useMemo(() => {
    return (
      dataAllCollectors?.map((item: any) => {
        return (
          <MenuItem key={item.id} className={styles["tag"]} value={item.id}>
            {item.full_name}
          </MenuItem>
        )
      })
    );
  }, [dataAllCollectors]);

  const handleSendNotifications = async () => {
    const filteredData = dataTrashCanSelected.filter((item: any) => item.collector_ids.length > 0 && (item.level > MEDIUM_LEVEL_TRASH_CAN));
    if (!filteredData.length) {
      errorSnackbar('通知を送信できません。');
      return;
    }
    const formData = {
      data: filteredData.map(({ level, ...rest }: any) => rest)
    };

    const result = await dispatch(sendNotificationFullTrashToCollector(formData) as any);
    if (result?.code === statusCode.OK) {
      setDataCheckBox([]);
      setDataTrashCanSelected([])
      setDataTrashCanSelected([]);
      setCheckBoxAll(false);
    }
  }

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth);
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.admin.trashCanList}</title>
      </Helmet>
      <HeaderAdmin />
      <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
        <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
          <Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <Box sx={{ marginRight: "20px" }}>
                <Typography variant="h5" className={styles.titlePage}>ゴミ箱の管理</Typography>
              </Box>
            </Box>
          </Box>
          {dataCheckBox?.length === 0 ? (
            <Box>
              <Button
                className={`${styles.btnTabs1} ${typePage === MAP_TYPE ? styles.btnSelected : ''}`}
                onClick={() => setTypePage(MAP_TYPE)}>
                <Iconify icon="zondicons:location" width={20} className={styles.iconChild} />
                <Typography sx={{ marginLeft: "5px" }} variant="subtitle1" className={styles.btnText}>マップ</Typography>
              </Button>
              <Button
                className={`${styles.btnTabs2} ${typePage === LIST_TYPE ? styles.btnSelected : ''}`}
                onClick={() => {
                  setTypePage(LIST_TYPE)
                  dispatch(getListTrashByAdmin(buildQuerySearch()) as any)
                }}>
                <Iconify icon="charm:menu-hamburger" width={20} className={styles.iconChild} />
                <Typography sx={{ marginLeft: "5px" }} variant="subtitle1" className={styles.btnText}>一覧</Typography>
              </Button>
            </Box>
          ) : (
            <Box>
              {/*<Button className={styles.btnAddGroup} onClick={handleAddCollectorAssignments}>*/}
              {/*  <Iconify icon="material-symbols:add" className={styles.iconAddGroup} />*/}
              {/*  <Typography variant="subtitle1" className={styles.btnText}>グループを作成</Typography>*/}
              {/*</Button>*/}
              <Button className={styles.btnAdd} onClick={handleAddCollectorAssignments}>
                <Iconify icon="material-symbols:add" className={styles.iconAdd} />
                <Typography variant="subtitle1" className={styles.btnText}>割り当て</Typography>
              </Button>
              {!hideButtonSendNotification && (
                <Button sx={{ marginLeft: 2 }} className={styles.btnNotificationAll} onClick={handleSendNotifications}>
                  <Typography variant="subtitle1" className={styles.btnText}>お知らせ</Typography>
                </Button>
              )}
            </Box>
          )
          }
        </Stack>
        <Stack direction="row" alignItems="end" gap={2}>
          <Box
            gap={2}
            sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '100%' }}
            className={styles.searchContainer}
          >
            <Box>
              <Typography className={styles.labelInput}>ゴミ箱</Typography>
              <InputBase
                name="trashcan_name"
                keyword="trashcan_name"
                type="text"
                className={styles.inputName}
                size="small"
                placeholder="ゴミ箱"
                handleChange={handleInputName}
                value={trashCanName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginLeft: '-8px' }}>
                      <Iconify icon="" width={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Typography className={styles.labelInput}>グループ</Typography>
              <InputBase
                name="group"
                keyword="group"
                type="text"
                size="small"
                className={styles.inputName}
                placeholder="グループ"
                handleChange={handleInputGroupName}
                value={groupName}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ marginLeft: '-8px' }}>
                      <Iconify icon="" width={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Typography className={styles.labelInput}>ステータス</Typography>
              <InputSelectBase
                placeholder="ステータス"
                name="status"
                keyword="status"
                type="text"
                className={styles.inputName}
                size="small"
                handleChange={(keyword: any, value: any) => handleInputChange(keyword, value)}
                defaultOption={defaultOption}
                options={trashCanLevelOptions}
                customChipItemTrashCan={true}
                value={status}
                isEdit={true}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" sx={{ paddingLeft: '8px' }}>
                      <Iconify icon="" width={20} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>

            <Box>
              <Typography className={styles.labelInput}>担当者</Typography>
              <Select
                multiple
                MenuProps={MenuProps}
                labelId="collectorsId"
                id="collector_id"
                value={choiceCollectors}
                onChange={handleSelectCollectors}
                className={styles.select2Style}
                sx={{
                  '& .MuiSelect-select': {
                    overflowY: 'scroll !important',
                    paddingY: '10px !important',
                    maxHeight: '24px !important',
  
                    '&::-webkit-scrollbar': {
                      display: 'none'
                    }
                  },
                  '& .MuiTypography-root': {
                    fontSize: '14px',
                    paddingLeft: 0
                  },
                  '& .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: borderColor,
                    borderRadius: '8px'
                  },
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: primaryColor,
                  },
                  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderWidth: '1px',
                    borderColor: primaryColor,
                  },
                }}
                displayEmpty
                renderValue={(selected) => {
                  if (selected.length === 0) {
                    return <Typography sx={{
                      opacity: '0.3',
                      fontFamily: primaryFont,
                      pl: 1
                    }}>担当者</Typography>;
                  }
                  return (
                    <Stack gap={1} direction="row" flexWrap="wrap">
                      {mapIdsToValuesProvince(selected, dataAllCollectors)?.map((item: any) => (
                        <Chip
                          key={item.id}
                          label={item.full_name}
                          onDelete={() =>
                            setChoiceCollectors(
                              choiceCollectors.filter((collector) => collector !== item.id)
                            )
                          }
                          deleteIcon={
                            <span
                              style={{
                                fontSize: "12px",
                                color: "black",
                              }}
                              onMouseDown={(event) => event.stopPropagation()}
                            >
                              &times;
                            </span>
                          }
                          style={{
                            height: "24px",
                            fontWeight: "bold",
                            fontFamily: primaryFont
                          }}
                        />
                      ))}
                    </Stack>
                  );
                }}
              >
                {menuCollectorsItems}
              </Select>
            </Box>
          </Box>
          <Stack flexDirection="row" gap={1} className={styles.searchContainer}>
            <Button className={styles.btnSearch} onClick={() => handleSearch(false)}>
              <Typography className={styles.searchText}>検索</Typography>
            </Button>
            <Button className={styles.btnCancel} onClick={() => handleSearch(true)}>
              <Typography className={styles.searchText}>クリア</Typography>
            </Button>
          </Stack>
        </Stack>
        {typePage === LIST_TYPE ?
          <Box sx={{ padding: 0 }}>
            <Typography
              className={styles.descriptionTable}
              sx={{ mt: 3 }}>グループ作成・割り当てのため、選択します</Typography>
            <TableContainer className={styles.tableContainer} sx={{ marginTop: "10px" }}>
              <Table
                size="small"
                sx={{ minWidth: 960 }}>
                <TableHeadCustom
                  className={styles.borderTableHeader}
                  headLabel={TABLE_HEAD}
                  rowCount={dataListTrashByAdmin?.length}
                  numSelected={table.selected?.length}
                  isTableTrash={true}
                />
                <TableBody className={styles.borderTable}>
                  {loading ? renderSkeleton : renderList}
                  {dataListTrashByAdmin && !loading && (
                    <>
                      {/* <TableEmptyRows
                          height={56}
                          emptyRows={emptyRows(
                            table.page - 1,
                            table.rowsPerPage,
                            dataListTrashByAdmin.length
                          )}
                        /> */}
                      <TableNoData notFound={notFound} />
                    </>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {dataListTrashByAdmin?.length > 0 && paginate?.total_page > 1 && (
              <CustomPagination
                paginate={paginate}
                page={pageTrash}
                setPage={setPageTrash}
                disabled={paginate?.total_page === 1}
              />
            )}
          </Box>
          :
          <Box>
            <CustomMapAdmin dataListTrash={dataListTrashByAdmin} markerLocation={markerLocation} />
          </Box>
        }
      </Container>

      <DialogCollectorAssignment
        openDialogCollectorAssignment={openDialogCollectorAssignment}
        setOpenDialogCollectorAssignment={setOpenDialogCollectorAssignment}
        defaultOption={defaultOption}
        options={dataAllCollectors}
        dataTrashCanIdCheckBox={dataCheckBox}
        actionNext={callApiGetListTrash}
      />
    </>
  )
}

export default AdminTrashList;
