/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUserLogin } from "../../redux/actions/auth";
import statusCode from "../../constants/statusCode";
import { DEACTIVE, REDIRECT_BEFORE_LOGIN, ROLE_ADMIN, WEB_SOCKET_URL } from "../../constants";
import { Box, CircularProgress } from "@mui/material";
import paths from "../../constants/paths";

const useAuth = () => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [isActive, setIsActive] = useState<number>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await dispatch(fetchUserLogin() as any);
        if (res.code === statusCode.OK) {
          setIsAuth(true);
          setIsActive(res.data?.is_active);
          if (res.data?.role === ROLE_ADMIN) {
            const socket = new WebSocket(WEB_SOCKET_URL);
            socket.onopen = function(e) {
              socket.send(JSON.stringify({ userId: res.data?.id }));
            }
            socket.onmessage = (event) => {
              const message = JSON.parse(event.data);
              if ((message.type === 'DEACTIVED' || message.type === 'DELETED') && message.id === res.data?.id) {
                localStorage.clear();
                navigate(paths.auth.login);
                socket.close();
              }
              if (message.type === 'CHANGE_COMPANY' && message.id === res.data?.id) navigate(0);
            };
          }
        } else {
          setIsAuth(false);
        }
      } catch (err) {
        console.log(err);
        setIsAuth(false);
      }
    };
    fetchData();
  }, []);

  return {isAuth, isActive};
};

const ProtectedRoute = () => {
  const {isAuth, isActive} = useAuth();
  let location = useLocation();

  if (isAuth === false) {
    return (
      <Navigate
        to={REDIRECT_BEFORE_LOGIN}
        replace
        state={{ path: location.pathname }}
      />
    );
  } else if (isAuth === true && isActive === DEACTIVE) {
    localStorage.clear();
    return (
      <Navigate
        to={REDIRECT_BEFORE_LOGIN}
      />
    );
  } else if (isAuth === true) {
    return <Outlet />;
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
      <CircularProgress />
    </Box>
  );
};

export default ProtectedRoute;
