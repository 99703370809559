import { useDispatch, useSelector } from "react-redux";
import { AppBar, Container, Box, Avatar } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useResponsive } from "../../../hooks/use-responsive";
import { HEADER } from "../../../config-layout";
import { MEDIUM_WIDTH_SCREEN, ROLE_SUPER_ADMIN } from "../../../constants";
import LOGO from '../../../assets/logo/SPA_LOGO.svg'
import styles from "./../styles.module.scss";
import paths from "../../../constants/paths";
import { logout } from "../../../redux/actions/auth";
import { useRef, useState, useEffect, memo } from "react";
import { getClientWidth } from "../../../helpers";
import user from "../../../assets/images/user.png";
import trashcan from "../../../assets/images/trashcan.png";
import company from "../../../assets/images/company.png";
import userActive from "../../../assets/images/user_active.png";
import companyActive from "../../../assets/images/company_active.png";
import trashcanActive from "../../../assets/images/trashcan_active.png";

const HeaderNavItem = memo(({ active, icon, text, url }: any) => {
  return (
    <Link to={url} className={styles.textLink}>
      <Box className={`${styles.btnUser} ${active ? styles.btnActiveSpa : ''}`}>
        <img src={icon} alt='logo' />
        <Box className={styles.textMenu}>{text}</Box>
      </Box>
    </Link>
  );
});

const HeaderSuperAdmin = () => {
  const lgUp = useResponsive('up', 'lg');
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const authState = useSelector((state: any) => state.auth);
  const userLoginRole = authState.dataInfoUserLogin?.role;

  const handleLogout = async () => {
    const result = await dispatch(logout() as any);
    if (result) {
      navigate(paths.auth.login);
    }
  }

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <AppBar
      sx={{
        height: HEADER.H_DESKTOP,
        zIndex: (theme) => theme.zIndex.appBar + 1,
        transition: (theme) => theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        backgroundColor: 'white',
      }}>
      <Container
        maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? 'xl' : 'lg'}
        className='h-100 d-flex align-items-center justify-content-between'>
        <Box className='h-100 d-flex d-flex align-items-center'>
          <Link to={paths.superAdmin.adminList}>
            <img src={LOGO} alt='LOGO' />
          </Link>

          <Box className='h-100 d-flex align-items-center'>
            <HeaderNavItem
              url={paths.superAdmin.adminList}
              active={location.pathname.includes('/spa/admin')}
              icon={location.pathname.includes('/spa/admin') ? userActive : user}
              text='管理者一覧'
            />
            <HeaderNavItem
              url={paths.superAdmin.companyList}
              active={location.pathname.includes('/spa/company')}
              icon={location.pathname.includes('/spa/company') ? companyActive : company}
              text='会社一覧'
            />
            <HeaderNavItem
              url={paths.superAdmin.trashList}
              active={location.pathname.includes('/spa/trashcan')}
              icon={location.pathname.includes('/spa/trashcan') ? trashcanActive : trashcan}
              text='ゴミ箱の管理'
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Avatar
              src={authState?.dataInfoUserLogin?.avatar}
              alt='Admin'
              sx={{
                width: 48,
                height: 48,
                border: (theme) => `solid 2px ${theme.palette.background.default}`,
                mr: lgUp ? 2 : 0,
              }}
            />

            {lgUp && (
              <Box className={styles.sectionProfile}>
                <Box className={`${styles.adminName} ${styles.textCommon} ${styles.truncateText}`}>{authState?.dataInfoUserLogin?.full_name}</Box>
                <Box onClick={handleLogout} className={styles.adminEmail}>{userLoginRole === ROLE_SUPER_ADMIN ? 'ログアウト' : authState?.dataInfoUserLogin?.email}</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

export default HeaderSuperAdmin;
