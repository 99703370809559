import { Container, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styles from './styles.module.scss';
import paths from '../../constants/paths';
import pageTitles from '../../constants/pageTitles';
import { Helmet } from 'react-helmet';

const SetPasswordExpired = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate(paths.auth.login);
  };

  return (
    <>
      <Helmet>
        <title>{pageTitles.auth.adminSetPassword}</title>
      </Helmet>
      <Container maxWidth="md" className={styles.container}>
        <Box className={styles.box}>
          <ErrorOutlineIcon color="error" className={styles.icon} />
          <Box className={styles.subtitle}>
              パスワードの設定に失敗しました。
          </Box>
          <Box className={styles.description}>
              パスワード設定リンクの有効期限が切れました。新しいリンクを要求してください。
          </Box>
          <Button onClick={handleGoHome} variant="contained" className={styles.btnBackToHome}>ログインページに戻る</Button>
        </Box>
      </Container>
    </>
  );
};

export default SetPasswordExpired;
