import axios from 'axios';
import statusCode from '../constants/statusCode';
import { successSnackbar, errorSnackbar } from './snackbar';
import { CONFIG_AXIOS } from '../constants/axiosBase';

class BaseAxios {
  protected instance: any;
  constructor() {
    const instance = axios.create(CONFIG_AXIOS);
    instance.interceptors.request.use(function (config) {
      const token = localStorage.getItem('access_token');
      if (token) {
        config.headers = config.headers ?? {};
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    });
    /**
     * interceptors response
     */
    instance.interceptors.response.use(this.handleSuccess, this.handleError);
    this.instance = instance;
  }

  handleSuccess(response: any) {
    return response;
  }

  handleError(error: any) {
    if (error.response) {
      if (error.response.status === statusCode.UNAUTHORIZED) {
        // localStorage.clear();
      } else {
        console.log(error.response)
      }
    }
    return Promise.reject(error);
  }

  async publicRequest(payload: any) {
    return this.request(payload);
  }

  /**
   * @method
   * @description
   * @param {*} payload this is object contain url, method, params, data
   * @param {url} this is url api
   * @param {method} this is method https request
   * @param {params} this params in request
   * @param {data} this data in body
   * @param {showNotification} check show notification
   * @param {showNotificationSuccess} check show notification success
   */
  async request(payload: any) {
    const { showNotification, showNotificationSuccess, ...rest } = this.defaultParamters(payload);
    const token = localStorage.getItem('access_token');
    /**
     * @param {headers} this contain Accept, Authorization
     */
    const newParams = {
      ...rest,
      'Authorization': `Bearer ${token}`,
    };

    try {
      const response = await this.instance(newParams);
      const data = response?.data;

      // Case: UNAUTHORIZED
      if (response?.status === statusCode.UNAUTHORIZED) {
        console.log(response?.status)
        localStorage.clear();
      // Case: status 200 or 201 && had showNotificationSuccess: true
      } else if (data?.data || [statusCode.OK, statusCode.CREATED].includes(response?.status)) {
        if (data?.message && showNotificationSuccess) {
          successSnackbar(data?.message);
        }
      // Case: not status 200 or 201 or had error
      } else if ((data?.error || ![statusCode.OK, statusCode.CREATED].includes(response?.status)) && showNotification) {
        errorSnackbar(data?.message || 'Error!');
        if (response?.status === statusCode.UNAUTHORIZED) {
          localStorage.clear();
        }
      }
      return data;
    } catch (error) {
      throw error;
    }
  }

  defaultParamters(payload: any) {
    const { showNotification = true, ...args } = payload;

    return {
      showNotification,
      ...args,
    };
  }
}

export const baseAxios = new BaseAxios();
