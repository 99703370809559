import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { TableRow, TableCell, Typography, IconButton, Box, Stack, Popper, Link } from "@mui/material";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import { changeStatusAdmin, sendMailSetPasswordToAdmin } from "../../../redux/actions/admin";
import { ACTIVE, DEACTIVE } from "../../../constants";
import paths from "../../../constants/paths";
import {useNavigate} from "react-router-dom";
import ConfirmDialog from "../../../components/dialog/confirm-dialog";

type Props = {
  row: any;
  actionNext: any;
  index: any;
  activePopup: string | null;
  setActivePopup: (id: string | null) => void;
  page: number;
};

export default function AdminTableRow({ row, actionNext, index, activePopup, setActivePopup, page }: Props) {
  const { id, email, full_name, tel, is_active, is_set_password, company_id, company_name } = row;
  const [openDialog, setOpenDialog] = useState(false);
  const [newStatus, setNewStatus] = useState(is_active);
  const dispatch = useDispatch();
  const loadingEditAdmin = useSelector((state: any) => state.admin.loading);

  const popperRef = useRef<HTMLDivElement | null>(null);

  const open = activePopup === id;
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setActivePopup(open ? null : id);
  };
  const navigate = useNavigate();

  const handleChangeActive = async () => {
    const result = await dispatch(changeStatusAdmin(id, { is_active: newStatus }) as any);
    if (result?.code === 200) {
      actionNext();
    }
    setOpenDialog(false);
  };

  const handleViewDetailAdmin = () => {
    navigate(`${paths.superAdmin.adminInfo}/${id}`, { state: { backPage: page } });
  };

  const handleEditAdmin = () => {
    navigate(`${paths.superAdmin.adminDetail}/${id}`, { state: { viewInfoFrom: false, backPage: page } });
  };

  const handleSendEmail = () => {
    setActivePopup(null);
    dispatch(sendMailSetPasswordToAdmin({ email }) as any);
  }

  const handleOpenDialog = (status: number) => {
    setNewStatus(status);
    setOpenDialog(true);
    setActivePopup(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
      setActivePopup(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.longText2} ${styles.textCommon}`}>
          {full_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.longText2} ${styles.textCommon}`}>
          {email}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          {tel}
        </Typography>
      </TableCell>

      <TableCell className={styles.textCommon}>
        {is_active === 1 ? (
          <span className={styles.btnActive}>アクティブ</span>
        ) : (
          <span className={styles.btnInActive}>無効</span>
        )}
      </TableCell>

      <TableCell>
        <Link onClick={() => navigate(`${paths.superAdmin.companyDetail}/${company_id}`)} variant="subtitle1" className={`${styles.longText2} ${styles.textCommon} ${styles.pointer}`}>
          {company_name}
        </Link>
      </TableCell>

      <TableCell>
        <IconButton
          edge="start"
          onClick={handleClick}
          className={styles.optionCell}
          data-id={id}
        >
          <Iconify icon="ri:more-2-fill" />
        </IconButton>
        <Popper open={open} anchorEl={document.querySelector(`[data-id='${id}']`)} placement="bottom-start" ref={popperRef}>
          <Box className={styles.optionBoxAdminList}>
            {is_set_password === false && <Stack onClick={handleSendEmail} className={styles.itemPopper}>
              <Iconify icon="material-symbols:mail" className={styles.iconPopper}/>
              <Box className={styles.optionText}>パスワード再発行</Box>
            </Stack>}
            <Stack onClick={handleEditAdmin} className={styles.itemPopper}>
              <Iconify icon="ic:baseline-edit" width={20} color="#636B74" />
              <Box className={styles.optionText}>編集</Box>
            </Stack>
            <Stack onClick={handleViewDetailAdmin} className={styles.itemPopper}>
              <Iconify icon="carbon:view-filled" width={20} color="#636B74" />
              <Box className={styles.optionText}>詳細</Box>
            </Stack>
            <Stack className={styles.itemPopper} alignContent="center">
              {is_active === 1 ? (
                <Box onClick={() => handleOpenDialog(DEACTIVE)} display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
                  <Iconify icon="material-symbols:close" width={20} />
                  <Box className={styles.optionText}>無効にする</Box>
                </Box>
              ) : (
                <Box onClick={() => handleOpenDialog(ACTIVE)} display="flex" alignItems="center" sx={{ cursor: "pointer" }}>
                  <Iconify icon="material-symbols:circle-outline" width={20} />
                  <Box className={styles.optionText}>アクティブにする</Box>
                </Box>
              )}
            </Stack>
          </Box>
        </Popper>
      </TableCell>
      <ConfirmDialog
        title="変更の確認"
        content={newStatus === 1 ? "この管理者を有効にしますか？" : "この管理者を無効にしますか？"}
        open={openDialog}
        loading={loadingEditAdmin}
        onClose={handleCloseDialog}
        onConfirm={handleChangeActive}
      />
    </TableRow>
  );
}
