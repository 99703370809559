import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { ADMIN_REDIRECT_AFTER_LOGIN, DEACTIVE, REDIRECT_BEFORE_LOGIN, ROLE_SUPER_ADMIN, SUPER_ADMIN_REDIRECT_AFTER_LOGIN } from "../../constants";

interface CustomRouteProps {
  element: React.ReactElement;
  role: number;
}

const CustomRoute: React.FC<CustomRouteProps> = ({ element, role }) => {
  const authState = useSelector((state: any) => state.auth);
  let userLoginRole = authState.dataInfoUserLogin?.role;
  let userLoginStatus = authState.dataInfoUserLogin?.is_active;
  if (userLoginStatus === DEACTIVE) {
    localStorage.clear();
    return (
      <Navigate
        to={REDIRECT_BEFORE_LOGIN}
      />
    );
  }
  if (userLoginRole !== role) {
    return <Navigate to={userLoginRole === ROLE_SUPER_ADMIN ? SUPER_ADMIN_REDIRECT_AFTER_LOGIN : ADMIN_REDIRECT_AFTER_LOGIN} />
  }
  return element;
};
export default CustomRoute;
