import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Container, Grid, IconButton, InputAdornment, Stack } from "@mui/material";
import styles from './styles.module.scss';
import LOGO from '../../../../assets/logo/LOGO.svg';
import * as Yup from 'yup';
import { useLocation } from 'react-router-dom';
import { checkActiveTokenCollector, collectorSetPassword } from '../../../../redux/actions/auth';
import PasswordDefault from '../../../../components/input/inputPassword';
import Iconify from '../../../../components/iconify';
import SetPasswordExpired from '../../../../components/expired/set-password';
import statusCode from '../../../../constants/statusCode';
import { Helmet } from 'react-helmet';
import pageTitles from '../../../../constants/pageTitles';

type DataFormType = {
  password: string;
  passwordConfirm: string;
};

const INIT_STATE = {
  password: '',
  passwordConfirm: '',
}

const CollectorSetPassword = () => {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };

  const query = useQuery();
  const token = query.get('token');

  const dispatch = useDispatch();
  const dataAuth = useSelector((state: any) => state.auth);
  const errorReducer = dataAuth?.error?.response?.data?.errors;
  const expiredTokenError = dataAuth?.error?.response?.data?.code
  const [dataForm, setDataForm] = useState<DataFormType>(INIT_STATE);
  const [errors, setErrors] = useState<DataFormType>(INIT_STATE);
  const [showPass, setShowPass] = useState<boolean>(false);
  const [showPassConfirm, setShowPassConfirm] = useState<boolean>(false);
  const [isUpdateSuccess, setIsUpdateSuccess] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);

  const loadingSetPassword = useSelector((state: any) => state.auth.loading);

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleShowPassConfirm = () => {
    setShowPassConfirm(!showPassConfirm);
  };

  const validate = async () => {
    try {
      await validationSchema.validate(dataForm, { abortEarly: false });
      setErrors(INIT_STATE);
      return true;
    } catch (err: any) {
      const newErrors: DataFormType = { ...INIT_STATE };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof DataFormType] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const submitSetPassword = async (e: any) => {
    e.preventDefault();
    if (await validate()) {
      const result = await dispatch(collectorSetPassword({
        password: dataForm.password,
        confirm_password: dataForm.passwordConfirm,
        token: token
      }) as any);
      setIsUpdateSuccess(result)
    }
  };

  const handleInputChange = (key: any, value: any) => {
    setDataForm({
      ...dataForm,
      [key]: value
    })
    setErrors({ ...errors, [key]: "" });
  };

  useEffect(() => {
    setErrors((state: any) => ({
      ...state,
      password: errorReducer?.password,
      passwordConfirm: errorReducer?.passwordConfirm,
    }))
  }, [errorReducer])

  useEffect(() => {
    setLoading(true);
    dispatch(checkActiveTokenCollector({ token }) as any).then(() => {
      setLoading(false);
    });
  }, [])

  const validationSchema = Yup.object({
    password: Yup.string()
      .matches(/[A-Z]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .matches(/[a-z]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .matches(/[0-9]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .matches(/[\W_]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .min(8, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .required('パスワードを入力してください'),
    passwordConfirm: Yup.string().required('パスワード確認を入力してください').oneOf([Yup.ref('password')], 'パスワードと確認パスワードが一致しません'),
  });

  return (
    <>
      <Helmet>
        <title>{pageTitles.auth.collectorSetPassword}</title>
      </Helmet>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        (expiredTokenError === statusCode.BAD_REQUEST || !token) ? (
          <SetPasswordExpired />
        ) : (
          <Container maxWidth="sm" className={`${styles.containerFluid} ${styles.textCenter}`} sx={{ display: 'flex' }}>
            <div>
              <div className={styles.textCenter}>
                <img
                  alt="McCarthy Logo"
                  src={LOGO}
                  className={styles.textCenter}
                />
              </div>
              {isUpdateSuccess ? (
                <Box sx={{ mt: 6 }}>
                  <div className={styles.successCoverBox}>
                    <div className={styles.successMessage}>
                      <p className={styles.successTitle}>パスワードの設定に成功しました。</p>
                      <p className={styles.successSubtitle}>引き続きアプリをご利用いただくには、アプリにログインしてください。</p>
                    </div>
                  </div>
                </Box>
              ) : (
                <>
                  <p className={`${styles.textCenter} ${styles.subTitle}`}>新しいパスワードを入力してください</p>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <label className={`${styles.subTitlePassword}`}>新しいパスワード</label>
                      <PasswordDefault
                        name="password"
                        id="password"
                        autoComplete="current-password"
                        errorText={errors?.password}
                        handleChange={handleInputChange}
                        className={`${styles.textCenter}`}
                        setShowPass={handleShowPass}
                        showPass={showPass}
                        size="small"
                        value={dataForm.password}
                        placeholder="パスワードを入力してください"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon="material-symbols:lock" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPass} edge="end">
                                <Iconify icon={showPass ? 'mdi:eye-off' : 'mdi:eye'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors?.password && (
                        <Box className={styles.errorText}>{errors.password}</Box>
                      )}
                    </Grid>
                    <Grid item xs={12} mb={3}>
                      <label className={`${styles.subTitlePassword}`}>新しいパスワード確認用</label>
                      <PasswordDefault
                        name="passwordConfirm"
                        id="passwordConfirm"
                        errorText={errors?.passwordConfirm}
                        handleChange={handleInputChange}
                        className={`${styles.textCenter}`}
                        setShowPass={handleShowPassConfirm}
                        showPass={showPassConfirm}
                        size="small"
                        value={dataForm.passwordConfirm}
                        placeholder="パスワード確認を入力してください"
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Iconify icon="material-symbols:lock" />
                            </InputAdornment>
                          ),
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton onClick={handleShowPassConfirm} edge="end">
                                <Iconify icon={showPassConfirm ? 'mdi:eye-off' : 'mdi:eye'} />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors?.passwordConfirm && (
                        <Box className={styles.errorText}>{errors.passwordConfirm}</Box>
                      )}
                    </Grid>
                    <Grid item xs={12} className={`${styles.textCenter}`}>
                      {loadingSetPassword ? (
                        <Stack flexDirection="row" className={styles.btnLoginLoading} textAlign="center" justifyContent="center">
                          <CircularProgress size={25} />
                        </Stack>
                      ) : (
                        <Button
                          onClick={e => submitSetPassword(e)} variant="contained"
                          className={`${styles.btnSubmit}`}>設定
                        </Button>
                      )}
                    </Grid>
                  </Grid>
                </>
              )}
            </div>
          </Container>
        )
      )}
    </>
  );
}

export default CollectorSetPassword;
