import { Box, Button, CircularProgress, InputAdornment, Stack } from "@mui/material";
import styles from "./styles.module.scss";
import InputBase from "../input/inputBase";
import Iconify from "../iconify";
import React, { useEffect, useState } from "react";
import DatePickerCustom from "../input/datePickerCustom";
import { useDispatch, useSelector } from "react-redux";
import { AdminUpdateProfile } from "../../redux/actions/admin";
import { TYPE_UPDATE_PROFILE } from "../../constants";
import moment from "moment/moment";
import * as yup from "yup";
import {isValidDate, regexPostalCode} from "../../helpers";

type DataFormType = {
  full_name: string,
  birthday: any,
};

const INIT_DATA = {
  full_name: "",
  birthday: null,
};

const PersonalInformation = (props: any) => {
  const { dataAdminProfile, actionNext } = props;
  const [defaultValues] = useState<DataFormType>(INIT_DATA);
  const [errors, setErrors] = useState(defaultValues);
  const [dataUpdate, setDataUpdate] = useState(dataAdminProfile);
  const dispatch = useDispatch();

  const loadingAdminUpdateProfile = useSelector((state: any) => state.admin.loading);

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate({
      ...dataUpdate,
      [key]: value
    });

    setErrors({
      ...errors,
      [key]: ''
    });
  };

  useEffect(() => {
    if (dataAdminProfile) {
      setDataUpdate({
        full_name: dataAdminProfile.full_name,
        birthday: dataAdminProfile.birthday,
        tel: dataAdminProfile.tel,
        postal_code: dataAdminProfile.postal_code,
        prefecture: dataAdminProfile.prefecture,
        city: dataAdminProfile.city,
        address: dataAdminProfile.address,
        avatar: dataAdminProfile.avatar,
      });
    }
  }, [dataAdminProfile])

  const validationSchema = yup.object().shape({
    full_name: yup
      .string()
      .required("名前は必ず入力してください")
      .max(255, '255文字以内で入力してください'),
    postal_code: yup
      .string()
      .matches(regexPostalCode, {
        message: '郵便番号は半角数字を入力してください。',
        excludeEmptyString: true,
      })
      .max(255, '255文字以内で入力してください')
      .notRequired(),
    birthday: yup
      .date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('format', date => {
        if (!date) {
          return true;
        }

        return isValidDate(date);
      })
      .test(
        'is-in-range',
        '1900年1月1日以降の日付を入力してください',
        value => !value || new Date(value) >= new Date(1900, 0, 1)
      )
      .test(
        'is-not-in-future',
        '未来の日付は入力できません',
        value => !value || new Date(value) <= new Date()
      )
      .typeError('生年月日の形式が正しくありません。')
      .notRequired(),
  });

  const validateUpdateBasic = async () => {
    try {
      setErrors(defaultValues);
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultValues }; // Ensure we start with default values
      err?.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultValues] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleUpdatePersonalInformation = async () => {
    if (await validateUpdateBasic()) {
      const formData = {
        full_name: dataUpdate.full_name,
        birthday: dataUpdate?.birthday ? moment(dataUpdate?.birthday).format("YYYY-MM-DD") : null,
      }

      const result = await dispatch(AdminUpdateProfile(formData, TYPE_UPDATE_PROFILE.BASIC) as any);
      if (result?.code === 200) {
        actionNext();
      }
    }
  }

  return (
    <Box className={styles.sectionBox}>
      <Box className={styles.contentBox}>
        <Box>
          <Box className={styles.nameBox}>
            <Box className={styles.titleAdminDetail}>名前
              <Box
                sx={{ display: "inline-block" }}>
                <Box className={styles.iconRequired}>*</Box>
              </Box>
            </Box>
            <InputBase
              name="full_name"
              keyword="full_name"
              errorText={errors?.full_name}
              type="text"
              className={styles.inputNameAdmin}
              handleChange={handleInputChange}
              placeholder="名前を入力してください"
              size="small"
              value={dataUpdate?.full_name ?? dataAdminProfile?.full_name}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="heroicons:user-circle-16-solid" width={20} />
                  </InputAdornment>
                ),
              }}
            />
            {errors?.full_name && (
              <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.full_name}</Box>
            )}
          </Box>

          <Box className={styles.companyBox} sx={{ mt: 2 }}>
            <Box className={styles.titleAdminDetail}>生年月日</Box>
            <DatePickerCustom
              values={dataUpdate}
              setValues={setDataUpdate}
              name="birthday"
              size="small"
            />
            {errors?.birthday && (
              <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.birthday}</Box>
            )}
          </Box>

          <Box className={styles.companyBox} sx={{ mt: 2 }}>
            <Box className={styles.titleAdminDetail}>会社名</Box>
            <InputBase
              name="company"
              keyword="company"
              type="text"
              disabled={true}
              className={styles.inputNameAdmin}
              placeholder="会社を選択してください。"
              value={dataAdminProfile?.company?.name}
              size="small"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="ion:bag" width={20} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mt={2} sx={{ textAlign: "end" }}>
            <Button onClick={handleUpdatePersonalInformation}>
              {loadingAdminUpdateProfile ? (
                <Stack flexDirection="row" className={styles.btnLoading}>
                  <CircularProgress size={25} />
                </Stack>
              ) : (
                <Stack className={styles.btnCreate} flexDirection="row">
                  <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                  <Box>保存</Box>
                </Stack>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PersonalInformation;
