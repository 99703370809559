import { Skeleton, TableCell, TableRow } from "@mui/material";

type Props = {
  rows: number;
};

export function TableRowSkeleton({ rows }: Props) {
  return (
    <TableRow hover>
      {[...Array(rows)].map((_, index) => (
        <TableCell key={index}>
          <Skeleton />
        </TableCell>
      ))}
    </TableRow>
  );
}
