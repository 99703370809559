import styles from "./styles.module.scss";
import {Box, Button, CircularProgress, FormGroup, Stack} from "@mui/material";
import React, {useEffect, useState} from "react";
import Typography from "@mui/material/Typography";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Iconify from "../iconify";
import {useDispatch, useSelector} from "react-redux";
import {ACTIVE, DEACTIVE, TYPE_UPDATE_PROFILE} from "../../constants";
import {AdminUpdateProfile} from "../../redux/actions/admin";
import CheckIcon from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

const CustomCheckbox = styled(Checkbox)({
  '&.MuiCheckbox-root': {
    color: 'white',
  },
  '&.Mui-checked': {
    color: '#0B6BCB',
  },
  '& .MuiSvgIcon-root': {
    borderRadius: '4px',
    border: '2px solid #636B74',
    backgroundColor: 'white',
  },
  '&.Mui-checked .MuiSvgIcon-root': {
    border: '2px solid #636B74',
    backgroundColor: 'white',
  },
});

const NotificationInformation = (props: any) => {
  const {dataAdminProfile, actionNext} = props;
  const [dataUpdate, setDataUpdate] = useState(dataAdminProfile);
  const dispatch = useDispatch();
  const loadingAdminUpdateProfile = useSelector((state: any) => state.admin.loading);

  useEffect(() => {
    if (dataAdminProfile) {
      setDataUpdate({
        is_receive_email: dataAdminProfile.is_receive_email === ACTIVE,
        is_receive_notification: dataAdminProfile.is_receive_notification === ACTIVE,
      });
    }
  }, [dataAdminProfile])

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setDataUpdate((prevData: any) => ({
      ...prevData,
      [name]: checked,
    }));
  };

  const handleUpdateNotification = async () => {
    const formData = {
      is_receive_email: dataUpdate?.is_receive_email === true ? ACTIVE : DEACTIVE,
      is_receive_notification: dataUpdate?.is_receive_notification === true ? ACTIVE : DEACTIVE,
    }

    const result = await dispatch(AdminUpdateProfile(formData, TYPE_UPDATE_PROFILE.NOTIFICATION) as any);
    if (result?.code === 200) {
      actionNext();
    }
  }

  return (
    <Box className={styles.sectionBox}>
      <Box className={styles.contentBox}>
        <Box>
          <Typography className={styles.textNotification}>通知を通じて受け取る</Typography>
          <FormGroup>
            <FormControlLabel
              control={
              <CustomCheckbox
                checkedIcon={<CheckIcon />}
                checked={dataUpdate?.is_receive_email}
                onChange={handleCheckboxChange}
                name="is_receive_email"
              />}
              label="メールアドレス"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: "Noto Sans JP",
                },
              }}
            />
            <FormControlLabel
              control={
              <CustomCheckbox
                checkedIcon={<CheckIcon />}
                checked={dataUpdate?.is_receive_notification}
                onChange={handleCheckboxChange}
                name="is_receive_notification"
              />}
              label="アプリ通知"
              sx={{
                '& .MuiFormControlLabel-label': {
                  fontFamily: "Noto Sans JP",
                },
              }}
            />
          </FormGroup>
          <Box mt={2} sx={{ textAlign: "end" }}>
            <Button onClick={handleUpdateNotification}>
              {loadingAdminUpdateProfile ? (
                <Stack flexDirection="row" className={styles.btnLoading}>
                  <CircularProgress size={25} />
                </Stack>
              ) : (
                <Stack className={styles.btnCreate} flexDirection="row">
                  <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                  <Box>保存</Box>
                </Stack>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default NotificationInformation;
