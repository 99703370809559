import { Box, Skeleton } from "@mui/material";
import styles from "./styles.module.scss";

const AdminMapSkeleton = () => {
  return (
    <Box>
      <Box sx={{ mt: 2 }}>
        <Skeleton className={styles.labelSkeleton} variant="rectangular" />
        <Skeleton className={styles.inputSkeleton} variant="rectangular" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Skeleton className={styles.labelSkeleton} variant="rectangular" />
        <Skeleton className={styles.inputSkeleton} variant="rectangular" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Skeleton className={styles.labelSkeleton} variant="rectangular" />
        <Skeleton className={styles.inputSkeleton} variant="rectangular" />
      </Box>
      <Box sx={{ mt: 2 }}>
        <Skeleton className={styles.labelSkeleton} variant="rectangular" />
        <Skeleton className={styles.inputSkeleton} variant="rectangular" />
      </Box>
    </Box>
  );
};

export default AdminMapSkeleton;
