/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from "@mui/material";
import styles from "./../styles.module.scss";
import HeaderAdmin from "../../../../components/header/admin/header";
import { HEADER } from "../../../../config-layout";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  getCollectorDetail,
  getTrashCanListForCollector,
} from "../../../../redux/actions/collector";
import avatarDefault from "../../../../../src/assets/images/default.png";
import Iconify from "../../../../components/iconify";
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  useTable,
} from "../../../../components/table";
import CustomPagination from "../../../../components/pagination";
import DetailAssignTableRow from "./table-row";
import paths from "../../../../constants/paths";
import { TableRowSkeleton } from "../../../../components/skeleton/table-row";
import { ITEMS_PER_PAGE, MEDIUM_WIDTH_SCREEN } from "../../../../constants";
import {
  getClientWidth,
  renderAddress,
  truncateText
} from "../../../../helpers";
import Custom404Page from "../../../404";
import statusCode from "../../../../constants/statusCode";
import Loading from "../../../../components/loading";
import BreadcrumbsComponent from "../../../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import pageTitles from "../../../../constants/pageTitles";

export default function CollectorDetail() {
  const [currentPage, setCurrentPage] = useState(1);
  const [activePopup, setActivePopup] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const dataCollector = useSelector((state: any) => state.collector.dataCollectorDetail?.data);
  const dataTrashCans = useSelector((state: any) => state.collector.dataTrashCanListForCollector?.data);
  const paginate = useSelector((state: any) => state.collector.dataTrashCanListForCollector?.paginate);
  const generalError = useSelector((state: any) => state.collector.error?.response?.status);
  const isAdmin404Err = useSelector((state: any) => state?.trash?.isAdmin404Err);

  const { id } = useParams();

  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "収集スタッフ一覧", href: paths.admin.collectorList },
  ];

  const TABLE_HEAD = [
    { id: "id", label: (<Box className={styles.textCommon}>No.</Box>), width: 80 },
    { id: "trashcan_name", label: (<Box className={styles.textCommon}>ゴミ箱</Box>), width: 300 },
    { id: "group_name", label: (<Box className={styles.textCommon}>グループ</Box>), width: 280 },
    { id: "level", label: (<Box className={styles.textCommon}>ステータス</Box>), width: 100 },
    { id: "action", label: (<Box className={styles.textCommon}></Box>), width: 100, align: "center" },
  ];

  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 1,
  });

  const buildQuery = () => {
    const query: any = { pages: currentPage };
    return query;
  };

  const callApiGetTrashCanList = () => {
    if (id) dispatch(getTrashCanListForCollector(buildQuery(), id) as any)
  };

  const handlePopupChange = (id: string | null) => {
    setActivePopup(id);
  };

  useEffect(() => {
    if (id) {
      dispatch(getCollectorDetail(id as any) as any).then(() => setLoadingData(false));
    }
  }, [id]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      dispatch(getTrashCanListForCollector(buildQuery(), id) as any).then((result: any) => {
        setLoading(false);
      });
    }
  }, [currentPage]);

  const handleEditCollector = () => {
    navigate(`${paths.admin.editCollector}/${id}`, { state: { viewInfoFrom: true } });
  }

  const handleAssignCollector = () => {
    navigate(`${paths.admin.assignCollector}/${id}`);
  }

  const renderSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD.length} />
      ))}
    </>
  );

  const renderList = (
    <>
      {dataTrashCans?.map((row: any, index: any) => (
        <DetailAssignTableRow
          key={row.trashcan_id}
          collector_id={id}
          row={row}
          index={(currentPage - 1) * 10 + index + 1}
          actionNext={callApiGetTrashCanList}
          activePopup={activePopup}
          setActivePopup={handlePopupChange}
        />
      ))}
    </>
  );

  const notFound = !dataTrashCans?.length;
  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.admin.collectorDetail}</title>
      </Helmet>
      {(generalError === statusCode.NOTFOUND || isAdmin404Err) ? (
        <Custom404Page />
      ) : (
        <Box>
          <HeaderAdmin />
          {
            dataCollector && !loadingData ?
              (
                <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
                  <Card
                    sx={{ py: 3, marginTop: `${HEADER.H_DESKTOP}px` }}
                    className={styles.cardContainer}>
                    <BreadcrumbsComponent items={breadcrumbItems} currentLabel={dataCollector?.full_name} />
                    <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 mt-4 px-0 ${styles.cardContainer}`} ref={ref}>
                      <Stack flexDirection="row">
                        <Box>
                          <img
                            className={styles.bigAvatar}
                            src={dataCollector?.avatar ? dataCollector?.avatar : avatarDefault}
                            alt="avatar" />
                        </Box>
                        <Box className={styles.boxContainer}>
                          <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="start"
                            className="pb-2">
                            <Box>
                              <Box className={styles.firstInfoCollector}>
                                <Box className={styles.textTitle}>
                                  {truncateText(dataCollector?.full_name, 30)}
                                </Box>
                                <Typography className={`ps-3 ${styles.textCommon}`}>
                                  {dataCollector?.is_active === 1 ? (
                                    <span className={styles.btnActive}>アクティブ</span>
                                  ) : (
                                    <span className={styles.btnInActive}>無効</span>
                                  )}
                                </Typography>
                              </Box>
                              <Box className={styles.textSubtitle} sx={{ mt: 1 }}>
                                {truncateText(dataCollector?.company?.name, 30)}
                              </Box>
                            </Box>
                            <Box sx={{ display: "flex" }}>
                              <Stack onClick={handleEditCollector}>
                                <Button className={styles.btnEdit} sx={{ marginRight: 2 }}>
                                  <Iconify
                                    icon="ic:baseline-edit"
                                    className={styles.iconEdit} />
                                  <Typography
                                    variant="subtitle1"
                                    className={`${styles.btnText} ${styles.textCommon}`}>
                                    収集スタッフ編集
                                  </Typography>
                                </Button>
                              </Stack>
                              {dataCollector?.is_active ? (
                                <Stack onClick={handleAssignCollector}>
                                  <Button className={styles.btnAdd}>
                                    <Iconify
                                      icon="material-symbols:add"
                                      className={styles.iconAdd} />
                                    <Typography
                                      variant="subtitle1"
                                      className={`${styles.btnText} ${styles.textCommon}`}>
                                      割り当て
                                    </Typography>
                                  </Button>
                                </Stack>
                              ) : (
                                <Box></Box>
                              )}

                            </Box>
                          </Stack>
                          <Divider className="my-2" />
                          {renderAddress(dataCollector) && (
                            <Box className={styles.infoUser}>
                              <Iconify
                                className={styles.iconWithSubColor}
                                icon="zondicons:location"
                                width={24} />
                              <Box className={styles.textSubtitle}>
                                {renderAddress(dataCollector)}
                              </Box>
                            </Box>
                          )}
                          <Box className={styles.infoUser}>
                            <Iconify
                              className={styles.iconWithSubColor}
                              icon="ic:baseline-email"
                              width={24}
                            />
                            <Box className={styles.textSubtitle}>
                              {dataCollector?.email}
                            </Box>
                          </Box>
                          {dataCollector?.tel && (
                            <Box className={styles.infoUser}>
                              <Iconify
                                className={styles.iconWithSubColor}
                                icon="gridicons:phone"
                                width={24} />
                              <Box className={styles.textSubtitle}>
                                {dataCollector?.tel}
                              </Box>
                            </Box>
                          )}
                        </Box>
                      </Stack>
                    </Container>

                    {dataCollector?.is_active ? (
                      <>
                        <TableContainer className={styles.tableContainer}>
                          <Table size="small" sx={{ minWidth: 960 }}>
                            <TableHeadCustom
                              className={styles.borderTableHeader}
                              headLabel={TABLE_HEAD}
                              rowCount={dataTrashCans?.length}
                              numSelected={table.selected?.length} />
                            <TableBody className={styles.borderTable}>
                              {loading ? renderSkeleton : renderList}
                              {dataTrashCans && !loading && (
                                <>
                                  <TableEmptyRows
                                    height={56}
                                    emptyRows={emptyRows(
                                      table.page - 1,
                                      table.rowsPerPage,
                                      dataTrashCans.length
                                    )}
                                  />
                                  <TableNoData notFound={notFound} />
                                </>
                              )}
                            </TableBody>
                          </Table>
                        </TableContainer>
                        {dataTrashCans && paginate?.total_page > 1 && (
                          <CustomPagination
                            paginate={paginate}
                            page={currentPage}
                            setPage={setCurrentPage}
                            disabled={paginate?.total_page === 1} />
                        )}
                      </>
                    ) : (
                      <Box></Box>
                    )}
                  </Card>
                </Container>
              ) : (<Loading />)
          }
        </Box>
      )}
    </>
  );
}
