import { parse, isDate, formatDistanceToNow, isValid } from 'date-fns';
import { HIGH_LEVEL_TRASH_CAN, MEDIUM_LEVEL_TRASH_CAN, trashCanLevelColors, trashCanLevelLabels } from '../constants/index'
import { primaryFont } from '../constants/colors'
import { ja } from 'date-fns/locale';
import { isEmpty } from 'lodash';

export const getTrashCanLevelColor = (percentage: any) => {
  if (percentage < 20) return trashCanLevelColors.low;
  if (percentage < 40) return trashCanLevelColors.mediumLow;
  if (percentage < 60) return trashCanLevelColors.medium;
  if (percentage < 80) return trashCanLevelColors.mediumHigh;
  return trashCanLevelColors.high;
};

export const getTrashCanLevelClass = (percentage: any, styles: any) => {
  if (percentage < 20) return styles.trashCanBlockLow;
  if (percentage < 40) return styles.trashCanBlockMediumLow;
  if (percentage < 60) return styles.trashCanBlockMedium;
  if (percentage < 80) return styles.trashCanBlockMediumHigh;
  return styles.trashCanBlockHigh;
};

export const getTrashCanStyleBySuperAdmin = (percentage: any) => {
  if (!percentage || percentage < MEDIUM_LEVEL_TRASH_CAN) return {
    background: trashCanLevelColors.mediumLow,
    label: trashCanLevelLabels.low,
    font: primaryFont
  };

  if (percentage === HIGH_LEVEL_TRASH_CAN) return {
    background: trashCanLevelColors.high,
    label: trashCanLevelLabels.high,
    font: primaryFont
  };

  return {
    background: trashCanLevelColors.medium,
    label: trashCanLevelLabels.medium,
    font: primaryFont
  };
}

export const renderAddress = (data: any) => {
  if (!data) return '';

  const { postal_code, prefecture, city, address } = data;
  if (isEmpty(postal_code) && isEmpty(prefecture) && isEmpty(city) && isEmpty(address)) {
    return '';
  }

  return `〒${postal_code ?? ''} ${prefecture ?? ''}${city ?? ''}${address ?? ''}`;
};

export const checkButtonNotificationTrashCanBySuperAdmin = (dataListTrashByAdmin: any): boolean => {
  return dataListTrashByAdmin?.some((trashCan: any) => trashCan.level >= MEDIUM_LEVEL_TRASH_CAN);
}

export const getNumberTrashCanFull = (trashcans: any) => {
  return trashcans.filter((trashCan: any) => trashCan.level === 100).length;
}

export const truncateText = (text: string, maxLength: number): string => {
  if (text?.length <= maxLength) {
    return text;
  }

  let truncatedText = '';
  let length = 0;

  for (let i = 0; i < text?.length; i++) {
    const char = text.charAt(i);
    const charWidth = /[\u0800-\u4e00]/.test(char) ? 2 : 1;
    length += charWidth;

    if (length > maxLength) {
      return `${truncatedText.substring(0, i)}...`;
    }

    truncatedText += char;
  }

  return truncatedText;
};

export const filterEmptyFields = (data: any) => {
  const filteredData: any = {};
  Object.keys(data).forEach(key => {
    const value = data[key];
    if (
      value !== null &&
      value !== undefined &&
      value !== '' &&
      (!Array.isArray(value) || value.length > 0)
    ) {
      filteredData[key] = value;
    }
  });

  return filteredData;
};

export const parseDateString = (value: any, originalValue: any) => {
  const parsedDate = isDate(originalValue) ? originalValue : parse(originalValue, 'yyyy-MM-dd', new Date());
  return isValid(parsedDate) ? parsedDate : new Date('');
};

export const checkDuplicateValues = (array: any) => {
  const uniqueValues = new Set(array);
  return uniqueValues.size !== array.length;
};

export const getRelativeTime = (date: Date) => {
  return formatDistanceToNow(new Date(date.getTime()), { addSuffix: true, locale: ja });
}

export const isValidDate = (date: Date) => {
  if (!(date instanceof Date) || isNaN(date.getTime())) {
    return false;
  }

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();

  if (year > 9999 || year < 1000) {
    return false;
  }

  if (month < 0 || month > 11) {
    return false;
  }

  const daysInMonth = new Date(year, month + 1, 0).getDate();
  if (day < 1 || day > daysInMonth) {
    return false;
  }

  return true;
}

export const getClientWidth = (setClientWidth: any, ref: any) => {
  const updateWidth = () => {
    if (ref.current) {
      setClientWidth(ref.current.offsetWidth);
    }
  };

  updateWidth();

  window.addEventListener('resize', updateWidth);

  return () => {
    window.removeEventListener('resize', updateWidth);
  };
}

export const getAddress = (results: any) => {
  let address = results[0].formatted_address;

  const addressComponents = results[0].address_components;

  // const premise = addressComponents.find((item: any) => item?.types.includes('premise'))?.long_name || '';
  const country = addressComponents.find((item: any) => item?.types.includes('country'))?.long_name;
  const plusCode = addressComponents.find((item: any) => item?.types.includes('plus_code'))?.long_name;

  const removePartsRegex = new RegExp(`\\b(${country}|${plusCode})\\b`, 'gi');
  const newAddress = address
    .replace(address?.includes(`${country}、`) ? country + '、' : country, '')
    .replace(removePartsRegex, '')
    .replace(/\s*,\s*/g, ', ')
    .replace(/,,+/g, ', ')
    .replace(/^\s*,\s*|\s*,\s*$/g, '')
    .replace(/\s{2,}/g, ' ')
    .trim();

  return newAddress;
}

export const regexPostalCode = /^\d{3}-?\d{4}$/;
