import HeaderAdmin from "../../../components/header/admin/header";
import React, {useEffect, useRef, useState} from "react";
import styles from "./styles.module.scss";
import {Box, Container, Grid, Stack, Typography, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import avatarDefault from "../../../assets/images/default.png";
import PersonIcon from '@mui/icons-material/Person';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import LockIcon from '@mui/icons-material/Lock';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import {borderColor, primaryFont} from "../../../constants/colors";
import NotificationInformation from "../../../components/notification-setting";
import PasswordInformation from "../../../components/password-information";
import ContactInformation from "../../../components/contact-information";
import PersonalInformation from "../../../components/personal-information";
import {useDispatch, useSelector} from "react-redux";
import {AdminUpdateProfile, clearData, getProfileAdmin} from "../../../redux/actions/admin";
import paths from "../../../constants/paths";
import {useNavigate} from "react-router-dom";
import {ACTIVE, DEFAULT_LENGTH_TEXT, MEDIUM_WIDTH_SCREEN, TYPE_UPDATE_PROFILE, WEB_SOCKET_URL} from "../../../constants";
import {fetchUserLogin, logout} from "../../../redux/actions/auth";
import {errorSnackbar} from "../../../commons/snackbar";
import { getClientWidth, truncateText } from "../../../helpers";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

const AdminProfile = () => {
  const [value, setValue] = useState(0);
  const borderColors = borderColor;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  useEffect(() => {
    dispatch(clearData() as any);
    // eslint-disable-next-line
  }, [value]);

  const handleLogout = async (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    const result = await dispatch(logout() as any);

    if (result) {
      navigate(paths.auth.login);
      const socket = new WebSocket(WEB_SOCKET_URL);
      socket.close();
    }
  };
  const dataAdminProfile = useSelector((state: any) => state?.admin?.dataAdminProfile?.data);
  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  const callApiGetProfile = async () => {
    await dispatch(getProfileAdmin() as any);
    await dispatch(fetchUserLogin() as any)
  };

  useEffect(() => {
    dispatch(getProfileAdmin() as any)
    // eslint-disable-next-line
  }, []);

  const handleFileInputChange = async (event: any) => {
    const files = event.target.files[0];
    if (files?.type.includes('image')) {
      files.preview = URL.createObjectURL(files);
      const newFile = new File([files], getNameFile(files.name));
      const formData = {
        avatar: newFile,
      }
      const result = await dispatch(AdminUpdateProfile(formData, TYPE_UPDATE_PROFILE.AVATAR) as any);
      if (result?.code === 200) {
        await callApiGetProfile();
      }
    } else {
      errorSnackbar('写真をアップロードできませんでした。アップロードする写真は4 MB以下で、フォーマットはJPG、JPEG、PNG、SVG、HEIFのいずれかを使用してください。');
      event.target.value = null;
    }
  }

  function getNameFile(string: any) {
    const getName = string.split(".")[0];
    const type = string.split(".").pop();
    return changeToSlug(getName) + "." + type;
  }

  function changeToSlug(string: string) {
    string = string.toLowerCase();
    string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    string = string.replace(/[đĐ]/g, "d");
    string = string.replace(/([^0-9a-z-\s])/g, "");
    string = string.replace(/(\s+)/g, "-");
    string = string.replace(/-+/g, "-");
    return string;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <>{children}</>
          </Box>
        )}
      </div>
    );
  }
  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.admin.profile}</title>
      </Helmet>
      <HeaderAdmin />
      <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
        <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ marginRight: "20px" }}>
              <Typography variant="h5" className={styles.titlePage}>プロフィールを編集</Typography>
            </Box>
          </Box>
        </Stack>

        <Grid container spacing={2} mt={3}>
          <Grid item xs={12} md={4}>
            <Box className={styles.sectionMenu}>
              <Box>
                <img className={styles.imageAvatar} src={dataAdminProfile?.avatar || avatarDefault} alt="avatar"/>
              </Box>
              <Box>
                <Box className={styles.sectionName} sx={{display: "flex", flexWrap: "wrap"}}>
                  <Typography mb={1} className={`${styles.fullName} ${styles.truncateText}`}>{dataAdminProfile?.full_name}
                  </Typography>
                  <Typography className={styles.status}>
                    {dataAdminProfile?.is_active === ACTIVE ? (<span className={styles.btnActive}>
                    アクティブ
                  </span>) : (<span style={{marginLeft: "15px", fontSize: "14px"}} className={styles.btnInActive}>
                    無効
                  </span>)}
                  </Typography>
                </Box>
                <Typography className={styles.textInfoAdmin} mb={1}>Admin</Typography>
                <Typography className={styles.textInfoAdmin}>{truncateText(dataAdminProfile?.company?.name, DEFAULT_LENGTH_TEXT)}</Typography>
              </Box>
            </Box>

            <Box mt={3}>
              <label htmlFor="upload-image" className={styles.btnUploadImage}>
                プロフィール画像
                <input
                  id="upload-image"
                  type="file"
                  accept="image/*"
                  hidden
                  onChange={handleFileInputChange}
                />
              </label>
            </Box>

            <List component="nav">
              <ListItem
                button
                selected={value === 0}
                onClick={(event) => handleChange(event, 0)}
                sx={{
                  borderBottom: `1px solid ${borderColors}`,
                  borderTop: `1px solid ${borderColors}`,
                }}
                className={value === 0 ? styles.selectedItem : ""}
              >
                <ListItemIcon className={value === 0 ? styles.selectedIcon : ""}>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary="個人情報変更" primaryTypographyProps={{fontSize: '14px', fontFamily: primaryFont}} className={`${value} === 0 ? ${styles.selectedText} ${styles.textCommon} ${styles.primaryFontSize} : ""`}/>
                <ListItemIcon>
                  <ArrowForwardIosIcon className={styles.iconArrow} />
                </ListItemIcon>
              </ListItem>
              <ListItem
                button
                selected={value === 1}
                onClick={(event) => handleChange(event, 1)}
                sx={{
                  borderBottom: `1px solid ${borderColors}`,
                }}
                className={value === 1 ? styles.selectedItem : ""}
              >
                <ListItemIcon className={value === 1 ? styles.selectedIcon : ""}>
                  <PhoneIphoneIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontSize: '14px', fontFamily: primaryFont}} primary="連絡情報変更" className={value === 1 ? styles.selectedText : ""} />
                <ListItemIcon>
                  <ArrowForwardIosIcon className={styles.iconArrow} />
                </ListItemIcon>
              </ListItem>
              <ListItem
                button
                selected={value === 2}
                onClick={(event) => handleChange(event, 2)}
                sx={{
                  borderBottom: `1px solid ${borderColors}`,
                }}
                className={value === 2 ? styles.selectedItem : ""}
              >
                <ListItemIcon className={value === 2 ? styles.selectedIcon : ""}>
                  <LockIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontSize: '14px', fontFamily: primaryFont}} primary="パスワード変更" className={value === 2 ? styles.selectedText : ""} />
                <ListItemIcon>
                  <ArrowForwardIosIcon className={styles.iconArrow} />
                </ListItemIcon>
              </ListItem>
              <ListItem
                button
                selected={value === 3}
                onClick={(event) => handleChange(event, 3)}
                sx={{
                  borderBottom: `1px solid ${borderColors}`,
                }}
                className={value === 3 ? styles.selectedItem : ""}
              >
                <ListItemIcon className={value === 3 ? styles.selectedIcon : ""}>
                  <SettingsIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontSize: '14px', fontFamily: primaryFont}} primary="通知設定" className={value === 3 ? styles.selectedText : ""} />
                <ListItemIcon>
                  <ArrowForwardIosIcon className={styles.iconArrow} />
                </ListItemIcon>
              </ListItem>
              <ListItem
                button
                selected={value === 4}
                onClick={(event) => handleLogout(event, 4)}
                sx={{
                  borderBottom: `1px solid ${borderColors}`,
                }}
                className={value === 4 ? styles.selectedItem : ""}
              >
                <ListItemIcon className={value === 4 ? styles.selectedIcon : ""}>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primaryTypographyProps={{fontSize: '14px', fontFamily: primaryFont}} primary="ログアウト" className={value === 4 ? styles.selectedText : ""} />
                <ListItemIcon>
                  <ArrowForwardIosIcon className={styles.iconArrow} />
                </ListItemIcon>
              </ListItem>
            </List>
          </Grid>
          <Grid item xs={12} md={8}>
            <TabPanel value={value} index={0}>
              <PersonalInformation
                dataAdminProfile={dataAdminProfile}
                actionNext={callApiGetProfile}
              />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <ContactInformation
                dataAdminProfile={dataAdminProfile}
                actionNext={callApiGetProfile}
              />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <PasswordInformation
                actionNext={callApiGetProfile}
              />
            </TabPanel>
            <TabPanel value={value} index={3}>
              <NotificationInformation
                dataAdminProfile={dataAdminProfile}
                actionNext={callApiGetProfile}
              />
            </TabPanel>
            <TabPanel value={value} index={4}>
            </TabPanel>
          </Grid>
        </Grid>

      </Container>
    </>
  )
}

export default AdminProfile;
