const pageTitles = {
  auth: {
    login: "ログイン画面",
    adminSetPassword: "管理者｜パスワード再設定",
    adminForgotPassword: "管理者｜パスワード再設定",
    adminResetPassword: "管理者｜パスワード再設定",
    collectorSetPassword: "収集スタッフ｜パスワード再設定",
    collectorForgotPassword: "収集スタッフ｜パスワード再設定",
    collectorResetPassword: "収集スタッフ｜パスワード再設定"
  },

  admin: {
    notificationList: "管理者｜お知らせ",
    collectorList: "管理者｜収集スタッフ一覧",
    editCollector: "管理者｜収集スタッフの編集",
    collectorDetail: "管理者｜収集スタッフの詳細",
    assignCollector: "管理者｜収集スタッフの割り当て",
    trashCanList: "管理者｜ゴミ箱の管理",
    trashCanDetail: "管理者｜ゴミ箱の編集",
    profile: "管理者｜プロフィールを編集",
  },

  superAdmin: {
    adminList: "スーパーアドミン｜管理者の管理",
    adminDetail: "スーパーアドミン｜管理者の詳細",
    adminInfo: "スーパーアドミン｜管理者の詳細",
    companyList: "スーパーアドミン｜会社の管理",
    companyDetail: "スーパーアドミン｜会社情報を編集",
    companyInfo: "スーパーアドミン｜会社の詳細",
    trashList: "スーパーアドミン｜ゴミ箱の管理",
    addTrash: "スーパーアドミン｜ゴミ箱の追加",
    trashDetail: "スーパーアドミン｜ゴミ箱の編集"
  }
}

export default pageTitles;
