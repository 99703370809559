/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Box, Button, Container, Stack, Table, TableBody, TableContainer, Typography } from "@mui/material";
import HeaderSuperAdmin from "../../../components/header/super-admin/header";
import styles from './styles.module.scss';
import Iconify from "../../../components/iconify";
import InputBase from "../../../components/input/inputBase";
import { TableEmptyRows, TableHeadCustom, TableNoData, emptyRows, useTable } from "../../../components/table";
import InputSelectBase from "../../../components/input/inputSelect";
import SuperAdminTrashCanTableRow from "./table-row";
import { useDispatch, useSelector } from "react-redux";
import { getListTrashBySuperAdmin } from "../../../redux/actions/trashcan";
import {
  defaultOption,
  ITEMS_PER_PAGE,
  LAT_DEFAULT,
  LIST_TYPE, LONG_DEFAULT,
  MAP_TYPE,
  MEDIUM_WIDTH_SCREEN,
  trashCanLevelOptions
} from "../../../constants";
import { useLocation, useNavigate } from "react-router-dom";
import CustomPagination from "../../../components/pagination";
import { isEmpty } from "lodash";
import { TableRowSkeleton } from "../../../components/skeleton/table-row";
import paths from "../../../constants/paths";
import Checkbox from "@mui/material/Checkbox";
import DialogAddCompanyToTrash from "../../../components/dialog/dialogAddCompanyToTrash";
import { getAllCompany } from "../../../redux/actions/company";
import CustomMapSuperAdmin from "../../../components/super-admin-maps";
import { getClientWidth } from "../../../helpers";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

type CompanyOption = {
  id: string,
  name: string
}

const TrashList = () => {
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const [filters, setFilters] = useState({ trashCanName: '', groupName: '', status: '', companyName: '', orderBy: '', order: 'asc' });
  const [trashCanName, setTrashCanName] = useState('');
  const [status, setStatus] = useState('');
  const [groupName, setGroupName] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [typePage, setTypePage] = useState(LIST_TYPE);
  const [currentPage, setCurrentPage] = useState(backPage ?? 1);
  const [markerLocation, setMarkerLocation] = useState<any>({
    lat: LAT_DEFAULT,
    long: LONG_DEFAULT,
    isDefaultLocation: false,
  });
  const [markerLocationDefault, setMarkerLocationDefault] = useState<any>();
  const [loading, setLoading] = useState(true);
  const [checkBoxAll, setCheckBoxAll] = useState<any>(false);
  const [dataCheckBox, setDataCheckBox] = useState<any>([]);
  const [openDialogAddCompanyToTrash, setOpenDialogAddCompanyToTrash] = useState<any>(false);

  const dispatch = useDispatch();
  const dataListTrash = useSelector((state: any) => state?.trash?.dataListTrash?.data);
  const dataCompany = useSelector((state: any) => state.company.dataGetAllCompany?.data);
  const paginate = useSelector((state: any) => state?.trash?.dataListTrash?.paginate)
  const navigate = useNavigate();

  const companyOptions = dataCompany?.map((company: CompanyOption) => ({
    value: company.id,
    label: company.name
  }));

  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 1,
  });

  const buildQuery = () => {
    const query: any = {
      pages: currentPage,
    };

    if (!isEmpty(filters.trashCanName)) {
      query.trashcan_name = filters.trashCanName;
    }

    if (!isEmpty(filters.status.toString())) {
      query.status = filters.status;
    }

    if (!isEmpty(filters.groupName)) {
      query.group_name = filters.groupName;
    }

    if (!isEmpty(filters.companyName)) {
      query.company_name = filters.companyName;
    }

    if (!isEmpty(filters.orderBy)) {
      query.orderBy = filters.orderBy;
      query.order = filters.order;
    }

    return query;
  };
  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });

        if (permission.state === 'granted' || permission.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (pos: any) => {
              const coords = pos.coords;
              setMarkerLocationDefault({
                lat: coords.latitude,
                long: coords.longitude,
                isDefaultLocation: true,
              });
            },
            (error) => {
              console.error(error);
              // Set default location on error
              setMarkerLocationDefault({
                lat: LAT_DEFAULT,
                long: LONG_DEFAULT,
                isDefaultLocation: true,
              });
            }
          );
        } else {
          setMarkerLocationDefault({
            lat: LAT_DEFAULT,
            long: LONG_DEFAULT,
            isDefaultLocation: true,
          });
        }
      } catch (error) {
        console.error(error);
        setMarkerLocationDefault({
          lat: LAT_DEFAULT,
          long: LONG_DEFAULT,
          isDefaultLocation: true,
        });
      }
    };

    fetchLocation();
  }, []);

  useEffect(() => {
    if (checkBoxAll) {
      const trashCanIds = dataListTrash.map((item: any) => item.trashcan_id);
      setDataCheckBox(trashCanIds);
    } else {
      setDataCheckBox([]);
    }
  }, [checkBoxAll]);

  useEffect(() => {
    setCheckBoxAll(false);
  }, [currentPage]);

  useEffect(() => {
    dispatch(getAllCompany() as any)
  }, [])

  const handleCheckBoxAll = () => {
    setCheckBoxAll(!checkBoxAll);
  }

  const TABLE_HEAD = [
    {
      id: 'checkbox',
      label: (
        <Checkbox
          checked={checkBoxAll}
          onChange={handleCheckBoxAll}
        />
      ),
      width: 40
    },
    { id: "id", label: (<Box className={`${styles.textCommon} ${styles.tableHeadColor}`}>No.</Box>), width: 80 },
    {
      id: "trashcan_name",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer} ${styles.tableHeadColor}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('trashcan_name')}
        >
          <Box className={`${styles.textCommon} ${styles.titleStatus}`}>ゴミ箱</Box>
          {filters.orderBy === 'trashcan_name' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 370,
    },
    {
      id: "group_name",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer} ${styles.tableHeadColor}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('group_name')}
        >
          <Box className={`${styles.textCommon} ${styles.titleStatus}`}>グループ</Box>
          {filters.orderBy === 'group_name' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 180,
    },
    {
      id: "level",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer} ${styles.tableHeadColor}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('level')}
        >
          <Box className={`${styles.textCommon} ${styles.titleStatus}`}>ステータス</Box>
          {filters.orderBy === 'level' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 180,
    },
    {
      id: "company",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer} ${styles.tableHeadColor}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('company_name')}
        >
          <Box className={`${styles.textCommon} ${styles.titleStatus}`}>会社名</Box>
          {filters.orderBy === 'company_name' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 270,
    },
    { id: "action", label: (<Box className={`${styles.textCommon} ${styles.tableHeadColor}`}></Box>), width: 100, align: 'center' },
  ];

  const handleSort = (column: any) => {
    let order = 'asc';
    if (filters.orderBy === column && filters.order === 'asc') {
      order = 'desc';
    }
    setFilters({
      ...filters,
      orderBy: column,
      order,
    });
    setDataCheckBox([]);
    setCheckBoxAll(false);
  };

  const callApiGetListTrash = () => {
    dispatch(getListTrashBySuperAdmin(buildQuery()) as any);
    setDataCheckBox([]);
    setCheckBoxAll(false);
  };

  const handleAddCompanyToTrash = () => {
    setOpenDialogAddCompanyToTrash(true);
  }

  useEffect(() => {
    setLoading(true);
    dispatch(getListTrashBySuperAdmin(buildQuery()) as any).then((result: any) => {
      if (typePage === MAP_TYPE && result) {
        const [dataTrash] = result?.data;
        setMarkerLocation({
          ...dataTrash,
          isDefaultLocation: true,
        });
      }
      setLoading(false);
    });
  }, [currentPage, filters]);

  const renderSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD.length} />
      ))}
    </>
  );

  const renderList = (
    dataListTrash?.map((row: any, index: any) => (
      <SuperAdminTrashCanTableRow
        key={row.trashcan_id}
        id={row.trashcan_id}
        row={row}
        typePage={typePage}
        setTypePage={setTypePage}
        markerLocation={markerLocation}
        dataCheckBox={dataCheckBox}
        setDataCheckBox={setDataCheckBox}
        setMarkerLocation={setMarkerLocation}
        index={(currentPage - 1) * 10 + index + 1}
        page={currentPage}
      />
    ))
  );

  const handleInputChange = useCallback(
    (name: string, value: any) => {
      switch (name) {
        case 'trashCanName':
          setTrashCanName(value);
          break;
        case 'status':
          setStatus(value);
          break;
        case 'groupName':
          setGroupName(value);
          break;
        case 'companyName':
          setCompanyName(value);
          break;
        default:
          setFilters((prevState: any) => ({
            ...prevState,
            [name]: value,
          }));
          break;
      }
    }, [],
  );

  const handleSearchTrashCan = (isEmpty: boolean = false) => {
    if (isEmpty) {
      setTrashCanName('');
      setStatus('');
      setGroupName('');
      setCompanyName('');
      setFilters((prevState) => ({
        ...prevState,
        trashCanName: '',
        status: '',
        groupName: '',
        companyName: '',
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        trashCanName: trashCanName,
        status: status,
        groupName: groupName,
        companyName: companyName,
      }));
    }
    setCurrentPage(1);
  }

  const notFound = dataListTrash && (dataListTrash?.length === 0);

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.trashList}</title>
      </Helmet>
      <HeaderSuperAdmin />
      <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
        <Stack
          alignItems="center"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <Typography variant="h5" className={styles.titlePage}>
              ゴミ箱の管理
            </Typography>
          </Box>
          {
            dataCheckBox?.length === 0 ? (
              <Box>
                <Button
                  className={`${styles.btnTabs1} ${typePage === MAP_TYPE ? styles.btnSelected : ""
                    }`}
                  onClick={() => setTypePage(MAP_TYPE)}
                >
                  <Iconify
                    icon="zondicons:location"
                    width={20}
                    className={styles.iconChild}
                  />
                  <Typography
                    sx={{ marginLeft: "5px" }}
                    variant="subtitle1"
                    className={styles.btnText}
                  >
                    マップ
                  </Typography>
                </Button>
                <Button
                  className={`${styles.btnTabs2} ${typePage === LIST_TYPE ? styles.btnSelected : ""
                    }`}
                  onClick={() => {
                    setTypePage(LIST_TYPE)
                    dispatch(getListTrashBySuperAdmin(buildQuery()) as any)
                  }}
                  sx={{ marginRight: 2 }}
                >
                  <Iconify
                    icon="charm:menu-hamburger"
                    width={20}
                    className={styles.iconChild}
                  />
                  <Typography
                    sx={{ marginLeft: "5px" }}
                    variant="subtitle1"
                    className={styles.btnText}
                  >
                    一覧
                  </Typography>
                </Button>
                <Button
                  className={styles.btnAddSpa}
                  onClick={() => navigate(paths.superAdmin.addTrash)}
                >
                  <Iconify
                    icon="material-symbols:add"
                    className={styles.iconAdd}
                  />
                  <Typography variant="subtitle1" className={`${styles.btnText} ${styles.textCommon}`}>
                    ゴミ箱を追加
                  </Typography>
                </Button>
              </Box>
            ) : (
              <Box>
                <Button className={styles.btnAddSpa} onClick={handleAddCompanyToTrash}>
                  <Iconify icon="material-symbols:add" className={styles.iconAdd} />
                  <Typography variant="subtitle1" className={styles.btnText}>割り当て</Typography>
                </Button>
              </Box>
            )
          }
        </Stack>
        <Stack direction="row" alignItems="end" gap={2}>
          <Box
            gap={2}
            sx={{ pt: 3, display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', width: '100%' }}
            className={styles.searchContainer}
          >
            <Box>
              <Box className={styles.titleInput}>ゴミ箱</Box>
              <InputBase
                name="trashCanName"
                keyword="trashCanName"
                type="text"
                className={styles.inputName}
                size="small"
                placeholder="ゴミ箱"
                handleChange={handleInputChange}
                value={trashCanName}
              />
            </Box>
            <Box>
              <Box className={styles.titleInput}>グループ</Box>
              <InputBase
                name="groupName"
                keyword="groupName"
                type="text"
                size="small"
                className={styles.inputName}
                placeholder="グループ"
                handleChange={handleInputChange}
                value={groupName}
              />
            </Box>
            <Box>
              <Box className={styles.titleInput}>ステータス</Box>
              <InputSelectBase
                name="status"
                keyword="status"
                type="text"
                className={styles.inputName}
                size="small"
                defaultOption={defaultOption}
                handleChange={(keyword: any, value: any) =>
                  handleInputChange(keyword, value)
                }
                placeholder="ステータス"
                options={trashCanLevelOptions}
                customChipItemTrashCan={true}
                isEdit={true}
                value={status}
              />
            </Box>
            <Box>
              <Box className={styles.titleInput}>会社名</Box>
              <InputBase
                name="companyName"
                keyword="companyName"
                type="text"
                className={styles.inputName}
                placeholder="会社名"
                size="small"
                handleChange={handleInputChange}
                value={companyName}
              />
            </Box>
          </Box>
          <Stack flexDirection="row" gap={1} className={styles.searchContainer}>
            <Button className={styles.btnSearchSpa} onClick={() => handleSearchTrashCan(false)}>
              <Typography className={styles.searchText}>検索</Typography>
            </Button>
            <Button className={styles.btnCancel} onClick={() => handleSearchTrashCan(true)}>
              <Typography className={styles.searchText}>クリア</Typography>
            </Button>
          </Stack>
        </Stack>
        <>
          {typePage === LIST_TYPE ? (
            <Box sx={{ padding: 0 }}>
              <TableContainer className={styles.tableContainer}>
                <Table size="small" sx={{ minWidth: 960 }}>
                  <TableHeadCustom
                    className={styles.borderTableHeader}
                    headLabel={TABLE_HEAD}
                    rowCount={dataListTrash?.length}
                    numSelected={table.selected?.length}
                    isTableTrash={true}
                  />
                  <TableBody className={styles.borderTable}>
                    {loading ? renderSkeleton : renderList}
                    {dataListTrash && !loading && (
                      <>
                        <TableEmptyRows
                          height={56}
                          emptyRows={emptyRows(
                            table.page - 1,
                            table.rowsPerPage,
                            dataListTrash.length
                          )}
                        />
                        <TableNoData notFound={notFound} />
                      </>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              {dataListTrash?.length > 0 && paginate?.total_page > 1 && (
                <CustomPagination
                  paginate={paginate}
                  page={currentPage}
                  setPage={setCurrentPage}
                  disabled={paginate?.total_page === 1}
                />
              )}
            </Box>
          ) : (
            <Box>
              <CustomMapSuperAdmin
                dataListTrash={dataListTrash}
                markerLocation={markerLocation}
                markerLocationDefault={markerLocationDefault}
              />
            </Box>
          )}
        </>
      </Container>
      <DialogAddCompanyToTrash
        openDialogAddCompanyToTrash={openDialogAddCompanyToTrash}
        setOpenDialogAddCompanyToTrash={setOpenDialogAddCompanyToTrash}
        companyOptions={companyOptions}
        dataTrashCanIdCheckBox={dataCheckBox}
        actionNext={callApiGetListTrash}
      />
    </>
  );
}
export default TrashList;
