import {
  Box,
  Chip,
  Grid,
  Popover,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import styles from "./../styles.module.scss";
import { getTrashCanStyleBySuperAdmin } from "../../../../helpers";
import Iconify from "../../../../components/iconify";
import { useEffect, useState } from "react";
import { getListAllCollectorsByTrashCanId } from "../../../../redux/actions/collector";
import { useDispatch, useSelector } from "react-redux";
import avatarDefault from "../../../../../src/assets/images/default.png";

type Props = {
  row: any;
  index: any;
  isCancel: boolean;
  onValueChange: (id: string) => void;
};

export default function AssignCollectorTableRow({
  row,
  index,
  isCancel,
  onValueChange,
}: Props) {
  const {
    trashcan_id,
    trashcan_name,
    level,
    collectors,
    total_collectors,
  } = row;
  const [checked, setChecked] = useState(false);
  const chipStyle = getTrashCanStyleBySuperAdmin(level);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const allCollectors = useSelector(
    (state: any) => state?.collector?.dataAllCollectorsByTrashCan?.data
  );
  const open = Boolean(anchorEl);
  const idPopover = open ? "simple-popover" : undefined;

  const handleAssign = () => {
    setChecked(!checked);
    onValueChange(trashcan_id);
  };

  useEffect(() => {
    setChecked(false);
  }, [isCancel]);

  const handleGetAllCollectorsByTrashCan = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    dispatch(getListAllCollectorsByTrashCanId(row?.trashcan_id) as any);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const renderCollectors = (collectors: any[]) => {
    const maxDisplay = 5;
    const displayedCollectors = collectors.slice(0, maxDisplay);

    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {displayedCollectors.map((collector) => (
            <Tooltip key={collector.id} title={collector.full_name} arrow>
              <Box className={styles.managerAvatar} sx={{ mr: 1 }}>
                {collector.avatar ? (
                  <img
                    className={styles.imageAssignAvatar}
                    src={collector.avatar}
                    alt={collector.full_name}
                  />
                ) : (
                  <img className={styles.imageAssignAvatar} src={avatarDefault} alt={collector.full_name} />
                )}
              </Box>
            </Tooltip>
          ))}
        </Box>
        {total_collectors > maxDisplay && (
          <Iconify
            icon="ri:more-fill"
            width={30}
            sx={{ ml: 2, cursor: "pointer" }}
            onClick={handleGetAllCollectorsByTrashCan}
          />
        )}
      </Box>
    );
  };

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.longText2}`}>
          {trashcan_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          グループ #1
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          <Chip
            size="small"
            label={chipStyle.label}
            sx={{
              background: chipStyle.background,
              color: "white",
              fontWeight: "bold",
              fontFamily: chipStyle.font,
            }}
          />
        </Typography>
      </TableCell>

      <TableCell>{collectors ? renderCollectors(collectors) : null}</TableCell>

      <TableCell className={styles.actions}>
        <Box display="flex" justifyContent="center">
          {row.is_assigned ? (
            <Iconify
              className={checked ? styles.iconChecked : styles.iconNotChecked}
              icon={checked ? "material-symbols:check" : "ic:baseline-minus"}
              width={24}
              onClick={handleAssign}
            />
          ) : (
            <Iconify
              className={checked ? styles.iconChecked : styles.iconNotChecked}
              icon={checked ? "material-symbols:check" : "material-symbols:add"}
              width={24}
              onClick={handleAssign}
            />
          )}
        </Box>
      </TableCell>

      <Popover
        id={idPopover}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        className={`${styles.popover} ${styles.popUpMoreCollector}`}
      >
        <Box sx={{ p: 2 }}>
          <Typography variant="subtitle1">{trashcan_name}の収集スタッフ一覧</Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {allCollectors?.map((collector: any) => (
              <Grid container alignItems="center" spacing={2} sx={{ pt: 2 }}>
                <Grid item>
                  <Box className={styles.managerAvatarPopup}>
                    {collector.avatar ? (
                      <img
                        className={`${styles.pointer} ${styles.imageAssignAvatar}`}
                        src={collector.avatar}
                        alt={collector.full_name}
                      />
                    ) : (
                      <img className={`${styles.pointer} ${styles.imageAssignAvatar}`} src={avatarDefault} alt={collector.full_name} />
                    )}
                  </Box>
                </Grid>
                <Grid item className={styles.popUpCollector}>
                  <Box className={styles.longText2}>{collector?.full_name}</Box>
                </Grid>
              </Grid>
            ))}
          </Box>
        </Box>
      </Popover>
    </TableRow>
  );
}
