import { Box, Button, Container, InputAdornment, Stack, Table, TableBody, TableContainer, Typography } from "@mui/material";
import HeaderSuperAdmin from "../../../components/header/super-admin/header";
import styles from './styles.module.scss';
import Iconify from "../../../components/iconify";
import InputBase from "../../../components/input/inputBase";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { TableEmptyRows, TableHeadCustom, TableNoData, emptyRows, useTable } from "../../../components/table";
import AdminTableRow from "./admin-table-row";
import { useBoolean } from "../../../hooks/use-boolean";
import { useDispatch, useSelector } from 'react-redux';
import { getListAdmin } from '../../../redux/actions/admin';
import AddNewAdmin from './add-new-admin';
import { getAllCompany } from '../../../redux/actions/company';
import { isEmpty } from "lodash";
import CustomPagination from "../../../components/pagination";
import { ITEMS_PER_PAGE, MEDIUM_WIDTH_SCREEN } from "../../../constants";
import { TableRowSkeleton } from "../../../components/skeleton/table-row";
import { getClientWidth } from "../../../helpers";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

const AdminList = () => {
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const [filters, setFilters] = useState({ search: '', orderBy: '', order: 'asc' });
  const [loading, setLoading] = useState(true);
  const [searchValue, setSearchValue] = useState('');
  const [isBackToPage1, setIsBackToPage1] = useState(false);

  const TABLE_HEAD = [
    { id: "id.", label: (<Box className={styles.textCommon}>No.</Box>), width: 80 },
    { id: "full_name",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('full_name')}
        >
          <Box className={styles.textCommon}>名前</Box>
          {filters.orderBy === 'full_name' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 220
    },
    { id: "email",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('email')}
        >
          <Box className={styles.textCommon}>メールアドレス</Box>
          {filters.orderBy === 'email' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 240
    },
    { id: "tel", label: (<Box className={styles.textCommon}>電話番号</Box>), width: 200 },
    {
      id: "is_active",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('is_active')}
        >
          <Box className={styles.titleStatus}>ステータス</Box>
          {filters.orderBy === 'is_active' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 220,
    },
    {
      id: "company_name",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('company_name')}
        >
          <Box className={styles.titleStatus}>会社名</Box>
          {filters.orderBy === 'company_name' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 220,
    },
    { id: "", label: (<Box className={styles.textCommon}>その他</Box>), width: 120 },
  ];

  const [currentPage, setCurrentPage] = useState(backPage ?? 1);
  const [activePopup, setActivePopup] = useState<string | null>(null);

  const dispatch = useDispatch();
  const dataAdmin = useSelector((state: any) => state.admin.dataAdminList?.data);
  const paginate = useSelector((state: any) => state.admin.dataAdminList?.paginate);

  const handleInputChange = useCallback((name: string, value: any) => {
    if (name === 'search') {
      setSearchValue(value);
    } else {
      setFilters((prevState: any) => ({
        ...prevState,
        [name]: value,
      }));
    }
  }, []);

  const handleSort = (column: string) => {
    let order = 'asc';
    if (filters.orderBy === column && filters.order === 'asc') {
      order = 'desc';
    }
    setFilters({
      ...filters,
      orderBy: column,
      order,
    });
  };

  const handleSearchAdmin = (isEmpty: boolean = false) => {
    if (isEmpty) {
      setSearchValue('');

      setFilters((prevState) => ({
        ...prevState,
        search: '',
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        search: searchValue,
      }));
    }
    setCurrentPage(1);
  };

  const buildQuery = () => {
    const query: any = {
      pages: currentPage,
    };

    if (!isEmpty(filters.search)) {
      query.full_name = filters.search;
    }

    if (!isEmpty(filters.orderBy)) {
      query.orderBy = filters.orderBy;
      query.order = filters.order;
    }
    return query;
  };

  const callApiGetListAdmin = () => {
    dispatch(getListAdmin(buildQuery()) as any);
  };

  const table = useTable(
    {
      defaultRowsPerPage: 10,
      defaultCurrentPage: 1,
    }
  );
  useEffect(() => {
    setLoading(true);
    dispatch(getListAdmin(buildQuery()) as any).then(() => {
      setLoading(false);
    });
  }, [currentPage, filters]);

  const defaultNewValues = {
    full_name: '',
    company_id: '',
    postal_code: '',
    tel: '',
    prefecture: '',
    city: '',
    address: '',
  };

  const openAddNewAdminDialog = useBoolean(false);

  const handleAddNewAdmin = () => {
    openAddNewAdminDialog.onTrue();
    dispatch(getAllCompany() as any);
  };

  const handlePopupChange = (id: string | null) => {
    setActivePopup(id);
  };

  const renderSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD.length} />
      ))}
    </>
  );

  const renderList = (<>
    {dataAdmin?.map((row: any, index: any) => (
      <AdminTableRow
        key={row.id}
        row={row}
        actionNext={callApiGetListAdmin}
        index={(currentPage - 1) * 10 + index + 1}
        activePopup={activePopup}
        setActivePopup={handlePopupChange}
        page={currentPage}
      />
    ))}
  </>);

  const notFound = !dataAdmin?.length;

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  useEffect(() => {
    if (isBackToPage1) {
      setCurrentPage(1)
      setIsBackToPage1(false)
    }
  }, [isBackToPage1]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.adminList}</title>
      </Helmet>
      <HeaderSuperAdmin />
      <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
        <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography variant="h5" className={styles.titlePage}>管理者一覧</Typography>
          </Box>
          <Button className={styles.btnAddSpa} onClick={handleAddNewAdmin}>
            <Iconify icon="material-symbols:add" width={20} className={styles.iconAdd} />
            <Typography variant="subtitle1" className={styles.btnText}>管理者を追加</Typography>
          </Button>
        </Stack>
        <AddNewAdmin openAddNewAdminDialog={openAddNewAdminDialog} defaultValues={defaultNewValues} actionNext={callApiGetListAdmin} setIsBackToPage1={setIsBackToPage1}/>

        <Stack alignItems="center" flexDirection="row" justifyContent="space-between" gap={2} className={styles.searchContainer}>
          <InputBase
            name="search"
            keyword="search"
            type="text"
            className={styles.inputSearch}
            handleChange={handleInputChange}
            placeholder="検索"
            size="small"
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={styles.inputAdornment}>
                  <Iconify icon="material-symbols:search" width={24} />
                </InputAdornment>
              ),
            }}
          />
          <Stack flexDirection="row" gap={1}>
            <Button className={styles.btnSearchSpa} onClick={() => handleSearchAdmin(false)}>
              <Typography className={styles.searchText}>検索</Typography>
            </Button>
            <Button className={styles.btnCancel} onClick={() => handleSearchAdmin(true)}>
              <Typography className={styles.searchText}>クリア</Typography>
            </Button>
          </Stack>
        </Stack>

        {/* table */}
        <TableContainer className={styles.tableContainer}>
          <Table size="small" sx={{ minWidth: 960 }}>
            <TableHeadCustom
              className={styles.borderTableHeader}
              headLabel={TABLE_HEAD}
              rowCount={dataAdmin?.length}
              numSelected={table.selected?.length}
            />
            <TableBody className={styles.borderTable}>
              {loading ? renderSkeleton : renderList}
              {dataAdmin && !loading && (
                <>
                  <TableEmptyRows
                    height={56}
                    emptyRows={emptyRows(
                      table.page - 1,
                      table.rowsPerPage,
                      dataAdmin.length
                    )}
                  />
                  <TableNoData notFound={notFound} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {dataAdmin && paginate?.total_page > 1 && (
          <CustomPagination
            paginate={paginate}
            page={currentPage}
            setPage={setCurrentPage}
            disabled={paginate?.total_page === 1}
          />
        )}
      </Container>
    </>
  );
};

export default AdminList;
