import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography, List, ListItem } from "@mui/material";
import styles from './styles.module.scss';
import { primaryFont } from "../../../constants/colors";
import Iconify from "../../iconify";
import { EmojioneV1Warning } from "../../../assets/icons/icon-warning";

type DeleteCompanyDialogProps = {
  open: boolean;
  loading: boolean;
  company: any;
  onClose: () => void;
  onConfirm: () => void;
};

export default function DeleteCompanyDialog({ open, loading, company, onClose, onConfirm }: DeleteCompanyDialogProps) {
  return (
    <Dialog open={open} fullWidth sx={{ p: 3 }}>
      <DialogTitle sx={{ fontFamily: primaryFont }} justifyContent="space-between" display="flex">
        <Box className={styles.titleDialog}>削除の確認</Box>
        <Iconify sx={{ cursor: "pointer" }} icon="material-symbols:close" width={20} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box>
          <DialogContentText id="alert-dialog-description" className={styles.contentDialog} sx={{ fontFamily: primaryFont }}>
            <Typography display="inline" className={`${styles.textCommon} ${styles.textBold}`} sx={{ fontFamily: primaryFont }}>{company.name}</Typography>
            を削除してもよろしいですか？
          </DialogContentText>
        </Box>
        <Box className={styles.warningContainer}>
          <Box className={`${styles.textBold} d-flex align-items-center`}>
          <Typography sx={{ mr: 1 }}><EmojioneV1Warning /></Typography>警告内容
          </Box>
          <Box>
            現在、こちらの会社は以下の情報を管理しています:
            <List>
              <ListItem sx={{ padding: '0 0 0 10px' }}>
                {`${company.number_admins} 管理者`}
              </ListItem>
              <ListItem sx={{ padding: '5px 0 0 10px' }}>
                {`${company.number_collectors} 収集者`}
              </ListItem>
              <ListItem sx={{ padding: '5px 0 0 10px' }}>
                {`${company.number_trash_cans} ゴミ箱`}
              </ListItem>
            </List>
            削除すると、会社のすべての情報は復元できません。
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" columnGap={2} sx={{ px: 2, mb: 3 }}>
          <Button className={styles.btnBack} sx={{ fontFamily: primaryFont, px: 1 }} onClick={onClose}>
            キャンセル
          </Button>
          {loading ? (
            <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnLoadingDelete}>
              <CircularProgress size={18} sx={{ color: "white" }} />
            </Stack>
          ) : (
            <Button className={styles.btnSubmitDialog} onClick={onConfirm} sx={{ fontFamily: primaryFont, px: 2 }}>
              <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
              <Box>はい</Box>
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
