import Pagination, { paginationClasses } from "@mui/material/Pagination";
import PaginationItem from '@mui/material/PaginationItem';
import { Stack } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ROLE_SUPER_ADMIN } from "../../constants";
import {
  adminPrimaryColor,
  superAdminPrimaryColor,
  borderColor,
  primaryFont,
  paginateBorderColor
} from "../../constants/colors";

const CustomPagination = (props: any) => {
  const { paginate, page, setPage, disabled } = props;
  const authState = useSelector((state: any) => state.auth);
  const primaryColor = authState.dataInfoUserLogin.role === ROLE_SUPER_ADMIN ? superAdminPrimaryColor : adminPrimaryColor;

  const handleChangePagination = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  return (
    <Stack>
      <Pagination
        sx={{
          [`& .${paginationClasses.ul}`]: {
            justifyContent: "end",
            marginTop: "20px",
            'li': {
              '&:first-of-type': {
                width: "80px",
                'button': {
                  width: "100%",
                  borderLeft: 0,
                  borderRadius: "6px 0 0 6px",
                }
              },
              '&:last-of-type': {
                width: "80px",
                'button': {
                  width: "100%",
                  borderRadius: "0 6px 6px 0",
                }
              },
            }
          },
          '& .MuiPaginationItem-root': {
            borderRadius: 0,
            backgroundColor: `${paginateBorderColor}`,
            margin: 0,
            fontFamily: primaryFont,
            fontSize: "14px",
            fontWeight: "700",
            borderTop: 0,
            borderBottom: 0,
            borderRight: 0,
            width: '40px',
            height: '40px',
            borderLeft: `1px solid ${borderColor}`,
            '&.Mui-selected': {
              backgroundColor: primaryColor,
              color: "#FFFFFF",
              '&:hover': {
                backgroundColor: primaryColor,
              },
            },
            '&:hover': {
              backgroundColor: `${paginateBorderColor}`,
            },
          },
          '& .MuiPaginationItem-ellipsis': {
            width: '40px',
            height: '40px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderLeft: `1px solid ${borderColor}`
          },
        }}
        count={paginate?.total_page}
        page={page}
        disabled={disabled}
        variant="outlined"
        shape="rounded"
        onChange={handleChangePagination}
        renderItem={(item) => {
          return (
            <PaginationItem
              {...item}
              components={{
                previous: () => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    width: "100%",
                    height: "100%",
                    justifyContent: "center"
                  }}>
                    <ArrowBackIosIcon sx={{ fontSize: "12px", fontWeight: '700' }} />
                    <span style={{ marginLeft: '3px', fontWeight: '700', fontSize: "14px", lineHeight: 1 }}>戻る</span>
                  </div>
                ),
                next: () => (
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    height: "100%",
                    width: "100%",
                    justifyContent: "center"
                  }}>
                    <span style={{ marginRight: '3px', fontWeight: '700', fontSize: "14px", lineHeight: 1 }}>次</span>
                    <ArrowForwardIosIcon sx={{ fontSize: "12px", fontWeight: '700' }} />
                  </div>
                )
              }}
            />
          );
        }}
      />
    </Stack>
  );
}

export default CustomPagination;
