import * as t from "../constants/index";
import { baseAxios } from "../../commons/axiosBase";
import { REQUEST_METHOD } from "../../constants";
import { doAction } from "./index";

interface BodyListCollector {
  full_name?: any;
  orderBy?: string;
  order?: string;
  pages: number;
}

interface TrashCanListForCollector {
  pages: number;
}

interface BodyListTrashCanIds {
  trashcan_ids: string[];
}

export const changeActiveAccount = (userId: number, body: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/change-active-collector/${userId}`,
    data: body,
    method: REQUEST_METHOD.PATCH,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));
  if (apiResponse) {
    dispatch({
      type: t.ADMIN_CHANGE_ACTIVE_COLLECTOR,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const getListCollector = (body: BodyListCollector) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/users/list`,
    params: body,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));
  if (apiResponse) {
    dispatch({
      type: t.GET_LIST_COLLECTORS,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const getCollectorDetail = (id: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/users/${id}`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_COLLECTOR_DETAIL,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const editCollector = (body: any, id: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/users/${id}`,
    data: body,
    method: REQUEST_METHOD.PUT,
    headers: { "Content-Type": "multipart/form-data" },
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.ADMIN_EDIT_COLLECTOR,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const getAllCollectors = () => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/users/get-all`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_GET_ALL_COLLECTORS,
      payload: apiResponse,
    });

  }

  return apiResponse;
};

export const getListAllCollectorsByTrashCanId = (trashcan_id: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/users/get-all-by-trash-can/${trashcan_id}`,
    method: REQUEST_METHOD.GET,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_GET_ALL_COLLECTORS_BY_TRASHCAN_ID,
      payload: apiResponse,
    });
  }

  return apiResponse;
}

export const getTrashCanListForCollector = (body: TrashCanListForCollector, userId: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/list-by-collector/${userId}`,
    params: body,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_TRASH_CAN_LIST_FOR_COLLECTOR,
      payload: apiResponse,
    });
  }
  return apiResponse;
}

export const assignTrashToCollector = (body: BodyListTrashCanIds, userId: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/assign-to-collector/${userId}`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.ASSIGN_TRASH_TO_COLLECTOR,
      payload: apiResponse,
    })
  }

  return apiResponse;
}

export const cancelAssignTrashToCollector = (body: BodyListTrashCanIds, userId: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/cancel-assign-to-collector/${userId}`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}
