import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, Typography } from "@mui/material";
import styles from './styles.module.scss';
import { primaryFont } from "../../../constants/colors";
import Iconify from "../../iconify";

type DeleteTrashcanDialogProps = {
  open: boolean;
  loading: boolean;
  trashcan: any;
  onClose: () => void;
  onConfirm: () => void;
};

export default function DeleteTrashcanDialog({ open, loading, trashcan, onClose, onConfirm }: DeleteTrashcanDialogProps) {
  return (
    <Dialog open={open} fullWidth sx={{ p: 3 }}>
      <DialogTitle sx={{ fontFamily: primaryFont }} justifyContent="space-between" display="flex">
        <Box className={styles.titleDialog}>削除の確認</Box>
        <Iconify sx={{ cursor: "pointer" }} icon="material-symbols:close" width={20} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box>
          <DialogContentText id="alert-dialog-description" className={styles.contentDialog} sx={{ fontFamily: primaryFont }}>
            <Typography display="inline" className={`${styles.textCommon} ${styles.textBold}`} sx={{ fontFamily: primaryFont }}>{trashcan?.name}</Typography>
            こちらのゴミ箱を本当に削除してもよろしいでしょうか？
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" columnGap={2} sx={{ px: 2, mb: 3 }}>
          <Button className={styles.btnBack} sx={{ fontFamily: primaryFont, px: 1 }} onClick={onClose}>
            キャンセル
          </Button>
          {loading ? (
            <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnLoadingDelete}>
              <CircularProgress size={18} sx={{ color: "white" }} />
            </Stack>
          ) : (
            <Button className={styles.btnSubmitDialog} onClick={onConfirm} sx={{ fontFamily: primaryFont, px: 2 }}>
              <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
              <Box>はい</Box>
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
