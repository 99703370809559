/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';
import { DialogTitle, DialogContent, InputAdornment, DialogActions, Stack, Button, Typography, Box, Container, CircularProgress } from "@mui/material";
import InputBase from "../../../components/input/inputBase";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import React, { useEffect, useState, useCallback, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { editCompany, getCompanyDetail } from '../../../redux/actions/company';
import FormProvider from "../../../components/form/form-provider";
import { useForm } from 'react-hook-form';
import { getByPostCode } from '../../../redux/actions/postal-code';
import { debounce } from 'lodash';
import Loading from '../../../components/loading';
import HeaderSuperAdmin from '../../../components/header/super-admin/header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { MEDIUM_WIDTH_SCREEN } from '../../../constants';
import { filterEmptyFields, getClientWidth, regexPostalCode } from '../../../helpers';
import paths from '../../../constants/paths';
import Custom404Page from '../../404';
import statusCode from '../../../constants/statusCode';
import BreadcrumbsComponent from "../../../components/breadcrumbs";
import { Helmet } from 'react-helmet';
import pageTitles from '../../../constants/pageTitles';

type Props = {
  openCompanyDetailDialog: any,
  openEditCompanyDialog: any,
  defaultValues: any,
  isDetail: any,
}

export default function CompanyDetail({ defaultValues }: Props) {
  const { id } = useParams();
  const [errors, setErrors] = useState(defaultValues);
  const dispatch = useDispatch();
  const dataCompanyDetail = useSelector((state: any) => state.company.dataCompanyDetail?.data);
  const [dataUpdate, setDataUpdate] = useState(dataCompanyDetail);
  const [isPostalCodeTyping, setIsPostalCodeTyping] = useState<boolean>(false);
  const [showErrorPostalCode, setShowErrorPostalCode] = useState<boolean>(false);
  const [errorPostalCode, setErrorPostalCode] = useState('');
  const navigate = useNavigate();

  const location = useLocation();
  const { viewInfoFrom, backPage } = location.state || { state: { viewInfoFrom: true, backPage: 1 } }

  const loadingEditCompany = useSelector((state: any) => state.company.loading);
  const generalError = useSelector((state: any) => state?.company?.error?.response?.status);
  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "会社一覧", href: paths.superAdmin.companyList },
  ];
  const getByPostCodeDebounced = useCallback(debounce((postalCode: string) => {
    dispatch(getByPostCode({ postal_code: postalCode }) as any)
      .then((response: any) => {
        if (response?.data) {
          setErrorPostalCode('');
          setDataUpdate((prevData: any) => ({
            ...prevData,
            prefecture: response.data.prefecture,
            city: response.data.area ? response.data.city + response.data.area : response.data.city,
          }));
        } else {
          setShowErrorPostalCode(true);
          setDataUpdate((prevData: any) => ({
            ...prevData,
            prefecture: "",
            city: "",
          }));
        }
      })
      .catch(() => {
        setErrorPostalCode('郵便番号の検索中にエラーが発生しました。');
      })
      .finally(() => {
        setIsPostalCodeTyping(false);
      });
  }, 1500), [dispatch]);

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate({
      ...dataUpdate,
      [key]: value
    });

    setErrors({
      ...errors,
      [key]: ''
    });

    if (key === 'postal_code') {
      setIsPostalCodeTyping(true);
      if (!value) {
        setDataUpdate((prevData: any) => ({
          ...prevData,
          prefecture: "",
          city: "",
        }));
      }
      if (value && !regexPostalCode.test(value)) {
        setErrorPostalCode('郵便番号の形式が正しくありません');
        setIsPostalCodeTyping(false);
      } else {
        setErrorPostalCode('');
        setShowErrorPostalCode(false);
        getByPostCodeDebounced(value);
      }
    }
  };

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('正しいメールアドレスを入力してください')
      .matches(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+/, '正しいメールアドレスを入力してください')
      .required('メールアドレスは必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    name: yup
      .string()
      .required('会社名は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    tel: yup
      .string()
      .matches(/^\+?[\d-]+$/, {
        message: '電話番号の形式が正しくありません',
        excludeEmptyString: true,
      })
      .required('電話番号は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    postal_code: yup
      .string()
      .matches(regexPostalCode, {
        message: '郵便番号の形式が正しくありません',
        excludeEmptyString: true,
      })
      .max(255, '255文字以内で入力してください')
      .notRequired(),
    prefecture: yup
      .string()
      .max(255, '255文字以内で入力してください')
      .notRequired(),
    city: yup
      .string()
      .max(255, '255文字以内で入力してください')
      .notRequired(),
    address: yup
      .string()
      .max(255, '255文字以内で入力してください')
      .notRequired(),
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      setErrors(defaultValues);
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultValues };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof any] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async () => {
    const formData = {
      email: dataUpdate?.email ?? dataCompanyDetail?.email,
      name: dataUpdate?.name ?? dataCompanyDetail?.name,
      tel: dataUpdate?.tel ?? dataCompanyDetail?.tel,
      postal_code: dataUpdate?.postal_code ?? dataUpdate?.postal_code,
      prefecture: dataUpdate?.prefecture ?? dataCompanyDetail?.prefecture,
      city: dataUpdate?.city ?? dataCompanyDetail?.city,
      address: dataUpdate?.address
    };

    if (await validate()) {
      if (errorPostalCode) {
        return;
      }
      if (showErrorPostalCode && dataUpdate?.postal_code) {
        setErrorPostalCode('郵便番号が見つかりませんでした。');
        return;
      }
      await dispatch(editCompany(filterEmptyFields(formData), id) as any);
      if (viewInfoFrom) {
        navigate(`${paths.superAdmin.companyInfo}/${id}`);
      } else {
        navigate(paths.superAdmin.companyList)
      }
    }
  });

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  useEffect(() => {
    dispatch(getCompanyDetail(id as any) as any)
    setDataUpdate(dataCompanyDetail);
  }, [id]);

  useEffect(() => {
    if (dataCompanyDetail) {
      setDataUpdate({
        name: dataCompanyDetail.name,
        email: dataCompanyDetail.email,
        tel: dataCompanyDetail.tel,
        postal_code: dataCompanyDetail.postal_code,
        prefecture: dataCompanyDetail.prefecture,
        city: dataCompanyDetail.city,
        address: dataCompanyDetail.address,
        avatar: dataCompanyDetail.avatar,
        company_id: dataCompanyDetail?.company?.id,
      });
    }
  }, [dataCompanyDetail])


  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.companyDetail}</title>
      </Helmet>
      {generalError === statusCode.NOTFOUND ? (
        <Custom404Page />
      ) : (
        <Box>
          <HeaderSuperAdmin />
          {dataCompanyDetail ? (
            <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
              <Stack alignItems="center" flexDirection="row" justifyContent="space-between" className={styles.breadcrumbContainer}>
                <BreadcrumbsComponent items={breadcrumbItems} currentLabel={dataCompanyDetail?.name} />
              </Stack>
              <Box>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  <Box sx={{ py: 2 }}>
                    <DialogTitle sx={{ display: "flex", justifyContent: "space-between", fontFamily: "Noto Sans JP", px: 0 }}>
                      <Box className={styles.titleDialog}>会社情報を編集</Box>
                    </DialogTitle>
                    <DialogContent sx={{ px: 0 }}>
                      <Box className={styles.nameBox}>
                        <Stack flexDirection="row">
                          <Box className={styles.titleName}>会社名</Box>
                          <Box className={styles.iconRequired}>*</Box>
                        </Stack>
                        <InputBase
                          name="name"
                          keyword="name"
                          type="text"
                          className={styles.inputName}
                          placeholder="収集スタッフの会社名を入力してください。"
                          size="small"
                          value={dataUpdate?.name ?? dataCompanyDetail?.name}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="heroicons:user-circle-16-solid" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors?.name && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.name}</Box>
                        )}
                      </Box>

                      <Box className={styles.emailBox} sx={{ mt: 2 }}>
                        <Stack flexDirection="row">
                          <Box className={styles.titleInputAddCompany}>メールアドレス</Box>
                          <Box className={styles.iconRequired}>*</Box>
                        </Stack>
                        <InputBase
                          name="email"
                          keyword="email"
                          type="text"
                          className={styles.inputEmail}
                          placeholder="example@example.com"
                          size="small"
                          value={dataUpdate?.email ?? dataCompanyDetail?.email}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="ic:baseline-email" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors?.email && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.email}</Box>
                        )}
                      </Box>

                      <Box className={styles.emailBox} sx={{ mt: 2 }}>
                        <Stack flexDirection="row">
                          <Box className={styles.titleInputAddCompany}>電話番号</Box>
                          <Box className={styles.iconRequired}>*</Box>
                        </Stack>
                        <InputBase
                          name="tel"
                          keyword="tel"
                          type="text"
                          className={styles.inputEmail}
                          placeholder="(000)-000-0000"
                          size="small"
                          value={dataUpdate?.tel ?? dataCompanyDetail?.tel}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="gridicons:phone" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors?.tel && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.tel}</Box>
                        )}
                      </Box>

                      <Box className={styles.emailBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleInputAddCompany}>郵便番号</Box>
                        <InputBase
                          name="postal_code"
                          keyword="postal_code"
                          type="text"
                          className={styles.inputEmail}
                          placeholder="000-0000"
                          size="small"
                          value={dataUpdate?.postal_code ?? dataCompanyDetail?.postal_code}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="mdi:location" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {(errors?.postal_code || errorPostalCode) && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>
                            {errors?.postal_code || errorPostalCode}
                          </Box>
                        )}
                      </Box>

                      <Box className={styles.emailBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleInputAddCompany}>都道府県</Box>
                        <InputBase
                          disabled={true}
                          name="prefecture"
                          keyword="prefecture"
                          type="text"
                          className={styles.inputEmail}
                          placeholder="都道府県"
                          size="small"
                          value={errorPostalCode ? '' : (dataUpdate?.prefecture ?? dataCompanyDetail?.prefecture)}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="mdi:location" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <Box className={styles.emailBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleInputAddCompany}>市区町村名</Box>
                        <InputBase
                          disabled={true}
                          name="city"
                          keyword="city"
                          type="text"
                          className={styles.inputEmail}
                          placeholder="市区町村名"
                          size="small"
                          value={errorPostalCode ? '' : (dataUpdate?.city ?? dataCompanyDetail?.city)}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="mdi:location" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <Box className={styles.emailBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleInputAddCompany}>番地・建物名・号室</Box>
                        <InputBase
                          name="address"
                          keyword="address"
                          type="text"
                          className={styles.inputEmail}
                          placeholder="番地・建物名・号室"
                          size="small"
                          value={(dataUpdate?.address ?? dataCompanyDetail?.address)}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="mdi:location" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {errors?.address && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.address}</Box>
                        )}
                      </Box>
                    </DialogContent>
                    <DialogActions sx={{ px: 0 }}>
                      <Stack direction="row" columnGap={2}>
                        {!viewInfoFrom ? (
                          <Button className={styles.btnBack} onClick={() => navigate(`${paths.superAdmin.companyInfo}/${id}`, { state: { backPage: backPage } })}>
                            <Typography variant="subtitle1" className={styles.btnText}>キャンセル</Typography>
                          </Button>
                        ) : (
                          <Button className={styles.btnBack} onClick={() => navigate(`${paths.superAdmin.companyList}`, { state: { backPage: backPage } })}>
                            <Typography variant="subtitle1" className={styles.btnText}>キャンセル</Typography>
                          </Button>
                        )}

                        {loadingEditCompany ? (
                          <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnAddSpa} sx={{ width: 100 }}>
                            <CircularProgress size={18} sx={{ color: "white" }} />
                          </Stack>
                        ) : (
                          <Button disabled={isPostalCodeTyping} sx={{ px: 2, width: 100 }} className={styles.btnSwitchToEditCompany} type="submit">
                            <Iconify icon="material-symbols:check" width={24} className={styles.iconSubmit} />
                            <Box className={`${styles.textCommon} ${styles.btnText}`}>編集</Box>
                          </Button>
                        )}
                      </Stack>
                    </DialogActions>
                  </Box>
                </FormProvider>
              </Box>
            </Container>
          ) : (
            <Loading />
          )}
        </Box>
      )}
    </>
  );
}
