import * as t from "../constants/index";
import statusCode from "../../constants/statusCode";

interface Action {
  action: any;
  type?: string;
  loading?: string;
  role?: string;
}

interface DataErr {
  message: string;
  loading?: string;
  role?: string;
}

interface DataSuccess {
  data: any;
  type: string;
  loading?: string;
}

const isLoading = (key: string, loading = true) => (dispatch: any) => {
  dispatch({
    type: t.LOADING,
    key,
    loading,
  });
}

const hasError = (err: DataErr) => (dispatch: any) => {
  const { message = 'Something is wrong!', loading = 'loading', role } = err;
  dispatch({
    type: t.ERROR,
    payload: message,
    loading,
    role
  });
}

const callApiSuccess = (body: DataSuccess) => (dispatch: any) => {
  const { type, data: payload, loading = 'loading' } = body;
  dispatch({ type, payload, loading });
}


export const doAction = (action: Action) => async (dispatch: any) => {
  const { action: callApi, type = t.CALL_API_SUCCESS, loading = 'loading', role } = action;
  try {
    dispatch(isLoading(loading, true));
    const apiResponse = await callApi;

    if ([statusCode.OK, statusCode.CREATED].includes(apiResponse?.code)) {
      dispatch(callApiSuccess({ type, data: apiResponse?.data, loading }));
      return apiResponse;
    } else {
      dispatch(hasError({ message: apiResponse.message, loading, role }));
      return false;
    }
  } catch (err: any) {
    dispatch(hasError({ message: err, loading, role }))
    return false;
  }
};
