import paths from "./paths";

export const REQUEST_METHOD = {
  POST: 'post',
  GET: 'get',
  PATCH: 'patch',
  PUT: 'put',
  DELETE: 'delete',
};

export const TYPE_UPDATE_PROFILE = {
  BASIC: 'basic',
  AVATAR: 'avatar',
  INFORMATION: 'information',
  PASSWORD: 'password',
  NOTIFICATION: 'notification',
}

export const ADMIN_REDIRECT_AFTER_LOGIN = paths.admin.collectorList;
export const SUPER_ADMIN_REDIRECT_AFTER_LOGIN = paths.superAdmin.adminList;
export const REDIRECT_BEFORE_LOGIN = paths.auth.login;
export const WEB_SOCKET_URL = process.env.REACT_APP_SOCKET_URL ?? 'ws://localhost:8001';

export const ROLE_ADMIN: number = 1;
export const ROLE_SUPER_ADMIN: number = 0;

export const MEDIUM_LEVEL_TRASH_CAN = 70;
export const HIGH_LEVEL_TRASH_CAN = 100;

export const locale = 'ja';
export const ACTIVE: number = 1;
export const DEACTIVE: number = 0;

export const trashCanLevelColors = {
  low: '#4393E4',
  mediumLow: '#51BC51',
  medium: '#EA9A3E',
  mediumHigh: '#E47474',
  high: '#C41C1C',
};

export const trashCanLevelLabels = {
  low: '空',
  medium: '> 70%',
  high: '満'
}

export const LAT_DEFAULT = 35.2608776
export const LONG_DEFAULT = 139.160184
export const LIST_TYPE = 'list';
export const MAP_TYPE = 'map';

export const defaultOption = {
  value: 0,
  label: '選択',
}

export const trashCanLevelOptions = [
  {
    value: 1,
    label: trashCanLevelLabels.low,
    background: '#51BC51',
  },
  {
    value: 2,
    label: trashCanLevelLabels.medium,
    background: '#EA9A3E',
  },
  {
    value: 3,
    label: trashCanLevelLabels.high,
    background: '#C41C1C',
  }
]

export const ITEMS_PER_PAGE = 10;
export const DEFAULT_LENGTH_TEXT = 20;
export const DEFAULT_LENGTH_COMPANY_TEXT = 50;
export const DEFAULT_LENGTH_TEXT_30 = 30;

export const MEDIUM_TRASHCAN_LEVEL = 70;
export const FULL_TRASHCAN_LEVEL = 100;

export const MEDIUM_WIDTH_SCREEN: number = 1920;
export const DEFAULT_LANGUAGE: string = 'ja';
export const DEFAULT_REGION: string = 'jp';
