import React, { useState } from 'react';
import { useEffect, useRef } from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography, IconButton, Box, Stack } from "@mui/material";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import { useNavigate } from "react-router-dom";
import paths from "../../../constants/paths";
import Popper from "@mui/material/Popper";
import { useDispatch, useSelector } from 'react-redux';
import { deleteCompany } from '../../../redux/actions/company';
import DeleteCompanyDialog from '../../../components/dialog/delete-company-dialog';

type Props = {
  row: any;
  index: any;
  actionNext: any
  activePopup: string | null;
  setActivePopup: (id: string | null) => void;
  page: number
};

export default function CompanyTableRow({ row, index, actionNext, activePopup, setActivePopup, page }: Props) {
  const { id, name, tel, email } = row;
  const navigate = useNavigate();
  const open = activePopup === id;
  const dispatch = useDispatch();
  const [openDialog, setOpenDialog] = useState(false);
  const loadingEditCompany = useSelector((state: any) => state.company.loading);

  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setActivePopup(open ? null : id);
  };

  const handleViewDetailCompany = () => {
    navigate(`${paths.superAdmin.companyInfo}/${id}`, { state: { backPage: page } });
  };

  const handleEditCompany = () => {
    navigate(`${paths.superAdmin.companyDetail}/${id}`, { state: { viewInfoFrom: false, backPage: page } });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
      setActivePopup(null);
    }
  };

  const handleDeleteCompany = async () => {
    const result = await dispatch(deleteCompany(id) as any);
    if (result?.code === 200) {
      actionNext();
    }
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
    setActivePopup(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon}`}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.longText2} ${styles.textCommon}`}>
          {name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.longText2} ${styles.textCommon}`}>
          {email}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon}`}>
          {tel}
        </Typography>
      </TableCell>

      <TableCell>
        <IconButton
          edge="start"
          onClick={handleClick}
          className={styles.optionCell}
          data-id={id}
        >
          <Iconify icon="ri:more-2-fill" />
        </IconButton>
        <Popper open={open} anchorEl={document.querySelector(`[data-id='${id}']`)} placement="bottom-start" ref={popperRef}>
          <Box className={styles.optionCompanyBox}>
            <Stack flexDirection="row" alignItems="center" sx={{ p: 1, cursor: 'pointer' }} onClick={handleEditCompany}>
              <Iconify icon="ic:baseline-edit" width={20} color="#636B74" />
              <Box className={styles.optionText}>編集</Box>
            </Stack>

            <Stack flexDirection="row" alignItems="center" sx={{ p: 1, cursor: 'pointer' }} onClick={handleViewDetailCompany}>
              <Iconify icon="carbon:view-filled" width={20} color="#636B74" />
              <Box className={styles.optionText}>詳細</Box>
            </Stack>

            <Stack flexDirection="row" alignItems="center" sx={{ p: 1, cursor: 'pointer' }} onClick={handleOpenDialog}>
              <Iconify icon="mdi:trash" width={20} color="#636B74" />
              <Box className={styles.optionText}>削除</Box>
            </Stack>
          </Box>
        </Popper>
      </TableCell>
      <DeleteCompanyDialog
        open={openDialog}
        loading={loadingEditCompany}
        onClose={handleCloseDialog}
        onConfirm={handleDeleteCompany}
        company={row}
      />
    </TableRow>
  );
}
