import * as t from "../constants/index";

const initState: any = {
  loading: false,
  error: null,
  dataListCollectors: null,
  dataAllCollectors: null,
  dataAllCollectorsByTrashCan: null,
};

const collector = (state = initState, action: any) => {
  switch (action.type) {
    case t.GET_LIST_COLLECTORS:
      return {
        ...state,
        dataListCollectors: action.payload,
        paginate: action.payload,
      };
    case t.DATA_GET_ALL_COLLECTORS:
      return {
        ...state,
        dataAllCollectors: action.payload,
      };
    case t.DATA_GET_ALL_COLLECTORS_BY_TRASHCAN_ID:
      return {
        ...state,
        dataAllCollectorsByTrashCan: action.payload,
      };
    case t.DATA_COLLECTOR_DETAIL:
      return {
        ...state,
        [action.loading]: false,
        dataCollectorDetail: action.payload,
        error: null
      };
    case t.DATA_TRASH_CAN_LIST_FOR_COLLECTOR:
      return {
        ...state,
        [action.loading]: false,
        dataTrashCanListForCollector: action.payload,
        paginate: action.payload,
        error: null
      };
    case t.ASSIGN_TRASH_TO_COLLECTOR:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        error: null,
      };
    case t.LOADING:
      return {
        ...state,
        [action.key]: action.loading,
      };
    case t.CALL_API_SUCCESS:
      return {
        ...state,
        [action.loading]: false,
        error: null
      };
    case t.ADMIN_CREATE_COLLECTOR:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.ADMIN_EDIT_COLLECTOR:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.ADMIN_CHANGE_ACTIVE_COLLECTOR:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.ERROR:
      return {
        ...state,
        error: action.payload,
        [action.loading]: false,
      };
    default:
      return { ...state };
  }
};

export default collector;
