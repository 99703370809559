import { Box, Button, CircularProgress, InputAdornment, Stack } from "@mui/material";
import styles from "./styles.module.scss";
import InputBase from "../input/inputBase";
import Iconify from "../iconify";
import React, { useCallback, useState, useMemo } from "react";
import { debounce } from "lodash";
import { getByPostCode } from "../../redux/actions/postal-code";
import { useDispatch, useSelector } from "react-redux";
import * as yup from "yup";
import { AdminUpdateProfile } from "../../redux/actions/admin";
import { TYPE_UPDATE_PROFILE } from "../../constants";
import { regexPostalCode } from "../../helpers";

type DataFormType = {
  tel: string,
  postal_code: string | null,
  prefecture: string,
  city: string,
  address: string,
};

const INIT_DATA = {
  tel: "",
  postal_code: null,
  prefecture: "",
  city: "",
  address: "",
};

const ContactInformation = (props: any) => {
  const { dataAdminProfile, actionNext } = props;
  const [defaultValues] = useState<DataFormType>(INIT_DATA);
  const [errors, setErrors] = useState(defaultValues);
  const [dataUpdate, setDataUpdate] = useState(dataAdminProfile);
  const [errorPostalCode, setErrorPostalCode] = useState('');
  const [showErrorPostalCode, setShowErrorPostalCode] = useState<boolean>(false);
  const [isPostalCodeTyping, setIsPostalCodeTyping] = useState<boolean>(false);
  const dispatch = useDispatch();

  const loadingAdminUpdateProfile = useSelector((state: any) => state.admin.loading);

  const debouncedGetByPostCode = useMemo(() =>
      debounce((postalCode: string) => {
        dispatch(getByPostCode({ postal_code: postalCode }) as any)
          .then((response: any) => {
            if (response?.data) {
              setErrorPostalCode('');
              setDataUpdate((prevData: any) => ({
                ...prevData,
                prefecture: response.data.prefecture,
                city: response.data.area ? response.data.city + response.data.area : response.data.city,
              }));
            } else {
              setShowErrorPostalCode(true);
              setDataUpdate((prevData: any) => ({
                ...prevData,
                prefecture: "",
                city: "",
              }));
            }
          })
          .catch(() => {
            setErrorPostalCode('郵便番号の検索中にエラーが発生しました。');
          })
          .finally(() => {
            setIsPostalCodeTyping(false);
          });
      }, 1500),
    [dispatch]
  );

  const validationSchema = yup.object().shape({
    tel: yup
      .string()
      .required('電話番号は必ず入力してください')
      .matches(/^\+?[\d-]+$/, {
        message: '電話番号の形式が正しくありません',
        excludeEmptyString: true,
      }),
    postal_code: yup
      .string()
      .matches(regexPostalCode, {
        message: '郵便番号の形式が正しくありません',
        excludeEmptyString: true,
      })
      .max(255, '255文字以内で入力してください')
      .notRequired(),
  });

  const validateContactInformation = async () => {
    try {
      setErrors(defaultValues);
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultValues }; // Ensure we start with default values
      err?.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultValues] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleUpdateContactInformation = async (data: any) => {
    if (await validateContactInformation()) {
      if (showErrorPostalCode && dataUpdate?.postal_code) {
        setErrorPostalCode('郵便番号が見つかりませんでした。');
        return;
      }
      const formData = {
        tel: dataUpdate.tel,
        postal_code: dataUpdate.postal_code,
        prefecture: dataUpdate.prefecture,
        city: dataUpdate.city,
        address: dataUpdate.address
      }

      const result = await dispatch(AdminUpdateProfile(formData, TYPE_UPDATE_PROFILE.INFORMATION) as any);
      if (result?.code === 200) {
        actionNext();
      }
    }
  }

  const handleInputChange = useCallback((key: string, value: string) => {
    setDataUpdate((prevData: any) => ({
      ...prevData,
      [key]: value
    }));

    if (key === 'postal_code') {
      setIsPostalCodeTyping(true);
      if (!value) {
        setDataUpdate((prevData: any) => ({
          ...prevData,
          prefecture: "",
          city: "",
        }));
      }
      if (value && !regexPostalCode.test(value)) {
        setErrorPostalCode('郵便番号の形式が正しくありません');
        setIsPostalCodeTyping(false);
      } else {
        setErrorPostalCode('');
        setShowErrorPostalCode(false);
        debouncedGetByPostCode(value);
      }
    }

    setErrors((prevErrors) => ({
      ...prevErrors,
      [key]: ''
    }));
  }, [debouncedGetByPostCode]);

  return (
    <Box className={styles.sectionBox}>
      <Box className={styles.contentBox}>
        <Box>
          <Box className={styles.emailBox}>
            <Box className={styles.titleAdminDetail}>電話番号
              <Box sx={{ display: "inline-block" }}>
                <Box className={styles.iconRequired}>*</Box>
              </Box>
            </Box>
            <InputBase
              name="tel"
              keyword="tel"
              type="text"
              errorText={errors?.tel}
              className={styles.inputNameAdmin}
              placeholder="(000)-000-0000"
              size="small"
              value={dataUpdate?.tel ?? dataAdminProfile?.tel}
              handleChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="gridicons:phone" width={20} />
                  </InputAdornment>
                ),
              }}
            />
            {errors?.tel && (
              <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.tel}</Box>
            )}
          </Box>

          <Box className={styles.emailBox} sx={{ mt: 2 }}>
            <Box className={styles.titleAdminDetail}>メールアドレス</Box>
            <InputBase
              name="email"
              keyword="email"
              disabled={true}
              type="text"
              className={styles.inputEmail}
              placeholder="example@example.com"
              size="small"
              value={dataAdminProfile?.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="ic:baseline-email" width={20} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box className={styles.postcalBox} sx={{ mt: 2 }}>
            <Box className={styles.titleAdminDetail}>郵便番号</Box>
            <InputBase
              name="postal_code"
              keyword="postal_code"
              type="text"
              className={styles.inputNameAdmin}
              placeholder="000-0000"
              size="small"
              value={dataUpdate?.postal_code ?? dataAdminProfile?.postal_code}
              handleChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="mdi:location" width={20} />
                  </InputAdornment>
                ),
              }}
            />
            {(errors?.postal_code || errorPostalCode) && (
              <Box sx={{ mt: 0.5 }} className={styles.errorText}>
                {errors?.postal_code || errorPostalCode}
              </Box>
            )}
          </Box>

          <Box className={styles.prefectureBox} sx={{ mt: 2 }}>
            <Box className={styles.titleAdminDetail}>都道府県</Box>
            <InputBase
              name="prefecture"
              disabled={true}
              keyword="prefecture"
              type="text"
              className={styles.inputNameAdmin}
              placeholder="都道府県"
              size="small"
              value={errorPostalCode ? '' : dataUpdate ? dataUpdate?.prefecture : dataAdminProfile?.prefecture}
              handleChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="mdi:location" width={20} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box className={styles.cityBox} sx={{ mt: 2 }}>
            <Box className={styles.titleAdminDetail}>市区町村名</Box>
            <InputBase
              disabled={true}
              name="city"
              keyword="city"
              type="text"
              className={styles.inputNameAdmin}
              placeholder="市区町村名"
              size="small"
              value={errorPostalCode ? '' : (dataUpdate ? dataUpdate?.city : dataAdminProfile?.city)}
              handleChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="mdi:location" width={20} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box className={styles.emailBox} sx={{ mt: 2 }}>
            <Box className={styles.titleAdminDetail}>番地・建物名・号室</Box>
            <InputBase
              name="address"
              keyword="address"
              type="text"
              className={styles.inputNameAdmin}
              placeholder="番地・建物名・号室"
              size="small"
              value={dataUpdate ? dataUpdate?.address : dataAdminProfile?.address}
              handleChange={handleInputChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" className={styles.inputAdornment}>
                    <Iconify icon="mdi:location" width={20} />
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box mt={2} sx={{ textAlign: "end" }}>
            <Button onClick={handleUpdateContactInformation} disabled={isPostalCodeTyping}>
              {loadingAdminUpdateProfile ? (
                <Stack flexDirection="row" className={styles.btnLoading}>
                  <CircularProgress size={25} />
                </Stack>
              ) : (
                <Stack className={styles.btnCreate} flexDirection="row">
                  <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                  <Box>保存</Box>
                </Stack>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default ContactInformation;
