import { AppBar, Container, Box, InputAdornment } from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Iconify from "../../iconify";
import { useResponsive } from "../../../hooks/use-responsive";
import { HEADER } from "../../../config-layout";
import { useSelector } from "react-redux";
import LOGO from '../../../assets/logo/LOGO.svg'
import styles from "./../styles.module.scss";
import paths from "../../../constants/paths";
import { useRef, useState, useEffect } from "react";
import { MEDIUM_WIDTH_SCREEN } from "../../../constants";
import { getClientWidth } from "../../../helpers";
import user from "../../../assets/images/user.png";
import trashcan from "../../../assets/images/trashcan.png";
import userActiveAdmin from "../../../assets/images/user_active_admin.png";
import trashcanActiveAdmin from "../../../assets/images/trashcan_active_admin.png";

const HeaderAdmin = () => {
  const lgUp = useResponsive('up', 'lg');
  const location = useLocation();
  const navigate = useNavigate();
  const authState = useSelector((state: any) => state.auth);

  const handlerRedirectProfile = async () => {
    navigate('/admin/profile');
  }

  const handlerRedirectNotifications = async () => {
    navigate('/admin/notifications/list');
  }

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <AppBar
      sx={{
        height: HEADER.H_DESKTOP,
        zIndex: (theme) => theme.zIndex.appBar + 1,
        transition: (theme) => theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        backgroundColor: 'white',
      }}>
      <Container
        maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? 'xl' : 'lg'}
        className='h-100 d-flex align-items-center justify-content-between'>
        <Box className='h-100 d-flex d-flex align-items-center'>
          <Link to={paths.admin.collectorList}>
            <img src={LOGO} alt='LOGO' />
          </Link>

          <Box className='h-100 d-flex align-items-center'>
            <HeaderNavItem
              url={paths.admin.trashCanList}
              active={location.pathname.includes('/admin/trashcan')}
              icon={location.pathname.includes('/admin/trashcan') ? trashcanActiveAdmin : trashcan}
              text='ゴミ箱の管理'
            />
            <HeaderNavItem
              url={paths.admin.collectorList}
              active={location.pathname.includes('/admin/collector')}
              icon={location.pathname.includes('/admin/collector') ? userActiveAdmin : user}
              text='収集スタッフ一覧'
            />
          </Box>
        </Box>

        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ position: 'relative', mr: 2, cursor: 'pointer' }} onClick={handlerRedirectNotifications}>
            <InputAdornment position='start'>
              <Box className={`${styles.iconContainer} ${location.pathname.includes('/admin/notifications/list') ? styles.active: ''}`}>
                <Iconify icon='clarity:notification-solid' width={24} height={24} />
                {authState?.dataInfoUserLogin?.unread_notifications > 0 && <span className={styles.badge}>{authState?.dataInfoUserLogin?.unread_notifications}</span>}
              </Box>
            </InputAdornment>
          </Box>

          <Box sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={handlerRedirectProfile}>
            <Box sx={{ mr: lgUp ? 2 : 0 }} className={`${styles.avatar} ${location.pathname.includes('/admin/profile') ? styles.active: ''}`}>
              <img src={authState?.dataInfoUserLogin?.avatar} alt='Admin' />
            </Box>

            {lgUp && (
              <Box className={styles.sectionProfile}>
                <Box className={`${styles.adminName} ${styles.textCommon} ${styles.truncateText}`}>{authState?.dataInfoUserLogin?.full_name}</Box>
                <Box className={`${styles.adminEmail} ${styles.truncateText}`}>{authState?.dataInfoUserLogin?.email}</Box>
              </Box>
            )}
          </Box>
        </Box>
      </Container>
    </AppBar>
  );
};

const HeaderNavItem = ({ active, icon, text, url }: any) => {
  return (
    <Link to={url} className={styles.textLink}>
      <Box className={`${styles.btnUser} ${active ? styles.btnActive : ''}`}>
        <img src={icon} alt='logo' />
        <Box className={styles.textMenu}>{text}</Box>
      </Box>
    </Link>
  );
};

export default HeaderAdmin;
