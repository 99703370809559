/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Table,
  TableBody,
  TableContainer,
  Tabs,
  Tab,
  Typography,
} from "@mui/material";
import styles from "./../styles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import avatarDefault from "../../../../../src/assets/images/default.png";
import Iconify from "../../../../components/iconify";
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  useTable,
} from "../../../../components/table";
import CustomPagination from "../../../../components/pagination";
import { getAdminDetail, getListCollectorOfAdmin, getListTrashCanOfAdmin } from "../../../../redux/actions/admin";
import CollectorsOfAdmin from "./collector-table";
import TrashcansOfAdmin from "./trashcan-table";
import { primaryFont } from "../../../../constants/colors";
import HeaderSuperAdmin from "../../../../components/header/super-admin/header";
import { isEmpty } from "lodash";
import paths from "../../../../constants/paths";
import { ITEMS_PER_PAGE, MEDIUM_WIDTH_SCREEN } from "../../../../constants";
import { TableRowSkeleton } from "../../../../components/skeleton/table-row";
import { spaTabsBarColor } from "../../../../constants/colors";
import { getClientWidth, renderAddress } from "../../../../helpers";
import Custom404Page from "../../../404";
import statusCode from "../../../../constants/statusCode";
import BreadcrumbsComponent from "../../../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import pageTitles from "../../../../constants/pageTitles";

export default function AdminDetailView() {
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const [currentPageCollector, setCurrentPageCollector] = useState(1);
  const [currentPageTrashcan, setCurrentPageTrashcan] = useState(1);
  const [tabIndex, setTabIndex] = useState(0);
  const dispatch = useDispatch();
  const [filtersCollector, setFiltersCollector] = useState({ orderBy: '', order: 'asc' });
  const [filtersTrashCan, setFiltersTrashCan] = useState({ orderBy: '', order: 'asc' });
  const [loading, setLoading] = useState(true);

  const dataAdmin = useSelector((state: any) => state.admin.dataAdminDetail?.data);
  const dataCollector = useSelector((state: any) => state.admin.dataListCollector?.data);
  const dataTrashcan = useSelector((state: any) => state.admin.dataListTrashCan?.data);
  const paginateDataCollector = useSelector((state: any) => state.admin.dataListCollector?.paginate);
  const paginateDataTrashcan = useSelector((state: any) => state.admin.dataListTrashCan?.paginate);
  const generalError = useSelector((state: any) => state?.admin?.error?.response?.status);
  const { id } = useParams();
  const navigate = useNavigate();

  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "管理者一覧", href: paths.superAdmin.adminList },
  ];

  const TABLE_HEAD_COLLECTOR = [
    { id: "id", label: (<Box className={styles.textCommon}>No.</Box>), width: 80 },
    { id: "collector_name", label: (<Box className={styles.textCommon}>名前</Box>), width: 220 },
    { id: "email", label: (<Box className={styles.textCommon}>メールアドレス</Box>), width: 220 },
    { id: "tel", label: (<Box className={styles.textCommon}>電話番号</Box>), width: 220 },
    {
      id: "is_active",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSortCollector('is_active')}>
          <Box className={styles.titleStatus}>ステータス</Box>
          {filtersCollector.orderBy === 'is_active' ? (
            filtersCollector.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 140
    },
  ];

  const TABLE_HEAD_TRASHCAN = [
    { id: "id", label: (<Box className={styles.textCommon}>No.</Box>), width: 80 },
    { id: "trashcan_name", label: (<Box className={styles.textCommon}>名前</Box>), width: 220 },
    {
      id: "group_id", label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer}`}
          direction="row"
          alignItems="center"
          onClick={() => console.log('sort by group')}>
          <Box className={styles.titleStatus}>グループ</Box>
          {filtersTrashCan.orderBy === 'group_id' ? (
            filtersTrashCan.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ), width: 140
    },
    {
      id: "level",
      label: (
        <Stack
          className={`${styles.textCommon} ${styles.pointer}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSortTrashCan('level')}>
          <Box className={styles.titleStatus}>ステータス</Box>
          {filtersTrashCan.orderBy === 'level' ? (
            filtersTrashCan.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 140
    },
  ];

  const tabs = {
    collector: 0,
    trashcan: 1
  };

  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 1,
  });

  const handleEditAdmin = () => {
    navigate(`${paths.superAdmin.adminDetail}/${id}`, { state: { viewInfoFrom: true } });
  }

  const buildQueryOfCollector = () => {
    const query: any = {
      pages: currentPageCollector,
    };

    if (!isEmpty(filtersCollector.orderBy)) {
      query.orderBy = filtersCollector.orderBy;
      query.order = filtersCollector.order;
    }

    return query;
  };

  const buildQueryOfTrashcan = () => {
    const query: any = {
      pages: currentPageTrashcan,
    };

    if (!isEmpty(filtersTrashCan.orderBy)) {
      query.orderBy = filtersTrashCan.orderBy;
      query.order = filtersTrashCan.order;
    }

    return query;
  };

  const handleSortCollector = async (field: string) => {
    setFiltersCollector((prevState) => ({
      ...prevState,
      orderBy: field,
      order: prevState.orderBy === field && prevState.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleSortTrashCan = async (field: string) => {
    setFiltersTrashCan((prevState) => ({
      ...prevState,
      orderBy: field,
      order: prevState.orderBy === field && prevState.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  useEffect(() => {
    if (id) {
      dispatch(getAdminDetail(id as any) as any);
    }
  }, [id]);

  useEffect(() => {
    if (id && tabIndex === tabs.collector) {
      setLoading(true);
      dispatch(getListCollectorOfAdmin(buildQueryOfCollector(), id) as any).then((result: any) => {
        setLoading(false);
      });
    }
  }, [currentPageCollector, filtersCollector, tabIndex]);

  useEffect(() => {
    if (id && tabIndex === tabs.trashcan) {
      setLoading(true);
      dispatch(getListTrashCanOfAdmin(buildQueryOfTrashcan(), id) as any).then((result: any) => {
        setLoading(false);
      });
    }
  }, [currentPageTrashcan, filtersTrashCan, tabIndex]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const notFoundTrashcan = !dataTrashcan?.length;
  const notFoundCollector = !dataCollector?.length;

  const renderCollectorSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD_COLLECTOR.length} />
      ))}
    </>
  );

  const renderTrashcanSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD_TRASHCAN.length} />
      ))}
    </>
  );

  const renderCollectorList = (
    <>
      <TableContainer
        sx={{
          position: "relative",
          overflow: "unset",
          overflowX: "auto",
        }}>
        <Table size="small" sx={{ minWidth: 960 }}>
          <TableHeadCustom
            className={styles.borderTableHeader}
            headLabel={TABLE_HEAD_COLLECTOR}
            rowCount={dataCollector?.length}
            numSelected={table.selected?.length} />
          <TableBody className={styles.borderTable}>
            {loading ? renderCollectorSkeleton : dataCollector?.map((row: any, index: any) => (
              <CollectorsOfAdmin
                key={row.id}
                row={row}
                index={(currentPageCollector - 1) * 10 + index + 1}
              />
            ))}
            {dataCollector && !loading && (
              <>
                <TableEmptyRows
                  height={56}
                  emptyRows={emptyRows(
                    table.page - 1,
                    table.rowsPerPage,
                    dataCollector.length
                  )}
                />
                <TableNoData notFound={notFoundCollector} />
              </>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {dataCollector && paginateDataCollector?.total_page > 1 && (
        <CustomPagination
          paginate={paginateDataCollector}
          page={currentPageCollector}
          setPage={setCurrentPageCollector}
          disabled={paginateDataCollector?.total_page === 1}
        />
      )}
    </>
  );

  const renderTrashcanList = (
    <>
      <TableContainer
        sx={{
          position: "relative",
          overflow: "unset",
          overflowX: "auto",
        }}>
        <Table size="small" sx={{ minWidth: 960 }}>
          <TableHeadCustom
            className={styles.borderTableHeader}
            headLabel={TABLE_HEAD_TRASHCAN}
            rowCount={dataTrashcan?.length}
            numSelected={table.selected?.length} />
          <TableBody className={styles.borderTable}>
            {loading ? renderTrashcanSkeleton : dataTrashcan?.map((row: any, index: any) => (
              <TrashcansOfAdmin
                key={row.trashcan_id}
                row={row}
                index={(currentPageTrashcan - 1) * 10 + index + 1}
              />
            ))}
            {dataTrashcan && !loading && (<>
              <TableEmptyRows
                height={56}
                emptyRows={emptyRows(
                  table.page - 1,
                  table.rowsPerPage,
                  dataTrashcan.length
                )}
              />
              <TableNoData notFound={notFoundTrashcan} />
            </>)}
          </TableBody>
        </Table>
      </TableContainer>
      {dataTrashcan && paginateDataTrashcan?.total_page > 1 && (
        <CustomPagination
          paginate={paginateDataTrashcan}
          page={currentPageTrashcan}
          setPage={setCurrentPageTrashcan}
          disabled={paginateDataTrashcan?.total_page === 1} />
      )}
    </>
  );

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.adminInfo}</title>
      </Helmet>
      {generalError === statusCode.NOTFOUND ? (
        <Custom404Page />
      ) : (
        <>
          <HeaderSuperAdmin />
          <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
            <Card
              sx={{ py: 1 }}
              className={styles.cardContainer}>
              <BreadcrumbsComponent items={breadcrumbItems} currentLabel={dataAdmin?.full_name} />

              <Stack flexDirection="row" sx={{ paddingTop: "20px" }}>
                <Box>
                  <img
                    className={styles.bigAvatar}
                    src={dataAdmin?.avatar ? dataAdmin?.avatar : avatarDefault}
                    alt="avatar" />
                </Box>
                <Box className={styles.boxContainer}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    className="pb-2">
                    <Box>
                      <Box className={`${styles.fullNameAdmin} pb-2`}>
                        {dataAdmin?.full_name}
                      </Box>
                      <Typography className={styles.textCommon}>
                        {dataAdmin?.is_active === 1 ? (
                          <span className={styles.btnActive}>アクティブ</span>
                        ) : (
                          <span className={styles.btnInActive}>無効</span>
                        )}
                      </Typography>
                      <Box className={styles.textSubtitle} sx={{ mt: 1 }}>
                        {dataAdmin?.company?.name}
                      </Box>
                    </Box>
                    <Stack className={styles.linkDiv} sx={{ paddingLeft: '20px' }} flexDirection="row">
                      <Button className={styles.btnBack} sx={{ fontFamily: primaryFont, px: 1, mr: 2 }} onClick={() => navigate(`${paths.superAdmin.adminList}`, { state: { backPage: backPage } })}>
                        戻る
                      </Button>
                      <Button className={styles.btnEditSpa} onClick={handleEditAdmin}>
                        <Iconify icon="ic:baseline-edit" width={20} className={styles.iconEdit} />
                        <Box className={`${styles.btnText} ${styles.textCommon}`}>
                          編集
                        </Box>
                      </Button>
                    </Stack>
                  </Stack>
                  <Divider className="my-2" />
                  {renderAddress(dataCollector) && (
                    <Box className={styles.infoUser}>
                      <Iconify className={styles.iconWithSubColor} icon="zondicons:location" width={24} />
                      <Box className={styles.textSubtitle}>
                        {renderAddress(dataCollector)}
                      </Box>
                    </Box>
                  )}
                  <Box className={styles.infoUser}>
                    <Iconify className={styles.iconWithSubColor} icon="ic:baseline-email" width={24} />
                    <Box className={styles.textSubtitle}>
                      {dataAdmin?.email}
                    </Box>
                  </Box>
                  {dataAdmin?.tel && (
                    <Box className={styles.infoUser}>
                      <Iconify className={styles.iconWithSubColor} icon="gridicons:phone" width={24} />
                      <Box className={styles.textSubtitle}>
                        {dataAdmin?.tel}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Stack>

              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="admin tabs"
                sx={{ mt: 3 }}
                TabIndicatorProps={{
                  sx: { display: 'none' },
                }}>
                <Tab
                  label="管理している収集スタッフ一覧"
                  sx={{
                    fontFamily: primaryFont,
                    color: `${spaTabsBarColor.inactiveContentColor}`,
                    fontSize: "16px",
                    borderBottom: `2px solid ${spaTabsBarColor.inactiveBorderColor}`,
                    backgroundColor: `${spaTabsBarColor.bgColor}`,
                    '&.Mui-selected': {
                      backgroundColor: `${spaTabsBarColor.activeBgColor}`,
                      color: `${spaTabsBarColor.activeContentColor}`,
                      borderBottom: `2px solid ${spaTabsBarColor.activeBorderColor}`,
                      fontSize: "16px",
                    },
                  }}
                />
                <Tab
                  label="管理しているゴミ箱一覧"
                  sx={{
                    fontFamily: primaryFont,
                    color: `${spaTabsBarColor.inactiveContentColor}`,
                    borderBottom: `2px solid ${spaTabsBarColor.inactiveBorderColor}`,
                    '&.Mui-selected': {
                      backgroundColor: `${spaTabsBarColor.activeBgColor}`,
                      color: `${spaTabsBarColor.activeContentColor}`,
                      borderBottom: `2px solid ${spaTabsBarColor.activeBorderColor}`,
                    },
                  }}
                />
              </Tabs>

              <TabPanel value={tabIndex} index={tabs.collector} className={styles.collectorTab}>
                {renderCollectorList}
              </TabPanel>

              <TabPanel value={tabIndex} index={tabs.trashcan}>
                {renderTrashcanList}
              </TabPanel>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}

// TabPanel component definition
const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
