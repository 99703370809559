import { combineReducers } from 'redux';
import auth from "./auth";
import postalCode from "./postal-code";
import company from "./company";
import collector from "./collector";
import admin from "./admin";
import trash from "./trashcan";

const rootReducer = combineReducers({
    auth,
    company,
    postalCode,
    collector,
    admin,
    trash,
});

export default rootReducer;
