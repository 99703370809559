import {
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./../styles.module.scss";

type Props = {
  row: any;
  index: any;
};

export default function CollectorsOfAdmin({ row, index }: Props) {
  const { email, full_name, tel, is_active } = row;

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.longText2}`}>
          {full_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.longText2}`}>
          {email}
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          {tel}
        </Typography>
      </TableCell>
      <TableCell>
        {is_active === 1 ? (
          <span className={styles.btnActive}>アクティブ</span>
        ) : (
          <span className={styles.btnInActive}>無効</span>
        )}
      </TableCell>
    </TableRow>
  );
}
