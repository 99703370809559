import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Container, Grid, InputAdornment } from "@mui/material";
import styles from './styles.module.scss';
import LOGO from '../../../../assets/logo/LOGO.svg';
import * as Yup from 'yup';
import InputBase from '../../../../components/input/inputBase';
import Iconify from '../../../../components/iconify';
import { collectorForgotPassword } from '../../../../redux/actions/auth';
import { Helmet } from 'react-helmet';
import pageTitles from '../../../../constants/pageTitles';

type DataFormType = {
  email: string;
};

const INIT_STATE = {
  email: '',
}

const CollectorForgotPassword = () => {
  const dataForgot = useSelector((state: any) => state.auth);
  const errorReducer = dataForgot?.error?.response?.data?.errors;

  const [errors, setErrors] = useState<DataFormType>(INIT_STATE);
  const [dataFormForgot, setDataFormForgot] = useState<DataFormType>(INIT_STATE);
  const [successMessage, setSuccessMessage] = useState(false);

  const dispatch = useDispatch();

  const validate = async () => {
    try {
      await validationSchemaForgot.validate(dataFormForgot, { abortEarly: false });
      setErrors(INIT_STATE);
      return true;
    } catch (err: any) {
      const newErrors: DataFormType = { ...INIT_STATE };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof DataFormType] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const submitForgotPassword = async (e: any) => {
    e.preventDefault();
    if (await validate()) {
      const response = await dispatch(collectorForgotPassword({
        email: dataFormForgot.email,
      }) as any);

      if (response.status === "success") {
        setSuccessMessage(true);
      }
    }
  };

  const redirectToLogin = () => {
    window.location.href = '/login';
  };

  const handleInputChange = (key: any, value: any) => {
    setDataFormForgot({
      ...dataFormForgot,
      [key]: value
    })
    setErrors({ ...errors, [key]: "" });
  };

  useEffect(() => {
    setErrors((state: any) => ({
      ...state,
      email: errorReducer?.email,
    }))
  }, [errorReducer])

  const validationSchemaForgot = Yup.object({
    email: Yup.string().email('メールアドレスが正しくありません')
      .matches(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+/, '正しいメールアドレスを入力してください')
      .required('メールアドレスは必須です'),
  });

  return (
    <>
      <Helmet>
        <title>{pageTitles.auth.collectorForgotPassword}</title>
      </Helmet>
      <Container maxWidth="sm" className={`${styles.containerFluid} ${styles.textCenter}`} sx={{ display: 'flex' }}>
        {successMessage ? (
          <div className={styles.successCoverBox}>
            <div className={styles.successMessage}>
              <p className={styles.successTitle}>パスワード再設定</p>
              <p className={styles.successSubtitle}>パスワード再設定用のメールが送信されました。<br />60分以内にメールに記載のURLよりパスワードを再設定してください。</p>
              <Button onClick={redirectToLogin} variant="contained" className={`${styles.btnBackToLogin}`}>
                ログイン画面へ
              </Button>
            </div>
          </div>
        ) :
          <div style={{ width: "100%" }}>
            <div className={styles.textCenter}>
              <img
                alt="McCarthy Logo"
                src={LOGO}
                className={styles.textCenter}
              />
            </div>
            <p className={`${styles.textCenter} ${styles.subTitle}`}> パスワードを再設定するリンクをメールでお送りします</p>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <label className={`${styles.subTitleEmail}`}>メールアドレス</label>
                <InputBase
                  name="email"
                  keyword="email"
                  errorText={errors?.email}
                  type="text"
                  handleChange={handleInputChange}
                  value={dataFormForgot?.email}
                  size="small"
                  placeholder="example@example.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="ic:baseline-email" width={24} />
                      </InputAdornment>
                    )
                  }}
                  className={`${styles.textCenter} ${styles.subTitle}`}
                  required={true}
                >
                </InputBase>
                {errors?.email && (
                  <Box className={styles.errorText}>{errors.email}</Box>
                )}
              </Grid>
              <Grid item xs={12} className={`${styles.textCenter}`}>
                <Button
                  onClick={e => submitForgotPassword(e)} variant="contained"
                  className={`${styles.btnSubmit}`}>送信
                </Button>
              </Grid>
            </Grid>

          </div>
        }
      </Container>
    </>
  );
}

export default CollectorForgotPassword;
