import { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchUserLogin } from "../../redux/actions/auth";
import statusCode from "../../constants/statusCode";
import { ADMIN_REDIRECT_AFTER_LOGIN, DEACTIVE, REDIRECT_BEFORE_LOGIN, ROLE_SUPER_ADMIN, SUPER_ADMIN_REDIRECT_AFTER_LOGIN } from "../../constants";
import { Box, CircularProgress } from "@mui/material";

const useAuth = () => {
  const [isAuth, setIsAuth] = useState<boolean>();
  const [role, setRole] = useState<number | null>(null);
  const [isActive, setIsActive] = useState<number>();
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    const fetchData = async () => {
      if (location.pathname.includes('/set-password') ||location.pathname.includes('/reset-password') || location.pathname.includes('/forgot-password')) {
        setIsAuth(true);
        return;
      }

      try {
        const res = await dispatch(fetchUserLogin() as any);
        if (res.code === statusCode.OK && res.data) {
          setRole(res.data?.role);
          setIsAuth(true);
          setIsActive(res.data?.is_active);
        } else {
          setIsAuth(false);
        }
      } catch (err) {
        console.log(err);
        setIsAuth(false);
      }
    };
    fetchData();
  }, [dispatch, location.pathname]);

  return { isAuth, role, isActive };
};

const PublicRoute = () => {
  const { isAuth, role, isActive } = useAuth();
  const location = useLocation();

  if (isAuth === false || (location.pathname.includes('/set-password') || location.pathname.includes('/reset-password') || location.pathname.includes('/forgot-password'))) {
    return <Outlet />;
  } else if (isAuth === true && isActive === DEACTIVE) {
    localStorage.clear();
    return (
      <Navigate
        to={REDIRECT_BEFORE_LOGIN}
      />
    );
  } else if (isAuth === true) {
    return (
      <Navigate
        to={ role === ROLE_SUPER_ADMIN ? SUPER_ADMIN_REDIRECT_AFTER_LOGIN : ADMIN_REDIRECT_AFTER_LOGIN }
        replace
        state={{ path: location.pathname }}
      />
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100vh",
      }}
    >
       <CircularProgress />
    </Box>
  );
};

export default PublicRoute;
