import {
  Box,
  Button,
  Chip,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "../styles.module.scss";
import { getTrashCanStyleBySuperAdmin } from "../../../../helpers";
import Iconify from "../../../../components/iconify";
import { trashCanLevelLabels } from "../../../../constants";
import paths from "../../../../constants/paths";
import {useNavigate} from "react-router-dom";
import React, { useRef, useEffect, useState } from "react";
import ConfirmDialog from "../../../../components/dialog/confirm-dialog";
import { useDispatch, useSelector } from "react-redux";
import { cancelAssignTrashToCollector } from "../../../../redux/actions/collector";
import {sendNotificationFullTrashToCollector} from "../../../../redux/actions/admin";

type Props = {
  row: any;
  index: any;
  actionNext: any;
  activePopup: string | null;
  collector_id: any;
  setActivePopup: (id: string | null) => void;
};

export default function DetailAssignTableRow({ row, index, actionNext, activePopup, setActivePopup, collector_id }: Props) {
  const { trashcan_id, trashcan_name, level } = row;
  const chipStyle = getTrashCanStyleBySuperAdmin(level);
  const navigate = useNavigate();
  const [openDialog, setOpenDialog] = useState(false);
  const dispatch = useDispatch();

  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleCancelAssignTrashCan = async () => {
    const result = await dispatch(
      cancelAssignTrashToCollector({ trashcan_ids: [trashcan_id] }, collector_id) as any
    );
    if (result?.code === 200) {
      actionNext();
    }
    setOpenDialog(false);
  }

  const handleEditTrashCan = () => {
    navigate(`${paths.admin.trashCanDetail}/${trashcan_id}`, { state: { viewCollectorInfoFrom: true, collector_id: collector_id } });
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
      setActivePopup(null);
    }
  };

  const loadingEditCollector = useSelector((state: any) => state.collector.loading);
  const handleOpenDialog = () => {
    setActivePopup(null);
    setOpenDialog(true);
  };

  const handleSendNotification = async () => {
    const formData = {
      data: [
        {
          trashcan_id: row?.trashcan_id,
          collector_ids: [+collector_id],
        }
      ]
    }
    await dispatch(sendNotificationFullTrashToCollector(formData) as any);
  }

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <TableRow hover>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.longText2}`}>
          {trashcan_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          グループ #1
        </Typography>
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" className={styles.textCommon}>
          <Chip
            size="small"
            label={chipStyle.label}
            sx={{
              background: chipStyle.background,
              color: "white",
              fontWeight: "bold",
              fontFamily: chipStyle.font,
            }}
          />
        </Typography>
      </TableCell>

      <TableCell>
        <Box className={(chipStyle?.label === trashCanLevelLabels.high || chipStyle?.label === trashCanLevelLabels.medium ) ? styles.sectionAction : styles.sectionActionNotNoti}>
          <Box className={styles.boxIconEdit} onClick={handleEditTrashCan}>
            <Iconify icon="ic:baseline-edit" color="#636B74" width={20} />
          </Box>
          <Box className={styles.boxIconMap}>
            <Iconify className={styles.iconMap} icon="ic:baseline-delete" width={20} onClick={handleOpenDialog}/>
          </Box>
          {
            (chipStyle?.label === trashCanLevelLabels.high || chipStyle?.label === trashCanLevelLabels.medium ) && (
              <Box>
                <Button className={styles.btnNotification} onClick={handleSendNotification}>
                  <Typography className={styles.textNotification}>お知らせ</Typography>
                </Button>
              </Box>
            )
          }
        </Box>
      </TableCell>
      <ConfirmDialog
        title="解除の確認"
        content="こちらのゴミ箱を本当に解除してもよろしいでしょうか。"
        open={openDialog}
        loading={loadingEditCollector}
        onClose={handleCloseDialog}
        onConfirm={handleCancelAssignTrashCan}
      />
    </TableRow>
  );
}
