import * as yup from 'yup';
import { Stack, Button, Box, Card, Container, Grid, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import InputBase from "../../../components/input/inputBase";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputSelectBase from '../../../components/input/inputSelect';
import HeaderSuperAdmin from '../../../components/header/super-admin/header';
import FormProvider from "../../../components/form/form-provider";
import { geocode, RequestType, setKey } from "react-geocode";
import { addNewTrash } from '../../../redux/actions/trashcan';
import { useNavigate } from 'react-router-dom';
import { getAllCompany } from '../../../redux/actions/company';
import { DEFAULT_LANGUAGE, DEFAULT_REGION, LAT_DEFAULT, LONG_DEFAULT } from '../../../constants';
import debounce from 'lodash/debounce';
import paths from '../../../constants/paths';
import { filterEmptyFields, getAddress } from '../../../helpers';
import CustomAddMap from './custom-add-map';
import AdminMapSkeleton from '../../../components/skeleton/map/map-detail';
import BreadcrumbsComponent from "../../../components/breadcrumbs";
import { Helmet } from 'react-helmet';
import pageTitles from '../../../constants/pageTitles';

type Props = {
  openAddNewAdminDialog: any,
  defaultValues: any,
}

type CompanyOption = {
  id: number,
  name: string
}

export default function AddNewTrash({ defaultValues }: Props) {
  setKey(`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
  const defaultNewValues = {
    trashcan_name: '',
    address: '',
    lat: LAT_DEFAULT,
    long: LONG_DEFAULT,
    group_id: '',
    company_id: '',
  }

  const defaultErrors = {
    trashcan_name: '',
    address: '',
    lat: '',
    long: '',
    group_id: '',
    company_id: '',
  }

  const [loading, setLoading] = useState(false);
  const [dataAdd, setDataAdd] = useState(defaultNewValues);
  const [errors, setErrors] = useState(defaultErrors);
  const [userAddress, setUserAddress] = useState('');
  const [markerLocation, setMarkerLocation] = useState({
    lat: LAT_DEFAULT,
    lng: LONG_DEFAULT
  });
  const [selectedCompany, setSelectedCompany] = useState<string | number>('');
  const [isHasSkeleton, setIsHasSkeleton] = useState(true);

  const defaultOption = {
    value: '0',
    label: '会社を選択してください。'
  }
  const dataCompany = useSelector((state: any) => state.company.dataGetAllCompany?.data)
  const companyOptions = dataCompany?.map((company: CompanyOption) => ({
    value: company.id,
    label: company.name
  })) || [];

  const loadingAddNewTrashcan = useSelector((state: any) => state.trash.loading);
  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "ゴミ箱の管理", href: paths.superAdmin.trashList },
  ];
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    trashcan_name: yup
      .string()
      .max(255, '255文字以内で入力してください')
      .required('ゴミ箱の名前は必ず入力してください'),
    lat: yup
      .string()
      .matches(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,16})?$/, '緯度は半角数字を入力してください。')
      .required('緯度は必ず入力してください'),
    long: yup
      .string()
      .matches(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,16})?$/, '経度は半角数字を入力してください。')
      .required('経度は必ず入力してください'),
    company_id: yup
      .string()
      .required('会社名は必ず入力してください')
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataAdd, { abortEarly: false });
      setErrors(defaultErrors);
      return true;
    } catch (err: any) {
      const newErrors: any = defaultErrors;
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultErrors] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues,
  });

  const handleInputChange = (key: string, value: string) => {
    setDataAdd({
      ...dataAdd,
      [key]: value
    });
    setErrors({
      ...errors,
      [key]: ''
    });
  };

  const handleMapClick = (location: { lat: number, lng: number }) => {
    setMarkerLocation(location);
    setDataAdd((prevData: any) => ({
      ...prevData,
      lat: location.lat,
      long: location.lng,
    }));
  };

  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async () => {
    const isValid = await validate();
    if (!isValid) return;

    const formData = {
      trashcan_name: dataAdd?.trashcan_name,
      lat: parseFloat(dataAdd?.lat.toString()),
      long: parseFloat(dataAdd?.long.toString()),
      company_id: dataAdd?.company_id,
    }
    const result = await dispatch(addNewTrash(filterEmptyFields(formData)) as any);
    if (result.code === 201) {
      setDataAdd(defaultValues);
      setErrors(defaultValues);
      navigate(paths.superAdmin.trashList);
    }
  });

  const handleChangeCompany = (key: any, value: number) => {
    setSelectedCompany(value);
    setDataAdd({
      ...dataAdd,
      [key]: value,
    });
    setErrors({
      ...errors,
      [key]: ''
    });
  };

  useEffect(() => {
    const fetchLocation = async () => {
      setLoading(true);
      setIsHasSkeleton(false)
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });

        if (permission.state === 'granted' || permission.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            async (pos) => {
              const { latitude, longitude } = pos.coords;
              setMarkerLocation({ lat: latitude, lng: longitude });
              setDataAdd(prevData => ({
                ...prevData,
                lat: latitude,
                long: longitude,
              }));

              await geocode(RequestType.LATLNG, `${latitude}, ${longitude}`)
                .then(({ results }) => {
                  geocode(RequestType.ADDRESS, getAddress(results), {
                    language: DEFAULT_LANGUAGE,
                    region: DEFAULT_REGION
                  } as any).then((res) => {
                    if (res?.results.length > 0) {
                      const japaneseAddress = getAddress(res?.results);
                      setUserAddress(japaneseAddress);
                    }
                  }).catch(console.error);
                })
                .catch(console.error);
            },
            (error) => {
              console.error('Geolocation Error:', error);
              setMarkerLocation({ lat: LAT_DEFAULT, lng: LONG_DEFAULT });
            },
            { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
          );
        } else {
          setMarkerLocation({ lat: LAT_DEFAULT, lng: LONG_DEFAULT });
        }
      } catch (error) {
        console.error('Permission Error:', error);
        setMarkerLocation({ lat: LAT_DEFAULT, lng: LONG_DEFAULT });
      } finally {
        setLoading(false);
      }
    };

    fetchLocation();
    dispatch(getAllCompany() as any).finally(() => {
      setLoading(false);
      setIsHasSkeleton(false);
    }
    );
  }, [dispatch]);

  const debouncedUpdateMarker = useRef(
    debounce((lat: number, lng: number) => {
      setMarkerLocation({
        lat: parseFloat(lat.toString()),
        lng: parseFloat(lng.toString()),
      });
    }, 2000)
  ).current;

  useEffect(() => {
    // Update marker location when form data changes
    if (dataAdd.lat !== LAT_DEFAULT && dataAdd.long !== LONG_DEFAULT) {
      debouncedUpdateMarker(dataAdd.lat, dataAdd.long);
    }
  }, [dataAdd.lat, dataAdd.long]);

  useEffect(() => {
    if (markerLocation.lat && markerLocation.lng) {
      geocode(RequestType.LATLNG, `${markerLocation.lat}, ${markerLocation.lng}`)
        .then(({ results }) => {
          geocode(RequestType.ADDRESS, getAddress(results), {
            language: DEFAULT_LANGUAGE,
            region: DEFAULT_REGION
          } as any).then((res) => {
            if (res?.results.length > 0) {
              const japaneseAddress = getAddress(res?.results);
              setUserAddress(japaneseAddress);
            }
          }).catch(console.error);
        })
        .catch(console.error);
    }
  }, [markerLocation.lat, markerLocation.lng]);

  return (
    <Box>
      <Helmet>
        <title>{pageTitles.superAdmin.addTrash}</title>
      </Helmet>
      <HeaderSuperAdmin />
      <Container
        maxWidth="lg"
        className={`h-100 ${styles.cardContainer}`}>
        <Card sx={{ py: 1 }} className={styles.cardContainer}>
          {/* title */}
          <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
            <BreadcrumbsComponent items={breadcrumbItems} currentLabel={"ゴミ箱を追加"} />
          </Stack>
          {loading && (
            <Stack direction="row" justifyContent="center" sx={{ mt: 3 }}>
              <CircularProgress />
            </Stack>
          )}
          <FormProvider methods={methods} onSubmit={onSubmit}>
            <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
              <Box className={styles.titlePage}>新しいゴミ箱を追加します</Box>
              <Box>
                <Stack direction="row" columnGap={2}>
                  <Button sx={{ px: 2 }} className={styles.btnBack} onClick={() => navigate(paths.superAdmin.trashList)} >
                    キャンセル
                  </Button>
                  {loadingAddNewTrashcan ? (
                    <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnAddSpa} sx={{ width: 100 }}>
                      <CircularProgress size={18} sx={{ color: "white" }} />
                    </Stack>
                  ) : (
                    <Button type="submit" sx={{ px: 2, width: 100 }} className={styles.btnAddSpa}>
                      <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                      <Box className={`${styles.textCommon} ${styles.btnText}`}>追加</Box>
                    </Button>
                  )}
                </Stack>
              </Box>
            </Stack>
            <Grid
              sx={{ mt: 4 }}
              justifyContent="space-between"
              container
            >
              {isHasSkeleton ? (
                <Grid item md={6}>
                  <AdminMapSkeleton />
                </Grid>
              ) : (
                <Grid md={6}>
                  <Box className={styles.boxInput}>
                    <Box className={styles.titleInput}>ゴミ箱の名前</Box>
                    <InputBase
                      name="trashcan_name"
                      keyword="trashcan_name"
                      type="text"
                      className={styles.inputNameAdmin}
                      handleChange={handleInputChange}
                      placeholder="ゴミ箱の名前を付けてください"
                      size="small"
                      value={dataAdd?.trashcan_name}
                    />
                    {errors?.trashcan_name && (
                      <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.trashcan_name}</Box>
                    )}
                  </Box>

                  <Box className={styles.boxInput} sx={{ mt: 2 }}>
                    <Box className={styles.titleInput}>住所</Box>
                    <InputBase
                      disabled
                      name="address"
                      keyword="address"
                      type="text"
                      className={styles.inputNameAdmin}
                      placeholder="住所を入力してください"
                      size="small"
                      value={userAddress}
                    />
                    {errors?.address && (
                      <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.address}</Box>
                    )}
                  </Box>

                  <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                    <Grid md={5.5}>
                      <Box className={styles.boxInput}>
                        <Box className={styles.titleInput}>経度</Box>
                        <InputBase
                          name="long"
                          keyword="long"
                          type="text"
                          className={styles.inputNameAdmin}
                          handleChange={handleInputChange}
                          placeholder="経度を入力してください"
                          size="small"
                          value={dataAdd?.long}
                        />
                        {errors?.long && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.long}</Box>
                        )}
                      </Box>
                    </Grid>
                    <Grid md={5.5} justifyContent="space-between">
                      <Box className={styles.boxInput}>
                        <Box className={styles.titleInput}>緯度</Box>
                        <InputBase
                          name="lat"
                          keyword="lat"
                          type="text"
                          className={styles.inputNameAdmin}
                          handleChange={handleInputChange}
                          placeholder="緯度を入力してください"
                          size="small"
                          value={dataAdd?.lat}
                        />
                        {errors?.lat && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.lat}</Box>
                        )}
                      </Box>
                    </Grid>
                  </Grid>

                  {/* <Box className={styles.boxInput} sx={{ mt: 2 }}>
                    <Box className={styles.titleInput}>グループ</Box>
                    <InputSelectBase
                      name="group_id"
                      keyword="group_id"
                      type="text"
                      className={styles.inputCompany}
                      handleChange={() => console.log('handleChange')}
                      size="small"
                      value="1"
                      placeholder="会社を選択してください。"
                    />
                  </Box> */}

                  <Box sx={{ mt: 1 }}>
                    <Box className={styles.boxInput} sx={{ mt: 2 }}>
                      <Box className={styles.titleInput}>会社名</Box>
                      <InputSelectBase
                        name="company_id"
                        keyword="company_id"
                        type="text"
                        className={styles.inputCompany}
                        handleChange={handleChangeCompany}
                        size="small"
                        value={selectedCompany ?? defaultOption?.value}
                        placeholder="会社を選択してください。"
                        options={companyOptions}
                        defaultOption={defaultOption}
                      />
                      {errors?.company_id && (
                        <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.company_id}</Box>
                      )}
                    </Box>
                  </Box>
                </Grid>
              )}

              <Grid md={5}>
                <CustomAddMap
                  markerLocation={{
                    lat: isNaN(markerLocation.lat) ? LAT_DEFAULT : markerLocation.lat,
                    lng: isNaN(markerLocation.lng) ? LONG_DEFAULT : markerLocation.lng,
                  }}
                  setMarkerLocation={handleMapClick}
                  isHasSkeleton={isHasSkeleton}
                  setIsHasSkeleton={setIsHasSkeleton}
                />
              </Grid>
            </Grid>
          </FormProvider>
        </Card>
      </Container>

    </Box>
  );
}
