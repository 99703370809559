/* eslint-disable react-hooks/exhaustive-deps */
import HeaderAdmin from "../../../components/header/admin/header";
import styles from "./styles.module.scss";
import {Box, CircularProgress, Container, Skeleton, Stack, Typography} from "@mui/material";
import Iconify from "../../../components/iconify";
import { useEffect, useState } from "react";
import { getListNotification, readAllNotification, readNotification } from "../../../redux/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { ITEMS_PER_PAGE } from "../../../constants";
import { getRelativeTime } from "../../../helpers";
import statusCode from "../../../constants/statusCode";
import { fetchUserLogin } from "../../../redux/actions/auth";
import IconNotification from "../../../assets/icons/icon-notification";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

const AdminListNotification = () => {
  const UNREAD = 0;
  const READ = 1;
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const dispatch = useDispatch();
  const dataNotification = useSelector((state: any) => state.admin.dataListNotification?.data);
  const paginate = useSelector((state: any) => state.admin.dataListNotification?.paginate);
  const [notifications, setNotifications] = useState<any[]>([]);

  const buildQuery = () => {
    const query: any = {
      pages: currentPage,
    };
    return query;
  };

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      dispatch(getListNotification(buildQuery()) as any).then((result: any) => {
        setLoading(false);
        setIsFirstRender(false);
      });
    }, 100);
  }, [currentPage]);

  useEffect(() => {
    if (dataNotification) {
      if (currentPage === 1 && notifications.length > 0) return;
      setNotifications((prevState: any) => [...prevState, ...dataNotification]);
    }
  }, [dataNotification])

  const renderSkeleton = <>
    {[...Array(ITEMS_PER_PAGE / 2)].map((_, index: any) => (
      <Skeleton key={index} variant="rectangular" height={90} sx={{ marginBottom: 1 }} />
    ))}
  </>

  const handleReadNotification = async (id: number) => {
    let newArray = notifications;
    const notification = newArray.find((item: any) => item.id === id && item.is_read === UNREAD);
    if (notification) {
      const result = await dispatch(readNotification({ id }) as any);
      if (result.code === statusCode.OK) {
        notification.is_read = READ;
        newArray.map((item: any) => {
          if (item.id === id) return notification;
          return item;
        });
        setNotifications([...newArray]);
        await dispatch(fetchUserLogin() as any);
      }
    }
  }

  const handleReadAllNotification = async () => {
    const result = await dispatch(readAllNotification() as any);
    if (result.code === statusCode.OK) {
      let newArray = notifications;
      newArray.map((item: any) => {
        if (item.is_read === UNREAD) item.is_read = READ;
        return item;
      });
      setNotifications([...newArray]);
      await dispatch(fetchUserLogin() as any);
    }
  }

  return(
    <>
      <Helmet>
        <title>{pageTitles.admin.notificationList}</title>
      </Helmet>
      <HeaderAdmin/>
      <Container maxWidth="md" className={`h-100 ${styles.cardContainer}`}>
        <Stack alignItems="flex-end" flexDirection="row" justifyContent="space-between">
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            <Box sx={{ marginRight: "20px" }}>
              <Typography variant="h5" className={styles.titlePage}>お知らせ</Typography>
            </Box>
            {notifications && notifications.length > 0 && <Box>
              <Box className={styles.btnRead} onClick={handleReadAllNotification}>
                <Iconify icon="charm:tick" width={18.33} className={styles.iconRead} />
                <Typography variant="subtitle1" className={styles.btnText}>すべて既読にします</Typography>
              </Box>
            </Box>}
          </Box>
        </Stack>

        <Box sx={{ pt: 3 }}>
          <Box className={styles.sectionNotification}>
            {loading && isFirstRender ? renderSkeleton : notifications.length > 0 ? notifications?.map((item: any, index: any) => (
              <Box
                key={index}
                className={item?.is_read === UNREAD ? styles.aboutNotificationNotRead : styles.aboutNotification}
                onClick={() => handleReadNotification(item?.id)}
              >
                <Box sx={{ display: "flex", alignItems: "flex-start", justifyContent: "space-between"}}>
                  <Box className={styles.overviewNotification}>
                    <Box className={styles.sectionBell}>
                      <Box sx={{padding: "18px"}}>
                        <Iconify icon="mdi:bell" width={20} className={styles.iconBell} />
                      </Box>
                    </Box>
                    <Box>
                      <Typography variant="subtitle1" className={styles.titleNotification}>{item.message}</Typography>
                    </Box>
                  </Box>
                  <Box className={styles.sectionTime}>
                    <Typography variant="body2" className={styles.timeNotification}>{getRelativeTime(new Date(item?.created_date))}</Typography>
                  </Box>
                </Box>
              </Box>
            )) : <Stack alignItems="center" sx={{ marginTop: 4 }}>
              <IconNotification />
              <Typography variant="subtitle1" className={styles.textNoNotification}>お知らせがありません</Typography>
            </Stack>}
          </Box>
        </Box>

        {paginate && paginate.current_page < paginate.total_page && <Box className={styles.sectionLoadMore} sx={{ pt: 3 }}>
          <Box className={styles.overviewLoadMore}>
            {loading ? <CircularProgress /> : <Stack direction="row" alignItems="center" onClick={() => setCurrentPage(paginate.current_page + 1)}>
              <Typography variant="subtitle1" className={styles.textLoadMore}>もっと読み込む</Typography>
              <Iconify icon="bi:three-dots" width={20} className={styles.iconDots} /> 
            </Stack>}
          </Box>
        </Box>}
      </Container>
    </>
  )
}

export default AdminListNotification;
