export default function IconNotification() {
  return (
    <svg
    viewBox="0 0 112 112"
    width="112"
    height="112"
    className="x19dipnz x1lliihq x1tzjh5l x1k90msu x2h7rmj x1qfuztq"
    style={{ '--color': 'var(--primary-icon)' } as React.CSSProperties}
  >
    <rect
      width="18.98"
      height="18.98"
      x="34.96"
      y="82"
      fill="#1876f2"
      rx="9.49"
      transform="rotate(-15 44.445 91.471)"
    ></rect>
    <circle cx="43.01" cy="26.27" r="6.85" fill="#7a7d81"></circle>
    <path fill="#bcc0c4" d="M75.28 43.44a26.72 26.72 0 1 0-51.62 13.83L30 81l51.62-13.87z"></path>
    <path fill="#bcc0c4" d="M90.78 75.64 26.33 92.9l3.22-13.63 51.62-13.83 9.61 10.2z"></path>
    <rect
      width="66.91"
      height="8.88"
      x="25.35"
      y="80.75"
      fill="#bcc0c4"
      rx="4.44"
      transform="rotate(-15 58.793 85.207)"
    ></rect>
  </svg>
  );
}
