import { Box } from "@mui/material";
import styles from './style.module.scss';

const Loading = () => {
  return (
    <Box className={styles.overlay}>
      <Box className={styles.spinner}></Box>
    </Box>
  );
};

export default Loading;
