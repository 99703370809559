import * as t from "../constants/index";
import { baseAxios } from "../../commons/axiosBase";
import { REQUEST_METHOD } from "../../constants";
import { doAction } from "./index";
import statusCode from "../../constants/statusCode";

interface BodyLogin {
  email: string;
  password: string;
}

interface AdminForgotPassWord {
  email: string;
}

interface AdminResetPassWord {
  password: string;
  confirm_password: string;
  token: string | null;
}

interface CollectorForgotPassWord {
  email: string;
}

interface CollectorResetPassWord {
  password: string;
  confirm_password: string;
  token: string | null;
}

interface adminCreateCollector {
  full_name: string;
  email: string;
}

// admin
export const adminLogin = (body: BodyLogin) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/login`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi,
  }));

  if (apiResponse?.data) {
    localStorage.setItem("access_token", apiResponse?.data?.access_token);
    dispatch({
      type: t.ADMIN_LOGIN,
      payload: apiResponse?.data,
    });
  }
  return apiResponse;
}

export const adminForgotPassword = (body: AdminForgotPassWord) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/forgot-password`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_USER_LOGIN,
      payload: apiResponse?.data,
    });
  }
  return apiResponse;
};

export const adminResetPassword = (body: AdminResetPassWord) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/password-reset`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_USER_LOGIN,
      payload: apiResponse?.data,
    })
  }
  return apiResponse;
}

export const adminSetPassword = (body: AdminResetPassWord) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/set-password`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_USER_LOGIN,
      payload: apiResponse?.data,
    })
  }
  return apiResponse;
}

export const checkActiveTokenAdmin = (body: { token: any }) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/auth/check-active-token`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}

export const checkActiveTokenCollector = (body: { token: any }) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/auth/collector/check-active-token`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}
// collector
export const collectorForgotPassword = (body: CollectorForgotPassWord) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/auth/forgot-password`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_USER_LOGIN,
      payload: apiResponse?.data,
    })
  }
  return apiResponse;
}

export const collectorResetPassword = (body: CollectorResetPassWord) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/auth/password-reset`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_USER_LOGIN,
      payload: apiResponse?.data,
    })
  }
  return apiResponse;
}

export const collectorSetPassword = (body: AdminResetPassWord) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/auth/set-password`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_USER_LOGIN,
      payload: apiResponse?.data,
    })
  }
  return apiResponse;
}

export const adminCreateAccountCollector = (body: adminCreateCollector) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/users/create`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.ADMIN_CREATE_COLLECTOR,
      payload: apiResponse?.data,
    })
  }
  return apiResponse;
}

export const fetchUserLogin = () => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/auth/fetch-user-login`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi,
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_USER_LOGIN,
      payload: apiResponse?.data,
    });
  }
  return apiResponse;
}

export const logout = () => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/auth/logout`,
    method: REQUEST_METHOD.POST,
  });

  try {
    const apiResponse = await dispatch(doAction({
      action: callApi
    }));

    if (apiResponse?.code === statusCode.OK) {
      localStorage.clear();
      dispatch({
        type: t.LOGOUT,
      });
    }

    return apiResponse;
  } catch (error) {
    dispatch({
      type: t.ERROR,
      payload: 'Logout failed.',
    });
    return null;
  }
};
