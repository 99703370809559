import React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography, Box, Link, Chip } from "@mui/material";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import { MAP_TYPE } from "../../../constants";
import { getTrashCanStyleBySuperAdmin } from "../../../helpers";
import { superAdminPrimaryColor, primaryFont } from "../../../constants/colors";
import paths from "../../../constants/paths";
import Checkbox from "@mui/material/Checkbox";
import { useNavigate } from "react-router-dom";

type Props = {
  row: any;
  index: any;
  typePage: any;
  setTypePage: any;
  markerLocation: any;
  setMarkerLocation: any;
  dataCheckBox: any;
  setDataCheckBox: any;
  id: string;
  page: number
};

export default function SuperadminTrashCanTableRow({ row, index, id, setTypePage, setMarkerLocation, dataCheckBox, setDataCheckBox, page }: Props) {
  const { trashcan_name, level, company } = row;
  const chipStyle = getTrashCanStyleBySuperAdmin(level);
  const primaryColor = superAdminPrimaryColor;
  const navigate = useNavigate();

  const handleRedirectMap = () => {
    setTypePage(MAP_TYPE);
    setMarkerLocation({
      ...row,
      isDefaultLocation: false,
    });
  };

  const checkValues = (id: string) => {
    return dataCheckBox?.includes(id);
  };

  const handleCheckBox = () => {
    const updatedCheckShow = !checkValues(id);

    setDataCheckBox((prevData: any) => {
      return updatedCheckShow
        ? [...prevData, row?.trashcan_id]
        : prevData.filter((id: any) => id !== row?.trashcan_id);
    });
  };

  const handleRedirectEditTrash = () => {
    navigate(`${paths.superAdmin.trashDetail}/${id}`, { state: { viewCollectorInfoFrom: false, backPage: page } });
  }

  return (
    <TableRow hover>
      <TableCell>
        <Checkbox
          checked={checkValues(id)}
          onChange={handleCheckBox}
        />
      </TableCell>
      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.spaPrimaryTextColor}`}>
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.longText2} ${styles.spaPrimaryTextColor}`}>
          {trashcan_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography variant="subtitle1" className={`${styles.textCommon} ${styles.spaPrimaryTextColor}`}>
          グループ #1
        </Typography>
      </TableCell>

      <TableCell>
        <Chip size="small" label={chipStyle.label}
          sx={{ background: chipStyle.background, color: "white", fontWeight: "bold", fontFamily: chipStyle.font }}
        />
      </TableCell>

      <TableCell>
        <Link href={`${paths.superAdmin.companyInfo}/${company?.id}`}
          sx={{ fontSize: "1rem", fontFamily: primaryFont, color: primaryColor }}
          className={`${styles.longText2} ${styles.spaPrimaryTextColor}`}>
            {company?.name}
        </Link>
      </TableCell>

      <TableCell>
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "baseline", gap: "10px" }} className={styles.itemPopper}>
          <Box sx={{ cursor: "pointer" }}>
            <Iconify className={styles.iconMap} icon="zondicons:location" width={20} onClick={handleRedirectMap} />
          </Box>
          <Box>
            <Box className={styles.boxIconEdit} onClick={handleRedirectEditTrash}>
              <Iconify icon="ic:baseline-edit" className={styles.iconPopper} width={20} />
            </Box>
          </Box>
        </Box>
      </TableCell>
    </TableRow>
  );
}
