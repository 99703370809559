/* eslint-disable react-hooks/exhaustive-deps */
import {
  InputAdornment,
  Stack,
  Button,
  Typography,
  Box,
  Card,
  Container,
  CircularProgress
} from "@mui/material";
import { useForm } from "react-hook-form";
import InputBase from "../../../components/input/inputBase";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormProvider from "../../../components/form/form-provider";
import { useDispatch, useSelector } from 'react-redux';
import { getByPostCode } from '../../../redux/actions/postal-code';
import { getAllCompany } from '../../../redux/actions/company';
import { debounce } from 'lodash';
import InputSelectBase from '../../../components/input/inputSelect';
import { changeStatusAdmin, editAdmin, getAdminDetail } from '../../../redux/actions/admin';
import HeaderSuperAdmin from '../../../components/header/super-admin/header';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import DatePickerCustom from "../../../components/input/datePickerCustom";
import moment from "moment";
import { ACTIVE, DEACTIVE, MEDIUM_WIDTH_SCREEN } from "../../../constants";
import * as yup from "yup";
import avatarDefault from "../../../assets/images/default.png";
import Loading from "../../../components/loading";
import { useBoolean } from "../../../hooks/use-boolean";
import { filterEmptyFields, getClientWidth, regexPostalCode } from "../../../helpers";
import paths from "../../../constants/paths";
import { errorSnackbar } from "../../../commons/snackbar";
import Custom404Page from "../../404";
import statusCode from "../../../constants/statusCode";
import ConfirmDialog from "../../../components/dialog/confirm-dialog";
import BreadcrumbsComponent from "../../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

type DataFormType = {
  full_name: string,
  birthday: any,
  company_id: any,
  tel: string,
  postal_code?: string | null,
  prefecture: string,
  city: string,
  address: string,
  avatar: any,
};

const INIT_DATA = {
  full_name: "",
  birthday: null,
  company_id: "",
  tel: "",
  postal_code: "",
  prefecture: "",
  city: "",
  address: "",
  avatar: "",
};

type CompanyOption = {
  id: number,
  name: string
}
export default function AdminDetail() {
  const defaultErrors = {
    full_name: "",
    birthday: "",
    company_id: "",
    tel: "",
    postal_code: "",
    prefecture: "",
    city: "",
    address: "",
    avatar: "",
  }

  const [defaultValues] = useState<DataFormType>(INIT_DATA);
  const { id } = useParams();
  const [errors, setErrors] = useState(defaultErrors);
  const [errorPostalCode, setErrorPostalCode] = useState('');
  const [previewImage, setPreviewImage] = useState('');
  const [loading, setLoading] = useState(true);
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const dataCompany = useSelector((state: any) => state.company.dataGetAllCompany?.data);
  const dataAdmin = useSelector((state: any) => state.admin.dataAdminDetail?.data);
  const [dataUpdate, setDataUpdate] = useState(dataAdmin);
  const [isPostalCodeTyping, setIsPostalCodeTyping] = useState<boolean>(false);
  const [showErrorPostalCode, setShowErrorPostalCode] = useState<boolean>(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const { viewInfoFrom, backPage } = location.state || { state: { viewInfoFrom: true, backPage: 1 } }

  const loadingEditAdmin = useSelector((state: any) => state.admin.loading);
  const generalError = useSelector((state: any) => state?.admin?.error?.response?.status);

  const adminOptions = dataCompany?.map((company: CompanyOption) => ({
    value: company.id,
    label: company.name
  }
  ));

  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "管理者一覧", href: paths.superAdmin.adminList },
  ];

  const getByPostCodeDebounced = useCallback(debounce((postalCode: string) => {
    dispatch(getByPostCode({ postal_code: postalCode }) as any)
      .then((response: any) => {
        if (response?.data) {
          setErrorPostalCode('');
          setDataUpdate((prevData: any) => ({
            ...prevData,
            prefecture: response.data.prefecture,
            city: response.data.area ? response.data.city + response.data.area : response.data.city,
          }));
        } else {
          setShowErrorPostalCode(true);
          setDataUpdate((prevData: any) => ({
            ...prevData,
            prefecture: "",
            city: "",
          }));
        }
      })
      .catch(() => {
        setErrorPostalCode('郵便番号の検索中にエラーが発生しました。');
      })
      .finally(() => {
        setIsPostalCodeTyping(false);
      });
  }, 1500), [dispatch]);

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate({
      ...dataUpdate,
      [key]: value
    });

    setErrors({
      ...errors,
      [key]: ''
    });

    if (key === 'postal_code') {
      setIsPostalCodeTyping(true);
      if (!value) {
        setDataUpdate((prevData: any) => ({
          ...prevData,
          prefecture: "",
          city: "",
        }));
      }
      if (value && !regexPostalCode.test(value)) {
        setErrorPostalCode('郵便番号の形式が正しくありません');
        setIsPostalCodeTyping(false);
      } else {
        setErrorPostalCode('');
        setShowErrorPostalCode(false);
        getByPostCodeDebounced(value);
      }
    }
  };
  const [newStatus, setNewStatus] = useState(Number(dataAdmin?.is_active));
  const handleChangeStatusAdmin = async (status: any) => {
    setNewStatus(status)
    openDialog.onTrue()
  };

  const handleChangeActive = async () => {
    const result = await dispatch(changeStatusAdmin(Number(id), { is_active: newStatus }) as any);
    if (result?.code === 200) {
      dispatch(getAdminDetail(id as any) as any)
    }
    openDialog.onFalse();
  };

  useEffect(() => {
    dispatch(getAllCompany() as any)
    dispatch(getAdminDetail(id as any) as any)
  }, [id])

  const validationSchema = yup.object().shape({
    full_name: yup
      .string()
      .required('名前は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    tel: yup
      .string()
      .required('電話番号は必ず入力してください')
      .matches(/^\+?[\d-]+$/, {
        message: '電話番号の形式が正しくありません',
        excludeEmptyString: true,
      }),
    company_id: yup
      .string()
      .required('会社名は必ず入力してください'),
    address: yup
      .string()
      .notRequired()
      .max(255, '255文字以内で入力してください'),
    postal_code: yup
      .string()
      .matches(regexPostalCode, {
        message: '郵便番号の形式が正しくありません',
        excludeEmptyString: true,
      })
      .max(255, '255文字以内で入力してください')
      .notRequired(),
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      setErrors(defaultErrors);
      return true;
    } catch (err: any) {
      const newErrors: any = defaultErrors;
      err?.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultErrors] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async () => {
    const isValid = await validate();
    if (!isValid) return;
    if (showErrorPostalCode && dataUpdate?.postal_code) {
      setErrorPostalCode('郵便番号が見つかりませんでした。');
      return;
    }
    const filteredData = filterEmptyFields(dataUpdate);

    const formData = new FormData();
    Object.keys(filteredData).forEach((value: string) => {
      let convertedValue = filteredData[value as keyof typeof filteredData];
      if (value === 'birthday') {
        convertedValue = filteredData?.birthday ? moment(filteredData?.birthday).format("YYYY-MM-DD") : null;
      }
      formData.append(
        value,
        Array.isArray(convertedValue)
          ? JSON.stringify(convertedValue)
          : convertedValue
      );
    });

    if (errorPostalCode) {
      return;
    }
    const result = await dispatch(editAdmin(formData, id) as any);
    if (result?.code === 200) {
      if (viewInfoFrom) {
        navigate(`${paths.superAdmin.adminInfo}/${id}`);
      } else {
        navigate(paths.superAdmin.adminList);
      }
    }
  });

  useEffect(() => {
    if (id && dataAdmin && dataAdmin.id === parseInt(id ?? '')) {
      setDataUpdate({
        full_name: dataAdmin.full_name,
        birthday: dataAdmin.birthday,
        tel: dataAdmin.tel,
        postal_code: dataAdmin.postal_code,
        prefecture: dataAdmin.prefecture,
        city: dataAdmin.city,
        address: dataAdmin.address,
        avatar: dataAdmin.avatar,
        company_id: dataAdmin?.company?.id,
      });
      setLoading(false);
    }
  }, [dataAdmin, id])

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file?.type.includes('image')) {
      file.preview = URL.createObjectURL(file);
      const newFile = new File([file], getNameFile(file.name));
      setDataUpdate({ ...dataUpdate, avatar: newFile });
      setPreviewImage(file.preview);
    } else {
      errorSnackbar('写真をアップロードできませんでした。アップロードする写真は4 MB以下で、フォーマットはJPG、JPEG、PNG、SVG、HEIFのいずれかを使用してください。');
      event.target.value = null;
    }
  };

  function getNameFile(string: any) {
    const getName = string.split(".")[0];
    const type = string.split(".").pop();
    return changeToSlug(getName) + "." + type;
  }

  function changeToSlug(string: string) {
    string = string.toLowerCase();
    string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    string = string.replace(/[đĐ]/g, "d");
    string = string.replace(/([^0-9a-z-\s])/g, "");
    string = string.replace(/(\s+)/g, "-");
    string = string.replace(/-+/g, "-");
    return string;
  }

  const openDialog = useBoolean(false);

  const handleCloseDialog = () => {
    openDialog.onFalse();
  };

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.adminDetail}</title>
      </Helmet>
      {loading ? <Loading /> : generalError === statusCode.NOTFOUND ? (
        <Custom404Page />
      ) : (
        <Box>
          <HeaderSuperAdmin />
          {dataAdmin ? (
            <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
              <Card
                sx={{ py: 1 }}
                className={styles.cardContainer}>
                <BreadcrumbsComponent items={breadcrumbItems} currentLabel={dataAdmin?.full_name} />
              </Card>
              <Box>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                  <Card className={`mt-2 ${styles.cardContainer}`}>
                    <Stack flexDirection="row" gap={2} alignItems="center" sx={{ mb: 3 }}>
                      {previewImage ?
                        <Box>
                          <img onClick={handleImageClick} className={styles.imageAvatar} src={previewImage} alt="avatar" />
                          <input style={{ display: 'none' }} ref={fileInputRef} accept="image/*" multiple type="file" onChange={handleFileInputChange} />
                        </Box>
                        :
                        <Box>
                          <img onClick={handleImageClick} className={styles.imageAvatar} src={dataAdmin?.avatar ? dataAdmin?.avatar : avatarDefault} alt="avatar" />
                          <input style={{ display: 'none' }} ref={fileInputRef} accept="image/*" multiple type="file" onChange={handleFileInputChange} />
                        </Box>}
                      <Box className={styles.adminName}>{dataAdmin?.full_name}</Box>
                      <Box sx={{ width: '160px' }}>
                        {dataAdmin?.is_active === 1 ? (<span className={styles.btnActive}>
                          アクティブ
                        </span>) : (<span className={styles.btnInActive}>
                          無効
                        </span>)}
                      </Box>
                    </Stack>
                    <Box className={styles.nameBox}>
                      <Stack flexDirection="row">
                        <Box className={styles.titleAdminDetail}>名前</Box>
                        <Box className={styles.iconRequired}>*</Box>
                      </Stack>
                      <InputBase
                        name="full_name"
                        keyword="full_name"
                        errorText={errors?.full_name}
                        type="text"
                        className={styles.inputNameAdmin}
                        handleChange={handleInputChange}
                        placeholder="収集スタッフの名前を入力してください。"
                        size="small"
                        value={dataUpdate?.full_name}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={styles.inputAdornment}>
                              <Iconify icon="heroicons:user-circle-16-solid" width={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors?.full_name && (
                        <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.full_name}</Box>
                      )}
                    </Box>

                    <Box className={styles.companyBox} sx={{ mt: 2 }}>
                      <Box className={styles.titleAdminDetail}>生年月日</Box>
                      <DatePickerCustom
                        values={dataUpdate}
                        setValues={setDataUpdate}
                        name="birthday"
                        size="small"
                      />
                    </Box>

                    <Box className={styles.companyBox} sx={{ mt: 2 }}>
                      <Box className={styles.titleAdminDetail}>会社名</Box>
                      <InputSelectBase
                        name="company_id"
                        keyword="company_id"
                        type="text"
                        className={styles.inputNameAdmin}
                        handleChange={handleInputChange}
                        isEdit={true}
                        size="small"
                        value={dataUpdate?.company_id}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className="inputAdornment" sx={{ paddingLeft: "12px" }}>
                              <Iconify icon="ion:bag" width={20} />
                            </InputAdornment>
                          ),
                        }}
                        options={adminOptions}
                      />
                      {errors?.company_id && (
                        <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.company_id}</Box>
                      )}
                    </Box>

                    <Stack flexDirection="row" justifyContent="space-between">
                      <Box className={styles.postcalBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleAdminDetail}>郵便番号</Box>
                        <InputBase
                          name="postal_code"
                          keyword="postal_code"
                          type="text"
                          className={styles.inputNameAdmin}
                          placeholder="000-0000"
                          size="small"
                          value={dataUpdate?.postal_code}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="mdi:location" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                        {(errors?.postal_code || errorPostalCode) && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>
                            {errors?.postal_code || errorPostalCode}
                          </Box>
                        )}
                      </Box>

                      <Box className={styles.prefectureBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleAdminDetail}>都道府県</Box>
                        <InputBase
                          name="prefecture"
                          disabled={true}
                          keyword="prefecture"
                          type="text"
                          className={styles.inputNameAdmin}
                          placeholder="都道府県"
                          size="small"
                          value={errorPostalCode ? '' : dataUpdate?.prefecture}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="mdi:location" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                      <Box className={styles.cityBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleAdminDetail}>市区町村名</Box>
                        <InputBase
                          disabled={true}
                          name="city"
                          keyword="city"
                          type="text"
                          className={styles.inputNameAdmin}
                          placeholder="市区町村名"
                          size="small"
                          value={errorPostalCode ? '' : (dataUpdate ? dataUpdate?.city : dataAdmin?.city)}
                          handleChange={handleInputChange}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={styles.inputAdornment}>
                                <Iconify icon="mdi:location" width={20} />
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </Stack>

                    <Box className={styles.emailBox} sx={{ mt: 2 }}>
                      <Box className={styles.titleAdminDetail}>番地・建物名・号室</Box>
                      <InputBase
                        name="address"
                        keyword="address"
                        type="text"
                        className={styles.inputNameAdmin}
                        placeholder="番地・建物名・号室"
                        size="small"
                        value={dataUpdate ? dataUpdate?.address : dataAdmin?.address}
                        handleChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={styles.inputAdornment}>
                              <Iconify icon="mdi:location" width={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors?.address && (
                        <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.address}</Box>
                      )}
                    </Box>

                    <Box className={styles.emailBox} sx={{ mt: 2 }}>
                      <Stack flexDirection="row">
                        <Box className={styles.titleAdminDetail}>電話番号</Box>
                        <Box className={styles.iconRequired}>*</Box>
                      </Stack>
                      <InputBase
                        name="tel"
                        keyword="tel"
                        type="text"
                        errorText={errors?.tel}
                        className={styles.inputNameAdmin}
                        placeholder="+8162-747-5013"
                        size="small"
                        value={dataUpdate?.tel}
                        handleChange={handleInputChange}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start" className={styles.inputAdornment}>
                              <Iconify icon="gridicons:phone" width={20} />
                            </InputAdornment>
                          ),
                        }}
                      />
                      {errors?.tel && (
                        <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.tel}</Box>
                      )}
                    </Box>

                    <Stack direction="row" columnGap={2} sx={{ mt: 4 }} justifyContent="space-between">
                      {dataAdmin?.is_active === ACTIVE ? (
                        <Button className={styles.btnDeactiveAction} onClick={() => handleChangeStatusAdmin(DEACTIVE)}>
                          無効にする
                        </Button>
                      ) : (
                        <Button className={styles.btnActiveAction} onClick={() => handleChangeStatusAdmin(ACTIVE)}>
                          アクティブにする
                        </Button>
                      )}

                      <Stack direction="row" columnGap={2}>
                        {viewInfoFrom ? (
                          <Button className={styles.btnBack} onClick={() => navigate(`${paths.superAdmin.adminInfo}/${id}`, { state: { backPage: backPage } })}>
                            キャンセル
                          </Button>
                        ) : (
                          <Button className={styles.btnBack} onClick={() => navigate(`${paths.superAdmin.adminList}`, { state: { backPage: backPage } })}>
                            キャンセル
                          </Button>
                        )}
                        {loadingEditAdmin ? (
                          <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnAddSpa} sx={{ width: 100 }}>
                            <CircularProgress size={18} sx={{ color: "white" }} />
                          </Stack>
                        ) : (
                          <Button disabled={isPostalCodeTyping} type="submit" className={styles.btnSubmit} sx={{ width: 100 }}>
                            <Iconify icon="material-symbols:check" width={24} className={styles.iconSubmit} />
                            <Typography variant="subtitle1" className={styles.btnText}>保存</Typography>
                          </Button>
                        )}
                      </Stack>
                    </Stack>
                  </Card>
                </FormProvider>
              </Box>
              <ConfirmDialog
                title="変更の確認"
                content={newStatus === 1 ? "この管理者を有効にしますか？" : "この管理者を無効にしますか？"}
                open={openDialog?.value}
                loading={loadingEditAdmin}
                onClose={handleCloseDialog}
                onConfirm={handleChangeActive}
              />
            </Container>
          ) : (
            <Loading />
          )}
        </Box>
      )}
    </>
  )
}
