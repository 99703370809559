import * as t from "../constants/index";

const initState: any = {
  loading: false,
  error: null,
  dataListTrash: null,
  dataListTrashByAdmin: null,
  dataTrashCanDetailByAdmin: null,
  isAdmin: false,
  isSuperAdmin: false,
};

const trash = (state = initState, action: any) => {
  switch (action.type) {
    case t.SUPER_ADMIN_ADD_NEW_TRASHCAN:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.GET_LIST_TRASH_BY_ADMIN:
      return {
        ...state,
        dataListTrashByAdmin: action.payload,
      };
    case t.GET_LIST_TRASH_BY_SUPER_ADMIN:
      return {
        ...state,
        dataListTrash: action.payload,
      };
    case t.DATA_TRASHCAN_DETAIL:
      return {
        ...state,
        [action.loading]: false,
        dataTrashcanDetail: action.payload,
        error: null
      };
    case t.DATA_TRASHCAN_DETAIL_BY_ADMIN:
      return {
        ...state,
        [action.loading]: false,
        dataTrashCanDetailByAdmin: action.payload,
        error: null
      };
    case t.UPDATE_TRASHCAN_SUPER_ADMIN_SUCCESS:
      return {
        ...state,
        [action.loading]: true,
        dataTrashCanSuperAdminAfterUpdate: action.payload,
        error: null
      };
    case t.UPDATE_TRASHCAN_ADMIN_SUCCESS:
      return {
        ...state,
        [action.loading]: false,
        dataTrashCanAdminAfterUpdate: action.payload,
        error: null
      };
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        error: null,
      };
    case t.LOADING:
      return {
        ...state,
        [action.key]: action.loading,
      };
    case t.CALL_API_SUCCESS:
      return {
        ...state,
        [action.loading]: false,
        error: null
      };
    case t.ERROR:
      return {
        ...state,
        error: action.payload,
        [action.loading]: false,
        isAdmin404Err: action.role === 'admin' ? true : state.isAdmin,
        isSuperAdmin404Err: action.role === 'super' ? true : state.isSuperAdmin,
      };
    default:
      return { ...state };
  }
};

export default trash;
