/* eslint-disable react-hooks/exhaustive-deps */
import * as yup from 'yup';
import { Dialog, DialogTitle, DialogContent, InputAdornment, DialogActions, Stack, Button, Box, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import InputBase from "../../../components/input/inputBase";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import { useCallback, useState } from 'react';
import FormProvider from "../../../components/form/form-provider";
import { useDispatch, useSelector } from 'react-redux';
import { getByPostCode } from '../../../redux/actions/postal-code';
import { addNewCompany } from '../../../redux/actions/company';
import { debounce } from 'lodash';
import { filterEmptyFields, regexPostalCode } from '../../../helpers';

type Props = {
  openAddNewCompanyDialog: any,
  defaultValues: any,
  nextAction: any,
  buildQuery: any,
  setIsBackToPage1: any
}

export default function AddNewCompany({ openAddNewCompanyDialog, defaultValues, nextAction, buildQuery, setIsBackToPage1 }: Props) {
  const [errors, setErrors] = useState(defaultValues);
  const [dataAdd, setDataAdd] = useState(defaultValues);
  const [errorPostalCode, setErrorPostalCode] = useState('');
  const [isPostalCodeTyping, setIsPostalCodeTyping] = useState<boolean>(false);
  const [showErrorPostalCode, setShowErrorPostalCode] = useState<boolean>(false);
  const dispatch = useDispatch();

  const loadingAddNewCompany = useSelector((state: any) => state.company.loading);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('正しいメールアドレスを入力してください')
      .matches(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+/, '正しいメールアドレスを入力してください')
      .required('メールアドレスは必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    name: yup
      .string()
      .required('会社名は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    tel: yup
      .string()
      .matches(/^\+?[\d-]+$/, {
        message: '電話番号の形式が正しくありません',
        excludeEmptyString: true,
      })
      .required('電話番号は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    postal_code: yup
      .string()
      .matches(regexPostalCode, {
        message: '郵便番号の形式が正しくありません',
        excludeEmptyString: true,
      })
      .max(255, '255文字以内で入力してください')
      .notRequired(),
    prefecture: yup
      .string()
      .max(255, '255文字以内で入力してください')
      .notRequired(),
    city: yup
      .string()
      .max(255, '255文字以内で入力してください')
      .notRequired(),
    address: yup
      .string()
      .max(255, '255文字以内で入力してください')
      .notRequired(),
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataAdd, { abortEarly: false });
      setErrors(defaultValues);
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultValues };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultValues] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async () => {
    const isValid = await validate();
    if (!isValid) return;
    if (showErrorPostalCode && dataAdd?.postal_code) {
      setErrorPostalCode('郵便番号が見つかりませんでした。');
      return;
    }
    const formData = {
      email: dataAdd.email,
      name: dataAdd.name,
      tel: dataAdd.tel,
      postal_code: dataAdd.postal_code,
      prefecture: dataAdd.prefecture,
      city: dataAdd.city,
      address: dataAdd.address
    }

    if (errorPostalCode) {
      return;
    }
    const result = await dispatch(addNewCompany(filterEmptyFields(formData)) as any);

    if (result) {
      setIsBackToPage1(true)
      setDataAdd(defaultValues);
      setErrors(defaultValues);
      handleCloseDialog();
      dispatch(nextAction(buildQuery()))
    }
  });

  const handleInputChange = (key: keyof typeof defaultValues, value: any) => {
    setDataAdd({
      ...dataAdd,
      [key]: value
    });

    setErrors({
      ...errors,
      [key]: ''
    });

    if (key === 'postal_code') {
      setIsPostalCodeTyping(true);
      if (!value) {
        setDataAdd((prevData: any) => ({
          ...prevData,
          prefecture: "",
          city: "",
        }));
      }
      if (value && !regexPostalCode.test(value)) {
        setErrorPostalCode('郵便番号の形式が正しくありません');
        setIsPostalCodeTyping(false);
      } else {
        setErrorPostalCode('');
        setShowErrorPostalCode(false);
        getByPostCodeDebounced(value);
      }
    }
  };

  const getByPostCodeDebounced = useCallback(debounce((postalCode: string) => {
    dispatch(getByPostCode({ postal_code: postalCode }) as any)
      .then((response: any) => {
        if (response?.data) {
          setErrorPostalCode('');
          setDataAdd((prevData: any) => ({
            ...prevData,
            prefecture: response.data.prefecture,
            city: response.data.area ? response.data.city + response.data.area : response.data.city,
          }));
        } else {
          setShowErrorPostalCode(true);
          setDataAdd((prevData: any) => ({
            ...prevData,
            prefecture: "",
            city: "",
          }));
        }
      })
      .catch(() => {
        setErrorPostalCode('郵便番号の検索中にエラーが発生しました。');
      })
      .finally(() => {
        setIsPostalCodeTyping(false);
      });
  }, 1500), [dispatch]);

  const handleCloseDialog = () => {
    openAddNewCompanyDialog.onFalse();
    setDataAdd(defaultValues);
  };

  return (
    <Box>
      <Dialog open={openAddNewCompanyDialog.value} fullWidth maxWidth="sm">
        <FormProvider methods={methods} onSubmit={onSubmit}>
          <Box sx={{ py: 2, px: 3 }}>
            <DialogTitle sx={{ display: "flex", justifyContent: "space-between", fontFamily: "Noto Sans JP" }}>
              <Box className={styles.titleDialog}>会社を追加</Box>
              <Box>
                <Iconify icon="material-symbols:close" width={16} onClick={handleCloseDialog} sx={{ cursor: "pointer" }} />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box className={styles.nameBox}>
                <Stack flexDirection="row">
                  <Box className={styles.titleName}>会社名</Box>
                  <Box className={styles.iconRequired}>*</Box>
                </Stack>
                <InputBase
                  name="name"
                  keyword="name"
                  errorText={errors?.name}
                  type="text"
                  className={styles.inputName}
                  handleChange={handleInputChange}
                  placeholder="会社名を入力してください。"
                  size="small"
                  value={dataAdd?.name}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="heroicons:user-circle-16-solid" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.name && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.name}</Box>
                )}
              </Box>

              <Box className={styles.emailBox} sx={{ mt: 2 }}>
                <Stack flexDirection="row">
                  <Box className={styles.titleInputAddCompany}>メールアドレス</Box>
                  <Box className={styles.iconRequired}>*</Box>
                </Stack>
                <InputBase
                  name="email"
                  keyword="email"
                  errorText={errors?.email}
                  type="text"
                  className={styles.inputEmail}
                  handleChange={handleInputChange}
                  placeholder="example@example.com"
                  size="small"
                  value={dataAdd?.email}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="ic:baseline-email" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.email && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.email}</Box>
                )}
              </Box>

              <Box className={styles.emailBox} sx={{ mt: 2 }}>
                <Stack flexDirection="row">
                  <Box className={styles.titleInputAddCompany}>電話番号</Box>
                  <Box className={styles.iconRequired}>*</Box>
                </Stack>
                <InputBase
                  name="tel"
                  keyword="tel"
                  errorText={errors?.tel}
                  type="text"
                  className={styles.inputEmail}
                  handleChange={handleInputChange}
                  placeholder="(000)-000-0000"
                  size="small"
                  value={dataAdd?.tel}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="gridicons:phone" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.tel && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.tel}</Box>
                )}
              </Box>

              <Box className={styles.emailBox} sx={{ mt: 2 }}>
                <Box className={styles.titleInputAddCompany}>郵便番号</Box>
                <InputBase
                  name="postal_code"
                  keyword="postal_code"
                  errorText={errors?.postal_code || errorPostalCode}
                  type="text"
                  className={styles.inputEmail}
                  handleChange={handleInputChange}
                  placeholder="000-0000"
                  size="small"
                  value={dataAdd.postal_code}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="mdi:location" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {(errors?.postal_code || errorPostalCode) && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>
                    {errors?.postal_code || errorPostalCode}
                  </Box>
                )}
              </Box>

              <Box className={styles.emailBox} sx={{ mt: 2 }}>
                <Box className={styles.titleInputAddCompany}>都道府県</Box>
                <InputBase
                  name="prefecture"
                  keyword="prefecture"
                  type="text"
                  disable={true}
                  className={styles.inputEmail}
                  placeholder="都道府県"
                  size="small"
                  value={errorPostalCode ? '' : dataAdd.prefecture}
                  disabled={true}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="mdi:location" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box className={styles.emailBox} sx={{ mt: 2 }}>
                <Box className={styles.titleInputAddCompany}>市区町村名</Box>
                <InputBase
                  disabled={true}
                  name="city"
                  keyword="city"
                  type="text"
                  className={styles.inputEmail}
                  placeholder="市区町村名"
                  size="small"
                  value={errorPostalCode ? '' : dataAdd.city}
                  handleChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="mdi:location" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Box>

              <Box className={styles.emailBox} sx={{ mt: 2 }}>
                <Box className={styles.titleInputAddCompany}>番地・建物名・号室</Box>
                <InputBase
                  name="address"
                  keyword="address"
                  type="text"
                  className={styles.inputEmail}
                  placeholder="番地・建物名・号室"
                  size="small"
                  value={errorPostalCode ? '' : dataAdd.address}
                  handleChange={handleInputChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start" className={styles.inputAdornment}>
                        <Iconify icon="mdi:location" width={20} />
                      </InputAdornment>
                    ),
                  }}
                />
                {errors?.address && (
                  <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.address}</Box>
                )}
              </Box>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" columnGap={2} sx={{ px: 2 }}>
                <Button sx={{ px: 2 }} className={styles.btnBack} onClick={handleCloseDialog} >
                  キャンセル
                </Button>
                {loadingAddNewCompany ? (
                  <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnAddSpa} sx={{ width: 100 }}>
                    <CircularProgress size={18} sx={{ color: "white" }} />
                  </Stack>
                ) : (
                  <Button type="submit" sx={{ px: 2, width: 100 }} className={styles.btnAddSpa} disabled={isPostalCodeTyping}>
                    <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                    <Box className={`${styles.textCommon} ${styles.btnText}`}>追加</Box>
                  </Button>
                )}
              </Stack>
            </DialogActions>
          </Box>
        </FormProvider>
      </Dialog>
    </Box>
  );
}
 