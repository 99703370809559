import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route, Navigate } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import reportWebVitals from "./reportWebVitals";

import "./index.css";
import "bootstrap/dist/css/bootstrap.min.css";

import routes from "./routes";
import "@fontsource/noto-sans-jp";

import Custom404Page from "./pages/404";
import ProtectedRoute from "./components/route/protected-route";
import PublicRoute from "./components/route/public-route";
import CustomRoute from "./components/route/custom-route";
import { locale, REDIRECT_BEFORE_LOGIN } from "./constants";
import 'dayjs/locale/ja';
import 'moment/locale/ja';
import dayjs from 'dayjs';
import moment from "moment";

dayjs.locale(locale);
moment.locale(locale);
const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Router>
        <Routes>
          <Route path="/" element={<PublicRoute />}>
            {routes.public.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route path="/" element={<Navigate to={REDIRECT_BEFORE_LOGIN} />} />
          </Route>
          <Route path="/" element={<ProtectedRoute />}>
            {routes.protected.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={
                  <CustomRoute
                    element={route.element}
                    role={route?.role}
                  />
                }
              />
            ))}
          </Route>
          <Route path="/" element={<PublicRoute />}>
            {routes.exceptionRoute.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
            <Route path="/" element={<Navigate to={REDIRECT_BEFORE_LOGIN} />} />
          </Route>
          <Route path="*" element={<Custom404Page />} />
        </Routes>
      </Router>
    </React.StrictMode>
  </Provider>
);

reportWebVitals();
