import { Box, Button, Container, InputAdornment, Stack, Table, TableBody, TableContainer, Typography, Dialog, DialogTitle, DialogContent, DialogActions } from "@mui/material";
import HeaderSuperAdmin from "../../../components/header/super-admin/header";
import styles from './styles.module.scss';
import Iconify from "../../../components/iconify";
import InputBase from "../../../components/input/inputBase";
import { useCallback, useEffect, useRef, useState } from "react";
import { TableEmptyRows, TableHeadCustom, TableNoData, emptyRows, useTable } from "../../../components/table";
import CompanyTableRow from "./company-table-row";
import { useBoolean } from "../../../hooks/use-boolean";
import { useDispatch, useSelector } from 'react-redux';
import { getListCompany } from '../../../redux/actions/company';
import AddNewCompany from './add-new-company';
import { isEmpty } from "lodash";
import CustomPagination from "../../../components/pagination";
import { ITEMS_PER_PAGE, MEDIUM_WIDTH_SCREEN } from "../../../constants";
import { TableRowSkeleton } from "../../../components/skeleton/table-row";
import { getClientWidth } from "../../../helpers";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

const CompanyList = () => {
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const [filters, setFilters] = useState({ search: '', orderBy: '', order: 'asc' });
  const [currentPage, setCurrentPage] = useState(backPage ?? 1);
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(true);
  const [activePopup, setActivePopup] = useState<string | null>(null);
  const [isBackToPage1, setIsBackToPage1] = useState(false);

  const defaultNewValues = {
    name: '',
    email: '',
    tel: '',
    postal_code: '',
    prefecture: '',
    city: '',
    address: ''
  }

  const dispatch = useDispatch();
  const dataCompany = useSelector((state: any) => state.company.dataCompany.data);
  const paginate = useSelector((state: any) => state.company.dataCompany.paginate);

  const handleSort = async (field: string) => {
    setFilters((prevState) => ({
      ...prevState,
      orderBy: field,
      order: prevState.orderBy === field && prevState.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const buildQuery = () => {
    const query: any = {
      pages: currentPage,
    };

    if (!isEmpty(filters.search)) {
      query.name = filters.search;
    }

    if (!isEmpty(filters.orderBy)) {
      query.orderBy = filters.orderBy;
      query.order = filters.order;
    }

    return query;
  };

  const handleInputChange = useCallback(
    (name: string, value: any) => {
      if (name === 'search') {
        setSearchValue(value);
      } else {
        setFilters((prevState: any) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }, [],
  );

  const handleSearchCompany = (isEmpty: boolean = false) => {
    if (isEmpty) {
      setSearchValue('');
      setFilters((prevState) => ({
        ...prevState,
        search: '',
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        search: searchValue,
      }));
    }
    setCurrentPage(1);
  }

  const callApiGetList = () => dispatch(getListCompany(buildQuery()) as any);

  useEffect(() => {
    setLoading(true);
    dispatch(getListCompany(buildQuery()) as any).then(() => {
      setLoading(false);
    });
  }, [currentPage, filters]);

  const TABLE_HEAD = [
    { id: "id.", label: (<Box className={styles.textCommon}>No</Box>), width: 20 },
    {
      id: "name",
      label: (
        <Stack
          className={`${styles.pointer} ${styles.textCommon}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('name')}
        >
          <Box className={styles.titleStatus}>会社名</Box>
          {filters.orderBy === 'name' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 280
    },
    { id: "email",
      label: (
        <Stack
          className={`${styles.pointer} ${styles.textCommon}`}
          direction="row"
          alignItems="center"
          onClick={() => handleSort('email')}
        >
          <Box className={styles.textCommon}>メールアドレス</Box>
          {filters.orderBy === 'email' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 220
    },
    { id: "tel", label: (<Box className={styles.textCommon}>電話番号</Box>), width: 220 },
    { id: "", label: (<Box className={styles.textCommon}>その他</Box>), width: 80 },
  ];

  const table = useTable(
    {
      defaultRowsPerPage: 10,
      defaultCurrentPage: 1,
    }
  );

  const openAddNewCompanyDialog = useBoolean(false);

  const renderSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD.length} />
      ))}
    </>
  );

  const handlePopupChange = (id: string | null) => {
    setActivePopup(id);
  };

  const renderList = (
    <>
      {dataCompany && dataCompany.length > 0 && dataCompany?.map((row: any, index: any) => (
        <CompanyTableRow key={row.id} row={row} actionNext={callApiGetList} index={(currentPage - 1) * 10 + index + 1} activePopup={activePopup}
          setActivePopup={handlePopupChange} page={currentPage}/>
      ))}
    </>
  );

  const notFound = !dataCompany?.length;
  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  useEffect(() => {
    if (isBackToPage1) {
      setCurrentPage(1)
      setIsBackToPage1(false)
    }
  }, [isBackToPage1]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.companyList}</title>
      </Helmet>
      <HeaderSuperAdmin />
      <Container
        maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"}
        className={`h-100 ${styles.cardContainer}`}
        ref={ref}>
        <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography variant="h5" className={`${styles.titlePage} ${styles.textCommon}`}>会社一覧</Typography>
          </Box>
          <Button className={styles.btnAddSpa} onClick={() => openAddNewCompanyDialog.onTrue()}>
            <Iconify icon="material-symbols:add" className={styles.iconAdd} />
            <Typography variant="subtitle1" className={styles.btnText}>会社を追加</Typography>
          </Button>
        </Stack>

        <Dialog open={openAddNewCompanyDialog.value}>
          <DialogTitle>会社を追加</DialogTitle>
          <DialogContent>
            <AddNewCompany defaultValues={defaultNewValues} openAddNewCompanyDialog={openAddNewCompanyDialog} nextAction={getListCompany} buildQuery={buildQuery} setIsBackToPage1={setIsBackToPage1}/>
          </DialogContent>
          <DialogActions>
            <Button onClick={openAddNewCompanyDialog.onFalse}>キャンセル</Button>
          </DialogActions>
        </Dialog>

        <Stack sx={{ pt: 3 }} alignItems="center" flexDirection="row" justifyContent="space-between" gap={2} className={styles.searchContainer}>
          <InputBase
            name="search"
            keyword="search"
            type="text"
            className={styles.inputSearch}
            handleChange={handleInputChange}
            placeholder="検索"
            size="small"
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={styles.inputAdornment}>
                  <Iconify icon="material-symbols:search" width={24} />
                </InputAdornment>
              ),
            }}
          />
          <Stack flexDirection="row" gap={1}>
            <Button className={styles.btnSearchSpa} onClick={() => handleSearchCompany(false)}>
              <Typography className={styles.searchText}>検索</Typography>
            </Button>
            <Button className={styles.btnCancel} onClick={() => handleSearchCompany(true)}>
              <Typography className={styles.searchText}>クリア</Typography>
            </Button>
          </Stack>
        </Stack>

        <TableContainer className={styles.tableContainer}>
          <Table
            size="small"
            sx={{ minWidth: 960 }}
          >
            <TableHeadCustom
              className={styles.borderTableHeader}
              headLabel={TABLE_HEAD}
              rowCount={dataCompany?.length}
              numSelected={table.selected?.length}
            />

            <TableBody className={styles.borderTable}>
              {loading ? renderSkeleton : renderList}
              {dataCompany && !loading && (
                <>
                  <TableEmptyRows
                    height={56}
                    emptyRows={emptyRows(
                      table.page - 1,
                      table.rowsPerPage,
                      dataCompany.length
                    )}
                  />
                  <TableNoData notFound={notFound} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataCompany && paginate?.total_page > 1 && (
          <CustomPagination
            paginate={paginate}
            page={currentPage}
            setPage={setCurrentPage}
            disabled={paginate?.total_page === 1}
          />
        )}
      </Container>
    </>
  )
}

export default CompanyList;
