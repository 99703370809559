import React from 'react';
import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { ROLE_SUPER_ADMIN } from "../../../constants";
import {
  adminPrimaryColor,
  superAdminPrimaryColor,
  borderColor,
  placeholderColor,
  primaryFont
} from "../../../constants/colors";

const InputBase = (props: any) => {
  const {
    labelText,
    type,
    handleChange,
    errorText,
    placeholder,
    id,
    helperText,
    value,
    minRows,
    disabled = false,
    size,
    variant = "outlined",
    className,
    InputProps,
    required
  } = props;

  const authState = useSelector((state: any) => state.auth);
  const primaryColor = authState?.dataInfoUserLogin?.role === ROLE_SUPER_ADMIN ? superAdminPrimaryColor : adminPrimaryColor;

  return (
    <TextField
      disabled={disabled}
      multiline={!!minRows}
      minRows={minRows}
      error={!!errorText}
      helperText={helperText}
      label={labelText}
      size={size}
      fullWidth
      variant={variant}
      type={type}
      id={id}
      value={value ?? ""}
      placeholder={placeholder}
      className={className}
      onChange={(e: any) => props?.keyword
        ? handleChange(props?.keyword, e.target.value)
        : handleChange(e.target.value)
      }
      required={required}
      InputProps={InputProps}
      sx={{
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderWidth: '1.5px',
            borderColor: borderColor,
            borderRadius: '8px',
            fontFamily: primaryFont
          },
          '&:hover fieldset': {
            borderWidth: '1.5px',
            borderColor: disabled ? 'none' : primaryColor,
            fontFamily: primaryFont
          },
          '&.Mui-focused fieldset': {
            borderWidth: '1.5px',
            borderColor: disabled ? 'none' : primaryColor,
            fontFamily: primaryFont
          },
        },
        '& .MuiInputBase-input::placeholder': {
          fontSize: '14px',
          color: placeholderColor,
          fontFamily: primaryFont
        },
        '& .MuiInputBase-input': {
          display: 'flex',
          alignItems: 'center',
          height: '2.7rem',
          padding: 0,
          paddingLeft: InputProps ? 0 : '14px',
          paddingRight: InputProps ? 0 : '14px',
          fontFamily: primaryFont
        },
      }}
    />
  );
}

export default InputBase;
