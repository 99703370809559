import * as t from "../constants/index";

const initState: any = {
  loading: false,
  error: null,
  dataAdminList: null,
  dataAdminProfile: null,
  dataListAdminByCompany: null,
  dataListTrashByCompany: null,
};

const admin = (state = initState, action: any) => {
  switch (action.type) {
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        error: null,
      };
    case t.LOADING:
      return {
        ...state,
        [action.key]: action.loading,
      };
    case t.CALL_API_SUCCESS:
      return {
        ...state,
        [action.loading]: false,
        error: null
      };
    case t.ADD_NEW_ADMIN:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.SUPER_ADMIN_EDIT_ADMIN:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.DATA_ADMIN_PROFILE:
      return {
        ...state,
        [action.loading]: false,
        dataAdminProfile: action.payload,
        error: null
      };
    case t.DATA_ADMIN_LIST:
      return {
        ...state,
        [action.loading]: false,
        dataAdminList: action.payload,
        paginate: action.payload,
        error: null
      };
    case t.DATA_LIST_ADMIN_BY_COMPANY:
      return {
        ...state,
        [action.loading]: false,
        dataListAdminByCompany: action.payload,
        paginate: action.payload,
        error: null
      };
    case t.DATA_LIST_TRASH_BY_COMPANY:
      return {
        ...state,
        [action.loading]: false,
        dataListTrashByCompany: action.payload,
        paginate: action.payload,
        error: null
      };
    case t.DATA_ADMIN_DETAIL:
      return {
        ...state,
        [action.loading]: false,
        dataAdminDetail: action.payload,
        error: null
      };
    case t.DATA_LIST_COLLECTOR_OF_ADMIN:
      return {
        ...state,
        [action.loading]: false,
        dataListCollector: action.payload,
        paginate: action.payload,
        error: null
      };
    case t.DATA_LIST_TRASHCAN_OF_ADMIN:
      return {
        ...state,
        [action.loading]: false,
        dataListTrashCan: action.payload,
        paginate: action.payload,
        error: null
      };
    case t.GET_LIST_NOTIFICATION:
      return {
        ...state,
        [action.loading]: false,
        dataListNotification: action.payload,
        error: null
      };
    case t.ADMIN_UPDATE_PROFILE:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.ERROR:
      return {
        ...state,
        error: action.payload,
        [action.loading]: false,
      };
    default:
      return { ...state };
  }
};

export default admin;
