import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Container, Grid, InputAdornment, Stack } from "@mui/material";
import styles from './styles.module.scss';
import LOGO from '../../../../assets/logo/LOGO.svg';
import * as Yup from 'yup';
import { adminForgotPassword } from '../../../../redux/actions/auth';
import InputBase from '../../../../components/input/inputBase';
import Iconify from '../../../../components/iconify';
import { useNavigate } from 'react-router-dom';
import paths from '../../../../constants/paths';
import { Helmet } from 'react-helmet';
import pageTitles from '../../../../constants/pageTitles';

type DataFormType = {
  email: string;
};

const INIT_STATE = {
  email: '',
}

const AdminForgotPassword = () => {
  const dataForgot = useSelector((state: any) => state.auth);
  const errorReducer = dataForgot?.error?.response?.data?.errors;
  const [successMessage, setSuccessMessage] = useState(false);
  const [errors, setErrors] = useState<DataFormType>(INIT_STATE);
  const [dataFormForgot, setDataFormForgot] = useState<DataFormType>(INIT_STATE);
  const navigate = useNavigate();
  const [generalError, setGeneralError] = useState('');

  const generalErrMsg = useSelector((state: any) => state.auth.error?.response?.data?.message)
  const statusCodeError = useSelector((state: any) => state.auth.error?.response?.status)
  const loadingForgotPassword = useSelector((state: any) => state.admin.loading);

  const dispatch = useDispatch();

  const validate = async () => {
    try {
      await validationSchemaForgot.validate(dataFormForgot, { abortEarly: false });
      setErrors(INIT_STATE);
      return true;
    } catch (err: any) {
      const newErrors: DataFormType = { ...INIT_STATE };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof DataFormType] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const submitForgotPassword = async (e: any) => {
    e.preventDefault();
    if (await validate()) {
      const response = await dispatch(adminForgotPassword({
        email: dataFormForgot.email,
      }) as any);

      if (response.status === "success") {
        setSuccessMessage(true);
      }
    }
  };

  const handleInputChange = (key: any, value: any) => {
    setDataFormForgot({
      ...dataFormForgot,
      [key]: value
    })
    setErrors({ ...errors, [key]: "" });
  };

  useEffect(() => {
    setErrors((state: any) => ({
      ...state,
      email: errorReducer?.email,
    }))
  }, [errorReducer])

  const validationSchemaForgot = Yup.object({
    email: Yup.string().email('メールアドレスが正しくありません。')
      .matches(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+/, '正しいメールアドレスを入力してください')
      .required('メールアドレスは入力してください。'),
  });

  const redirectToLogin = () => navigate(paths.auth.login);

  useEffect(() => {
    if (statusCodeError !== 401) {
      setGeneralError(generalErrMsg)
    }
  }, [generalErrMsg]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.auth.adminForgotPassword}</title>
      </Helmet>
      <Container maxWidth="sm" className={`${styles.containerFluid}`} sx={{ display: 'flex' }}>
        {successMessage ? (
          <div style={{ width: "100%" }}>
            <div className={styles.textCenter}>
              <img
                alt="McCarthy Logo"
                src={LOGO}
                className={styles.textCenter}
              />
            </div>
            <Box sx={{ mt: 6 }}>
              <div className={styles.successCoverBox}>
                <div className={styles.successMessage}>
                  <p className={styles.successTitle}>パスワード再設定</p>
                  <p className={styles.successSubtitle}>パスワード再設定用のメールが送信されました。<br />60分以内にメールに記載のURLよりパスワードを再設定してください。</p>
                  <Button onClick={redirectToLogin} variant="contained" className={`${styles.btnBackToLogin}`}>
                    ログイン画面へ
                  </Button>
                </div>
              </div>
            </Box>
          </div>
        ) : (
          <div style={{ width: "100%" }}>
            <div className={styles.textCenter}>
              <img
                alt="McCarthy Logo"
                src={LOGO}
                className={styles.textCenter}
              />
            </div>
            <p className={`${styles.textCenter} ${styles.subTitle}`}> パスワードを再設定するリンクをメールでお送りします</p>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <label className={`${styles.subTitleEmail}`}>メールアドレス</label>
                <InputBase
                  name="email"
                  keyword="email"
                  errorText={errors?.email}
                  type="text"
                  handleChange={handleInputChange}
                  value={dataFormForgot?.email}
                  size="small"
                  placeholder="example@example.com"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <Iconify icon="ic:baseline-email" width={24} />
                      </InputAdornment>
                    )
                  }}
                  className={`${styles.textCenter} ${styles.subTitle}`}
                  required={true}
                >
                </InputBase>
                {errors?.email && (
                  <Box className={styles.errorText}>{errors.email}</Box>
                )}
                {generalError && (
                  <Box className={styles.errorText}>{generalError}</Box>
                )}
              </Grid>
              <Grid item xs={12} className={`${styles.textCenter}`}>
                {loadingForgotPassword ? (
                  <Stack flexDirection="row" className={styles.btnForgotLoading} textAlign="center" justifyContent="center">
                    <CircularProgress size={25} />
                  </Stack>
                ) : (
                  <Button
                    onClick={e => submitForgotPassword(e)} variant="contained"
                    className={`${styles.btnSubmit}`}>
                    送信
                  </Button>
                )}
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
    </>
  );

}

export default AdminForgotPassword;
