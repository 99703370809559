import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack } from "@mui/material";
import styles from './styles.module.scss';
import { primaryFont } from "../../../constants/colors";
import Iconify from "../../iconify";

type ConfirmDialogProps = {
  title: string;
  content: string;
  open: boolean;
  loading: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

export default function ConfirmDialog({ title, content, open, loading, onClose, onConfirm }: ConfirmDialogProps) {
  return (
    <Dialog open={open} fullWidth maxWidth="xs" sx={{ p: 3 }}>
      <DialogTitle sx={{ fontFamily: primaryFont }} justifyContent="space-between" display="flex">
        <Box className={styles.titleDialog}>{title}</Box>
        <Iconify sx={{ cursor: "pointer" }} icon="material-symbols:close" width={20} onClick={onClose} />
      </DialogTitle>
      <DialogContent>
        <Box>
          <DialogContentText id="alert-dialog-description" className={styles.contentDialog}>
            {content}
          </DialogContentText>
        </Box>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" columnGap={2} sx={{ px: 2, mb: 3 }}>
          <Button className={styles.btnBack} sx={{ fontFamily: primaryFont, px: 1 }} onClick={onClose}>
            キャンセル
          </Button>
          {loading ? (
            <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnLoadingDelete}>
              <CircularProgress size={18} sx={{ color: "white" }} />
            </Stack>
          ) : (
            <Button className={styles.btnSubmitDialog} onClick={onConfirm} sx={{ fontFamily: primaryFont, px: 2 }}>
              <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
              <Box>はい</Box>
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
