import { Breadcrumbs, Link } from "@mui/material";
import styles from "./style.module.scss";
import {truncateText} from "../../helpers";
import {DEFAULT_LENGTH_TEXT} from "../../constants";

interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface BreadcrumbsComponentProps {
  items: BreadcrumbItem[];
  currentLabel: string;
}

export default function BreadcrumbsComponent({ items, currentLabel }: BreadcrumbsComponentProps) {
  return (
    <Breadcrumbs aria-label="breadcrumb" className={styles.textCommon}>
      {items.map((item, index) => (
        <Link
          key={index}
          underline="hover"
          color="inherit"
          href={item.href}
          className={styles.textBreadcrumbs}
        >
          {item.label}
        </Link>
      ))}
      <Link underline="hover" className={styles.textBreadcrumbsCurrent}>
        {truncateText(currentLabel, DEFAULT_LENGTH_TEXT)}
      </Link>
    </Breadcrumbs>
  );
}
