import styles from "./styles.module.scss";
import { Box, Button, CircularProgress, IconButton, InputAdornment, Stack, Typography } from "@mui/material";
import PasswordDefault from "../input/inputPassword";
import React, { useEffect, useState } from "react";
import Iconify from "../iconify";
import * as yup from "yup";
import { AdminUpdateProfile } from "../../redux/actions/admin";
import { useDispatch, useSelector } from "react-redux";
import { TYPE_UPDATE_PROFILE } from "../../constants";
import * as Yup from "yup";

type DataFormType = {
  old_password: string,
  new_password: string,
  new_password_confirmed: string,
};

const INIT_DATA = {
  old_password: "",
  new_password: "",
  new_password_confirmed: "",
};

const PasswordInformation = (props: any) => {
  const { actionNext } = props;
  const [defaultValues] = useState<DataFormType>(INIT_DATA);
  const [errors, setErrors] = useState(defaultValues);
  const [dataUpdate, setDataUpdate] = useState(defaultValues);
  const [showCurrentPass, setShowCurrentPass] = useState(false);
  const [showNewPass, setShowNewPass] = useState(false);
  const [showConfirmedPass, setShowConfirmedPass] = useState(false);
  const dispatch = useDispatch();
  const error = useSelector((state: any) => state?.admin?.error);
  const loadingAdminUpdateProfile = useSelector((state: any) => state.admin.loading);

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate({
      ...dataUpdate,
      [key]: value
    });

    setErrors({
      ...errors,
      [key]: ''
    });
  };

  useEffect(() => {
    if (error) {
      setErrors({ ...errors, old_password: error?.response?.data?.error?.message });
    }
    // eslint-disable-next-line
  }, [error]);

  const handleShowNewPass = () => {
    setShowNewPass(!showNewPass);
  };

  const handleShowCurrentPass = () => {
    setShowCurrentPass(!showCurrentPass);
  };

  const handleShowConfirmPass = () => {
    setShowConfirmedPass(!showConfirmedPass);
  };

  const validationSchema = yup.object().shape({
    old_password: Yup.string().required('パスワードを入力してください'),
    new_password: Yup.string()
      .matches(/[A-Z]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .matches(/[a-z]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .matches(/[0-9]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .matches(/[\W_]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .min(8, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
      .required('パスワードを入力してください'),
    new_password_confirmed: Yup.string().required('パスワード確認を入力してください').oneOf([Yup.ref('new_password')], '新しいパスワードと確認用パスワードが一致しません。'),
  });

  const validateUpdatePassword = async () => {
    try {
      setErrors(defaultValues);
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultValues }; // Ensure we start with default values
      err?.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultValues] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const handleUpdatePasswordInformation = async () => {
    if (await validateUpdatePassword()) {
      const formData = {
        old_password: dataUpdate?.old_password,
        new_password: dataUpdate?.new_password,
      };

      const result = await dispatch(AdminUpdateProfile(formData, TYPE_UPDATE_PROFILE.PASSWORD) as any);
      if (result?.code === 200) {
        setDataUpdate(INIT_DATA);
        setErrors(INIT_DATA);
        actionNext();
      }
    }
  }

  return (
    <Box className={styles.sectionBox}>
      <Box className={styles.contentBox}>
        <Box>
          <Box sx={{ mt: 2 }}>
            <label className={`${styles.subTitlePassword}`}>既存パスワード
              <Box
                sx={{ display: "inline-block" }}>
                <Box className={styles.iconRequired}>*</Box>
              </Box>
            </label>
            <PasswordDefault
              name="old_password"
              id="old_password"
              errorText={errors?.old_password}
              handleChange={handleInputChange}
              className={`${styles.textCenter}`}
              size="small"
              value={dataUpdate?.old_password}
              placeholder="パスワードを入力してください"
              showPass={showCurrentPass}
              setShowPass={setShowCurrentPass}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="material-symbols:lock" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowCurrentPass} edge="end">
                      <Iconify icon={showCurrentPass ? 'mdi:eye-off' : 'mdi:eye'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors?.old_password && (
              <Typography mb={-1} variant='subtitle1' className={styles.errorText}>{errors.old_password}</Typography>
            )}
          </Box>

          <Box sx={{ mt: 2 }}>
            <label className={`${styles.subTitlePassword}`}>新しいパスワード
              <Box
                sx={{ display: "inline-block" }}>
                <Box className={styles.iconRequired}>*</Box>
              </Box>
            </label>
            <PasswordDefault
              name="new_password"
              id="new_password"
              errorText={errors?.new_password}
              handleChange={handleInputChange}
              className={`${styles.textCenter}`}
              size="small"
              value={dataUpdate?.new_password}
              placeholder="パスワードを入力してください"
              showPass={showNewPass}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="material-symbols:lock" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowNewPass} edge="end">
                      <Iconify icon={showNewPass ? 'mdi:eye-off' : 'mdi:eye'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors?.new_password && (
              <Typography mb={-1} variant='subtitle1' className={styles.errorText}>{errors.new_password}</Typography>
            )}
          </Box>

          <Box sx={{ mt: 2 }}>
            <label className={`${styles.subTitlePassword}`}>新しいパスワード確認用
              <Box
                sx={{ display: "inline-block" }}>
                <Box className={styles.iconRequired}>*</Box>
              </Box>
            </label>
            <PasswordDefault
              name="new_password_confirmed"
              id="new_password_confirmed"
              errorText={errors?.new_password_confirmed}
              handleChange={handleInputChange}
              className={`${styles.textCenter}`}
              size="small"
              value={dataUpdate?.new_password_confirmed}
              placeholder="パスワードを入力してください"
              showPass={showConfirmedPass}
              setShowPass={setShowConfirmedPass}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Iconify icon="material-symbols:lock" />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleShowConfirmPass} edge="end">
                      <Iconify icon={showConfirmedPass ? 'mdi:eye-off' : 'mdi:eye'} />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {errors?.new_password_confirmed && (
              <Typography mb={-1} variant='subtitle1' className={styles.errorText}>{errors.new_password_confirmed}</Typography>
            )}
          </Box>

          <Box mt={2} sx={{ textAlign: "end" }}>
            <Button onClick={handleUpdatePasswordInformation}>
              {loadingAdminUpdateProfile ? (
                <Stack flexDirection="row" className={styles.btnLoading}>
                  <CircularProgress size={25} />
                </Stack>
              ) : (
                <Stack className={styles.btnCreate} flexDirection="row">
                  <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                  <Box>保存</Box>
                </Stack>
              )}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default PasswordInformation;
