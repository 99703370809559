const paths = {
  auth: {
    login: "/login",
    adminSetPassword: "/admin/set-password",
    adminForgotPassword: "/admin/forgot-password",
    adminResetPassword: "/admin/reset-password",
    collectorSetPassword: "/set-password",
    collectorForgotPassword: "/forgot-password",
    collectorResetPassword: "/reset-password"
  },

  admin: {
    notificationList: "/admin/notifications/list",
    collectorList: "/admin/collector/list",
    editCollector: "/admin/collector/edit",
    collectorDetail: "/admin/collector",
    assignCollector: "/admin/collector/assign",
    trashCanList: "/admin/trashcan/list",
    trashCanDetail: "/admin/trashcan",
    profile: "/admin/profile",
  },

  superAdmin: {
    adminList: "/spa/admin/list",
    adminDetail: "/spa/admin",
    adminInfo: "/spa/admin-info",
    companyList: "/spa/company/list",
    companyDetail: "/spa/company",
    companyInfo: "/spa/company-info",
    trashList: "/spa/trashcan/list",
    addTrash: "/spa/trashcan/create",
    trashDetail: "/spa/trashcan"
  }
}

export default paths;
