import { TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { ROLE_SUPER_ADMIN } from "../../../constants";
import {
  adminPrimaryColor,
  superAdminPrimaryColor,
  borderColor,
  primaryFont,
} from "../../../constants/colors";

const PasswordDefault = (props: any) => {
  const {
    labelText,
    handleChange,
    showPass,
    errorText,
    placeholder,
    id,
    helperText,
    value,
    name,
    disabled = false,
    className,
    size,
    InputProps
  } = props;

  const authState = useSelector((state: any) => state.auth);
  const primaryColor = authState?.dataInfoUserLogin?.role === ROLE_SUPER_ADMIN ? superAdminPrimaryColor : adminPrimaryColor;

  return (
    <>
      <TextField
        label={labelText}
        disabled={disabled}
        id={id}
        type={showPass ? 'text' : 'password'}
        error={!!errorText}
        helperText={helperText}
        placeholder={placeholder}
        fullWidth
        value={value ?? ""}
        name={name}
        className={className}
        size={size}
        onChange={(e: any) =>
          name
            ? handleChange(name, e.target.value)
            : handleChange(e.target.value)
        }
        InputProps={InputProps}
        sx={{
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderWidth: '1.5px',
              borderColor: borderColor,
              borderRadius: '8px',
              fontFamily: primaryFont,
            },
            '&:hover fieldset': {
              borderWidth: '1.5px',
              borderColor: primaryColor,
              fontFamily: primaryFont,
            },
            '&.Mui-focused fieldset': {
              borderWidth: '1.5px',
              borderColor: primaryColor,
              fontFamily: primaryFont,
            },
            '& .MuiInputBase-input': {
              display: 'flex',
              alignItems: 'center',
              height: '2.7rem',
              padding: 0,
              fontFamily: primaryFont,
            }
          },
        }}
      />
    </>
  )
}

export default PasswordDefault;
