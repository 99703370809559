import { Select, MenuItem, FormControl, InputLabel, FormHelperText, Typography, Chip } from '@mui/material';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from "react-redux";
import { DEFAULT_LENGTH_COMPANY_TEXT, DEFAULT_LENGTH_TEXT_30, ROLE_SUPER_ADMIN } from "../../../constants";
import {
  adminPrimaryColor,
  superAdminPrimaryColor,
  borderColor,
  primaryFont,
} from "../../../constants/colors";
import { truncateText } from '../../../helpers';

const InputSelectBase = (props: any) => {
  const {
    labelText,
    handleChange,
    errorText,
    keyword,
    id,
    helperText,
    value,
    disabled = false,
    size,
    variant = "outlined",
    className,
    options = [],
    required,
    multiple = false,
    InputProps,
    defaultOption,
    isEdit,
    customChipItemTrashCan,
    placeholder,
    isAdminAssignCollector
  } = props;
  const [selectedValue, setSelectedValue] = useState(value || '');
  const selectRef = useRef<HTMLDivElement>(null);

  const authState = useSelector((state: any) => state.auth);
  const primaryColor = authState.dataInfoUserLogin.role === ROLE_SUPER_ADMIN ? superAdminPrimaryColor : adminPrimaryColor;

  useEffect(() => {
    if (isEdit) {
      setSelectedValue(value);
    }
  }, [isEdit, value]);

  const handleSelectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedValue(event.target.value);
    handleChange(keyword, event.target.value);
  };

  const getDefaultValue = () => {
    if (selectedValue === '' && !value && defaultOption) {
      return defaultOption.value;
    }
    return selectedValue;
  };

  return (
    <FormControl
      fullWidth
      variant={variant}
      size={size}
      className={className}
      disabled={disabled}
      error={!!errorText}
      required={required}
    >
      {labelText && <InputLabel id={`${id}-label`}>{labelText}</InputLabel>}
      <Select
        labelId={`${id}-label`}
        id={id}
        value={getDefaultValue()}
        onChange={(e: any) => handleSelectChange(e)}
        renderValue={selectedValue !== '' ? undefined : () => (
          <Typography sx={{
            opacity: '0.3',
            fontFamily: primaryFont,
            fontSize: '14px',
            marginTop: '1px'
          }}>{placeholder}</Typography>
        )}
        multiple={multiple}
        startAdornment={InputProps?.startAdornment}
        MenuProps={{
          PaperProps: {
            sx: {
              maxHeight: 200,
              width: selectRef.current ? selectRef.current.clientWidth : 'auto',
              '& .MuiMenuItem-root': {
                fontFamily: 'Noto Sans JP',
              },
            },
          },
        }}
        sx={{
          height: '2.7rem',
          padding: 0,
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: borderColor,
            borderRadius: '8px'
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: primaryColor,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderWidth: '1px',
            borderColor: primaryColor,
          },
        }}
        ref={selectRef}>
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value} disabled={option.value === defaultOption?.value}
            sx={{
              fontFamily: primaryFont,
              whiteSpace: 'normal',
            }}>
            {customChipItemTrashCan ? (
              <Chip
                size="small"
                label={option.label}
                sx={{
                  background: option.background,
                  color: "white",
                  fontWeight: "bold",
                  fontFamily: primaryFont
                }}
              />
            ) : (
              truncateText(option.label, isAdminAssignCollector ? DEFAULT_LENGTH_TEXT_30 : DEFAULT_LENGTH_COMPANY_TEXT)
            )}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default InputSelectBase;
