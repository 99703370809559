import React from 'react';
import ReactDOM from 'react-dom';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Button from '@mui/material/Button';
import { HEADER } from '../config-layout';
import { snackbarColor, primaryFont } from '../constants/colors';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const successSnackbar = (message: string, action?: () => void, actionText?: string) => {
  renderSnackbar(message, 'success', action, actionText);
};

export const errorSnackbar = (message: string, action?: () => void, actionText?: string) => {
  renderSnackbar(message, 'error', action, actionText);
};

const renderSnackbar = (message: string, severity: 'success' | 'error', action?: () => void, actionText?: string) => {
  const snackbarContainer = document.createElement('div');
  document.body.appendChild(snackbarContainer);

  const handleClose = () => {
    ReactDOM.unmountComponentAtNode(snackbarContainer);
    document.body.removeChild(snackbarContainer);
  };

  const style = {
    top: `calc(${HEADER.H_DESKTOP}px + 25px)`,
  };

  let snackbarStyle = {
    backgroundColor: snackbarColor.successBgColor,
    color: snackbarColor.successColor,
    border: `1px solid ${snackbarColor.successborderColor}`,
    btnBackgroundColor: snackbarColor.successColor
  };

  if (severity === 'error') {
    snackbarStyle = {
      backgroundColor: snackbarColor.errorBgColor,
      color: snackbarColor.errorColor,
      border: `1px solid ${snackbarColor.errorborderColor}`,
      btnBackgroundColor: snackbarColor.errorColor
    };
  }

  ReactDOM.render(
    <Snackbar
      open
      autoHideDuration={5000}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      style={style}
      sx={{
        '& .MuiAlert-root': {
          backgroundColor: snackbarStyle.backgroundColor,
          color: snackbarStyle.color,
          border: snackbarStyle.border,
          fontFamily: primaryFont,
          fontSize: '1rem',
          borderRadius: '10px',
          padding: '1px 20px 1px 10px'
        },
      }}>
      <Alert
        severity={severity}
        action={
          action ? (
            <Button
              color="inherit"
              size="small"
              onClick={action}
              sx={{
                fontWeight: 'bold',
                fontFamily: primaryFont,
                backgroundColor: snackbarStyle.btnBackgroundColor,
                color: 'white',
                borderRadius: '6px',
                paddingLeft: '10px',
                paddingRight: '10px',
                '&:hover': {
                  backgroundColor: snackbarStyle.btnBackgroundColor,
                }
              }}>
              {actionText}
            </Button>
          ) : null
        }
      >
        {message}
      </Alert>
    </Snackbar>,
    snackbarContainer
  );
};
