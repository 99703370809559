/* eslint-disable react-hooks/exhaustive-deps */
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { editTrashcanByAdmin, getTrashcanDetailByAdmin } from "../../../redux/actions/trashcan";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Card, Chip, CircularProgress, Container, Grid, IconButton, Skeleton, Stack, Typography } from "@mui/material";
import HeaderAdmin from "../../../components/header/admin/header";
import styles from './styles.module.scss';
import { HEADER } from "../../../config-layout";
import { filterEmptyFields, getAddress, getClientWidth, getTrashCanStyleBySuperAdmin, truncateText } from "../../../helpers";
import { DEFAULT_LANGUAGE, DEFAULT_REGION, LAT_DEFAULT, LONG_DEFAULT, MEDIUM_TRASHCAN_LEVEL, MEDIUM_WIDTH_SCREEN } from "../../../constants";
import FormProvider from "../../../components/form/form-provider";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import Iconify from "../../../components/iconify";
import InputBase from "../../../components/input/inputBase";
import InputSelectBase from "../../../components/input/inputSelect";
import CustomMapAdmin from "../../../components/admin-maps";
import { geocode, RequestType, setKey } from "react-geocode";
import debounce from "lodash/debounce";
import paths from "../../../constants/paths";
import { getAllCollectors } from "../../../redux/actions/collector";
import { isEmpty, isNumber } from "lodash";
import Custom404Page from "../../404";
import statusCode from "../../../constants/statusCode";
import AdminMapSkeleton from "../../../components/skeleton/map/map-detail";
import BreadcrumbsComponent from "../../../components/breadcrumbs";
import { sendNotificationFullTrashToCollector } from "../../../redux/actions/admin";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

type DataFormType = {
  trashcan_name: string,
  address: string,
  lat: any,
  long: any,
  collector_ids: any
}

const defaultErrors = {
  trashcan_name: '',
  address: '',
  lat: '',
  long: '',
  collector_ids: ''
}

export default function AdminTrashDetail() {
  setKey(`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);

  const { id } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(defaultErrors);
  const [userAddress, setUserAddress] = useState('');
  const trashcanDetail = useSelector((state: any) => state.trash?.dataTrashCanDetailByAdmin?.data)
  const [markerLocation, setMarkerLocation] = useState({
    lat: parseFloat(trashcanDetail?.lat ?? LAT_DEFAULT),
    lng: parseFloat(trashcanDetail?.long ?? LONG_DEFAULT),
  });
  const [collectors, setCollectors] = useState([{ id: Date.now(), collector_id: '' }]);
  const INIT_DATA = {
    trashcan_name: trashcanDetail?.trashcan_name,
    address: '',
    lat: LAT_DEFAULT,
    long: LONG_DEFAULT,
    collector_ids: trashcanDetail?.collectors?.reduce((collectorArr: any[], collector: any) => {
      collectorArr.push(collector?.collector_id);
      return collectorArr;
    }, [])
  }
  const location = useLocation();
  const { viewCollectorInfoFrom, collector_id, backPage } = location.state || { state: { viewCollectorInfoFrom: true, backPage: 1 } }
  const [defaultValues] = useState<DataFormType>(INIT_DATA);
  const [dataUpdate, setDataUpdate] = useState(trashcanDetail);
  const [isHasSkeleton, setIsHasSkeleton] = useState(true);
  const [isSubmit, setIsSubmit] = useState(false);

  const loadingUpdateTrashcan = useSelector((state: any) => state.trash.loading);
  const generalError = useSelector((state: any) => state?.trash?.error?.response?.status);
  const isAdmin404Err = useSelector((state: any) => state?.trash?.isAdmin404Err);

  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "ゴミ箱の管理", href: paths.admin.trashCanList },
  ];

  const validationSchema = yup.object().shape({
    trashcan_name: yup
      .string()
      .required('ゴミ箱の名前は必ず入力してください'),
    lat: yup
      .string()
      .matches(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,16})?$/, '経度は半角数字を入力してください。')
      .required('緯度は必ず入力してください'),
    long: yup
      .string()
      .matches(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,16})?$/, '経度は半角数字を入力してください。')
      .required('経度は必ず入力してください'),
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      setErrors(defaultErrors);
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultErrors };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultErrors] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const chipStyle = getTrashCanStyleBySuperAdmin(trashcanDetail?.level);

  const handleMapClick = (location: { lat: number, lng: number }) => {
    setMarkerLocation(location);
    setDataUpdate((prevData: any) => ({
      ...prevData,
      lat: location.lat,
      long: location.lng,
      isDefaultLocation: true,
    }));
  };

  const debouncedUpdateLocation = useRef(
    debounce((lat: number, long: number) => {
      setMarkerLocation({
        lat: parseFloat(lat?.toString()),
        lng: parseFloat(long?.toString()),
      });

      if (lat && long) {
        geocode(RequestType.LATLNG, `${lat}, ${long}`)
          .then(({ results }) => {
            geocode(RequestType.ADDRESS, (results[0].formatted_address), {
              language: DEFAULT_LANGUAGE,
              region: DEFAULT_REGION
            } as any).then((res) => {
              if (res?.results.length > 0) {
                const japaneseAddress = getAddress(res?.results);
                setUserAddress(japaneseAddress);
              }
            }).catch(console.error);
          })
          .catch(console.error);
      }
    }, 2000)
  ).current;

  const handleAddInputSelectCollectors = () => {
    setIsSubmit(false);
    const newCollectorId = Date.now();
    setCollectors(prevCollectors => [
      ...prevCollectors,
      { id: newCollectorId, collector_id: '' }
    ]);
  };

  const handleSelectInputChange = (name: string, value: any) => {
    setIsSubmit(false);
    const collectorId = parseInt(name.split('_')[2]);
    setCollectors(prevCollectors => prevCollectors.map(collector => {
      if (collector.id === collectorId) {
        return { ...collector, collector_id: value };
      }
      return collector;
    }));
  };

  const handleDeleteCollector = (id: any) => setCollectors(prevCollectors => prevCollectors.filter(collector => collector.id !== id));

  const methods = useForm({
    defaultValues,
  });
  const { handleSubmit } = methods;

  const onsubmit = handleSubmit(async () => {
    setIsSubmit(true);
    const isValid = await validate();
    if (!isValid) return;

    const currentCollector = collectors?.reduce((currentCollector: any[], collector: any) => {
      currentCollector.push(collector?.collector_id);
      return currentCollector;
    }, []);

    if (!currentCollector?.some((item: any) => !isNumber(item))) {
      const formData = {
        trashcan_name: dataUpdate?.trashcan_name,
        lat: dataUpdate?.lat,
        long: dataUpdate?.long,
        collector_ids: currentCollector
      };
      const newData = filterEmptyFields(formData);
      const result = await dispatch(editTrashcanByAdmin(newData, id) as any);
      if (result) {
        setErrors(defaultErrors);
        navigate(paths.admin.trashCanList);
      }
    }
  });

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate({
      ...dataUpdate,
      [key]: value
    });

    setErrors({
      ...errors,
      [key]: ''
    });
  };

  const handleSendNotifications = async () => {
    const data = [
      {
        trashcan_id: id,
        collector_ids: collectors.map((collector) => collector.id)
      }
    ];
    await dispatch(sendNotificationFullTrashToCollector({data}) as any);
  }

  const defaultOption = {
    value: '0',
    label: '選択'
  }

  const dataAllCollectors = useSelector((state: any) => state?.collector?.dataAllCollectors?.data);
  const transformedOptions = dataAllCollectors?.map((item: any) => ({
    value: item.id,
    label: item.full_name
  }));

  const getFilteredOptions = useCallback((collectorId: any) => {
    if (collectors && transformedOptions) {
      const selectedCollectorIds = collectors.map(collector => collector.collector_id).filter(id => id !== '');
      return transformedOptions?.filter((option: any) => !selectedCollectorIds.includes(option.value) || option.value === collectorId);
    }
  }, [collectors, transformedOptions]);

  const collectorInputs = useMemo(() => collectors.map((collector, index) => (
    <Box sx={{ mb: 3 }} key={collector.id}>
      <Grid container spacing={2}>
        <Grid item xs={11}>
          <Box className={styles.titleInput}>担当者 {index + 1}</Box>
          <InputSelectBase
            name={`collector_id_${collector.id}`}
            keyword={`collector_id_${collector.id}`}
            type="text"
            className={styles.inputCompany}
            handleChange={(keyword: any, value: any) => handleSelectInputChange(keyword, value)}
            size="small"
            value={collector?.id}
            placeholder={`担当者 #${index + 1}`}
            options={getFilteredOptions(collector.collector_id)}
            defaultOption={defaultOption}
          />
        </Grid>
        <Grid item xs={1} display="flex" alignItems="center" sx={{ marginTop: 3 }}>
          {collectors.length > 1 && (
            <IconButton className={styles.iconDelete} onClick={() => handleDeleteCollector(collector.id)} aria-label="delete" size="small">
              <Iconify icon="mdi:trash" width={20} height={20} />
            </IconButton>
          )}
        </Grid>
      </Grid>
      {collector.collector_id === '' && isSubmit && <Box sx={{ my: 1 }} className={styles.errorText}>担当者は選択してください。</Box>}
    </Box>
  )), [collectors, transformedOptions, defaultOption]);

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });

        if (permission.state === 'granted' || permission.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (pos) => {
              const coords = pos.coords;
              setMarkerLocation({
                lat: trashcanDetail?.lat ?? coords.latitude,
                lng: trashcanDetail?.long ?? coords.longitude
              });
              setDataUpdate((prevData: any) => ({
                ...prevData,
                lat: trashcanDetail?.lat,
                lng: trashcanDetail?.long
              }));
            },
            (error) => {
              console.error(error);
              setMarkerLocation({
                lat: LAT_DEFAULT,
                lng: LONG_DEFAULT
              });
            }
          );
        } else {
          setMarkerLocation({
            lat: LAT_DEFAULT,
            lng: LONG_DEFAULT
          });
        }
      } catch (error) {
        console.error(error);
        setMarkerLocation({
          lat: LAT_DEFAULT,
          lng: LONG_DEFAULT
        });
      }
    };

    if (dataUpdate?.lat === LAT_DEFAULT && dataUpdate?.long === LONG_DEFAULT && trashcanDetail) {
      fetchLocation();
      geocode(RequestType.LATLNG, `${trashcanDetail?.lat}, ${trashcanDetail?.long}`)
        .then(({ results }) => {
          geocode(RequestType.ADDRESS, (results[0].formatted_address), {
            language: DEFAULT_LANGUAGE,
            region: DEFAULT_REGION
          } as any).then((res) => {
            if (res?.results.length > 0) {
              const japaneseAddress = getAddress(res?.results);
              setUserAddress(japaneseAddress);
            }
          }).catch(console.error);
        })
        .catch(console.error);
    } else {
      debouncedUpdateLocation(dataUpdate?.lat, dataUpdate?.long);
    }
  }, [dataUpdate?.lat, dataUpdate?.long, trashcanDetail, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      setIsHasSkeleton(true);
      try {
        await dispatch(getTrashcanDetailByAdmin(id) as any);
        await dispatch(getAllCollectors() as any);
      } catch (error) {
        console.error(error);
      } finally {
        setIsHasSkeleton(false);
      }
    };
    fetchData();
  }, [id, dispatch]);

  useEffect(() => {
    if (trashcanDetail && !isEmpty(trashcanDetail?.collectors)) {
      setCollectors(trashcanDetail?.collectors.map((collector: any) => ({
        id: collector.id,
        collector_id: collector.id ?? ''
      })));
      setMarkerLocation({
        lat: parseFloat(trashcanDetail?.lat) ?? LAT_DEFAULT,
        lng: parseFloat(trashcanDetail?.long) ?? LONG_DEFAULT,
      });
    }

    setDataUpdate(trashcanDetail)
  }, [trashcanDetail]);

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.admin.trashCanDetail}</title>
      </Helmet>
      {(generalError === statusCode.NOTFOUND || isAdmin404Err) ? (
        <Custom404Page />
      ) : (
        <Box>
          <HeaderAdmin />
          <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
            <Card sx={{ py: 3, marginTop: `${HEADER.H_DESKTOP}px` }} className={styles.cardContainer}>
              <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
                {isHasSkeleton ? (
                  <Skeleton className={styles.labelSkeleton} variant="rectangular" />
                ) : (
                  <BreadcrumbsComponent items={breadcrumbItems} currentLabel={trashcanDetail?.trashcan_name} />
                )}
              </Stack>
              <FormProvider methods={methods} onSubmit={onsubmit}>
                {isHasSkeleton ? (
                  <Skeleton className={styles.labelSkeleton} variant="rectangular" />
                ) : (
                  <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                    <Stack direction="row" alignItems="center" columnGap={2}>
                      <Box className={styles.titlePage}>{truncateText(trashcanDetail?.trashcan_name, 30)}</Box>
                      <Chip size="small" label={chipStyle.label}
                        sx={{ background: chipStyle.background, color: "white", fontWeight: "bold", fontFamily: chipStyle.font }}
                      />
                    </Stack>
                    <Stack direction="row" columnGap={1}>
                      {trashcanDetail && trashcanDetail?.level >= MEDIUM_TRASHCAN_LEVEL && (
                        <Button sx={{ marginLeft: 2 }} className={styles.btnNotificationAll} onClick={handleSendNotifications}>
                          <Typography variant="subtitle1" className={styles.btnText}>お知らせ</Typography>
                        </Button>
                      )}
                      {!viewCollectorInfoFrom ? (
                        <Button className={styles.btnBack} onClick={() => navigate(`${paths.admin.trashCanList}`, { state: { backPage: backPage, previousUrl: location.pathname } })}>
                          <Typography variant="subtitle1" className={styles.btnText}>キャンセル</Typography>
                        </Button>
                      ) : (
                          <Button className={styles.btnBack} onClick={() => navigate(`${paths.admin.collectorDetail}/${collector_id}`)}>
                            <Typography variant="subtitle1" className={styles.btnText}>キャンセル</Typography>
                          </Button>
                      )}
                      <Button type="submit" className={styles.btnAdd} sx={{ width: '80px' }}>
                        {loadingUpdateTrashcan ? <CircularProgress size={18} sx={{ color: "white" }} /> : <>
                          <Iconify
                          icon="material-symbols:check"
                          className={styles.iconAdd} />
                          <Typography
                            variant="subtitle1"
                            className={`${styles.btnText} ${styles.textCommon}`}>
                            保存
                          </Typography>
                        </>}
                      </Button>
                    </Stack>
                  </Stack>
                )}
                <Grid
                  sx={{ mt: 2 }}
                  justifyContent="space-between"
                  container>
                  {id && isHasSkeleton ? (
                    <Grid item md={6}>
                      <AdminMapSkeleton />
                    </Grid>
                  ) : (
                    <Grid item md={6}>
                      <Box className={styles.boxInput}>
                        <Box className={styles.titleInput}>ゴミ箱の名前</Box>
                        <InputBase
                          name="trashcan_name"
                          keyword="trashcan_name"
                          type="text"
                          className={styles.inputNameAdmin}
                          handleChange={handleInputChange}
                          placeholder="ゴミ箱の名前を付けてください"
                          size="small"
                          value={dataUpdate?.trashcan_name ?? trashcanDetail?.trashcan_name} />
                        {errors?.trashcan_name && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.trashcan_name}</Box>
                        )}
                      </Box>

                      <Box className={styles.boxInput} sx={{ mt: 2 }}>
                        <Box className={styles.titleInput}>住所</Box>
                        <InputBase
                          disabled
                          name="address"
                          keyword="address"
                          type="text"
                          className={styles.inputNameAdmin}
                          placeholder="住所を入力してください"
                          size="small"
                          value={userAddress}
                        />
                        {errors?.address && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.address}</Box>
                        )}
                      </Box>

                      <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                        <Grid item md={5.5}>
                          <Box className={styles.boxInput}>
                            <Box className={styles.titleInput}>経度</Box>
                            <InputBase
                              name="long"
                              keyword="long"
                              type="text"
                              className={styles.inputNameAdmin}
                              handleChange={handleInputChange}
                              placeholder="経度を入力してください"
                              size="small"
                              value={dataUpdate?.long ?? trashcanDetail?.long} />
                            {errors?.long && (
                              <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.long}</Box>
                            )}
                          </Box>
                        </Grid>
                        <Grid item md={5.5} justifyContent="space-between">
                          <Box className={styles.titleInput}>
                            <Box className={styles.titleInput}>緯度</Box>
                            <InputBase
                              name="lat"
                              keyword="lat"
                              type="text"
                              className={styles.inputNameAdmin}
                              handleChange={handleInputChange}
                              placeholder="緯度を入力してください"
                              size="small"
                              value={dataUpdate?.lat ?? trashcanDetail?.lat} />
                            {errors?.lat && (
                              <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.lat}</Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      {/* <Box className={styles.boxInput} sx={{ mt: 2 }}>
                        <Box className={styles.titleInput}>グループ</Box>
                        <InputSelectBase
                          name="group_id"
                          keyword="group_id"
                          type="text"
                          className={styles.inputCompany}
                          size="small"
                          value="1"
                          placeholder="会社を選択してください。"
                        />
                      </Box> */}

                      <Box className={styles.boxInput} sx={{ mt: 2 }}>
                        {collectorInputs}
                        <Box className={`mt-1`}>
                          <Button className={styles.btnEdit} onClick={handleAddInputSelectCollectors}>
                            <Iconify icon="material-symbols:add" className={styles.iconAddCollector} />
                            <Typography variant="subtitle1" className={styles.btnText}>担当者を追加</Typography>
                          </Button>
                        </Box>
                      </Box>

                    </Grid>
                  )}
                  <Grid item md={5}>
                    <CustomMapAdmin
                      markerLocation={{
                        lat: isNaN(markerLocation.lat) ? LAT_DEFAULT : markerLocation.lat,
                        lng: isNaN(markerLocation.lng) ? LONG_DEFAULT : markerLocation.lng,
                      }}
                      setMarkerLocation={handleMapClick}
                      trashcanDetail={trashcanDetail}
                      isHasSkeleton={isHasSkeleton}
                      setIsHasSkeleton={setIsHasSkeleton}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
            </Card>
          </Container>
        </Box>
      )}
    </>
  )
}
