import * as t from "../constants/index";
import { baseAxios } from "../../commons/axiosBase";
import { REQUEST_METHOD } from "../../constants";
import { doAction } from "./index";

interface BodyListTrash {
  pages: number;
  userId: string | null;
}

export const getListTrashByAdmin = (body: BodyListTrash) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/list`,
    params: body,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));
  if (apiResponse) {
    dispatch({
      type: t.GET_LIST_TRASH_BY_ADMIN,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const getListTrashBySuperAdmin = (body: BodyListTrash) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/trashcan/list`,
    params: body,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));
  if (apiResponse) {
    dispatch({
      type: t.GET_LIST_TRASH_BY_SUPER_ADMIN,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const addNewTrash = (body: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/trashcan/create`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.SUPER_ADMIN_ADD_NEW_TRASHCAN,
      payload: apiResponse,
    })
  }

  return apiResponse;
}

export const getTrashcanDetail = (id: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/trashcan/${id}`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi,
    role: 'super'
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_TRASHCAN_DETAIL,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const getTrashcanDetailByAdmin = (id: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/${id}`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi,
    role: 'admin'
  }));
  
  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_TRASHCAN_DETAIL_BY_ADMIN,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const editTrashcan = (body: any, id: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/trashcan/${id}`,
    data: body,
    method: REQUEST_METHOD.PUT,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.UPDATE_TRASHCAN_SUPER_ADMIN_SUCCESS,
      payload: apiResponse,
    })
  }

  return apiResponse;
}

export const editTrashcanByAdmin = (body: any, id: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/admin/trashcan/${id}`,
    data: body,
    method: REQUEST_METHOD.PATCH,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.UPDATE_TRASHCAN_ADMIN_SUCCESS,
      payload: apiResponse,
    })
  }

  return apiResponse;
}

export const deleteTrashcan = (id: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/trashcan/${id}`,
    method: REQUEST_METHOD.DELETE,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}
