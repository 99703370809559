import * as yup from 'yup';
import React, { useEffect, useState } from "react";
import { Map, Marker, InfoWindow, useMap, APIProvider } from "@vis.gl/react-google-maps";
import styles from "./styles.module.scss";
import level1 from "../../assets/images/level1.png";
import level2 from "../../assets/images/level2.png";
import level3 from "../../assets/images/level3.png";
import { Box, Button, Skeleton, Stack, Typography } from "@mui/material";
import avatarDefault from "../../assets/images/default.png";
import Iconify from "../iconify";
import { HIGH_LEVEL_TRASH_CAN, LAT_DEFAULT, LONG_DEFAULT, MEDIUM_LEVEL_TRASH_CAN } from "../../constants";
import { useForm } from 'react-hook-form';
import { editTrashcan, getTrashcanDetail } from '../../redux/actions/trashcan';
import FormProvider from "../form/form-provider";
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import InputBase from '../input/inputBase';
import { filterEmptyFields } from '../../helpers';

const validationSchema = yup.object().shape({
  trashcan_name: yup.string().required('ゴミ箱の名前は必ず入力してください').max(255, '255文字以内で入力してください'),
});

const defaultError = { trashcan_name: '' };

const handleUrlLevel = (level: number) => {
  if (level < MEDIUM_LEVEL_TRASH_CAN) return level1;
  if (level < HIGH_LEVEL_TRASH_CAN) return level2;
  return level3;
};

const CustomMapSuperAdmin = ({ markerLocation, markerLocationDefault, dataListTrash, trashcanDetail, setMarkerLocation, isHasSkeleton, setIsHasSkeleton }: any) => {
  const map = useMap();
  const [selectedMarker, setSelectedMarker] = useState<any>((markerLocation && !markerLocation?.isDefaultLocation) ? markerLocation : null);
  const [firstRender, setFirstRender] = useState(true)

  const [isEditing, setIsEditing] = useState(false);
  const [dataUpdate, setDataUpdate] = useState(selectedMarker);
  const [errors, setErrors] = useState(defaultError);
  const [inListView, setInListView] = useState(true);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const dispatch = useDispatch();
  const trashcanDetailAfterUpdate = useSelector((state: any) => state.trash?.dataTrashCanSuperAdminAfterUpdate?.data);
  const trashcanDetailSuperAdminInList = useSelector((state: any) => state.trash?.dataTrashcanDetail?.data);

  const { id } = useParams();
  useEffect(() => {
    if (isEditing) {
      setIsHasSkeleton(true)
    }
  }, [markerLocation?.lat, markerLocation?.lng])

  const defaultValues = {
    trashcan_name: '',
    address: selectedMarker?.address ?? '',
    lat: selectedMarker?.lat ?? LAT_DEFAULT,
    long: selectedMarker?.long ?? LONG_DEFAULT,
    group_id: selectedMarker?.group_id ?? '',
    company_id: selectedMarker?.company_id ?? '',
  };

  const methods = useForm({ defaultValues });

  const validate = async () => {
    try {
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      setErrors(defaultError);
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultError };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultError] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  useEffect(() => {
    if (!markerLocation) {
      setSelectedMarker(null);
    }
  }, [markerLocation]);

  useEffect(() => {
    if (dataListTrash && firstRender) {
      map?.panTo({ lat: dataListTrash[0].lat ?? LAT_DEFAULT, lng: dataListTrash[0].long ?? LONG_DEFAULT });
      setSelectedMarker(dataListTrash[0]);
      setIsMapLoaded(true);
    }
    else if (markerLocation && !markerLocation.isDefaultLocation) {
      setFirstRender(false);
      map?.panTo({ lat: markerLocation.lat ?? LAT_DEFAULT, lng: markerLocation.long ?? LONG_DEFAULT });
      setSelectedMarker(markerLocation);
      setIsMapLoaded(true);
    }
  }, [markerLocation]);

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate((prev: any) => ({ ...prev, [key]: value }));
    setErrors(prev => ({ ...prev, [key]: '' }));
  };

  const handleMapClick = (marker: any) => {
    if (marker) {
      setSelectedMarker(marker);
      setIsEditing(false);
      setFirstRender(false);
      map?.panTo({ lat: marker.lat ?? LAT_DEFAULT, lng: marker.long ?? LONG_DEFAULT });
      setIsMapLoaded(true);
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  useEffect(() => {
    setDataUpdate(trashcanDetailSuperAdminInList);
  }, [isEditing])

  const handleBack = () => {
    setIsEditing(false);
    setErrors(defaultError);
  }

  const onSubmit = methods.handleSubmit(async () => {
    const isValid = await validate();
    if (!isValid) return;

    const formData = filterEmptyFields({
      trashcan_name: dataUpdate?.trashcan_name,
      lat: selectedMarker?.lat,
      long: selectedMarker?.long,
      company_id: selectedMarker?.company_id,
    });

    const result = await dispatch(editTrashcan(formData, id ?? selectedMarker.trashcan_id) as any);
    if (result?.code === 200) {
      setIsEditing(false);
      if (selectedMarker.trashcan_id) {
        setInListView(true)
      }

      setErrors(defaultError);
      dispatch(getTrashcanDetail(id ?? selectedMarker.trashcan_id as any) as any);
      setDataUpdate(trashcanDetailAfterUpdate);
    }
  });

  useEffect(() => {
    if (id) {
      setFirstRender(false);
      dispatch(getTrashcanDetail(id) as any);
      setInListView(false)
    }
  }, [id, dispatch]);

  useEffect(() => {
    if (selectedMarker?.trashcan_id) {
      dispatch(getTrashcanDetail(selectedMarker?.trashcan_id) as any);
      setInListView(false)
    }
  }, [dispatch, selectedMarker?.trashcan_id]);

  return (
    <APIProvider apiKey={`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}>
      {id ? (
        <DetailMap markerLocation={markerLocation} setMarkerLocation={setMarkerLocation} trashcanDetail={trashcanDetail} isHasSkeleton={isHasSkeleton} isMapLoaded={isMapLoaded} />
      ) : (
        <div className={styles.mapContainer}>
          <Map
            style={{ borderRadius: "20px" }}
            defaultZoom={13}
            defaultCenter={{ lat: (markerLocationDefault?.lat ?? markerLocation?.lat) || LAT_DEFAULT, lng: (markerLocationDefault?.long ?? markerLocation?.long) || LONG_DEFAULT }}
            gestureHandling="greedy"
            disableDefaultUI
          >
            {dataListTrash?.map((marker: any) => (
              <Marker
                position={{ lat: marker.lat, lng: marker.long }}
                key={marker.trashcan_id}
                onClick={() => handleMapClick(marker)}
                icon={{ url: handleUrlLevel(marker?.level) }}
              />
            ))}
            {selectedMarker && (
              <InfoWindow
                position={{ lat: selectedMarker.lat, lng: selectedMarker.long }}
                onCloseClick={() => {
                  setSelectedMarker(null);
                  handleBack();
                }}
                pixelOffset={[0, -50]}
                headerContent={
                  // !isEditing && (
                  <Stack className={styles.textTrashName} flexDirection="row" alignItems="center" columnGap={1}>
                    {inListView ? trashcanDetailAfterUpdate?.trashcan_name : selectedMarker?.trashcan_name}
                    <Iconify icon="ic:baseline-edit" width={18} className={styles.iconEdit} onClick={handleEditClick} />
                  </Stack>
                  // )
                }
              >
                <div style={{ width: isEditing ? "300px" : "180px" }}>
                  {isEditing ? (
                    <FormProvider methods={methods} onSubmit={onSubmit}>
                      <Box className={styles.headerInfoWindow} sx={{ display: "flex", flexDirection: "column" }}>
                        <InputBase
                          name="trashcan_name"
                          keyword="trashcan_name"
                          type="text"
                          className={styles.inputNameAdmin}
                          handleChange={handleInputChange}
                          placeholder="ゴミ箱の名前を付けてください"
                          size="small"
                          width="100%"
                          value={dataUpdate ? dataUpdate?.trashcan_name : selectedMarker?.trashcan_name}
                        />
                        {errors?.trashcan_name && (
                          <Box className={styles.errorText}>{errors?.trashcan_name}</Box>
                        )}
                        <Stack justifyContent="center" flexDirection="row" columnGap={1} sx={{ mt: errors?.trashcan_name ? 2 : 1 }}>
                          <Button onClick={() => handleBack()} className={styles.btnBack}>
                            <Typography className={styles.backText}>キャンセル</Typography>
                          </Button>
                          <Button type="submit" className={styles.btnSave}>
                            <Typography className={styles.saveText}>保存</Typography>
                          </Button>
                        </Stack>
                      </Box>
                    </FormProvider>
                  ) : (
                    <Box>
                      <p className={styles.textGroup}>グループ #1</p>
                      {selectedMarker?.collectors?.map((collector: any, index: any) => (
                        <Box key={index} sx={{ marginTop: "10px" }}>
                          <img className={styles.imageAdmin} src={collector.avatar || avatarDefault} alt="avatar" />
                          <span>{collector.full_name}</span>
                        </Box>
                      ))}
                    </Box>
                  )}
                </div>
              </InfoWindow>
            )}
          </Map>
        </div>
      )}
    </APIProvider>
  );
};

const DetailMap = ({ markerLocation, setMarkerLocation, trashcanDetail, isHasSkeleton, isMapLoaded }: any) => {
  const map = useMap();

  useEffect(() => {
    if (markerLocation) {
      map?.panTo(markerLocation);
    }
  }, [markerLocation, map]);

  const handleMapClick = (event: any) => {
    const newLocation = {
      lat: event?.detail?.latLng?.lat,
      lng: event?.detail?.latLng?.lng,
      isDefaultLocation: false,
    };
    setMarkerLocation(newLocation);
    if (newLocation) {
      map?.panTo(newLocation);
    }
  };

  const markerIcon = trashcanDetail ? { url: handleUrlLevel(trashcanDetail.level) } : undefined;

  return (
    isHasSkeleton || !isMapLoaded) ? (
    <Skeleton variant="rectangular" sx={{ height: "532px", width: "100%", borderRadius: "20px", overflow: "hidden" }} />
  ) : (
    <Box sx={{ height: "532px", width: "100%", borderRadius: "20px", overflow: "hidden" }}>
      <Map
        defaultZoom={18}
        defaultCenter={markerLocation}
        gestureHandling="greedy"
        disableDefaultUI
        onClick={handleMapClick}
      >
        <Marker position={markerLocation} icon={markerIcon} />
      </Map>
    </Box>
  );
};

export default CustomMapSuperAdmin;
