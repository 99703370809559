import * as t from "../constants/index";

const initState: any = {
  loading: false,
  error: null,
  dataPostalCode: ''
};

const postalCode = (state = initState, action: any) => {
  switch (action.type) {
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        error: null,
      };
    case t.LOADING:
      return {
        ...state,
        [action.key]: action.loading,
      };
    case t.CALL_API_SUCCESS:
      return {
        ...state,
        [action.loading]: false,
        error: null
      };
    case t.DATA_POSTAL_CODE:
      return {
        ...state,
        [action.loading]: false,
        dataPostalCode: action.payload,
        error: null
      };
    case t.ERROR:
      return {
        ...state,
        error: action.payload,
        [action.loading]: false,
      };
    default:
      return { ...state };
  }
};

export default postalCode;
