import * as t from "../constants/index";

const initState: any = {
  loading: false,
  error: null,
  dataCompany: ''
};

const company = (state = initState, action: any) => {
  switch (action.type) {
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        error: null,
      };
    case t.LOADING:
      return {
        ...state,
        [action.key]: action.loading,
      };
    case t.CALL_API_SUCCESS:
      return {
        ...state,
        [action.loading]: false,
        error: null
      };
    case t.DATA_COMPANY_LIST:
      return {
        ...state,
        [action.loading]: false,
        dataCompany: action.payload,
        paginate: action.payload,
        error: null
      };
    case t.DATA_GET_ALL_COMPANY:
      return {
        ...state,
        [action.loading]: false,
        dataGetAllCompany: action.payload,
        error: null
      }
    case t.DATA_COMPANY_DETAIL:
      return {
        ...state,
        [action.loading]: false,
        dataCompanyDetail: action.payload,
        error: null
      };
    case t.SUPER_ADMIN_ADD_NEW_COMPANY:
      return {
        ...state,
        [action.loading]: true,
        error: null
      };
    case t.ERROR:
      return {
        ...state,
        error: action.payload,
        [action.loading]: false,
      };
    default:
      return { ...state };
  }
};

export default company;
