import * as yup from 'yup';
import { Stack, Button, Typography, Box, Card, Container, Grid, Chip, CircularProgress, Skeleton } from "@mui/material";
import { useForm } from "react-hook-form";
import InputBase from "../../../components/input/inputBase";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InputSelectBase from '../../../components/input/inputSelect';
import HeaderSuperAdmin from '../../../components/header/super-admin/header';
import FormProvider from "../../../components/form/form-provider";
import CustomMapSuperAdmin from '../../../components/super-admin-maps';
import { geocode, RequestType, setKey } from "react-geocode";
import { deleteTrashcan, editTrashcan, getTrashcanDetail } from '../../../redux/actions/trashcan';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { getAllCompany } from '../../../redux/actions/company';
import { DEFAULT_LANGUAGE, DEFAULT_REGION, LAT_DEFAULT, LONG_DEFAULT, MEDIUM_WIDTH_SCREEN } from '../../../constants';
import debounce from 'lodash/debounce';
import { filterEmptyFields, getAddress, getClientWidth, getTrashCanStyleBySuperAdmin, truncateText } from '../../../helpers';
import paths from '../../../constants/paths';
import Custom404Page from '../../404';
import statusCode from '../../../constants/statusCode';
import DeleteTrashcanDialog from '../../../components/dialog/delete-trashcan-dialog';
import AdminMapSkeleton from '../../../components/skeleton/map/map-detail';
import BreadcrumbsComponent from "../../../components/breadcrumbs";
import { Helmet } from 'react-helmet';
import pageTitles from '../../../constants/pageTitles';

type Props = {
  openAddNewAdminDialog: any,
  defaultValues: any,
}

type CompanyOption = {
  id: string,
  name: string
}

export default function TrashDetail({ defaultValues }: Props) {
  setKey(`${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`);
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const defaultError = {
    trashcan_name: '',
    address: '',
    lat: '',
    long: '',
    group_id: '',
    company_id: '',
  }

  const trashcanDetail = useSelector((state: any) => state.trash?.dataTrashcanDetail?.data)
  const [dataUpdate, setDataUpdate] = useState(trashcanDetail);
  const [errors, setErrors] = useState(defaultError);
  const [markerLocation, setMarkerLocation] = useState({
    lat: parseFloat(trashcanDetail?.lat ?? LAT_DEFAULT),
    lng: parseFloat(trashcanDetail?.long ?? LONG_DEFAULT),
  });

  const { id } = useParams();
  const dataCompany = useSelector((state: any) => state.company.dataGetAllCompany?.data);
  const [userAddress, setUserAddress] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [isHasSkeleton, setIsHasSkeleton] = useState(false);

  const companyOptions = dataCompany?.map((company: CompanyOption) => ({
    value: company.id,
    label: company.name
  }));

  const loadingEditTrashcan = useSelector((state: any) => state.trash.loading);
  const isSuperAdmin404Err = useSelector((state: any) => state?.trash?.isSuperAdmin404Err);
  const generalError = useSelector((state: any) => state?.trash?.error?.response?.status);
  const loadingDeleteTrashcan = useSelector((state: any) => state?.trashcan?.loading);

  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "ゴミ箱の管理", href: paths.superAdmin.trashList },
  ];

  const chipStyle = getTrashCanStyleBySuperAdmin(trashcanDetail?.level);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const validationSchema = yup.object().shape({
    trashcan_name: yup
      .string()
      .required('ゴミ箱の名前は必ず入力してください'),
    lat: yup
      .string()
      .matches(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,16})?$/, '経度は半角数字を入力してください。')
      .required('緯度は必ず入力してください'),
    long: yup
      .string()
      .matches(/^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,16})?$/, '経度は半角数字を入力してください。')
      .required('経度は必ず入力してください'),
    company_id: yup
      .string()
      .required('会社名は必ず入力してください')
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      setErrors(defaultError);
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultError };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultError] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues,
  });

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate({
      ...dataUpdate,
      [key]: value
    });

    setErrors({
      ...errors,
      [key]: ''
    });
  };

  const handleMapClick = (location: { lat: number, lng: number }) => {
    setMarkerLocation(location);
    setDataUpdate((prevData: any) => ({
      ...prevData,
      lat: location?.lat,
      long: location?.lng,
      isDefaultLocation: true,
    }));
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleDeleteTrashcan = async () => {
    const result = await dispatch(deleteTrashcan(id) as any);
    if (result?.code === 200) {
      navigate(paths.superAdmin.trashList)
    }
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const { handleSubmit } = methods;
  const onSubmit = handleSubmit(async () => {
    const isValid = await validate();
    if (!isValid) return;

    const formData = {
      trashcan_name: dataUpdate?.trashcan_name,
      lat: dataUpdate?.lat,
      long: dataUpdate?.long,
      company_id: dataUpdate?.company_id,
    }
    const result = await dispatch(editTrashcan(filterEmptyFields(formData), id) as any);
    if (result) {
      setDataUpdate(defaultValues);
      setErrors(defaultValues);
      navigate(paths.superAdmin.trashList);
    }
  })

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);


  useEffect(() => {
    const fetchData = async () => {
      if (!id) return;
      setIsHasSkeleton(true);
      try {
        await dispatch(getTrashcanDetail(id) as any);
        await dispatch(getAllCompany() as any);
      } catch (error) {
        console.error(error);
      } finally {
        setIsHasSkeleton(false);
      }
    };
    fetchData();
  }, [id, dispatch]);

  useEffect(() => {
    if (trashcanDetail) {
      setDataUpdate(trashcanDetail);
      setMarkerLocation({
        lat: parseFloat(trashcanDetail?.lat ?? LAT_DEFAULT),
        lng: parseFloat(trashcanDetail?.long ?? LONG_DEFAULT),
      });
    }
  }, [trashcanDetail]);

  const debouncedUpdateLocation = useRef(
    debounce((lat: number, long: number) => {
      setMarkerLocation({
        lat: parseFloat(lat.toString()),
        lng: parseFloat(long.toString()),
      });

      if (lat && long) {
        geocode(RequestType.LATLNG, `${lat}, ${long}`)
          .then(({ results }) => {
            geocode(RequestType.ADDRESS, getAddress(results), {
              language: DEFAULT_LANGUAGE,
              region: DEFAULT_REGION
            } as any).then((res) => {
              if (res?.results.length > 0) {
                const japaneseAddress = getAddress(res?.results);
                setUserAddress(japaneseAddress);
              }
            }).catch(console.error);
          })
          .catch(console.error);
      }
    }, 2000)
  ).current;

  useEffect(() => {
    const fetchLocation = async () => {
      try {
        const permission = await navigator.permissions.query({ name: 'geolocation' });

        if (permission.state === 'granted' || permission.state === 'prompt') {
          navigator.geolocation.getCurrentPosition(
            (pos: any) => {
              const coords = pos.coords;
              setMarkerLocation({
                lat: coords.latitude,
                lng: coords.longitude
              });
              setDataUpdate((prevData: any) => ({
                ...prevData,
                lat: coords.latitude,
                long: coords.longitude,
              }));
            },
            (error) => {
              console.error(error);
              setMarkerLocation({
                lat: LAT_DEFAULT,
                lng: LONG_DEFAULT
              });
            }
          );
        } else {
          setMarkerLocation({
            lat: LAT_DEFAULT,
            lng: LONG_DEFAULT
          });
        }
      } catch (error) {
        console.error(error);
        setMarkerLocation({
          lat: LAT_DEFAULT,
          lng: LONG_DEFAULT
        });
      }
    };

    if (dataUpdate?.lat && dataUpdate?.long) {
      debouncedUpdateLocation(dataUpdate?.lat, dataUpdate?.long);
    } else {
      fetchLocation();
      if (trashcanDetail?.lat && trashcanDetail?.long) {
        geocode(RequestType.LATLNG, `${trashcanDetail?.lat}, ${trashcanDetail?.long}`)
          .then(({ results }) => {
            if (results.length > 0) {
              return geocode(RequestType.ADDRESS, getAddress(results), {
                language: DEFAULT_LANGUAGE,
                region: DEFAULT_REGION
              } as any);
            }
            return Promise.reject('No address results found');
          })
          .then(({ res }) => {
            if (res?.results.length > 0) {
              const japaneseAddress = getAddress(res?.results);
              setUserAddress(japaneseAddress);
            }
          })
          .catch(console.error);
      }
    }

    dispatch(getAllCompany() as any);
  }, [dataUpdate?.lat, dataUpdate?.long, trashcanDetail, dispatch]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.trashDetail}</title>
      </Helmet>
      {(generalError === statusCode.NOTFOUND || isSuperAdmin404Err) ? (
        <Custom404Page />
      ) : (
        <Box>
          <HeaderSuperAdmin />
          <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
            <Card sx={{ py: 1 }} className={styles.cardContainer}>
              <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
                {isHasSkeleton ? (
                  <Skeleton className={styles.labelSkeleton} variant="rectangular" />
                ) : (
                  <BreadcrumbsComponent items={breadcrumbItems} currentLabel={trashcanDetail?.trashcan_name} />
                )}
              </Stack>
              <FormProvider methods={methods} onSubmit={onSubmit}>
                {isHasSkeleton ? (
                  <Skeleton className={styles.labelSkeleton} variant="rectangular" />
                ) : (
                  <Stack direction="row" justifyContent="space-between" sx={{ mt: 2 }}>
                    <Stack direction="row" alignItems="center" columnGap={2}>
                      <Box className={styles.titlePage}>{truncateText(trashcanDetail?.trashcan_name, 30)}</Box>
                      <Chip size="small" label={chipStyle.label}
                        sx={{ background: chipStyle.background, color: "white", fontWeight: "bold", fontFamily: chipStyle.font }}
                      />
                    </Stack>
                    <Box>
                    <Stack direction="row" columnGap={2}>
                      <Button sx={{ px: 2 }} className={styles.btnBack} onClick={() => navigate(`${paths.superAdmin.trashList}`, { state: { backPage: backPage } })} >
                        キャンセル
                      </Button>
                      {loadingEditTrashcan ? (
                        <Stack flexDirection="row" alignItems="center" justifyContent="center" className={styles.btnAddSpa} sx={{ width: 100 }}>
                          <CircularProgress size={18} sx={{ color: "white" }} />
                        </Stack>
                      ) : (
                        <Button type="submit" sx={{ px: 2, width: 100 }} className={styles.btnAddSpa}>
                          <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                          <Box className={`${styles.textCommon} ${styles.btnText}`}>保存</Box>
                        </Button>
                      )}
                    </Stack>
                    </Box>
                  </Stack>
                )}
                <Grid
                  sx={{ mt: 2 }}
                  justifyContent="space-between"
                  container
                >
                  {id && isHasSkeleton ? (
                    <Grid item md={6}>
                      <AdminMapSkeleton />
                    </Grid>
                  ) : (
                    <Grid md={6}>
                      <Box className={styles.boxInput}>
                        <Box className={styles.titleInput}>ゴミ箱の名前</Box>
                        <InputBase
                          name="trashcan_name"
                          keyword="trashcan_name"
                          type="text"
                          className={styles.inputNameAdmin}
                          handleChange={handleInputChange}
                          placeholder="ゴミ箱の名前を付けてください"
                          size="small"
                          value={dataUpdate?.trashcan_name ?? ''}
                        />
                        {errors?.trashcan_name && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.trashcan_name}</Box>
                        )}
                      </Box>

                      <Box className={styles.boxInput} sx={{ mt: 2 }}>
                        <Box className={styles.titleInput}>住所</Box>
                        <InputBase
                          disabled
                          name="address"
                          keyword="address"
                          type="text"
                          className={styles.inputNameAdmin}
                          placeholder="住所を入力してください"
                          size="small"
                          value={userAddress}
                        />
                      </Box>

                      <Grid container justifyContent="space-between" sx={{ mt: 2 }}>
                        <Grid md={5.5}>
                          <Box className={styles.boxInput}>
                            <Box className={styles.titleInput}>経度</Box>
                            <InputBase
                              name="long"
                              keyword="long"
                              type="text"
                              className={styles.inputNameAdmin}
                              handleChange={handleInputChange}
                              placeholder="経度を入力してください"
                              size="small"
                              value={dataUpdate?.long ?? ''}
                            />
                            {errors?.long && (
                              <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.long}</Box>
                            )}
                          </Box>
                        </Grid>
                        <Grid md={5.5} justifyContent="space-between">
                          <Box className={styles.titleInput}>
                            <Box className={styles.titleInput}>緯度</Box>
                            <InputBase
                              name="lat"
                              keyword="lat"
                              type="text"
                              className={styles.inputNameAdmin}
                              handleChange={handleInputChange}
                              placeholder="緯度を入力してください"
                              size="small"
                              value={dataUpdate?.lat ?? ''}
                            />
                            {errors?.lat && (
                              <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.lat}</Box>
                            )}
                          </Box>
                        </Grid>
                      </Grid>

                      {/* <Box className={styles.boxInput} sx={{ mt: 2 }}>
                    <Box className={styles.titleInput}>グループ</Box>
                    <InputSelectBase
                      name="group_id"
                      keyword="group_id"
                      type="text"
                      className={styles.inputCompany}
                      size="small"
                      value="1"
                      placeholder="会社を選択してください。"
                    />
                  </Box> */}

                      <Box className={styles.companyBox} sx={{ mt: 2 }}>
                        <Box className={styles.titleInput}>会社名</Box>
                        <InputSelectBase
                          name="company_id"
                          keyword="company_id"
                          type="text"
                          isEdit={true}
                          className={styles.inputCompany}
                          handleChange={handleInputChange}
                          size="small"
                          value={dataUpdate?.company_id ?? trashcanDetail?.company_id}
                          sx={{ ml: 2 }}
                          options={companyOptions}
                        />
                        {errors?.company_id && (
                          <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors?.company_id}</Box>
                        )}
                      </Box>

                      <Box className={styles.boxInput} sx={{ mt: 4 }}>
                        <Button className={styles.btnDeleteTrashcan} onClick={handleOpenDialog}>
                          <Typography variant="subtitle1" className={styles.btnText}>ゴミ箱を削除</Typography>
                        </Button>
                      </Box>
                    </Grid>
                  )}

                  <Grid md={5}>
                    <CustomMapSuperAdmin
                      markerLocation={markerLocation}
                      setMarkerLocation={handleMapClick}
                      trashcanDetail={trashcanDetail}
                      isHasSkeleton={isHasSkeleton}
                      setIsHasSkeleton={setIsHasSkeleton}
                    />
                  </Grid>
                </Grid>
              </FormProvider>
              <DeleteTrashcanDialog
                open={openDialog}
                loading={loadingDeleteTrashcan}
                onClose={handleCloseDialog}
                onConfirm={handleDeleteTrashcan}
                trashcan={trashcanDetail}
              />
            </Card>
          </Container>
        </Box>
      )}
    </>
  );
}
