/* eslint-disable react-hooks/exhaustive-deps */
import { InputAdornment, Stack, Button, Typography, Box, Card, Container, CircularProgress } from "@mui/material";
import { useForm } from "react-hook-form";
import InputBase from "../../../components/input/inputBase";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import FormProvider from "../../../components/form/form-provider";
import { useDispatch, useSelector } from 'react-redux';
import { getByPostCode } from '../../../redux/actions/postal-code';
import { debounce } from 'lodash';
import { changeActiveAccount, editCollector, getCollectorDetail } from '../../../redux/actions/collector';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import HeaderAdmin from "../../../components/header/admin/header";
import DatePickerCustom from "../../../components/input/datePickerCustom";
import moment from "moment";
import avatarDefault from "../../../../src/assets/images/default.png"
import * as yup from "yup";
import { ACTIVE, DEACTIVE, DEFAULT_LENGTH_TEXT, MEDIUM_WIDTH_SCREEN } from "../../../constants";
import Loading from "../../../components/loading";
import { filterEmptyFields, getClientWidth, isValidDate, regexPostalCode, truncateText } from "../../../helpers";
import { errorSnackbar } from "../../../commons/snackbar";
import paths from "../../../constants/paths";
import { useBoolean } from "../../../hooks/use-boolean";
import Custom404Page from "../../404";
import statusCode from "../../../constants/statusCode";
import ConfirmDialog from "../../../components/dialog/confirm-dialog";
import BreadcrumbsComponent from "../../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

type DataFormType = {
  full_name: string,
  birthday: any,
  tel: string,
  postal_code: string,
  prefecture: string,
  city: string,
  address: string,
  avatar: any,
};

const INIT_DATA = {
  full_name: "",
  birthday: "",
  tel: "",
  postal_code: "",
  prefecture: "",
  city: "",
  address: "",
  avatar: "",
};

export default function AdminEditCollector() {
  const [defaultValues] = useState<DataFormType>(INIT_DATA);
  const { id } = useParams();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState(defaultValues);
  const [errorPostalCode, setErrorPostalCode] = useState('');
  const [loadingData, setLoadingData] = useState(true);
  const dataCollector = useSelector((state: any) => state.collector.dataCollectorDetail?.data);
  const [previewImage, setPreviewImage] = useState('');
  const [dataUpdate, setDataUpdate] = useState(dataCollector);
  const [newStatus, setNewStatus] = useState(Number(dataCollector?.is_active));
  const [isPostalCodeTyping, setIsPostalCodeTyping] = useState<boolean>(false);
  const [showErrorPostalCode, setShowErrorPostalCode] = useState<boolean>(false);

  const navigate = useNavigate();
  const location = useLocation();
  const { viewInfoFrom, backPage } = location.state || { state: { viewInfoFrom: true, backPage: 1 } }

  const loadingEditCollector = useSelector((state: any) => state.collector.loading);
  const generalError = useSelector((state: any) => state.collector.error?.response?.status);

  const openDialog = useBoolean(false);
  const handleCloseDialog = () => {
    openDialog.onFalse();
  };

  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "収集スタッフ一覧", href: paths.admin.collectorList },
  ];
  const getByPostCodeDebounced = useCallback(debounce((postalCode: string) => {
    dispatch(getByPostCode({ postal_code: postalCode }) as any)
      .then((response: any) => {
        if (response?.data) {
          setErrorPostalCode('');
          setDataUpdate((prevData: any) => ({
            ...prevData,
            prefecture: response.data.prefecture,
            city: response.data.area ? response.data.city + response.data.area : response.data.city,
          }));
        } else {
          setShowErrorPostalCode(true);
          setDataUpdate((prevData: any) => ({
            ...prevData,
            prefecture: "",
            city: "",
          }));
        }
      })
      .catch(() => {
        setErrorPostalCode('郵便番号の検索中にエラーが発生しました。');
      })
      .finally(() => {
        setIsPostalCodeTyping(false);
      });
  }, 1500), [dispatch]);

  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleInputChange = (key: any, value: any) => {
    setDataUpdate({
      ...dataUpdate,
      [key]: value
    });
    setErrors({
      ...errors,
      [key]: ''
    });
    if (key === 'postal_code') {
      setIsPostalCodeTyping(true);
      if (!value) {
        setDataUpdate((prevData: any) => ({
          ...prevData,
          prefecture: "",
          city: "",
        }));
      }
      if (value && !regexPostalCode.test(value)) {
        setErrorPostalCode('郵便番号の形式が正しくありません');
        setIsPostalCodeTyping(false);
      } else {
        setErrorPostalCode('');
        setShowErrorPostalCode(false);
        getByPostCodeDebounced(value);
      }
    }
  };

  useEffect(() => {
    if (id) {
      dispatch(getCollectorDetail(id as any) as any)
    }
  }, [id]);

  const handleFileInputChange = (event: any) => {
    const file = event.target.files[0];
    if (file?.type.includes('image')) {
      file.preview = URL.createObjectURL(file);
      const newFile = new File([file], getNameFile(file.name));
      setDataUpdate({ ...dataUpdate, avatar: newFile });
      setPreviewImage(file.preview);
    } else {
      errorSnackbar('写真をアップロードできませんでした。アップロードする写真は4 MB以下で、フォーマットはJPG、JPEG、PNG、SVG、HEIFのいずれかを使用してください。');
      event.target.value = null;
    }
  };

  function getNameFile(string: any) {
    const getName = string.split(".")[0];
    const type = string.split(".").pop();
    return changeToSlug(getName) + "." + type;
  }

  function changeToSlug(string: string) {
    string = string.toLowerCase();
    string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    string = string.replace(/[đĐ]/g, "d");
    string = string.replace(/([^0-9a-z-\s])/g, "");
    string = string.replace(/(\s+)/g, "-");
    string = string.replace(/-+/g, "-");
    return string;
  }

  const validationSchema = yup.object().shape({
    full_name: yup
      .string()
      .required('名前は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    tel: yup
      .string()
      .required('電話番号は必ず入力してください ')
      .matches(/^\+?[\d-]+$/, {
        message: '電話番号の形式が正しくありません',
        excludeEmptyString: true,
      }),
    address: yup
      .string()
      .notRequired()
      .max(255, '255文字以内で入力してください'),
    birthday: yup
      .date()
      .transform((curr, orig) => (orig === '' ? null : curr))
      .test('format', date => {
        if (!date) {
          return true;
        }

        return isValidDate(date);
      })
      .typeError('生年月日の形式が正しくありません。')
      .notRequired(),
    postal_code: yup
      .string()
      .matches(regexPostalCode, {
        message: '郵便番号の形式が正しくありません',
        excludeEmptyString: true,
      })
      .max(255, '255文字以内で入力してください')
      .notRequired(),
  });

  const validate = async () => {
    try {
      setErrors(defaultValues);
      await validationSchema.validate(dataUpdate, { abortEarly: false });
      return true;
    } catch (err: any) {
      const newErrors: any = { ...defaultValues };
      err?.inner.forEach((error: any) => {
        newErrors[error.path as keyof typeof defaultValues] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async () => {
    if (await validate()) {
      if (showErrorPostalCode && dataUpdate?.postal_code) {
        setErrorPostalCode('郵便番号が見つかりませんでした。');
        return;
      }
      const formData = {
        full_name: dataUpdate?.full_name ?? dataCollector?.full_name,
        tel: dataUpdate?.tel ?? dataCollector?.tel,
        postal_code: dataUpdate?.postal_code ?? dataCollector?.postal_code,
        prefecture: dataUpdate?.prefecture ?? dataCollector?.prefecture,
        city: dataUpdate?.city ?? dataCollector?.city,
        address: dataUpdate?.address,
        avatar: dataUpdate?.avatar ?? dataCollector?.avatar,
        birthday: dataUpdate?.birthday ? moment(dataUpdate?.birthday).format("YYYY-MM-DD") : dataCollector?.birthday,
      };

      if(errorPostalCode) {
        return ;
      }
      const result = await dispatch(editCollector(filterEmptyFields(formData), id) as any);
      if (result?.code === 200) {
        setErrors(defaultValues);
        navigate(`${paths.admin.collectorDetail}/${id}`);
      }
    }
  });


  const handleChangeActive = async () => {
    const result = await dispatch(changeActiveAccount(Number(id), { is_active: newStatus }) as any);
    if (result?.code === 200) {
      dispatch(getCollectorDetail(id as any) as any)
    }
    openDialog.onFalse();
  };

  const handleChangeStatusCollector = async (status: any) => {
    setNewStatus(status)
    openDialog.onTrue()
  };

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  useEffect(() => {
    if (dataCollector) {
      setErrors(defaultValues);
      setDataUpdate({
        full_name: dataCollector.full_name,
        tel: dataCollector.tel,
        postal_code: dataCollector.postal_code,
        prefecture: dataCollector.prefecture,
        city: dataCollector.city,
        address: dataCollector.address,
        avatar: dataCollector.avatar,
        birthday: dataCollector?.birthday
      });
    }
  }, [dataCollector]);

  useEffect(() => {
    dispatch(getCollectorDetail(id as any) as any).then(() => setLoadingData(false));;
    setDataUpdate(dataCollector);
  }, [id]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.admin.editCollector}</title>
      </Helmet>
      {generalError === statusCode.NOTFOUND ? (
        <Custom404Page />
      ) : (
        <Box>
          <HeaderAdmin />
          {dataCollector && !loadingData ? (
            <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
              <Card sx={{ py: 1 }} className={styles.cardContainer}>
                {/* title */}
                <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
                  <BreadcrumbsComponent items={breadcrumbItems} currentLabel={dataCollector?.full_name} />
                </Stack>
                <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} ref={ref}>
                  <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Stack className={styles.searchContainer}>
                      <Card className={`mt-4 ${styles.cardContainer}`}>
                        <Box className={`d-flex justify-content-start align-items-center`}>
                          {previewImage ?
                            <Box>
                              <img onClick={handleImageClick} className={styles.imageAvatar} src={previewImage} alt="avatar" />
                              <input style={{ display: 'none' }} ref={fileInputRef} accept="image/*" type="file" onChange={handleFileInputChange} />
                            </Box>
                            :
                            <Box>
                              <img onClick={handleImageClick} className={styles.imageAvatar} src={dataCollector?.avatar ? dataCollector?.avatar : avatarDefault} alt="avatar" />
                              <input style={{ display: 'none' }} ref={fileInputRef} accept="image/*" type="file" onChange={handleFileInputChange} />
                            </Box>}
                          <Box className={`ps-2 ${styles.firstInfoCollector}`}>{truncateText(dataCollector?.full_name, DEFAULT_LENGTH_TEXT)}</Box>
                          <Box className={`ps-2`}>
                            {dataCollector?.is_active === 1 ? (<span className={styles.btnActive}>
                              アクティブ
                            </span>) : (<span className={styles.btnInActive}>
                              無効
                            </span>)}
                          </Box>
                        </Box>
                        <Box className={`mt-4 ${styles.nameBox}`}>
                          <Stack flexDirection="row">
                            <Box className={styles.titleAdminDetail}>名前</Box>
                            <Box className={styles.iconRequired}>*</Box>
                          </Stack>
                          <InputBase
                            name="full_name"
                            keyword="full_name"
                            type="text"
                            errorText={errors?.full_name}
                            className={styles.inputNameAdmin}
                            handleChange={handleInputChange}
                            placeholder="収集スタッフの名前を入力してください。"
                            size="small"
                            value={dataUpdate?.full_name ?? dataCollector?.full_name}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className={styles.inputAdornment}>
                                  <Iconify icon="heroicons:user-circle-16-solid" width={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors?.full_name && (
                            <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.full_name}</Box>
                          )}
                        </Box>

                        <Box className={styles.birthDateBox} sx={{ mt: 2 }}>
                          <Box className={styles.titleAdminDetail}>生年月日</Box>
                          <DatePickerCustom
                            values={dataUpdate}
                            setValues={setDataUpdate}
                            name="birthday"
                            size="small"
                          />
                          {errors?.birthday && (
                            <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.birthday}</Box>
                          )}
                        </Box>

                        <Stack flexDirection="row" justifyContent="space-between">
                          <Box className={styles.postcalBox} sx={{ mt: 2 }}>
                            <Box className={styles.titleAdminDetail}>郵便番号</Box>
                            <InputBase
                              name="postal_code"
                              keyword="postal_code"
                              type="text"
                              size="small"
                              className={styles.inputNameAdmin}
                              placeholder="000-0000"
                              value={dataUpdate?.postal_code ?? dataCollector?.postal_code}
                              // handleChange={isEditDialog.value ? handleInputChange : undefined}
                              handleChange={handleInputChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" className={styles.inputAdornment}>
                                    <Iconify icon="mdi:location" width={20} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                            {(errors?.postal_code || errorPostalCode) && (
                              <Box sx={{ mt: 0.5 }} className={styles.errorText}>
                                {errors?.postal_code || errorPostalCode}
                              </Box>
                            )}
                          </Box>

                          <Box className={styles.prefectureBox} sx={{ mt: 2 }}>
                            <Box className={styles.titleAdminDetail}>都道府県</Box>
                            <InputBase
                              name="prefecture"
                              keyword="prefecture"
                              disabled={true}
                              type="text"
                              size="small"
                              className={styles.inputNameAdmin}
                              placeholder="都道府県"
                              value={errorPostalCode ? '' : dataUpdate ? dataUpdate?.prefecture : dataCollector?.prefecture}
                              handleChange={handleInputChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" className={styles.inputAdornment}>
                                    <Iconify icon="mdi:location" width={20} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>

                          <Box className={styles.cityBox} sx={{ mt: 2 }}>
                            <Box className={styles.titleAdminDetail}>市区町村名</Box>
                            <InputBase
                              sx={{ width: "250px" }}
                              disabled={true}
                              name="city"
                              keyword="city"
                              type="text"
                              className={styles.inputNameAdmin}
                              placeholder="市区町村名"
                              size="small"
                              value={errorPostalCode ? '' : (dataUpdate ? dataUpdate?.city : dataCollector?.city)}
                              handleChange={handleInputChange}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start" className={styles.inputAdornment}>
                                    <Iconify icon="mdi:location" width={20} />
                                  </InputAdornment>
                                ),
                              }}
                            />
                          </Box>
                        </Stack>

                        <Box className={styles.emailBox} sx={{ mt: 2 }}>
                          <Box className={styles.titleAdminDetail}>番地・建物名・号室</Box>
                          <InputBase
                            name="address"
                            keyword="address"
                            type="text"
                            className={styles.inputNameAdmin}
                            placeholder="番地・建物名・号室"
                            size="small"
                            value={dataUpdate ? dataUpdate?.address : dataCollector?.address}
                            handleChange={handleInputChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className={styles.inputAdornment}>
                                  <Iconify icon="mdi:location" width={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors?.address && (
                            <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.address}</Box>
                          )}
                        </Box>

                        <Box className={styles.emailBox} sx={{ mt: 2 }}>
                          <Stack flexDirection="row">
                            <Box className={styles.titleAdminDetail}>電話番号</Box>
                            <Box className={styles.iconRequired}>*</Box>
                          </Stack>
                          <InputBase
                            name="tel"
                            keyword="tel"
                            type="text"
                            errorText={errors?.tel}
                            className={styles.inputNameAdmin}
                            placeholder="(000)-000-0000"
                            size="small"
                            value={dataUpdate?.tel ?? dataCollector?.tel}
                            handleChange={handleInputChange}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start" className={styles.inputAdornment}>
                                  <Iconify icon="gridicons:phone" width={20} />
                                </InputAdornment>
                              ),
                            }}
                          />
                          {errors?.tel && (
                            <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.tel}</Box>
                          )}
                        </Box>

                        <Stack direction="row" columnGap={2} sx={{ mt: 4 }} justifyContent="space-between">
                          {dataCollector?.is_active === ACTIVE ? (
                            <Button sx={{ fontSize: "14px", fontWeight: "700", border: "1px solid #F09898", color: "#C41C1C", backgroundColor: "#FFFFFF", fontFamily: "Noto Sans JP", px: 2 }} onClick={() => handleChangeStatusCollector(DEACTIVE)}>
                              無効にする
                            </Button>
                          ) : (
                            <Button sx={{ fontSize: "14px", fontWeight: "700", border: "1px solid #97C3F0", color: "#0B6BCB", backgroundColor: "#FFFFFF", fontFamily: "Noto Sans JP", px: 2 }} onClick={() => handleChangeStatusCollector(ACTIVE)}>
                              アクティブにする
                            </Button>
                          )}

                          <Stack direction="row" columnGap={2}>
                            {
                              viewInfoFrom ? (
                                <Button sx={{ fontSize: "14px", fontWeight: "700", border: "1px solid #CDD7E1", color: "#32383E", fontFamily: "Noto Sans JP", px: 2 }} onClick={() => navigate(`${paths.admin.collectorDetail}/${id}`)}>
                                  キャンセル
                                </Button>
                              ) : (
                                <Button sx={{ fontSize: "14px", fontWeight: "700", border: "1px solid #CDD7E1", color: "#32383E", fontFamily: "Noto Sans JP", px: 2 }} onClick={() => navigate(`${paths.admin.collectorList}`, { state: { backPage: backPage } })}>
                                  キャンセル
                                </Button>
                              )
                            }
                            {loadingEditCollector ? (
                              <Stack flexDirection="row" className={styles.btnLoading}>
                                <CircularProgress size={25} />
                              </Stack>
                            ) : (
                              <Button type="submit" disabled={isPostalCodeTyping} className={styles.btnAdd} sx={{ px: 2 }}>
                                <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                                <Typography variant="subtitle1" className={`${styles.btnText} ${styles.textCommon}`}>保存</Typography>
                              </Button>
                            )}
                          </Stack>
                        </Stack>
                      </Card>
                    </Stack>
                  </FormProvider>
                  <ConfirmDialog
                    title="変更の確認"
                    content={newStatus === 1 ? "この収集スタッフを有効にしますか?" : "この収集スタッフを無効にしますか?"}
                    open={openDialog?.value}
                    loading={loadingEditCollector}
                    onClose={handleCloseDialog}
                    onConfirm={handleChangeActive}
                  />
                </Container>
              </Card>
            </Container>
          ) : (
            <Loading />
          )}
        </Box>
      )}
    </>
  );
}
