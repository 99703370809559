export const CALL_API_SUCCESS = "CALL_API_SUCCESS";
export const LOADING = "LOADING";
export const ERROR = "ERROR";

export const DATA_USER_LOGIN = "DATA_USER_LOGIN";
export const CLEAR_DATA = "CLEAR_DATA";
export const DATA_POSTAL_CODE = "DATA_POSTAL_CODE";

//company
export const DATA_COMPANY_LIST = "DATA_COMPANY_LIST";
export const DATA_GET_ALL_COMPANY = "DATA_GET_ALL_COMPANY";
export const DATA_COMPANY_DETAIL = "DATA_COMPANY_DETAIL";


// collector
export const GET_LIST_COLLECTORS = "GET_LIST_COLLECTORS";
export const DATA_COLLECTOR_DETAIL = "DATA_COLLECTOR_DETAIL";
export const DATA_GET_ALL_COLLECTORS = "DATA_GET_ALL_COLLECTORS";
export const DATA_GET_ALL_COLLECTORS_BY_TRASHCAN_ID = "DATA_GET_ALL_COLLECTORS_BY_TRASHCAN_ID";
export const DATA_TRASH_CAN_LIST_FOR_COLLECTOR = "DATA_TRASH_CAN_LIST_FOR_COLLECTOR";
export const DATA_LIST_COLLECTOR_OF_ADMIN = "DATA_LIST_COLLECTOR_OF_ADMIN";

// list admin
export const DATA_ADMIN_LIST = "DATA_ADMIN_LIST";
export const DATA_ADMIN_DETAIL = "DATA_ADMIN_DETAIL";
export const DATA_ADMIN_PROFILE = "DATA_ADMIN_PROFILE";
export const DATA_LIST_ADMIN_BY_COMPANY = "DATA_LIST_ADMIN_BY_COMPANY";
export const DATA_LIST_TRASH_BY_COMPANY = "DATA_LIST_TRASH_BY_COMPANY";

// notification
export const GET_LIST_NOTIFICATION = "GET_LIST_NOTIFICATION";

// trashcan
export const GET_LIST_TRASH_BY_SUPER_ADMIN = "GET_LIST_TRASH_BY_SUPER_ADMIN";
export const GET_LIST_TRASH_BY_ADMIN = "GET_LIST_TRASH_BY_ADMIN";
export const DATA_TRASHCAN_DETAIL = "DATA_TRASHCAN_DETAIL";
export const DATA_TRASHCAN_DETAIL_BY_ADMIN = "DATA_TRASHCAN_DETAIL_BY_ADMIN";
export const DATA_LIST_TRASHCAN_OF_ADMIN = "DATA_LIST_TRASHCAN_OF_ADMIN";
export const UPDATE_TRASHCAN_SUPER_ADMIN_SUCCESS = "UPDATE_TRASHCAN_SUPER_ADMIN_SUCCESS";
export const UPDATE_TRASHCAN_ADMIN_SUCCESS = "UPDATE_TRASHCAN_ADMIN_SUCCESS";

export const ADMIN_CREATE_COLLECTOR = "ADMIN_CREATE_COLLECTOR";
export const ADMIN_EDIT_COLLECTOR = "ADMIN_EDIT_COLLECTOR";
export const ADMIN_CHANGE_ACTIVE_COLLECTOR = "ADMIN_CHANGE_ACTIVE_COLLECTOR";
export const ADMIN_UPDATE_PROFILE = "ADMIN_UPDATE_PROFILE";

export const ADD_NEW_ADMIN = "ADD_NEW_ADMIN";
export const SUPER_ADMIN_EDIT_ADMIN = "SUPER_ADMIN_EDIT_ADMIN";
export const SUPER_ADMIN_ADD_NEW_COMPANY = "SUPER_ADMIN_ADD_NEW_COMPANY";
export const SUPER_ADMIN_EDIT_COMPANY = "SUPER_ADMIN_EDIT_COMPANY";
export const SUPER_ADMIN_ADD_NEW_TRASHCAN = "SUPER_ADMIN_ADD_NEW_TRASHCAN";

export const ADMIN_LOGIN = "ADMIN_LOGIN";
export const LOGOUT = "LOGOUT";

export const ASSIGN_TRASH_TO_COLLECTOR = "ASSIGN_TRASH_TO_COLLECTOR";
