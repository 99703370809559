import {
  Box,
  Button,
  CircularProgress,
  Dialog, DialogActions,
  DialogContent, DialogTitle, InputAdornment, Stack,
} from "@mui/material";
import styles from "./styles.module.scss";
import FormProvider from "../../form/form-provider";
import InputBase from "../../input/inputBase";
import Iconify from "../../iconify";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { adminCreateAccountCollector } from "../../../redux/actions/auth";
import { filterEmptyFields } from "../../../helpers";
import { isArray } from "lodash";

const ModalCreateAccountCollector = (props: any) => {
  const {
    openModalCreateAccountCollector,
    setOpenModalCreateAccountCollector,
    actionNext,
    setIsBackToPage1,
    isHasGeneralError,
    setIsHasGeneralError
  } = props;
  const defaultValues = {
    full_name: '',
    email: '',
  }

  const [dataAdd, setDataAdd] = useState(defaultValues);
  const [errors, setErrors] = useState(defaultValues);
  const [errorServer, setErrorsServer] = useState(defaultValues);
  const dispatch = useDispatch();

  const errorMsgs = useSelector((state: any) => state.collector?.error?.response?.data?.error)
  const loadingAddCollector = useSelector((state: any) => state?.collector?.loading);

  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .email('正しいメールアドレスを入力してください')
      .matches(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+/, '正しいメールアドレスを入力してください')
      .required('メールアドレスは必ず入力してください')
      .max(255, '255文字以内で入力してください'),
    full_name: yup
      .string()
      .required('名前は必ず入力してください')
      .max(255, '255文字以内で入力してください'),
  });

  const validate = async () => {
    try {
      await validationSchema.validate(dataAdd, { abortEarly: false });
      setErrors(defaultValues)
      return true;
    } catch (err: any) {
      const newErrors: any = defaultValues;
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof any] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const methods = useForm({
    defaultValues,
  });

  const { handleSubmit } = methods;

  const onSubmit = handleSubmit(async () => {
    if (await validate()) {
      setIsHasGeneralError(true)
      const result = await dispatch(adminCreateAccountCollector(filterEmptyFields(dataAdd)) as any);
      if (result?.code === 201) {
        setIsBackToPage1(true)
        actionNext();
        handleCloseModal();
      }
    }
  });

  const handleInputChange = (key: any, value: any) => {
    setDataAdd({
      ...dataAdd,
      [key]: value
    });
    setErrors({ ...errors, [key]: "" });
  };

  const handleCloseModal = () => {
    setDataAdd(defaultValues);
    setErrors(defaultValues);
    setOpenModalCreateAccountCollector(false);
    setIsHasGeneralError(false)
  };

  useEffect(() => {
    const updatedErrors = isArray(errorMsgs) && errorMsgs?.reduce((acc: any, error: any) => {
      if (acc.hasOwnProperty(error.field)) {
        acc[error.field] = error.message;
      }
      return acc;
    }, { ...defaultValues });

    setErrorsServer(updatedErrors);
  }, [errorMsgs]);

  return (
    <Dialog open={openModalCreateAccountCollector} fullWidth maxWidth="sm">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box sx={{ py: 2, px: 3 }}>
          <DialogTitle className={styles.dialogCollectorTitle}>
            <Box className={`${styles.titleDialog} ${styles.textCommon}`}>収集スタッフ追加</Box>
            <Box sx={{ cursor: 'pointer' }}>
              <Iconify icon="material-symbols:close" width={20} onClick={handleCloseModal} />
            </Box>
          </DialogTitle>
          <DialogContent>
            <Box className={styles.nameBox}>
              <Box className={styles.titleName}>名前</Box>
              <InputBase
                name="full_name"
                keyword="full_name"
                errorText={errors?.full_name}
                type="text"
                className={styles.inputName}
                handleChange={handleInputChange}
                placeholder="収集スタッフの名前を入力してください。"
                size="small"
                value={dataAdd?.full_name}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={styles.inputAdornment}>
                      <Iconify icon="heroicons:user-circle-16-solid" width={20} />
                    </InputAdornment>
                  ),
                }}
              />
              {errors?.full_name && (
                <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.full_name}</Box>
              )}
            </Box>

            <Box className={styles.emailBox} sx={{ mt: 2 }}>
              <Box className={styles.titleEmail}>メールアドレス</Box>
              <InputBase
                name="email"
                keyword="email"
                errorText={errors?.email}
                type="text"
                className={styles.inputEmail}
                handleChange={handleInputChange}
                placeholder="example@example.com"
                size="small"
                value={dataAdd?.email}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start" className={styles.inputAdornment}>
                      <Iconify icon="ic:baseline-email" width={20} />
                    </InputAdornment>
                  ),
                }}
              />
              {errors?.email && (
                <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errors.email}</Box>
              )}
              {errorServer?.email && !errors?.email && isHasGeneralError && (
                <Box sx={{ mt: 0.5 }} className={styles.errorText}>{errorServer.email}</Box>
              )}
            </Box>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" columnGap={2} sx={{ px: 2 }}>
              <Button sx={{ px: 2 }}  className={styles.btnBack} onClick={handleCloseModal}>
                キャンセル
              </Button>
              {loadingAddCollector ? (
                <Stack flexDirection="row" className={styles.btnLoading}>
                  <CircularProgress size={25} />
                </Stack>
              ) : (
                <Button type="submit" sx={{ px: 2 }} className={styles.btnAdd}>
                  <Iconify icon="material-symbols:check" width={24} sx={{ mr: 1 }} />
                  <Box className={`${styles.textCommon} ${styles.btnText}`}>追加</Box>
                </Button>
              )}
            </Stack>
          </DialogActions>
        </Box>
      </FormProvider>
    </Dialog>
  )
};

export default ModalCreateAccountCollector;
