/* eslint-disable react-hooks/exhaustive-deps */
import HeaderSuperAdmin from "../../../../components/header/super-admin/header";
import styles from "../styles.module.scss";
import {
  Box,
  Button,
  Card,
  Container,
  Divider,
  Stack,
  Tab, Table, TableBody,
  TableContainer,
  Tabs,
} from "@mui/material";
import ApartmentIcon from '@mui/icons-material/Apartment';
import paths from "../../../../constants/paths";
import { useDispatch, useSelector } from "react-redux";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { getCompanyDetail } from "../../../../redux/actions/company";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Iconify from "../../../../components/iconify";
import { primaryFont } from "../../../../constants/colors";
import {
  emptyRows,
  TableEmptyRows,
  TableHeadCustom,
  TableNoData,
  useTable
} from "../../../../components/table";
import CustomPagination from "../../../../components/pagination";
import {
  getListAdminByCompany,
  getListTrashCanByCompany,
} from "../../../../redux/actions/admin";
import { isEmpty } from "lodash";
import AdminOfCompany from "./admin-table";
import TrashcansOfCompany from "./trashcan-table";
import { ITEMS_PER_PAGE, MEDIUM_WIDTH_SCREEN } from "../../../../constants";
import { TableRowSkeleton } from "../../../../components/skeleton/table-row";
import { spaTabsBarColor } from "../../../../constants/colors";
import { getClientWidth, renderAddress } from "../../../../helpers";
import Custom404Page from "../../../404";
import statusCode from "../../../../constants/statusCode";
import BreadcrumbsComponent from "../../../../components/breadcrumbs";
import { Helmet } from "react-helmet";
import pageTitles from "../../../../constants/pageTitles";

export default function CompanyDetailView() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const [tabIndex, setTabIndex] = useState(0);
  const [currentPageAdmins, setCurrentPageAdmins] = useState(1);
  const [currentPageTrash, setCurrentPageTrash] = useState(1);
  const [filtersAdmin, setFiltersAdmin] = useState({ orderBy: '', order: 'asc' });
  const [filtersTrashCanOfCompany, setFiltersTrashCanOfCompany] = useState({ orderBy: '', order: 'asc' });
  const [loading, setLoading] = useState(true);

  const dataCompanyDetail = useSelector((state: any) => state.company.dataCompanyDetail?.data);
  const dataListAdminByCompany = useSelector((state: any) => state.admin.dataListAdminByCompany?.data);
  const paginateListAdminByCompany = useSelector((state: any) => state.admin.dataListAdminByCompany?.paginate);
  const dataListTrashByCompany = useSelector((state: any) => state.admin.dataListTrashByCompany?.data);
  const paginateListTrashByCompany = useSelector((state: any) => state.admin.dataListTrashByCompany?.paginate);
  const generalError = useSelector((state: any) => state?.admin?.error?.response?.status);

  const navigate = useNavigate();
  const breadcrumbItems = [
    { label: "ホーム", href: "/" },
    { label: "会社一覧", href: paths.superAdmin.companyList },
  ];
  const handleSortTrashCanOfCompany = async (field: string) => {
    setFiltersTrashCanOfCompany((prevState) => ({
      ...prevState,
      orderBy: field,
      order: prevState.orderBy === field && prevState.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const handleSortAdmin = async (field: string) => {
    setFiltersAdmin((prevState) => ({
      ...prevState,
      orderBy: field,
      order: prevState.orderBy === field && prevState.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const TABLE_HEAD_TRASHCAN_OF_COMPANY = [
    { id: "id", label: "No.", width: 80 },
    { id: "trashcan_name", label: "ゴミ箱", width: 220 },
    { id: "group", label: "グループ", width: 220 },
    {
      id: "level",
      label: (
        <Stack
          className={styles.pointer}
          direction="row"
          alignItems="center"
          onClick={() => handleSortTrashCanOfCompany('level')}>
          <Box className={styles.titleStatus}>ステータス</Box>
          {filtersTrashCanOfCompany.orderBy === 'level' ? (
            filtersTrashCanOfCompany.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 140
    },
  ];

  const TABLE_HEAD_ADMIN = [
    { id: "id", label: "No.", width: 80 },
    { id: "admin_name", label: "会社名", width: 220 },
    { id: "email", label: "メールアドレス", width: 220 },
    { id: "tel", label: "電話番号", width: 220 },
    {
      id: "is_active",
      label: (
        <Stack
          className={styles.pointer}
          direction="row"
          alignItems="center"
          onClick={() => handleSortAdmin('is_active')}
        >
          <Box className={styles.titleStatus}>ステータス</Box>
          {filtersAdmin.orderBy === 'is_active' ? (
            filtersAdmin.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 140
    },
  ];

  const tabs = {
    admin: 0,
    trashcan: 1
  };

  const table = useTable({
    defaultRowsPerPage: 10,
    defaultCurrentPage: 1,
  });

  const notFoundAdmin = !dataListAdminByCompany?.length;
  const notFoundTrashcan = !dataListTrashByCompany?.length;

  const buildQueryOfAdmin = useCallback(() => {
    const query: any = {
      pages: currentPageAdmins,
    };

    if (!isEmpty(filtersAdmin.orderBy)) {
      query.orderBy = filtersAdmin.orderBy;
      query.order = filtersAdmin.order;
    }

    return query;
  }, [currentPageAdmins, filtersAdmin]);

  const buildQueryOfTrashcan = useCallback(() => {
    const query: any = {
      pages: currentPageTrash,
    };

    if (!isEmpty(filtersTrashCanOfCompany.orderBy)) {
      query.orderBy = filtersTrashCanOfCompany.orderBy;
      query.order = filtersTrashCanOfCompany.order;
    }

    return query;
  }, [currentPageTrash, filtersTrashCanOfCompany]);

  useEffect(() => {
    if (id) {
      dispatch(getCompanyDetail(id as any) as any)
    }
  }, [id]);

  useEffect(() => {
    if (id && tabIndex === tabs.admin) {
      setLoading(true);
      dispatch(getListAdminByCompany(buildQueryOfAdmin(), id) as any).then((result: any) => {
        setLoading(false);
      });
    }
  }, [currentPageAdmins, filtersAdmin, tabIndex]);


  useEffect(() => {
    if (id && tabIndex === tabs.trashcan) {
      setLoading(true);
      dispatch(getListTrashCanByCompany(buildQueryOfTrashcan(), id) as any).then((result: any) => {
        setLoading(false);
      });
    }
  }, [currentPageTrash, filtersTrashCanOfCompany, tabIndex]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIndex(newValue);
  };

  const handleEditCompany = () => {
    navigate(`${paths.superAdmin.companyDetail}/${id}`, { state: { viewInfoFrom: true } });
  };

  const renderAdminSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD_ADMIN.length} />
      ))}
    </>
  );

  const renderTrashcanSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD_TRASHCAN_OF_COMPANY.length} />
      ))}
    </>
  );

  const renderAdminList = (
    <>
      <TableContainer
        sx={{
          position: "relative",
          overflow: "unset",
          overflowX: "auto",
        }}>
        <Table size="small" sx={{ minWidth: 960 }}>
          <TableHeadCustom
            className={styles.borderTableHeader}
            headLabel={TABLE_HEAD_ADMIN}
            rowCount={dataListAdminByCompany?.length}
            numSelected={table.selected?.length} />
          <TableBody className={styles.borderTable}>
            {loading ? renderAdminSkeleton : dataListAdminByCompany?.map((row: any, index: any) => (
              <AdminOfCompany
                key={row.trashcan_id}
                row={row}
                index={(currentPageAdmins - 1) * 10 + index + 1}
              />
            ))}
            {dataListAdminByCompany && !loading && (<>
              <TableEmptyRows
                height={56}
                emptyRows={emptyRows(
                  table.page - 1,
                  table.rowsPerPage,
                  dataListAdminByCompany.length
                )} />
              <TableNoData notFound={notFoundAdmin} />
            </>)}
          </TableBody>
        </Table>
      </TableContainer>
      {dataListAdminByCompany && paginateListAdminByCompany?.total_page > 1 && (
        <CustomPagination
          paginate={paginateListAdminByCompany}
          page={currentPageAdmins}
          setPage={setCurrentPageAdmins}
          disabled={paginateListAdminByCompany?.total_page === 1}
        />
      )}
    </>
  );

  const renderTrashcanListOfCompany = (
    <>
      <TableContainer
        sx={{
          position: "relative",
          overflow: "unset",
          overflowX: "auto",
        }}>
        <Table size="small" sx={{ minWidth: 960 }}>
          <TableHeadCustom
            className={styles.borderTableHeader}
            headLabel={TABLE_HEAD_TRASHCAN_OF_COMPANY}
            rowCount={dataListTrashByCompany?.length}
            numSelected={table.selected?.length}
          />
          <TableBody className={styles.borderTable}>
            {loading ? renderTrashcanSkeleton : dataListTrashByCompany?.map((row: any, index: any) => (
              <TrashcansOfCompany
                key={row.trashcan_id}
                row={row}
                index={(currentPageTrash - 1) * 10 + index + 1}
              />
            ))}
            {dataListTrashByCompany && !loading && (<>
              <TableEmptyRows
                height={56}
                emptyRows={emptyRows(
                  table.page - 1,
                  table.rowsPerPage,
                  dataListTrashByCompany.length
                )} />
              <TableNoData notFound={notFoundTrashcan} />
            </>)}
          </TableBody>
        </Table>
      </TableContainer>
      {dataListTrashByCompany && paginateListTrashByCompany?.total_page > 1 && (
        <CustomPagination
          paginate={paginateListTrashByCompany}
          page={currentPageTrash}
          setPage={setCurrentPageTrash}
          disabled={paginateListTrashByCompany?.total_page === 1} />
      )}
    </>
  );

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth)
  }, []);

  return (
    <>
      <Helmet>
        <title>{pageTitles.superAdmin.companyInfo}</title>
      </Helmet>
      {generalError === statusCode.NOTFOUND ? (
        <Custom404Page />
      ) : (
        <>
          <HeaderSuperAdmin />
          <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
            <Card
              sx={{ py: 1 }}
              className={styles.cardContainer}>
              <BreadcrumbsComponent items={breadcrumbItems} currentLabel={dataCompanyDetail?.name} />
              <Stack flexDirection="row" sx={{ paddingTop: '8px' }}>
                <Box>
                  <ApartmentIcon
                    className={styles.iconCompany}
                  />
                </Box>
                <Box className={styles.boxContainerCompany}>
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    className="pb-2">
                    <Box className={styles.textTitle}>
                      {dataCompanyDetail?.name}
                    </Box>
                    <Stack className={styles.linkDiv} sx={{ paddingLeft: '20px' }} flexDirection="row">
                      <Button className={styles.btnBack} sx={{ fontFamily: primaryFont, px: 1, mr: 2 }} onClick={() => navigate(`${paths.superAdmin.companyList}`, { state: { backPage: backPage } })}>
                        戻る
                      </Button>
                      <Button className={styles.btnEditSpa} onClick={handleEditCompany}>
                        <Iconify icon="ic:baseline-edit" width={20} className={styles.iconEdit} />
                        <Box className={`${styles.btnText} ${styles.textCommon}`}>
                          編集
                        </Box>
                      </Button>
                    </Stack>
                  </Stack>
                  <Divider className="my-2" />
                  {renderAddress(dataCompanyDetail) && (
                    <Box className={styles.infoUser}>
                      <Iconify className={styles.iconWithSubColor} icon="zondicons:location" width={24} />
                      <Box className={styles.textSubtitle}>
                        {renderAddress(dataCompanyDetail)}
                      </Box>
                    </Box>
                  )}
                  <Box className={styles.infoUser}>
                    <Iconify className={styles.iconWithSubColor} icon="ic:baseline-email" width={24} />
                    <Box className={styles.textSubtitle}>
                      {dataCompanyDetail?.email}
                    </Box>
                  </Box>
                  <Box className={styles.infoUser}>
                    <Iconify className={styles.iconWithSubColor} icon="gridicons:phone" width={24} />
                    <Box className={styles.textSubtitle}>
                      {dataCompanyDetail?.tel}
                    </Box>
                  </Box>
                </Box>
              </Stack>
              <Tabs
                value={tabIndex}
                onChange={handleTabChange}
                aria-label="admin tabs"
                sx={{ mt: 3 }}
                TabIndicatorProps={{
                  sx: {
                    display: 'none',
                  },
                }}>
                <Tab
                  label="管理者一覧"
                  sx={{
                    fontFamily: primaryFont,
                    color: `${spaTabsBarColor.inactiveContentColor}`,
                    fontSize: "16px",
                    borderBottom: `2px solid ${spaTabsBarColor.inactiveBorderColor}`,
                    backgroundColor: `${spaTabsBarColor.bgColor}`,
                    '&.Mui-selected': {
                      backgroundColor: `${spaTabsBarColor.activeBgColor}`,
                      color: `${spaTabsBarColor.activeContentColor}`,
                      borderBottom: `2px solid ${spaTabsBarColor.activeBorderColor}`,
                      fontSize: "16px",
                    },
                  }}
                />
                <Tab
                  label="管理しているゴミ箱一覧"
                  sx={{
                    fontFamily: primaryFont,
                    color: `${spaTabsBarColor.inactiveContentColor}`,
                    borderBottom: `2px solid ${spaTabsBarColor.inactiveBorderColor}`,
                    '&.Mui-selected': {
                      backgroundColor: `${spaTabsBarColor.activeBgColor}`,
                      color: `${spaTabsBarColor.activeContentColor}`,
                      borderBottom: `2px solid ${spaTabsBarColor.activeBorderColor}`,
                    },
                  }}
                />
              </Tabs>

              <TabPanel value={tabIndex} index={tabs.admin} className={styles.collectorTab}>
                {renderAdminList}
              </TabPanel>

              <TabPanel value={tabIndex} index={tabs.trashcan}>
                {renderTrashcanListOfCompany}
              </TabPanel>
            </Card>
          </Container>
        </>
      )}
    </>
  );
}

const TabPanel = (props: any) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          {children}
        </Box>
      )}
    </div>
  );
}
