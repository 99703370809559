import { doAction } from ".";
import { baseAxios } from "../../commons/axiosBase";
import { REQUEST_METHOD } from "../../constants";
import * as t from "../constants/index";

interface CompanyField {
  name: string;
  email: string;
  tel: string;
  postal_code: string;
  prefecture: string;
  address: string;
  city: string;
}

export const getListCompany = (params: { pages: number, name?: string, orderBy?: string, order?: string }) => async (dispatch: any) => {
  const { pages, name, order, orderBy } = params;

  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/list`,
    method: REQUEST_METHOD.GET,
    params: {
      pages,
      name,
      orderBy,
      order
    },
    showNotificationSuccess: false,
  });


  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_COMPANY_LIST,
      payload: apiResponse,
    });

  }

  return apiResponse;
};

export const getAllCompany = () => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/get-all`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_GET_ALL_COMPANY,
      payload: apiResponse,
    });

  }

  return apiResponse;
};

export const addNewCompany = (body: CompanyField) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/create`,
    data: body,
    method: REQUEST_METHOD.POST,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.SUPER_ADMIN_ADD_NEW_COMPANY,
      payload: apiResponse,
    })
  }

  return apiResponse;
}

export const getCompanyDetail = (id: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/${id}`,
    method: REQUEST_METHOD.GET,
    showNotificationSuccess: false,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.DATA_COMPANY_DETAIL,
      payload: apiResponse,
    })
  }
  return apiResponse;
}

export const editCompany = (body: CompanyField, id: any) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/${id}`,
    data: body,
    method: REQUEST_METHOD.PUT,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  if (apiResponse?.data) {
    dispatch({
      type: t.SUPER_ADMIN_EDIT_COMPANY,
      payload: apiResponse,
    })
  }

  return apiResponse;
}

export const deleteCompany = (id: string) => async (dispatch: any) => {
  const callApi = baseAxios.publicRequest({
    url: process.env.REACT_APP_API_ADDRESS + `/api/super-admin/company/${id}`,
    method: REQUEST_METHOD.DELETE,
    showNotificationSuccess: true,
  });

  const apiResponse = await dispatch(doAction({
    action: callApi
  }));

  return apiResponse;
}

