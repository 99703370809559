/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, CircularProgress, Container, Grid, IconButton, InputAdornment, Link, Stack } from "@mui/material";
import InputBase from "../../../components/input/inputBase";
import PasswordDefault from "../../../components/input/inputPassword";
import { adminLogin } from "../../../redux/actions/auth";
import styles from './styles.module.scss';
import Iconify from '../../../components/iconify';
import LOGO from '../../../../src/assets/logo/LOGO.svg';
import { useLocation, useNavigate } from 'react-router-dom';
import { ADMIN_REDIRECT_AFTER_LOGIN, ROLE_ADMIN, SUPER_ADMIN_REDIRECT_AFTER_LOGIN } from '../../../constants';
import statusCode from '../../../constants/statusCode';
import paths from '../../../constants/paths';
import { Helmet } from 'react-helmet';
import pageTitles from '../../../constants/pageTitles';

type DataFormType = {
  email: string;
  password: string;
};

const INIT_STATE = {
  email: '',
  password: ''
};

// Define validation schema using yup
const validationSchema = yup.object().shape({
  email: yup.string().email('正しいメールアドレスを入力してください').matches(/[a-zA-Z0-9]+@[a-zA-Z]+\.[a-zA-Z]+/, '正しいメールアドレスを入力してください').required('メールアドレスは必ず入力してください'),
  password: yup.string()
    .matches(/[A-Z]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
    .matches(/[a-z]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
    .matches(/[0-9]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
    .matches(/[\W_]/, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
    .min(8, '8文字以上で、大文字、特殊文字、半角英字、半角数字を含めてください。')
    .required('パスワードは必ず入力してください'),
});

const AdminLogin = () => {
  const dispatch = useDispatch();
  const dataLogin = useSelector((state: any) => state.auth);
  const errorReducer = dataLogin?.error?.response?.data?.errors;

  const generalErrMsg = useSelector((state: any) => state.auth.error?.response?.data?.message)
  const statusCodeError = useSelector((state: any) => state.auth.error?.response?.status)

  const [dataFormLogin, setDataFormLogin] = useState<DataFormType>(INIT_STATE);
  const [errors, setErrors] = useState<DataFormType>(INIT_STATE);
  const [generalError, setGeneralError] = useState('');
  const [showPass, setShowPass] = useState<boolean>(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const loadingLogin = useSelector((state: any) => state.auth.loading);

  const validate = async () => {
    try {
      await validationSchema.validate(dataFormLogin, { abortEarly: false });
      setErrors(INIT_STATE);
      return true;
    } catch (err: any) {
      const newErrors: DataFormType = { ...INIT_STATE };
      err.inner.forEach((error: any) => {
        newErrors[error.path as keyof DataFormType] = error.message;
      });
      setErrors(newErrors);
      return false;
    }
  };

  const login = async (e: any) => {
    e.preventDefault();
    if (await validate()) {
      const apiResponse = await dispatch(adminLogin({
        email: dataFormLogin.email,
        password: dataFormLogin.password
      }) as any);

      if (statusCodeError === 400) {
        setGeneralError(generalErrMsg)
      }

      if (apiResponse.data && apiResponse.code === statusCode.OK) {
        let userRole = apiResponse.data.user.role ?? ROLE_ADMIN;
        let url = state?.path || (userRole === ROLE_ADMIN ? ADMIN_REDIRECT_AFTER_LOGIN : SUPER_ADMIN_REDIRECT_AFTER_LOGIN);
        navigate(url);
      }
    }
  };

  const handleInputChange = (key: any, value: any) => {
    setDataFormLogin({
      ...dataFormLogin,
      [key]: value
    });
    setErrors({ ...errors, [key]: "" });
    setGeneralError('')
  };

  useEffect(() => {
    if (statusCodeError === 400) {
      setGeneralError(generalErrMsg)
    }
  }, [statusCodeError]);

  useEffect(() => {
    if (errorReducer) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        email: errorReducer?.email ?? prevErrors.email,
        password: errorReducer?.password ?? prevErrors.password,
      }));
    }
  }, [errorReducer]);

  const handleShowPass = () => {
    setShowPass(!showPass);
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      login(e);
    }
  };

  return (
    <>
      <Helmet>
        <title>{pageTitles.auth.login}</title>
      </Helmet>
      <Container maxWidth="sm" className={styles.containerFluid}>
        <Box>
          <Box className={styles.logo}>
            <img src={LOGO} alt="LOGO" />
          </Box>
          <Box className={styles.subTitle}>おかえりなさいませ。</Box>
          <Grid container spacing={1} sx={{ paddingTop: '1.8rem' }}>
            <Grid item xs={12} mt={2}>
              <Box component="form" onKeyDown={handleKeyDown}>
                <Grid item xs={12} mt={2} className={styles.inputContainer}>
                  <Box id={styles.labelEmail}>メールアドレス</Box>
                  <InputBase
                    name="email"
                    keyword="email"
                    errorText={errors?.email}
                    type="text"
                    className={styles.inputEmail}
                    handleChange={handleInputChange}
                    placeholder="example@example.com"
                    size="small"
                    value={dataFormLogin?.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={styles.inputAdornment}>
                          <Iconify icon="ic:baseline-email" width={24} />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.email && (
                    <Box className={styles.errorText}>{errors.email}</Box>
                  )}
                </Grid>
                <Grid item xs={12} mt={1} className={styles.inputContainer}>
                  <Box id={styles.labelPassword}>パスワード</Box>
                  <PasswordDefault
                    name="password"
                    id="password"
                    autoComplete="current-password"
                    errorText={errors?.password}
                    handleChange={handleInputChange}
                    setShowPass={handleShowPass}
                    showPass={showPass}
                    className={styles.inputPassword}
                    size="small"
                    value={dataFormLogin?.password}
                    placeholder="パスワードを入力してください"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start" className={styles.inputAdornmentPassword}>
                          <Iconify icon="material-symbols:lock" />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={handleShowPass} edge="end">
                            <Iconify icon={showPass ? 'mdi:eye-off' : 'mdi:eye'} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {errors?.password && (
                    <Box className={styles.errorText}>{errors.password}</Box>
                  )}
                </Grid>
              </Box>
              {generalError && (
                <Box className={styles.errorText}>{generalError}</Box>
              )}
            </Grid>

            <Grid item xs={12} className={styles.forgotPassword}>
              <Link href={paths.auth.adminForgotPassword}>パスワードを忘れた方はこちら</Link>
            </Grid>
            <Grid item xs={12}>
              {loadingLogin ? (
                <Stack flexDirection="row" className={styles.btnResetPassLoading} textAlign="center" justifyContent="center">
                  <CircularProgress size={25} />
                </Stack>
              ) : (
                <Button onClick={login} variant="contained" className={styles.btnLogin}>ログイン</Button>
              )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </>
  );
};

export default AdminLogin;
