import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { Typography, IconButton, Box, Stack, Popper } from "@mui/material";
import Iconify from "../../../components/iconify";
import styles from './styles.module.scss';
import { changeActiveAccount } from "../../../redux/actions/collector";
import { useDispatch, useSelector } from "react-redux";
import paths from "../../../constants/paths";
import {useNavigate} from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { DEACTIVE, ACTIVE } from "../../../constants";
import ConfirmDialog from "../../../components/dialog/confirm-dialog";

type Props = {
  row: any;
  index: any;
  actionNext: any;
  activePopup: string | null;
  setActivePopup: (id: string | null) => void;
  page: number
};


export default function CollectorTableRow({ row, index, actionNext, activePopup, setActivePopup, page }: Props) {
  const { id, email, full_name, tel, is_active } = row;
  const [openDialog, setOpenDialog] = useState(false);
  const [newStatus, setNewStatus] = useState(is_active);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const open = activePopup === id;
  const loadingEditCollector = useSelector((state: any) => state.collector.loading);

  const popperRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (popperRef.current && !popperRef.current.contains(event.target as Node)) {
      setActivePopup(null);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleChangeActive = async () => {
    const result = await dispatch(changeActiveAccount(id, { is_active: newStatus }) as any);
    if (result?.code === 200) {
      actionNext();
    }
    setOpenDialog(false);
  };

  const handleViewDetailCollector = () => {
    navigate(`${paths.admin.collectorDetail}/${id}`, { state: { backPage: page } });
  };

  const handleEditCollector = () => {
    navigate(`${paths.admin.editCollector}/${id}`, { state: { viewInfoFrom: false, backPage: page } });
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setActivePopup(open ? null : id);
  };

  const handleOpenDialog = (status: number) => {
    setNewStatus(status);
    setOpenDialog(true);
    setActivePopup(null);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  return (
    <TableRow hover sx={{ textAlign: "center" }}>
      <TableCell>
        <Typography className={styles.textCommon} variant="subtitle1">
          {index}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography className={`${styles.textCommon} ${styles.longText2}`} variant="subtitle1">
          {full_name}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography className={`${styles.textCommon} ${styles.longText2}`} variant="subtitle1">
          {email}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography className={styles.textCommon} variant="subtitle1">
          {tel}
        </Typography>
      </TableCell>

      <TableCell>
        <Typography className={styles.textCommon} variant="subtitle1">
          {is_active === 1 ? (<span className={styles.btnActive}>
            アクティブ
          </span>) : (<span className={styles.btnInActive}>
            無効
          </span>)}
        </Typography>
      </TableCell>

      <TableCell>
        <IconButton
          edge="start"
          onClick={handleClick}
          className={styles.optionCell}
          data-id={id}
        >
          <Iconify icon="ri:more-2-fill" />
        </IconButton>
        <Popper open={open} anchorEl={document.querySelector(`[data-id='${id}']`)} placement="bottom-start" ref={popperRef}>
          <Box className={styles.optionBoxAdminList}>
            <Stack onClick={handleEditCollector} flexDirection="row" alignItems="center" sx={{ p: 1, cursor: 'pointer' }}>
              <Iconify icon="ic:baseline-edit" width={20} color="#636B74" />
              <Box className={styles.optionText}>編集</Box>
            </Stack>


            <Stack onClick={handleViewDetailCollector} flexDirection="row" alignItems="center" sx={{ p: 1, cursor: 'pointer' }}>
              <Iconify icon="carbon:view-filled" width={20} color="#636B74" />
              <Box className={styles.optionText}>詳細</Box>
            </Stack>
            <Stack flexDirection="row" alignItems="center" sx={{ p: 1 }} alignContent="center">
              {is_active === 1 ? (
                <Box onClick={() => handleOpenDialog(DEACTIVE)} sx={{ display: 'flex', cursor: 'pointer', alignItems: "center" }}>
                  <Iconify icon="material-symbols:close" width={20} />
                  <Box className={styles.optionText}>無効にする</Box>
                </Box>
              ) : (
                <Box onClick={() => handleOpenDialog(ACTIVE)} sx={{ display: 'flex', cursor: 'pointer', alignItems: "center" }}>
                  <Iconify icon="material-symbols:circle-outline" width={20} />
                  <Box className={styles.optionText}>アクティブにする</Box>
                </Box>
              )}
            </Stack>
          </Box>
        </Popper>
      </TableCell>
      <ConfirmDialog
        title="変更の確認"
        content={newStatus === 1 ? "この収集スタッフを有効にしますか?" : "この収集スタッフを無効にしますか?"}
        open={openDialog}
        loading={loadingEditCollector}
        onClose={handleCloseDialog}
        onConfirm={handleChangeActive}
      />
    </TableRow>
  );
}
