/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Box, Button, Container, InputAdornment, Stack, Table, TableBody, TableContainer, Typography } from "@mui/material";
import HeaderAdmin from "../../../components/header/admin/header";
import styles from './styles.module.scss';
import Iconify from "../../../components/iconify";
import InputBase from "../../../components/input/inputBase";
import { useCallback, useEffect, useRef, useState } from "react";
import { TableEmptyRows, TableHeadCustom, TableNoData, emptyRows, useTable } from "../../../components/table";
import CollectorTableRow from "./table-row";
import ModalCreateAccountCollector from "../../../components/dialog/dialogCreateAccountCollector";
import { useDispatch, useSelector } from "react-redux";
import { getListCollector } from "../../../redux/actions/collector";
import { isEmpty } from "lodash";
import CustomPagination from "../../../components/pagination";
import { ITEMS_PER_PAGE, MEDIUM_WIDTH_SCREEN } from "../../../constants";
import { TableRowSkeleton } from "../../../components/skeleton/table-row";
import { getClientWidth, truncateText } from "../../../helpers";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import pageTitles from "../../../constants/pageTitles";

const AdminListCollector = () => {
  const location = useLocation();
  const { backPage } = location.state || { state: { backPage: 1 } }
  const [filters, setFilters] = useState({ search: '', orderBy: '', order: 'asc' });
  const [searchValue, setSearchValue] = useState('');
  const [openModalCreateAccountCollector, setOpenModalCreateAccountCollector] = useState<boolean>(false);
  const [pageCollector, setPageCollector] = useState(backPage ?? 1);
  const [loading, setLoading] = useState(true);
  const [activePopup, setActivePopup] = useState<string | null>(null);
  const [isBackToPage1, setIsBackToPage1] = useState(false);

  const handlePopupChange = (id: string | null) => {
    setActivePopup(id);
  };

  const dispatch = useDispatch();
  const dataListCollectors = useSelector((state: any) => state?.collector?.dataListCollectors?.data);
  const dataInfoUserLogin = useSelector((state: any) => state?.auth?.dataInfoUserLogin);
  const paginate = useSelector((state: any) => state?.collector?.dataListCollectors?.paginate);

  const handleInputChange = useCallback(
    (name: string, value: any) => {
      if (name === 'search') {
        setSearchValue(value);
      } else {
        setFilters((prevState: any) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }, [],
  );

  const handleSort = (field: string) => {
    setFilters((prevState) => ({
      ...prevState,
      orderBy: field,
      order: prevState.orderBy === field && prevState.order === 'asc' ? 'desc' : 'asc',
    }));
  };

  const buildQuery = () => {
    const query: any = {
      pages: pageCollector,
    };

    if (!isEmpty(filters.search)) {
      query.full_name = filters.search;
    }

    if (!isEmpty(filters.orderBy)) {
      query.orderBy = filters.orderBy;
      query.order = filters.order;
    }

    return query;
  };

  const handleSearchCollector = (isEmpty: boolean = false) => {
    if (isEmpty) {
      setSearchValue('');
      setFilters((prevState) => ({
        ...prevState,
        search: '',
      }));
    } else {
      setFilters((prevState) => ({
        ...prevState,
        search: searchValue,
      }));
    }
    setPageCollector(1);
  };

  useEffect(() => {
    setLoading(true)
    callApiGetList().then(() => {
      setLoading(false);
    });
  }, [pageCollector, filters]);

  const TABLE_HEAD = [
    { id: "id.", label: (<Box className={styles.textCommon}>No.</Box>), width: 80 },
    {
      id: "full_name",
      label: (
        <Stack direction="row" alignItems="center" className={`${styles.textCommon} ${styles.pointer}`} onClick={() => handleSort('full_name')}>
          <Box className={styles.titleStatus}>名前</Box>
          {filters.orderBy === 'full_name' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 220
    },
    {
      id: "email",
      label: (
        <Stack direction="row" alignItems="center" className={`${styles.textCommon} ${styles.pointer}`} onClick={() => handleSort('email')}>
          <Box className={styles.titleStatus}>メールアドレス</Box>
          {filters.orderBy === 'email' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 220
    },
    { id: "tel", label: (<Box className={styles.textCommon}>電話番号</Box>), width: 220 },
    {
      id: "is_active",
      label: (
        <Stack direction="row" alignItems="center" className={`${styles.textCommon} ${styles.pointer}`} onClick={() => handleSort('is_active')}>
          <Box className={styles.titleStatus}>ステータス</Box>
          {filters.orderBy === 'is_active' ? (
            filters.order === 'asc' ? (
              <Iconify icon="bi:sort-up" width={18} style={{ marginLeft: 8 }} />
            ) : (
              <Iconify icon="bi:sort-down" width={18} style={{ marginLeft: 8 }} />
            )
          ) : (
            <Iconify icon="bi:filter-left" width={18} style={{ marginLeft: 8 }} />
          )}
        </Stack>
      ),
      width: 220
    },
    { id: "", label: (<Box className={styles.textCommon}>その他</Box>), width: 80 },
  ];

  const table = useTable(
    {
      defaultRowsPerPage: 10,
      defaultCurrentPage: 1,
    }
  );

  const callApiGetList = () => dispatch(getListCollector(buildQuery()) as any);

  const renderSkeleton = (
    <>
      {[...Array(ITEMS_PER_PAGE)].map((_, index) => (
        <TableRowSkeleton key={index} rows={TABLE_HEAD.length} />
      ))}
    </>
  );

  const renderList = (
    <>
      {dataListCollectors?.map((row: any, index: any) => (
        <CollectorTableRow key={row.id} row={row} index={(pageCollector - 1) * 10 + index + 1} actionNext={callApiGetList} activePopup={activePopup} setActivePopup={handlePopupChange} page={pageCollector} />
      ))}
    </>
  );

  const notFound = !dataListCollectors?.length;
  const [isHasGeneralError, setIsHasGeneralError] = useState(false)

  const handleOpenModal = () => {
    setOpenModalCreateAccountCollector(true);
  };

  const ref = useRef<HTMLDivElement>(null);
  const [clientWidth, setClientWidth] = useState<number>(0);

  useEffect(() => {
    getClientWidth(ref, setClientWidth);
  }, []);


  useEffect(() => {
    callApiGetList();
  }, [pageCollector, filters]);

  useEffect(() => {
    if (isBackToPage1) {
      setPageCollector(1)
      setIsBackToPage1(false)
    }
  }, [isBackToPage1]);

  return (
    <>
      <Helmet>
        <title>{pageTitles.admin.collectorList}</title>
      </Helmet>
      <HeaderAdmin />
      <Container maxWidth={clientWidth < MEDIUM_WIDTH_SCREEN ? "xl" : "lg"} className={`h-100 ${styles.cardContainer}`} ref={ref}>
        {/* title */}
        <Stack alignItems="center" flexDirection="row" justifyContent="space-between">
          <Box>
            <Typography variant="h5" className={`${styles.titlePage} ${styles.textCommon}`}>収集スタッフ一覧</Typography>
            <Typography variant="subtitle1" className={`${styles.companyName} ${styles.textCommon}`}>{truncateText(dataInfoUserLogin?.company?.name, 30)}</Typography>
          </Box>
          <Button className={styles.btnAdd} onClick={handleOpenModal}>
            <Iconify icon="material-symbols:add" width={18.33} className={styles.iconAdd} />
            <Box className={`${styles.btnText} ${styles.textCommon}`}>収集スタッフ追加</Box>
          </Button>
        </Stack>

        {/* search */}
        <Stack sx={{ pt: 3 }} alignItems="center" flexDirection="row" justifyContent="space-between" gap={2} className={styles.searchContainer}>
          <InputBase
            name="search"
            keyword="search"
            type="text"
            className={styles.inputSearch}
            handleChange={handleInputChange}
            placeholder="検索"
            size="small"
            value={searchValue}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start" className={styles.inputAdornment}>
                  <Iconify icon="material-symbols:search" width={24} />
                </InputAdornment>
              ),
            }}
          />
          <Stack flexDirection="row" gap={1}>
            <Button className={styles.btnSearch} onClick={() => handleSearchCollector(false)}>
              <Typography className={styles.searchText}>検索</Typography>
            </Button>
            <Button className={styles.btnCancel} onClick={() => handleSearchCollector(true)}>
              <Typography className={styles.searchText}>クリア</Typography>
            </Button>
          </Stack>
        </Stack>

        {/* table */}
        <TableContainer className={styles.tableContainer}>
          <Table
            size="small"
            sx={{ minWidth: 960 }}
          >
            <TableHeadCustom
              className={styles.borderTableHeader}
              headLabel={TABLE_HEAD}
              rowCount={dataListCollectors?.length}
              numSelected={table.selected?.length}
            />

            <TableBody className={styles.borderTable}>
              {loading ? renderSkeleton : renderList}
              {dataListCollectors && !loading && (
                <>
                  <TableEmptyRows
                    height={56}
                    emptyRows={emptyRows(
                      table.page - 1,
                      table.rowsPerPage,
                      dataListCollectors.length
                    )}
                  />
                  <TableNoData notFound={notFound} />
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {dataListCollectors?.length > 0 && paginate?.total_page > 1 && (
          <CustomPagination
            paginate={paginate}
            page={pageCollector}
            disabled={paginate?.total_page === 1}
            setPage={setPageCollector}
          />
        )}
      </Container>

      <ModalCreateAccountCollector
        openModalCreateAccountCollector={openModalCreateAccountCollector}
        setOpenModalCreateAccountCollector={setOpenModalCreateAccountCollector}
        actionNext={callApiGetList}
        setIsBackToPage1={setIsBackToPage1}
        isHasGeneralError={isHasGeneralError}
        setIsHasGeneralError={setIsHasGeneralError}
      />
    </>
  )
}

export default AdminListCollector;
