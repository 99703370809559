import * as t from "../constants/index";

const initState: any = {
  loading: false,
  error: null,
  dataInfoUserLogin: null,
};

const auth = (state = initState, action: any) => {
  switch (action.type) {
    case t.ADMIN_LOGIN:
      return {
        ...state,
        [action.payload]: null,
        loading: true,
        error: null,
      };
    case t.DATA_USER_LOGIN:
      return {
        ...state,
        dataInfoUserLogin: action.payload,
        loading: true,
      };
    case t.CLEAR_DATA:
      return {
        ...state,
        [action.payload]: null,
        loading: false,
        error: null,
      };
    case t.LOGOUT:
      return {
        // ...state,
        dataInfoUserLogin: null,
        loading: false,
        error: null,
      };
    case t.LOADING:
      return {
        ...state,
        [action.key]: action.loading,
      };
    case t.CALL_API_SUCCESS:
      return {
        ...state,
        [action.loading]: false,
        error: null
      };
    case t.ERROR:
      return {
        ...state,
        error: action.payload,
        [action.loading]: false,
      };
    default:
      return { ...state };
  }
};

export default auth;
