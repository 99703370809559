import React from 'react';
import { Container, Button, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import styles from './styles.module.scss';

const Custom404Page = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <Container maxWidth="md" className={styles.container}>
      <Box className={styles.box}>
        <ErrorOutlineIcon color="error" className={styles.icon} />
        <Box className={styles.title}>
          404
        </Box>
        <Box className={styles.subtitle}>
          ページが見つかりません
        </Box>
        <Box className={styles.description}>
          お探しのページは削除されたか、名前が変更されたか、一時的に利用できない可能性があります。
        </Box>
        <Button onClick={handleGoHome} variant="contained" className={styles.btnBackToHome}>ホームページに戻る</Button>
      </Box>
    </Container>
  );
};

export default Custom404Page;
